// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#favourite-shipment {
  padding: 0px 24px;
}
#favourite-shipment .row-dashbored {
  padding: 20px 0px;
}
@media (max-width: 1600px) {
  #favourite-shipment {
    zoom: 1.3;
  }
}
#favourite-shipment .noShipmentFound-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
  font-size: 30px;
  color: rgb(108, 117, 125);
}
#favourite-shipment .noShipmentFound-subTitle {
  display: flex;
  justify-content: center;
  margin-top: 9px;
  color: rgb(108, 117, 125);
}`, "",{"version":3,"sources":["webpack://./src/Containers/FavouriteShipment/shipment.scss"],"names":[],"mappings":"AAAA;EAKE,iBAAA;AAHF;AADE;EACE,iBAAA;AAGJ;AAEE;EAPF;IAQI,SAAA;EACF;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,yBAAA;AAAJ","sourcesContent":["#favourite-shipment {\n  .row-dashbored {\n    padding: 20px 0px;\n  }\n\n  padding: 0px 24px;\n\n  @media (max-width: 1600px) {\n    zoom: 1.3;\n  }\n\n  .noShipmentFound-title {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 19px;\n    font-size: 30px;\n    color: rgb(108, 117, 125);\n  }\n\n  .noShipmentFound-subTitle {\n    display: flex;\n    justify-content: center;\n    margin-top: 9px;\n    color: rgb(108, 117, 125);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
