// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#successLoader-box {
  box-sizing: content-box;
}
@media (max-width: 575px) {
  #successLoader-box {
    padding: 24px 52px !important;
    word-break: break-word;
  }
}
#successLoader-box .loading-spinner {
  width: 3em;
  height: 3em;
  border: 0.3em solid;
  border-color: rgb(255, 255, 255) transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
  padding: 17px;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3215686275);
  width: 100% !important;
  height: 100%;
}

.bg-hide {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/Component/SuccessLoader/success.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAEE;EAJF;IAMI,6BAAA;IACA,sBAAA;EAAF;AACF;AAEE;EACE,UAAA;EACA,WAAA;EACA,mBAAA;EACA,4CAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,wCAAA;EACA,aAAA;AAAJ;AAEI;EACE;IACE,uBAAA;EAAN;EAGI;IACE,yBAAA;EADN;AACF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6CAAA;EACA,sBAAA;EACA,YAAA;AAJF;;AAOA;EACE,uBAAA;AAJF","sourcesContent":["#successLoader-box {\n  box-sizing: content-box;\n\n\n  @media (max-width: 575px) {\n    // width: 57% !important;\n    padding: 24px 52px !important;\n    word-break: break-word;\n  }\n\n  .loading-spinner {\n    width: 3em;\n    height: 3em;\n    border: 0.3em solid;\n    border-color: rgb(255, 255, 255) transparent;\n    border-radius: 50%;\n    display: inline-block;\n    box-sizing: border-box;\n    animation: rotation 1.5s linear infinite;\n    padding: 17px;\n\n    @keyframes rotation {\n      0% {\n        transform: rotate(0deg);\n      }\n\n      100% {\n        transform: rotate(360deg);\n      }\n    }\n  }\n}\n\n\n.bg-block {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #00000052;\n  width: 100% !important;\n  height: 100%;\n}\n\n.bg-hide {\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
