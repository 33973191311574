import React from "react";
import "./EximDottedLoader.scss";

const EximDottedLoader = () => {
  return (
    <div className="center-container">
      <div className="loader-cross">
        {Array.from({ length: 9 }).map((_, index) => (
          <div key={index} className="dot"></div>
        ))}
      </div>
    </div>
  );
};

export default EximDottedLoader;
