import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./CustomDataTable.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";

const CustomDataTable = ({
  data,
  header,
  pagination,
  handleSelectedShipments,
  handleDescendingAndAscendingOrder,
  isWorkSpace,
}) => {
  const rowHeight = 50;
  const [currentRowLength, setCurrentRowLength] = useState(25);
  const [gridHeight, setGridHeight] = useState(200);

  const handleGridHeightBasedOnRow = () => {
    if (data?.length === 0) {
      return setGridHeight(225);
    }
    if (data.length < 25) {
      const gridHeightDynamic = Math.min(data?.length * rowHeight, 5120) + 120;
      setGridHeight(gridHeightDynamic);
    }
  };

  useEffect(() => {
    handleGridHeightBasedOnRow();
  }, [data, rowHeight]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      const headerElement = document.querySelector(".ag-header");
      const scrollPosition = window.scrollY;
      const mediaQuery = window.matchMedia("(min-width: 1600px)");
      if (headerElement) {
        if (
          (!mediaQuery.matches && scrollPosition >= 238) ||
          (mediaQuery.matches && scrollPosition >= 320)
        ) {
          headerElement.style.position = "fixed";
          headerElement.style.top = isWorkSpace ? "2px" : "80px";
          headerElement.style.zIndex = "10000";
        } else {
          headerElement.style.position = "";
          headerElement.style.top = "";
          headerElement.style.zIndex = "";
          headerElement.style.backgroundColor = "";
          headerElement.style.boxShadow = "";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const gridOptions = useMemo(
    () => ({
      suppressCellFocus: true,
      suppressHeaderFocus: true,
      suppressCopyRowsToClipboard: true,
      suppressCopySingleCellRanges: true,
      suppressClipboardPaste: true,
      suppressAggFuncInHeader: true,
      suppressRowClickSelection: true,
      enableCellTextSelection: false,
      suppressClipboardApi: true,
      suppressContextMenu: true,
      getRowHeight: () => rowHeight,
    }),
    []
  );

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      checkboxes: false,
      headerCheckbox: false,
      enableClickSelection: false,
    };
  }, []);

  const DataTable = useCallback(() => {
    return (
      <AgGridReact
        key={JSON.stringify(data[0])}
        rowData={data}
        defaultColDef={defaultColDef}
        columnDefs={header}
        onRowSelected={({ node }) => {
          const isSelected = node.isSelected();
          handleSelectedShipments(isSelected, node.data._id);
        }}
        gridOptions={gridOptions}
        columnMenu={"legacy"}
        pagination={pagination}
        paginationPageSize={25}
        paginationPageSizeSelector={[25, 50, 100]}
        animateRows={true}
        columnHoverHighlight={false}
        statusBar={true}
        onPaginationChanged={(params) => {
          const currentPageSize = params.api.paginationGetPageSize();
          setCurrentRowLength(currentPageSize);
        }}
        onSortChanged={(params) => {
          const { colId, sort } = params.columns[0];
          handleDescendingAndAscendingOrder(colId, sort ?? "default");
        }}
        rowSelection={rowSelection}
      />
    );
  }, [
    header,
    data,
    defaultColDef,
    gridOptions,
    pagination,
    rowSelection,
    currentRowLength,
  ]);

  return (
    <>
      <div
        className="ag-theme-quartz"
        style={{
          width: "100%",
          height:
            data?.length >= 25
              ? currentRowLength === 25
                ? "1370px"
                : currentRowLength === 50
                ? "2622px"
                : "5120px"
              : `${gridHeight}px`,
          flex: 1,
          transition: "all 0.5s ease",
        }}
      >
        {DataTable()}
      </div>
    </>
  );
};

export default memo(CustomDataTable);
