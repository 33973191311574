// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e1e1e1;
  padding: 13px;
  max-width: 300px;
  max-height: 250px;
}
.info-card .save-query-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  background-color: #ddeaf1;
  color: black;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
}
.info-card .info-card-date {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
  color: #888;
}
.info-card .info-card-date .icon {
  margin-right: 5px;
  margin-top: 3.5px;
}
.info-card .info-card-details {
  margin-top: 2px;
  background-color: #f1f5f8;
  border-radius: 5px;
  padding: 5px 10px;
}
.info-card .info-card-details .save-query-card-details {
  list-style: disc !important;
  padding-left: 20px !important;
  margin: 5px 0 !important;
}
.info-card .info-card-details .save-query-card-details .save-query-li {
  margin: 5px 0;
  font-size: 0.9em;
  display: block !important;
}
.info-card .info-card-details .save-query-card-details .save-query-li .bullet_icon {
  font-size: 8px !important;
  margin-right: 10px !important;
}

.flex-x-y-axis-center {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Containers/DashboardNewUI/Component/SaveQueryCard/SaveQueryCard.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,wCAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,SAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AAAJ;AAEI;EACE,iBAAA;EACA,iBAAA;AAAN;AAIE;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;AAII;EACE,2BAAA;EACA,6BAAA;EACA,wBAAA;AAFN;AAIM;EACE,aAAA;EACA,gBAAA;EACA,yBAAA;AAFR;AAGQ;EACE,yBAAA;EACA,6BAAA;AADV;;AAQA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AALF","sourcesContent":[".info-card {\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n  border: 1px solid #e1e1e1;\n  padding: 13px;\n  max-width: 300px;\n  max-height: 250px;\n\n  .save-query-title {\n    margin: 0;\n    font-size: 16px;\n    font-weight: 600;\n    background-color: #ddeaf1;\n    color: black;\n    padding: 5px 10px 5px 10px;\n    border-radius: 5px;\n    text-transform: uppercase;\n  }\n\n  .info-card-date {\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n    font-size: 0.9em;\n    color: #888;\n\n    .icon {\n      margin-right: 5px;\n      margin-top: 3.5px;\n    }\n  }\n\n  .info-card-details {\n    margin-top: 2px;\n    background-color: #f1f5f8;\n    border-radius: 5px;\n    padding: 5px 10px;\n\n    .save-query-card-details {\n      list-style: disc !important;\n      padding-left: 20px !important;\n      margin: 5px 0 !important;\n\n      .save-query-li {\n        margin: 5px 0;\n        font-size: 0.9em;\n        display: block !important;\n        .bullet_icon {\n          font-size: 8px !important;\n          margin-right: 10px !important;\n        }\n      }\n    }\n  }\n}\n\n.flex-x-y-axis-center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
