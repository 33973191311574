// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region_button {
  border: 0.3px solid rgba(0, 0, 0, 0.275);
  margin: 0px 5px !important;
  color: #66686a !important;
  padding: 6px 8px !important;
  font-weight: 500 !important;
  font-size: 15px;
}
@media (max-width: 1350px) {
  .region_button {
    font-size: 10px !important;
  }
}
@media (max-width: 700px) {
  .region_button {
    margin-bottom: 8px !important;
  }
}

.active_region {
  border: none !important;
  color: #fff !important;
  background-color: #005d91 !important;
}

.deactivate_region {
  border: 1px solid rgba(0, 0, 0, 0.275) !important;
}`, "",{"version":3,"sources":["webpack://./src/Containers/ExploreCountryNewUI/components/CountryRegion.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,0BAAA;EACA,yBAAA;EACA,2BAAA;EACA,2BAAA;EACA,eAAA;AACF;AAAE;EAPF;IAQI,0BAAA;EAGF;AACF;AAFE;EAVF;IAWI,6BAAA;EAKF;AACF;;AAFA;EACE,uBAAA;EACA,sBAAA;EACA,oCAAA;AAKF;;AAHA;EACE,iDAAA;AAMF","sourcesContent":[".region_button {\n  border: 0.3px solid rgba(0, 0, 0, 0.275);\n  margin: 0px 5px !important;\n  color: #66686a !important;\n  padding: 6px 8px !important;\n  font-weight: 500 !important;\n  font-size: 15px;\n  @media (max-width: 1350px) {\n    font-size: 10px !important;\n  }\n  @media (max-width: 700px) {\n    margin-bottom: 8px !important;\n  }\n}\n\n.active_region {\n  border: none !important;\n  color: #fff !important;\n  background-color: #005d91 !important;\n}\n.deactivate_region {\n  border: 1px solid rgba(0, 0, 0, 0.275) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
