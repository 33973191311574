import React, { useEffect, useState } from "react";
import PowerBIReport from "../../Component/PowerBiDashBoard/PowerBiDashBoard";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import LinkInImage from "../../Asset/images/linkedin-icon.png";
import throwError from "../../utils/throwError";
import {
  MinDateForDatePicker,
  CurrentCheckedChart,
  SearchParams,
  TradeType,
} from "./../SummaryDetailsForTrade/SummartDetails.enum";
import {
  divideDateRange,
  getCookie,
  getDateExpressionUsingDividedDateRange,
} from "../../utils/CommanFunction";
import { Box } from "@mui/material";
import DateRangePicker from "../../Component/DateRangePicker/DateRangePicker";
// import ToggleSwitch from "../../Component/ToggleSwitch/ToggleSwitch";
import ToggleSwitchIe from "../../Component/ToggleSwitch/ToggleSwitchIe";
import { ButtonComponent } from "../../Component/ButtonComponent/Button";
import {
  Fetching_Summary_Data,
  viewSummaryLimit,
} from "../../utils/LoadingMsg";
import ErrorData from "../../utils/ErrorHandling";
import {
  getPowerBi,
  getPowerBiDashBoardForViewSummary,
  getUserSearchCount,
  salesActivityTrack,
} from "../../Services/service";
import PercentageLoader from "../../Component/PercentageLoader/LoaderWithPercentage";
import { logger } from "../../Logger/Logger";
function SummaryDetailsForTradePowerBi() {
  const search = useLocation().search;
  const tradeType = new URLSearchParams(search).get(SearchParams?.tradeType);
  const searchField =
    tradeType === TradeType?.IMPORT ? "IMPORTER_NAME" : "EXPORTER_NAME";
  const country = new URLSearchParams(search).get(SearchParams?.country);
  const dataEndDate = new URLSearchParams(search).get(
    SearchParams?.dataEndDate
  );
  const searchTerm =
    tradeType === TradeType?.IMPORT
      ? new URLSearchParams(search).get(SearchParams?.IMPORTER_NAME)
      : new URLSearchParams(search).get(SearchParams?.EXPORTER_NAME);
  const taxonomy_id = new URLSearchParams(search).get(
    SearchParams?.taxonomy_id
  );
  const currentStartDate = new URLSearchParams(search).get(
    SearchParams?.startDate
  );
  const currentEndDate = new URLSearchParams(search).get(SearchParams?.endDate);

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [currentChecked, setCurrentChecked] = useState(
    tradeType === TradeType?.IMPORT
      ? CurrentCheckedChart?.IMPORT_CHARTS
      : CurrentCheckedChart?.EXPORT_CHARTS
  );
  const [allSummaryData, setAllSummaryData] = useState({});

  //powerbi dashboard
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [powerBiDashBoard, setPowerBiDashBoard] = useState({});
  const [powerBiModelOpen, setPowerBiModelOpen] = useState(false);
  const [showLimitOverError, setShowLimitShowError] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getSearchCount(true);
  }, []);

  const getSearchCount = (isFirstTime) => {
    if (isFirstTime) {
      setDateRange({
        startMonthDate: currentStartDate,
        endMonthDate: currentEndDate,
        maxDateForEndMonth: currentEndDate,
        minDateForDatePicker: MinDateForDatePicker,
      });
    } else {
      setDateRange({
        startMonthDate:
          currentStartDate === dateRange?.startMonthDate
            ? currentStartDate
            : dateRange?.startMonthDate,
        endMonthDate:
          currentEndDate === dateRange?.endMonthDate
            ? currentEndDate
            : dateRange?.endMonthDate,
        maxDateForEndMonth: currentEndDate,
        minDateForDatePicker: MinDateForDatePicker,
      });
    }
    const payload = {
      tradeType,
      country,
      searchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange: {
        startDate: currentStartDate,
        endDate: currentEndDate,
      },
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      payload.dateExpraession = dateExpraession;
    }
    payload.account_id = getCookie("accountId");
    getUserSearchCount(payload)
      .then((e) => {
        if (e?.status === 200) {
          if (e?.data && Object.keys(e?.data).length > 0) {
            if (e?.data?.updatedSummaryLimit) {
              if (e?.data?.updatedSummaryLimit?.max_summary_limit) {
                if (
                  e?.data?.updatedSummaryLimit?.max_summary_limit
                    ?.remaining_limit <= 0
                ) {
                  setAllSummaryData(
                    e?.data?.updatedSummaryLimit?.max_summary_limit
                  );
                  setError(true);
                  setErrorMsg(viewSummaryLimit);
                } else {
                  setAllSummaryData(
                    e?.data?.updatedSummaryLimit?.max_summary_limit
                  );
                  getPowerBiDashBoardDetails(payload);
                }
              }
            }
          }
        } else {
          throw throwError(e);
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  const catchBlock = (err) => {
    const errorForLogger = `View Summary PowerBi :- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  const getPowerBiDashBoardDetails = (searchPayload) => {
    setDashboardLoading(true);
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + 5 <= 98 ? prevProgress + 5 : 98
      );
    }, 1000);
    searchPayload.isViewSummary = true;
    searchPayload.account_id = getCookie("accountId");
    searchPayload.isViewSummary = true;
    searchPayload.account_id = getCookie("accountId");
    getPowerBiDashBoardForViewSummary(searchPayload)
      .then((e) => {
        if (e?.status === 200) {
          let obj = e?.data;
          if (e?.data?.accessToken && e?.data?.datasetsId) {
            const payload = {
              datasets: [
                {
                  id: e?.data?.datasetsId,
                },
              ],

              reports: [
                {
                  allowEdit: false,
                  id: e?.data?.reportId,
                },
              ],
            };
            getPowerBi(payload, e?.data?.accessToken)
              .then((res) => {
                if (res.status === 200) {
                  obj.token = res?.data?.token;
                  setPowerBiDashBoard(obj);
                  setProgress(100);
                  setTimeout(() => {
                    clearInterval(interval);
                    setProgress(0);
                  }, 2000);
                  setDashboardLoading(false);
                  setPowerBiModelOpen(true);
                  setPowerBiModelOpen(true);
                } else {
                  throw throwError(res);
                }
              })
              .catch((err) => {
                setDashboardLoading(false);
                setProgress(0);
                setErrorForDashboard(err);
              });
          } else {
            setDashboardLoading(false);
            setProgress(0);
            setLoading(false);
            setPowerBiModelOpen(true);
          }
        } else {
          setDashboardLoading(false);
          setProgress(0);
          setLoading(false);
          throw throwError(e);
        }
      })
      .catch((err) => {
        setDashboardLoading(false);
        setProgress(0);
        setErrorForDashboard(err);
      });
    const activityPayload = {
      country: searchPayload?.country,
      tradeType: searchPayload?.tradeType,
    };

    const salesTrackPayload = {
      activityType: "POWERBI_REPORT",
      activityPayload: activityPayload,
      user_id: getCookie("user_id"),
      account_id: getCookie("accountId"),
    };
    salesActivityTrack(salesTrackPayload);
  };

  // useEffect(() => {
  //   setDateRange({
  //     startMonthDate: currentStartDate,
  //     endMonthDate: currentEndDate,
  //     maxDateForEndMonth: currentEndDate,
  //     minDateForDatePicker: MinDateForDatePicker,
  //   });
  //   const payload = {
  //     tradeType,
  //     country,
  //     searchField,
  //     searchTerm: searchTerm.split("_").join(" "),
  //     dateRange: {
  //       startDate: currentStartDate,
  //       endDate: currentEndDate,
  //     },
  //     taxonomy_id,
  //   };
  //   const dividedDateRange = divideDateRange(
  //     currentStartDate,
  //     currentEndDate,
  //     dataEndDate
  //   );
  //   if (dividedDateRange) {
  //     const dateExpraession =
  //       getDateExpressionUsingDividedDateRange(dividedDateRange);
  //     payload.dateExpraession = dateExpraession;
  //   }
  //   getPowerBiDashBoardDetails(payload);
  //   getSearchCount(true);
  // }, []);

  const handleChangeSwitch = (e) => {
    let checkedChart = "";
    if (currentChecked === CurrentCheckedChart?.IMPORT_CHARTS) {
      setCurrentChecked(CurrentCheckedChart?.EXPORT_CHARTS);
      checkedChart = CurrentCheckedChart?.EXPORT_CHARTS;
    } else {
      setCurrentChecked(CurrentCheckedChart?.IMPORT_CHARTS);
      checkedChart = CurrentCheckedChart?.IMPORT_CHARTS;
    }
    const ChangedSearchField =
      checkedChart === CurrentCheckedChart?.IMPORT_CHARTS
        ? "IMPORTER_NAME"
        : "EXPORTER_NAME";
    const ChangedTradeType =
      checkedChart === CurrentCheckedChart?.IMPORT_CHARTS
        ? TradeType?.IMPORT
        : TradeType?.EXPORT;
    const payload = {
      tradeType: ChangedTradeType,
      country,
      searchField: ChangedSearchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange,
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      payload.dateExpraession = dateExpraession;
    }
    setPowerBiModelOpen(false);
    getPowerBiDashBoardDetails(payload);
    getSearchCount();
  };

  const searchSummary = () => {
    const payload = {
      tradeType,
      country,
      searchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange,
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      payload?.dateRange?.startMonthDate ?? currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      payload.dateExpraession = dateExpraession;
    }
    setPowerBiModelOpen(false);
    getPowerBiDashBoardDetails(payload);
    getSearchCount();
  };

  const handleLinkedInPopup = () => {
    var left = windowDimensions.width / 2 - 800 / 2;
    var top = windowDimensions.height / 2 - 500 / 2;
    window.open(
      `https://cse.google.com/cse?cx=124f513d8bf3a0c39&q=${searchTerm
        .split("_")
        .join(" ")}`,
      searchTerm.split("_").join(" "),
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        800 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const setErrorForDashboard = (err) => {
    const errorForLogger = `View Summary PowerBi :- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };

  return (
    <div>
      <div id="summary-trade">
        {loading && (
          <Loading title={loadingMsg.title} content={loadingMsg.content} />
        )}
        {dashboardLoading && <PercentageLoader value={progress} />}

        {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
        <div className="paddingAll">
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <label className="mb-5 lable-position">Date Range</label>

                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Box>

                <Box item style={{ marginTop: "23px", width: "200px" }}>
                  <ButtonComponent
                    name={"search"}
                    btnIcon={SearchIcon}
                    style={{ marginLeft: "10px" }}
                    btnClick={searchSummary}
                  />
                </Box>
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                {/* <div>
                  <label className="switch">
                    <input type="checkbox" id="togBtn" onChange={handleChangeSwitch} />
                    <div className="slider round">
                      <span className="on text-center">{currentChecked}</span>
                    </div>
                  </label>
                </div> */}
                <ToggleSwitchIe
                  onChange={handleChangeSwitch}
                  currentChecked={currentChecked}
                />
                <div>
                  <img
                    src={LinkInImage}
                    alt="LinkIn Logo"
                    width={50}
                    height={50}
                    style={{ cursor: "pointer" }}
                    onClick={handleLinkedInPopup}
                  />
                </div>
              </div>
            </div>
            <div className="left-count-alert">
              You have Consumed{" "}
              <span style={{ color: "red" }} className="notranslate">
                {allSummaryData?.alloted_limit -
                  allSummaryData?.remaining_limit}
              </span>{" "}
              and Left with{" "}
              <span style={{ color: "green" }} className="notranslate">
                {allSummaryData?.remaining_limit}
              </span>{" "}
              Summary Limit
            </div>
          </Box>
        </div>
      </div>
      {powerBiModelOpen && (
        <PowerBIReport
          isViewSummary={true}
          powerBiDashBoard={powerBiDashBoard}
        />
      )}
    </div>
  );
}

export default SummaryDetailsForTradePowerBi;
