// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#detailsComponent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#detailsComponent .workspace-page-title {
  display: flex;
  margin: auto;
}
@media (max-width: 1000px) {
  #detailsComponent .workspace-page-title {
    margin: 10px;
    margin-bottom: 30px;
  }
}
#detailsComponent .workspace-page-title .sub-title {
  font-size: 1.2rem !important;
  padding-right: 3px;
}
#detailsComponent .workspace-page-title .font-style {
  font-size: 1.4rem;
  position: relative;
  bottom: 1px;
  left: 4px;
}
#detailsComponent #details-container {
  padding: 10px 0px;
  background: white;
  display: flex;
  align-items: center;
}
#detailsComponent #details-container #filter-title {
  margin-right: 15px;
  color: #005d91;
}
#detailsComponent #details-container .line {
  height: 30px;
  background: linear-gradient(265deg, rgb(255, 255, 255) 1%, rgb(0, 93, 145) 50%, rgb(255, 255, 255) 99%) !important;
  width: 1px;
  margin-right: 15px;
}
#detailsComponent #details-container .details {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 770px) {
  #detailsComponent {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/Containers/Workspace/Component/Details/details.scss"],"names":[],"mappings":"AAAA;EAKE,aAAA;EACA,8BAAA;EACA,mBAAA;AAHF;AAKE;EACE,aAAA;EACA,YAAA;AAHJ;AAKI;EAJF;IAKI,YAAA;IACA,mBAAA;EAFJ;AACF;AAII;EACE,4BAAA;EACA,kBAAA;AAFN;AAKI;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AAHN;AAOE;EACE,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AALJ;AAOI;EACE,kBAAA;EACA,cAAA;AALN;AAQI;EACE,YAAA;EACA,kHAAA;EAMA,UAAA;EACA,kBAAA;AAXN;AAcI;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;AAZN;;AAiBA;EACE;IACE,cAAA;EAdF;AACF","sourcesContent":["#detailsComponent {\n  // position: sticky;\n  // top: 0px;\n  // z-index: 2;\n  // background-color: white;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .workspace-page-title {\n    display: flex;\n    margin: auto;\n\n    @media (max-width: 1000px) {\n      margin: 10px;\n      margin-bottom: 30px;\n    }\n\n    .sub-title {\n      font-size: 1.2rem !important;\n      padding-right: 3px;\n    }\n\n    .font-style {\n      font-size: 1.4rem;\n      position: relative;\n      bottom: 1px;\n      left: 4px;\n    }\n  }\n\n  #details-container {\n    padding: 10px 0px;\n    background: white;\n    display: flex;\n    align-items: center;\n\n    #filter-title {\n      margin-right: 15px;\n      color: #005d91;\n    }\n\n    .line {\n      height: 30px;\n      background: linear-gradient(\n        265deg,\n        rgb(255, 255, 255) 1%,\n        rgb(0, 93, 145) 50%,\n        rgb(255, 255, 255) 99%\n      ) !important;\n      width: 1px;\n      margin-right: 15px;\n    }\n\n    .details {\n      display: flex;\n      justify-content: space-between;\n      flex-wrap: nowrap;\n    }\n  }\n}\n\n@media (max-width: 770px) {\n  #detailsComponent {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
