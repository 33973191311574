import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./ViewMore.scss";
import { Box } from "@mui/system";
import {
  _headerTitle_countryAndPort,
  _headerTitle_importAndExport,
} from "../../CollapseFilter";
import Zoom from "@mui/material/Zoom";
import { useState, useEffect } from "react";
import EximDottedLoader from "../../../EximDottedLoader/EximDottedLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const filterHeaderTitle = [
  "HS_CODE",
  "DATATYPE",
  "FILTER_HS_CODE_PRICE_QUANTITY",
  "MONTH",
  "SEARCH MODE",
];

const filterUiSelect_mode = "SEARCH_MODE";
const removeOrderAndNullFilter = "REMOVE_ORDER_OR_NULL";
const filterQauntityOrPriceUI = ["QUANTITY", "PRICE"];
const filterValueOrShipmentUI = ["VALUE", "SHIPMENT"];

export default function ViewMoreModal({
  open,
  setOpen,
  headerTitle,
  hsCodeOrMonthUI,
  countryOrPortUI,
  setshowItems,
  ImporterAndExporterUI,
  selectMode,
  removeOrderAndNull,
  valueOrShipmentUI,
  qauntityOrPriceUI,
  handleToggleFilter,
}) {
  const [contentVisible, setContentVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setshowItems(10);
    handleToggleFilter(headerTitle, false, true);
  };

  useEffect(() => {
    if (open) {
      setContentVisible(true);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [open]);

  const renderContent = () => {
    if (loading) {
      return <EximDottedLoader />;
    }

    if (filterHeaderTitle?.includes(headerTitle)) {
      return hsCodeOrMonthUI("VIEW_MORE");
    }
    if (_headerTitle_countryAndPort.includes(headerTitle)) {
      return countryOrPortUI("VIEW_MORE");
    }
    if (_headerTitle_importAndExport.includes(headerTitle)) {
      return ImporterAndExporterUI("VIEW_MORE");
    }
    if (headerTitle === filterUiSelect_mode) {
      return selectMode();
    }
    if (filterQauntityOrPriceUI?.includes(headerTitle)) {
      return qauntityOrPriceUI();
    }
    if (filterValueOrShipmentUI?.includes(headerTitle)) {
      return valueOrShipmentUI();
    }
    if (headerTitle === removeOrderAndNullFilter) {
      return removeOrderAndNull();
    }

    return null;
  };

  return (
    <div id="modal">
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Zoom}
        PaperProps={{
          style: {
            maxWidth: "1250px !important",
            minWidth: "1250px !important",
            borderRadius: "5px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            fontWeight: 600,
            letterSpacing: "0.6px",
          }}
        >
          {headerTitle.split("_").join(" ")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {contentVisible && renderContent()}
        </DialogContent>
      </Dialog>
    </div>
  );
}
