// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-summary-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #005d91;
  border-radius: 5px;
  align-items: center;
  width: 7.5rem;
  justify-content: center;
  color: #005d91;
  margin: 0px 5px;
  font-size: 0.9rem;
  height: 48px;
  margin-top: 14px;
}

.box-top {
  display: flex;
}

.box-down {
  margin-top: 4px;
}

.line {
  height: 1px;
  background: linear-gradient(96deg, rgb(255, 255, 255) 1%, rgb(0, 93, 145) 50%, rgb(255, 255, 255) 99%) !important;
  width: 60%;
}

.summary-container {
  display: flex;
  width: 100%;
  gap: 1%;
}
.summary-container .main-container-workSpace {
  display: flex;
  flex-direction: row;
  border: 1px solid #005d91;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  color: #005d91;
  font-size: 1rem;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
}
.summary-container .box-top-workSpace {
  display: flex;
}
.summary-container .box-down-workSpace {
  display: flex;
  flex-direction: row;
}
.summary-container .line-workSpace {
  margin-right: 10px;
  background: linear-gradient(96deg, rgb(255, 255, 255) 1%, rgb(0, 93, 145) 50%, rgb(255, 255, 255) 99%) !important;
  width: 2px;
}`, "",{"version":3,"sources":["webpack://./src/Containers/MarketPlace/Component/MarketSummary.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;EACA,iHAAA;EAMA,UAAA;AAJF;;AAOA;EACE,aAAA;EACA,WAAA;EACA,OAAA;AAJF;AAME;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAJJ;AAOE;EACE,aAAA;AALJ;AAQE;EACE,aAAA;EACA,mBAAA;AANJ;AASE;EACE,kBAAA;EAEA,iHAAA;EAMA,UAAA;AAbJ","sourcesContent":[".main-summary-container {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #005d91;\n  border-radius: 5px;\n  align-items: center;\n  width: 7.5rem;\n  justify-content: center;\n  color: #005d91;\n  margin: 0px 5px;\n  font-size: 0.9rem;\n  height: 48px;\n  margin-top: 14px;\n}\n\n.box-top {\n  display: flex;\n}\n\n.box-down {\n  margin-top: 4px;\n}\n\n.line {\n  height: 1px;\n  background: linear-gradient(\n    96deg,\n    rgba(255, 255, 255, 1) 1%,\n    rgba(0, 93, 145, 1) 50%,\n    rgba(255, 255, 255, 1) 99%\n  ) !important;\n  width: 60%;\n}\n\n.summary-container {\n  display: flex;\n  width: 100%;\n  gap: 1%;\n\n  .main-container-workSpace {\n    display: flex;\n    flex-direction: row;\n    border: 1px solid #005d91;\n    border-radius: 5px;\n    align-items: center;\n    justify-content: space-between;\n    color: #005d91;\n    font-size: 1rem;\n    width: 100%;\n    height: 45px;\n    padding: 0px 10px;\n  }\n\n  .box-top-workSpace {\n    display: flex;\n  }\n\n  .box-down-workSpace {\n    display: flex;\n    flex-direction: row;\n  }\n\n  .line-workSpace {\n    margin-right: 10px;\n    // height: 50%;\n    background: linear-gradient(\n      96deg,\n      rgba(255, 255, 255, 1) 1%,\n      rgba(0, 93, 145, 1) 50%,\n      rgba(255, 255, 255, 1) 99%\n    ) !important;\n    width: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
