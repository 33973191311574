export const INDIA_EXPORT_COLUMN_NAME = {
  // FOUR_DIGIT: "FOUR_DIGIT",
  EXP_DATE: "DATE",
  HS_CODE: "HS_CODE",
  PRODUCT_DESCRIPTION: "GOODS_DESCRIPTION",
  HS_CODE_DESCRIPTION: "HS_CODE_DESCRIPTION",
  QUANTITY: "QUANTITY",
  UNIT: "UNIT",
  ITEM_RATE_INV: "ITEM_PRICE_INV",
  CURRENCY: "CURRENCY",
  TOTAL_AMOUNT_INV_FC: "TOTAL_PRICE_INV_FC",
  FOB_INR: "FOB_INR",
  ITEM_RATE_INR: "UNIT_PRICE_INR",
  FOB_USD: "FOB_USD",
  USD_EXCHANGE_RATE: "EXCHANGE_RATE_USD",
  FOREIGN_PORT: "DESTINATION_PORT",
  COUNTRY: "COUNTRY",
  INDIAN_PORT: "INDIAN_PORT",
  IEC: "IEC",
  EXPORTER_NAME: "EXPORTER",
  ADDRESS: "ADDRESS",
  CITY: "CITY",
  PIN: "PIN",
  STATE: "STATE",
  BUYER_NAME: "CONSIGNEE_NAME",
  BUYER_ADDRESS: "CONSIGNEE_ADDRESS",
  INVOICE_NO: "INVOICE_NO",
  CUSH: "PORT_CODE",
  ITEM_NO: "ITEM_NO",
  DRAWBACK: "DRAWBACK",
  STD_QUANTITY: "STD_QUANTITY",
  STD_UNIT: "STD_UNIT",
  STD_ITEM_RATE_INR: "STD_ITEM_RATE_INR",
  STD_ITEM_RATE_INV: "STD_ITEM_RATE_USD",
  DECLARATION_NO: "DECLARATION_NO",
  CHA_NAME: "CHA_NAME",
};
export const INDIA_IMPORT_COLUMN_NAME = {
  HS_CODE: "HS_CODE",
  IMP_DATE: "DATE",
  PRODUCT_DESCRIPTION: "GOODS_DESCRIPTION",
  HS_CODE_DESCRIPTION: "HS_CODE_DESCRIPTION",
  TOTAL_ASSESS_USD: "TOTAL_VALUE_USD",
  TOTAL_ASSESSABLE_VALUE_INR: "TOTAL_VALUE_INR",
  IMPORTER_NAME: "IMPORTER",
  SUPPLIER_NAME: "SUPPLIER",
  UNIT: "UNIT",
  QUANTITY: "QUANTITY",
  ADDRESS: "ADDRESS",
  APPRAISING_GROUP: "APPRAISING_GROUP",
  DECLARATION_NO: "DECLARATION_NO",
  CHA_NAME: "CHA_NAME",
  CHA_NO: "CHA_NO",
  CITY: "CITY",
  CUSH: "PORT_CODE",
  IEC: "IEC",
  INDIAN_PORT: "INDIAN_PORT",
  INVOICE_CURRENCY: "INVOICE_CURRENCY",
  INVOICE_NO: "INVOICE_NO",
  INVOICE_UNITPRICE_FC: "INVOICE_UNITPRICE_FC",
  ORIGIN_COUNTRY: "COUNTRY_OF_ORIGIN",
  PORT_OF_SHIPMENT: "LOADING_PORT",
  RECORDS_TAG: "RECORDS_TAG",
  SUPPLIER_ADDRESS: "SUPPLIER_ADDRESS",
  TOTAL_DUTY_PAID: "DUTY_PAID_INR",
  TYPE: "BE_TYPE",
  UNIT_PRICE_USD: "UNIT_PRICE_USD",
  UNIT_VALUE_INR: "UNIT_PRICE_INR",
  STD_QUANTITY: "STD_QUANTITY",
  STD_UNIT: "STD_UNIT",
  STD_UNIT_PRICE_USD: "STD_UNIT_PRICE_USD",
  STD_UNIT_VALUE_INR: " STD_UNIT_VALUE_INR",
  PIN: "PIN",
  STATE: "STATE",
  // STD_SUPPLIER_NAME: "STD_SUPPLIER_NAME",
  // STD_SUPPLIER_ADDRESS: "STD_SUPPLIER_ADDRESS",
};

export const INDIA_ANALYZE_IMPORT_HEADER = [
  { key: "HS_CODE", title: "HS CODE" },
  { key: "ORIGIN_COUNTRY", title: "COUNTRY OF ORIGIN" },
  { key: "INDIAN_PORT", title: "INDIAN PORT" },
  { key: "PORT_OF_SHIPMENT", title: "LOADING PORT" },
  { key: "SUPPLIER_NAME", title: "SUPPLIER" },
  { key: "IMPORTER_NAME", title: "IMPORTER" },
  { key: "IEC", title: "IEC" },
  { key: "CHA_NO", title: "CHA NAME" },

  { key: "TOTAL_DUTY_PAID", title: "DUTY PAID INR" },
  { key: "TOTAL_ASSESS_USD", title: "TOTAL VALUE USD" },
  { key: "QUANTITY", title: "QUANTITY" },
  { key: "TOTAL_ASSESSABLE_VALUE_INR", title: "TOTAL VALUE INR" },
];

export const INDIA_ANALYZE_EXPORT_HEADER = [
  { key: "HS_CODE", title: "HS CODE" },
  { key: "COUNTRY", title: "COUNTRY" },
  { key: "EXPORTER_NAME", title: "EXPORTER" },
  { key: "INDIAN_PORT", title: "INDIAN PORT" },
  { key: "IEC", title: "IEC" },
  { key: "BUYER_NAME", title: "CONSIGNEE NAME" },
  { key: "FOREIGN_PORT", title: "DESTINATION PORT" },

  { key: "QUANTITY", title: "QUANTITY" },
  { key: "DRAWBACK", title: "DRAWBACK" },
  { key: "FOB_USD", title: "FOB USD" },
  { key: "FOB_INR", title: "FOB INR" },
];

// don't remove this, because it's getting used.
export const STATIC_COLUMNS_IMPORT = [
  "TOTAL VALUE INR",
  "DUTY PAID INR",
  "TOTAL VALUE USD",
  "QUANTITY",
];
export const STATIC_COLUMNS_CHART_IMPORT = [
  { key: "TOTAL_DUTY_PAID", title: "DUTY PAID INR" },
  { key: "TOTAL_ASSESS_USD", title: "TOTAL VALUE USD" },
  { key: "QUANTITY", title: "QUANTITY" },
  { key: "TOTAL_ASSESSABLE_VALUE_INR", title: "TOTAL VALUE INR" },
];
export const STATIC_COLUMNS_CHART_EXPORT = [
  { key: "QUANTITY", title: "QUANTITY" },
  { key: "DRAWBACK", title: "DRAWBACK" },
  { key: "FOB_USD", title: "FOB USD" },
  { key: "FOB_INR", title: "FOB INR" },
];
// don't remove this, because it's getting used.
export const STATIC_COLUMNS_EXPORT = [
  "QUANTITY",
  "FOB USD",
  "FOB INR",
  "DRAWBACK",
];
