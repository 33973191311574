import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import Explore from "./explore";
import { logger } from "../../Logger/Logger";
import { useAppContext } from "../../Contexts/AppContext";
import { GETDATAFROMURL } from "./Explore.enum";
import { useLocation, useNavigate } from "react-router";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RequestCompanyModal from "../ExploreCountryNewUI/components/RequestCompanyModal";
import ModalComponent from "../../Component/Modal/modal";

const ExploreWithError = (props) => {
  const {
    defaultCountries,
    importLoading,
    exportLoading,
    silkLoading,
    blImportLoading,
    blExportLoading,
  } = useAppContext();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const tradeType = queryParams.get(GETDATAFROMURL?.tradeType);
  const blFlag = queryParams.get(GETDATAFROMURL?.blFlag);
  const country = queryParams.get(GETDATAFROMURL?.country);
  const navigate = useNavigate();
  const [requestForCountryModal, setRequestForCountryModal] = useState(false);

  useEffect(() => {
    if (
      !importLoading &&
      !exportLoading &&
      !silkLoading &&
      !blImportLoading &&
      !blExportLoading
    ) {
      const isBlFlagFalse = blFlag === "false";
      const isImport = tradeType === "IMPORT";
      const isSilkRoute = tradeType === "EXIM";
      const countryList = isSilkRoute
        ? defaultCountries?.silkRoute
        : isBlFlagFalse
        ? isImport
          ? defaultCountries?.importCountries
          : defaultCountries?.exportCountries
        : isImport
        ? defaultCountries?.blImportCountries
        : defaultCountries?.blExportCountries;
      if (!countryList?.includes(country?.split(" ").join("_").toUpperCase())) {
        setRequestForCountryModal(true);
      }
    }
  }, [
    defaultCountries,
    importLoading,
    exportLoading,
    silkLoading,
    blImportLoading,
    blExportLoading,
  ]);

  const handleOkayClick = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    } else {
      setRequestForCountryModal(false);
      navigate("/countries?tradeType=all");
    }
  };

  const handleTicketManager = () => {
    navigate("/ticket-manager", {
      state: {
        countryName: country,
        tradeType: tradeType,
        upgradePlan: true,
      },
    });
  };

  const countryUpgradeModal = () => {
    return (
      <ModalComponent
        open={requestForCountryModal}
        setOpen={setRequestForCountryModal}
        modalBody={
          <RequestCompanyModal
            headerIcon={<LockOpenIcon style={{ fontSize: "30px" }} />}
            headerTitle={`Unlock ${country} Trade Insights`}
            onClick={() => handleTicketManager()}
            handleCancel={handleOkayClick}
            countryName={country}
          />
        }
        onClose={handleOkayClick}
        width="550px"
        isUpgradePlan={true}
        isFromExploreUrl={true}
      />
    );
  };
  function ErrorHandler({ error }) {
    logger(`Explore ${error?.message}`);
    return (
      <div role="alert">
        {error && <ErrorPopup errorMsg={error.message} open={error} />}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        {requestForCountryModal && countryUpgradeModal()}
        <Explore
          CLIENT_ACCOUNT_ID={props.CLIENT_ACCOUNT_ID}
          CLIENT_USER_ID={props.CLIENT_USER_ID}
        />
      </ErrorBoundary>
    </>
  );
};

export default ExploreWithError;
