import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./summaryDetailsForTrade.scss";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import LinkInImage from "../../Asset/images/linkedin-icon.png";
import { getSummaryDetailsForTrade } from "../../Services/service";
import { Box, Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsDrillDownChart from "highcharts/modules/drilldown";
import HighchartsNetworkgraph from "highcharts/modules/networkgraph";
import Sankey from "highcharts/modules/sankey";
import arcDiagram from "highcharts/modules/arc-diagram";
import Accessibility from "highcharts/modules/accessibility";
import highcharts3d from "highcharts/highcharts-3d";
import moment from "moment";
import { useRef } from "react";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import { Fetching_Summary_Data } from "../../utils/LoadingMsg";
import { ButtonComponent } from "../../Component/ButtonComponent/Button";
import SearchIcon from "@mui/icons-material/Search";
import DateRangePicker from "../../Component/DateRangePicker/DateRangePicker";
import ChartBox from "./Component/ChartBox/ChartBox";
import {
  ChartTitle,
  CurrentCheckedChart,
  MinDateForDatePicker,
  SearchParams,
  TradeType,
} from "./SummartDetails.enum";
import ToggleSwitchIe from "../../Component/ToggleSwitch/ToggleSwitchIe";
import {
  divideDateRange,
  getDateExpressionUsingDividedDateRange,
} from "../../utils/CommanFunction";

HighchartsDrillDownChart(Highcharts);
highcharts3d(Highcharts);
HighchartsNetworkgraph(Highcharts);
Sankey(Highcharts);
arcDiagram(Highcharts);
Accessibility(Highcharts);

export default function SummaryDetailsForTrade() {
  const search = useLocation().search;
  const tradeType = new URLSearchParams(search).get(SearchParams?.tradeType);
  const dataEndDate = new URLSearchParams(search).get(
    SearchParams?.dataEndDate
  );
  const taxonomy_id = new URLSearchParams(search).get(
    SearchParams?.taxonomy_id
  );
  const country = new URLSearchParams(search).get(SearchParams?.country);
  const searchField =
    tradeType === TradeType?.IMPORT ? "IMPORTER_NAME" : "EXPORTER_NAME";
  const searchTerm =
    tradeType === TradeType?.IMPORT
      ? new URLSearchParams(search).get(SearchParams?.IMPORTER_NAME)
      : new URLSearchParams(search).get(SearchParams?.EXPORTER_NAME);
  const currentStartDate = new URLSearchParams(search).get(
    SearchParams?.startDate
  );
  const currentEndDate = new URLSearchParams(search).get(SearchParams?.endDate);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [allSummaryData, setAllSummaryData] = useState({});
  const [summaryDetails, setSummaryDetails] = useState({});
  const [piechartData, setPieChartData] = useState({});
  const [columnChartData, setColumnChartData] = useState({});
  const [cmpAddress, setCmpAddress] = useState("");
  const [dateRange, setDateRange] = useState({});

  const [switchValue, setSwitchValue] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(
    tradeType === TradeType?.IMPORT
      ? CurrentCheckedChart?.IMPORT_CHARTS
      : CurrentCheckedChart?.EXPORT_CHARTS
  );

  const FILTER_PORT_QUANTITY = useRef();
  const FILTER_PRICE_QUANTITY = useRef();
  const FILTER_COUNTRY_PRICE_QUANTITY = useRef();
  const FILTER_HSCODE_PRICE_QUANTITY = useRef();
  const FILTER_BUYER_SELLER = useRef();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setDateRange({
      startMonthDate: currentStartDate,
      endMonthDate: currentEndDate,
      maxDateForEndMonth: currentEndDate,
      minDateForDatePicker: MinDateForDatePicker,
    });
    const summaryPayload = {
      tradeType,
      country,
      searchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange: {
        startDate: currentStartDate,
        endDate: currentEndDate,
      },
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      summaryPayload.dateExpraession = dateExpraession;
    }
    getSummaryData(summaryPayload, currentChecked);
  }, []);

  // const handleChangeEntityForNetworkChart = (selectedEntity) => {
  //   setCurrentSelectedRow(selectedEntity);
  //   setNetworkChart(FILTER_BUYER_SELLER, allSummaryData?.filter?.FILTER_BUYER_SELLER, selectedEntity);
  // };
  const setErrorForDashboard = (err) => {
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };

  const getSummaryData = (summaryPayload, currentCheckedtrade) => {
    setLoading(true);
    setLoadingMsg(Fetching_Summary_Data);
    getSummaryDetailsForTrade(summaryPayload)
      .then((e) => {
        if (e?.status === 500) {
          throw throwError(e);
        } else {
          if (e) {
            if (e?.data?.message) {
              setLoading(false);
              setError(true);
              setErrorMsg({
                title: "Warning",
                content: e?.data?.message,
              });
            } else {
              let Address = "";
              if (e?.data !== undefined) {
                Object.entries(e.data)?.length &&
                  Object.entries(e.data).forEach((e) => {
                    if (
                      e[0] === "ADDRESS" ||
                      e[0] === "IMPORTER_ADDRESS" ||
                      e[0] === "ADDRESS" ||
                      e[0] === "EXPORTER_ADDRESS"
                    ) {
                      Address = e[1];
                    }
                  });
              }
              setCmpAddress(Address);

              gatheringChartDetails(e, currentCheckedtrade);
              setLoading(false);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleLinkedInPopup = () => {
    var left = windowDimensions.width / 2 - 800 / 2;
    var top = windowDimensions.height / 2 - 500 / 2;
    window.open(
      `https://cse.google.com/cse?cx=124f513d8bf3a0c39&q=${searchTerm
        .split("_")
        .join(" ")}`,
      searchTerm.split("_").join(" "),
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        800 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const gatheringChartDetails = (e, currentCheckedtrade) => {
    setAllSummaryData(e);
    setSummaryDetails(e?.summary);

    setNetworkChart(
      FILTER_BUYER_SELLER,
      e?.filter?.FILTER_BUYER_SELLER,
      currentCheckedtrade
    );
    const pieChartDetails = {
      reference: FILTER_PORT_QUANTITY,
      chartName: ChartTitle?.Port_Vs_Quantity,
    };

    const pieChartArr = {
      FILTER_PORT_QUANTITY: [],
    };

    const multiBarChartArr = {
      FILTER_QUANTITY: [],
      FILTER_PRICE: [],
      xAxis: [],
    };

    const columnChartArr = {
      FILTER_COUNTRY_PRICE: [],
      FILTER_COUNTRY_QAUNTITY: [],
      FILTER_COUNTRY: [],
      FILTER_HSCODE: [],
      FILTER_HSCODE_PRICE: [],
      FILTER_HSCODE_QAUNTITY: [],
    };

    const drillDownColumnChart = {
      FILTER_BUYER_SELLER: [
        {
          name:
            currentChecked === CurrentCheckedChart?.IMPORT_CHARTS
              ? "Supplier"
              : "Buyer",
          data: [],
          colorByPoint: true,
        },
      ],
      FILTER_SUB_BUYER: [],
    };
    if (
      e?.filter?.FILTER_PORT_QUANTITY &&
      e?.filter?.FILTER_PORT_QUANTITY?.length
    ) {
      e?.filter?.FILTER_PORT_QUANTITY.forEach((ele) => {
        pieChartArr?.FILTER_PORT_QUANTITY.push([ele?._id, ele?.quantity]);
      });
    }

    if (
      e?.filter?.FILTER_COUNTRY_PRICE_QUANTITY &&
      e?.filter?.FILTER_COUNTRY_PRICE_QUANTITY?.length
    ) {
      e?.filter?.FILTER_COUNTRY_PRICE_QUANTITY?.forEach((ele) => {
        columnChartArr?.FILTER_COUNTRY_PRICE.push(ele?.price);
        columnChartArr?.FILTER_COUNTRY_QAUNTITY.push(ele?.quantity);
        columnChartArr?.FILTER_COUNTRY.push(`${ele?._id}`);
      });
    }
    if (
      e?.filter?.FILTER_HSCODE_PRICE_QUANTITY &&
      e?.filter?.FILTER_HSCODE_PRICE_QUANTITY?.length
    ) {
      e?.filter?.FILTER_HSCODE_PRICE_QUANTITY?.forEach((ele) => {
        columnChartArr?.FILTER_HSCODE_PRICE.push(ele?.price);
        columnChartArr?.FILTER_HSCODE_QAUNTITY.push(ele?.quantity);
        columnChartArr?.FILTER_HSCODE.push(ele?._id);
      });
    }

    if (
      e?.filter?.FILTER_PRICE_QUANTITY &&
      e?.filter?.FILTER_PRICE_QUANTITY?.length
    ) {
      let Pricearr = [];
      let qauntityArr = [];

      e?.filter?.FILTER_PRICE_QUANTITY?.forEach((ele) => {
        multiBarChartArr?.xAxis?.push(
          moment(ele?._id, "YYYY-MM-DDTHH:mm:ss").format("MMM")
        );
        qauntityArr.push(ele.quantity);
        Pricearr.push(ele.price);
      });
      multiBarChartArr.FILTER_QUANTITY = qauntityArr;
      multiBarChartArr.FILTER_PRICE = Pricearr;
    }

    if (
      e?.filter?.FILTER_BUYER_SELLER &&
      e?.filter?.FILTER_BUYER_SELLER?.length
    ) {
      const buyer = [];
      e?.filter?.FILTER_BUYER_SELLER.forEach((ele) => {
        const subBuyer = [];
        buyer.push({ name: ele._id, drilldown: ele._id, y: ele.buyerCount });

        ele.buyers &&
          ele.buyers.length &&
          ele.buyers.forEach((sub) => {
            subBuyer.push([sub._id, sub.subBuyerCount]);
          });
        drillDownColumnChart.FILTER_BUYER_SELLER[0].data = buyer;
        drillDownColumnChart?.FILTER_SUB_BUYER.push({
          id: ele._id,
          data: subBuyer,
        });
      });
    }

    setPieChartForSummary(
      pieChartArr.FILTER_PORT_QUANTITY,
      pieChartDetails?.reference,
      pieChartDetails?.chartName
    );

    setStackBarChartForSummary(
      "Price",
      "Qauntity",
      FILTER_PRICE_QUANTITY,
      multiBarChartArr
    );

    const colorForChart = [
      {
        column: "#6f7fb3",
        line: "#cf5b5b  ",
      },
      {
        column: "#67a5a0",
        line: "#c95e5b",
        // rgba(186, 60, 61, 0.9)
      },
    ];

    multiAxisChartForSummary(
      ChartTitle?.Country_Vs_Price_Vs_Quantity,
      FILTER_COUNTRY_PRICE_QUANTITY,
      columnChartArr?.FILTER_COUNTRY,
      columnChartArr?.FILTER_COUNTRY_QAUNTITY,
      columnChartArr?.FILTER_COUNTRY_PRICE,
      colorForChart[0]
    );

    multiAxisChartForSummary(
      ChartTitle?.HS_code_Vs_Price_Vs_Quantity,
      FILTER_HSCODE_PRICE_QUANTITY,
      columnChartArr?.FILTER_HSCODE,
      columnChartArr?.FILTER_HSCODE_QAUNTITY,
      columnChartArr?.FILTER_HSCODE_PRICE,
      colorForChart[1]
    );
    setPieChartData(pieChartArr);
    setColumnChartData(columnChartArr);
  };

  const setNetworkChart = (reference, chartData, currentCheckedtrade) => {
    const arr = [];
    const buyer = [];
    chartData &&
      chartData.length &&
      chartData.forEach((e) => {
        // handleCompositionNetworkChartStyle(e?._id);

        // for arcdiagram chart
        // arr.push([searchTerm?.split("_")?.join(" "), e._id]);
        arr.push([searchTerm?.split("_")?.join(" "), e._id]);
        buyer.push(e?._id);
        e.buyers &&
          e.buyers.length &&
          e.buyers.forEach((ele) => {
            arr.push([e._id, ele?._id]);
          });
      });

    // handleCompositionNetworkChartStyle(buyer);
    Highcharts.chart(reference.current, {
      chart: {
        type: "networkgraph",
      },
      title: {
        text:
          currentCheckedtrade === CurrentCheckedChart?.IMPORT_CHARTS
            ? "Suppliers/Sub-Suppliers"
            : "Buyers/Sub-Buyers",
      },
      subtitle: {
        text: "A Force-Directed Network Graph in Highcharts",
      },
      plotOptions: {
        networkgraph: {
          keys: ["from", "to"],
          layoutAlgorithm: {
            // enableSimulation: true,
            // friction: -0.9,
            linkLength: 40,
          },
        },
      },
      series: [
        {
          accessibility: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            linkFormat: "",
          },
          allowOverlap: true,
          // marker: {
          //   radius: 10,
          // },
          id: "lang-tree",
          data: arr,
        },
      ],
    });
  };

  Highcharts.addEvent(Highcharts.Series, "afterSetOptions", function (e) {
    var colors = Highcharts.getOptions().colors,
      i = 0,
      nodes = {};

    if (e.options.id === "lang-tree") {
      e.options.data.forEach(function (link) {
        if (link[0] === searchTerm?.split("_")?.join(" ")) {
          nodes[searchTerm?.split("_")?.join(" ")] = {
            id: searchTerm?.split("_")?.join(" "),
            marker: {
              radius: 40,
            },
            color: "#ff0000",
          };
          nodes[link[1]] = {
            id: link[1],
            marker: {
              radius: 20,
            },
            color: colors[i++],
          };
        } else if (nodes[link[0]] && nodes[link[0]].color) {
          nodes[link[1]] = {
            id: link[1],
            color: nodes[link[0]].color,
            marker: {
              radius: 5,
            },
          };
        }
      });

      e.options.nodes = Object.keys(nodes).map(function (id) {
        return nodes[id];
      });
    }
  });

  const setPieChartForSummary = (chartData, reference, chartName) => {
    Highcharts.chart(reference.current, {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: ChartTitle?.Port_Vs_Quantity,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        point: {
          // valueSuffix: "%",
          valueDescriptionFormat: "{point.y}.",
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          "<tr>" + '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
        },
      },
      series: [
        {
          name: chartName,
          colorByPoint: true,
          data: chartData,
        },
      ],
    });
  };

  const multiAxisChartForSummary = (
    chartName,
    reference,
    xAxisData,
    firstColumnData,
    secondColumnData,
    color
  ) => {
    Highcharts.chart(reference.current, {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: chartName,
        // align: "left",
      },

      xAxis: [
        {
          categories: xAxisData,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value}°C",
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          title: {
            text: "Price",
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: "Qauntity",
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
        },
        {
          // Tertiary yAxis
          gridLineWidth: 0,
          title: {
            text: "",
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "vertical",
        align: "left",
        x: 80,
        verticalAlign: "top",
        y: 55,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          "rgba(255,255,255,0.25)",
      },
      series: [
        {
          name: "Qauntity",
          type: "column",
          yAxis: 1,
          color: color.column,
          data: firstColumnData,
          tooltip: {
            // valueSuffix: " mm",
          },
        },
        {
          name: "Price",
          yAxis: 2,
          data: secondColumnData,
          color: color.line,
          marker: {
            enabled: false,
          },
          tooltip: {
            // valueSuffix: " mb",
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                floating: false,
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                x: 0,
                y: 0,
              },
              yAxis: [
                {
                  labels: {
                    align: "right",
                    x: 0,
                    y: -6,
                  },
                  showLastLabel: false,
                },
                {
                  labels: {
                    align: "left",
                    x: 0,
                    y: -6,
                  },
                  showLastLabel: false,
                },
                {
                  visible: false,
                },
              ],
            },
          },
        ],
      },
    });
  };

  const setStackBarChartForSummary = (
    firstSeriesName,
    secondSeriesName,
    reference,
    chartData
  ) => {
    Highcharts.chart(reference.current, {
      chart: {
        type: "bar",
      },
      title: {
        text: firstSeriesName + " Vs " + secondSeriesName,
      },
      xAxis: {
        categories: chartData?.xAxis,
        title: {
          text: "Month",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Value",
        },
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          name: firstSeriesName,
          data: chartData.FILTER_PRICE,
          color: "rgb(248, 161, 63)",
        },
        {
          name: secondSeriesName,
          data: chartData.FILTER_QUANTITY,
          color: "rgba(186, 60, 61, 0.9)",
        },
      ],
    });
  };

  const searchSummary = () => {
    const summaryPayload = {
      tradeType,
      country,
      searchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange,
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      summaryPayload.dateExpraession = dateExpraession;
    }
    getSummaryData(summaryPayload);
  };

  const handleChangeSwitch = (e) => {
    let checkedChart = "";
    setSwitchValue(e.target.checked);
    if (currentChecked === CurrentCheckedChart?.IMPORT_CHARTS) {
      setCurrentChecked(CurrentCheckedChart?.EXPORT_CHARTS);
      checkedChart = CurrentCheckedChart?.EXPORT_CHARTS;
    } else {
      setCurrentChecked(CurrentCheckedChart?.IMPORT_CHARTS);
      checkedChart = CurrentCheckedChart?.IMPORT_CHARTS;
    }
    const ChangedSearchField =
      checkedChart === CurrentCheckedChart?.IMPORT_CHARTS
        ? "IMPORTER_NAME"
        : "EXPORTER_NAME";
    const ChangedTradeType =
      checkedChart === CurrentCheckedChart?.IMPORT_CHARTS
        ? TradeType?.IMPORT
        : TradeType?.EXPORT;

    const summaryPayload = {
      tradeType: ChangedTradeType,
      country,
      searchField: ChangedSearchField,
      searchTerm: searchTerm.split("_").join(" "),
      dateRange,
      taxonomy_id,
    };
    const dividedDateRange = divideDateRange(
      currentStartDate,
      currentEndDate,
      dataEndDate
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRange(dividedDateRange);
      summaryPayload.dateExpraession = dateExpraession;
    }
    getSummaryData(summaryPayload, checkedChart);
  };
  let chartData = [
    {
      title: ChartTitle?.Port_Vs_Quantity,
      className: "set-chart-style",
      id: "imp-countryVsQauntityVsPrice",
      reference: FILTER_PORT_QUANTITY,
      classMainDiv: "SummaryDetails-card-body-chartBox",
    },
    {
      title: ChartTitle?.Country_Vs_Price_Vs_Quantity,
      className: "set-chart-style",
      id: "imp-priceVsQauntity",
      reference: FILTER_COUNTRY_PRICE_QUANTITY,
      classMainDiv: "SummaryDetails-card-body-chartBox",
    },
    {
      title: ChartTitle?.HS_code_Vs_Price_Vs_Quantity,
      className: "set-chart-style",
      id: "",
      reference: FILTER_HSCODE_PRICE_QUANTITY,
      classMainDiv: "SummaryDetails-card-body-chartBox",
    },
    {
      title: ChartTitle?.Price_Vs_Quantity,
      className: "set-chart-style",
      id: "imp-countryVsPort",
      reference: FILTER_PRICE_QUANTITY,
      classMainDiv: "SummaryDetails-card-body-chartBox",
    },
    {
      title: "",
      className: "set-drilldown-style",
      id: "imp-buyerVsSupplier",
      reference: FILTER_BUYER_SELLER,
      height: "400px",
    },
  ];
  return (
    <>
      <div id="summary-trade">
        {/* <Container maxWidth="xl"> */}
        {loading && (
          <Loading title={loadingMsg.title} content={loadingMsg.content} />
        )}
        {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
        <div className="paddingAll">
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <label className="mb-5 lable-position">Date Range</label>

                  <DateRangePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Box>

                <Box item style={{ marginTop: "23px", width: "200px" }}>
                  <ButtonComponent
                    name={"search"}
                    btnIcon={SearchIcon}
                    style={{ marginLeft: "10px" }}
                    btnClick={searchSummary}
                  />
                </Box>
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                {/* <div>
                  <label className="switch">
                    <input type="checkbox" id="togBtn" onChange={handleChangeSwitch} />
                    <div className="slider round">
                      <span className="on text-center">{currentChecked}</span>
                    </div>
                  </label>
                </div> */}
                <ToggleSwitchIe
                  onChange={handleChangeSwitch}
                  currentChecked={currentChecked}
                />
                <div>
                  <img
                    src={LinkInImage}
                    alt="LinkIn Logo"
                    width={50}
                    height={50}
                    style={{ cursor: "pointer" }}
                    onClick={handleLinkedInPopup}
                  />
                </div>
              </div>
            </div>

            <div className="left-count-alert">
              You have Consumed{" "}
              <span style={{ color: "red" }} className="notranslate">
                {allSummaryData.consumedCount}
              </span>{" "}
              and Left with{" "}
              <span style={{ color: "green" }} className="notranslate">
                {allSummaryData.allotedCount - allSummaryData.consumedCount}
              </span>{" "}
              Summary Limit
            </div>
          </Box>

          <div style={{ display: "flex", paddingTop: "20px" }}>
            <div>
              <h3 style={{ margin: "0px 0px 10px 0px" }}>
                {currentChecked === CurrentCheckedChart?.IMPORT_CHARTS
                  ? TradeType?.IMPORT
                  : TradeType?.EXPORT}{" "}
                SUMMARY
              </h3>
              <table>
                {Object.entries(summaryDetails) &&
                  !!Object.entries(summaryDetails)?.length &&
                  Object.entries(summaryDetails).map((e) => {
                    return (
                      <tr>
                        <td className="align-left" style={{ color: "#6c757d" }}>
                          {e[0]?.split("_").join(" ")}
                        </td>
                        <td>: {e[1]?.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div style={{ paddingLeft: "3%" }}>
              <h3 style={{ margin: "0px 0px 10px 0px" }}>COMPANY DETAILS</h3>

              <table>
                <tr>
                  <td style={{ color: "#6c757d" }}>COMPANY NAME</td>
                  <td>{`:  ${searchTerm.split("_").join(" ")}`}</td>
                </tr>
                {cmpAddress && (
                  <tr>
                    <td style={{ color: "#6c757d" }}>COMPANY ADDRESS</td>
                    <td>{`:  ${cmpAddress.split("_").join(" ")}`}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
        <span className="summaryPipe"></span>
        <Grid
          container
          className="set-chart-zoom"
          id="SummaryTradeChartContainer"
        >
          {chartData?.map((chart, index) => {
            return (
              <ChartBox
                chart={chart}
                key={index}
                chartData={chartData}
                index={index}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
}
