import axios from "axios";
import { instance } from "../App";
const urlHost = process.env.REACT_APP_BASE_LIVE;

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export const getSessionValue = (key) => {
  const value = sessionStorage.getItem(key);
  const res = JSON.parse(value);
  return res;
};
export const axiosPost = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await instance.post(mainUrl, data, {
      headers: {
        // Cookies: getCookie("tokenUI"),
        AdxToken: getSessionValue("adxToken"),
        AdxTokenExpireTime: getSessionValue("time"),
        // Cookies : localStorage.getItem("token"),
        React: true,
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};
export const axiosPostWithToken = async (url, payload, accessToken) => {
  let response = null;
  try {
    response = await instance.post(url, payload, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};

export const axiosPostWithContentType = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await instance.post(mainUrl, data, {
      headers: {
        // Cookies: getCookie("tokenUI"),
        AdxToken: getSessionValue("adxToken"),
        AdxTokenExpireTime: getSessionValue("time"),
        // Cookies : localStorage.getItem("token"),
        React: true,
      },
      withCredentials: true,
      responseType: "blob",
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};

export const axiosPut = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await instance.put(
      mainUrl,
      data,
      //    {
      //   withCredentials: true,
      // }
      {
        headers: {
          // Cookies : localStorage.getItem("token"),
          // Cookies: getCookie("tokenUI"),
          AdxToken: getSessionValue("adxToken"),
          AdxTokenExpireTime: getSessionValue("time"),
          React: true,
        },
        withCredentials: true,
      }
    );
  } catch (err) {
    throw err.response;
  }
  return response;
};

export const axiosGet = async (url) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;

    response = await instance.get(mainUrl, {
      headers: {
        // Cookies : localStorage.getItem("token"),
        // Cookies: getCookie("tokenUI"),
        AdxToken: getSessionValue("adxToken"),
        AdxTokenExpireTime: getSessionValue("time"),
        React: true,
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};
export const axiosGetWithOutAdxToken = async (url) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;

    response = await instance.get(mainUrl, {
      headers: {
        // Cookies : localStorage.getItem("token"),
        // Cookies: getCookie("tokenUI"),
        React: true,
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};
export const axiosDelete = async (url) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await instance.delete(
      mainUrl,
      //    {
      //   withCredentials: true,
      // }
      {
        headers: {
          // Cookies : localStorage.getItem("token"),
          // Cookies: getCookie("tokenUI"),
          AdxToken: getSessionValue("adxToken"),
          AdxTokenExpireTime: getSessionValue("time"),
          React: true,
        },
        withCredentials: true,
      }
    );
  } catch (err) {
    throw err.response;
  }
  return response;
};

let CancelToken;
export const axiosPostWithCancelRequest = async (url, data) => {
  if (CancelToken) {
    CancelToken.cancel("Operation Cancelled due to new Request");
  }
  CancelToken = axios.CancelToken.source();

  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await instance({
      method: "post",
      // cancelToken: source.token,
      cancelToken: CancelToken.token,
      url: mainUrl,
      data: data,
      headers: {
        // Cookies: getCookie("tokenUI"),
        React: true,
        AdxToken: getSessionValue("adxToken"),
        AdxTokenExpireTime: getSessionValue("time"),
      },
      withCredentials: true,
    });
  } catch (err) {
    throw err.response;
  }
  return response;
};
