// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace-card-chartBox {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 24px;
  border-radius: 0.25rem;
}
.workspace-card-chartBox .workspace-card-header-chartBox {
  background-color: #005d91 !important;
  padding: 1.5rem;
  color: #fff !important;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.workspace-card-chartBox .close-minimize {
  float: right;
  position: relative;
}
.workspace-card-chartBox #card-icons {
  cursor: pointer;
}
.workspace-card-chartBox .set-card-minimize-position {
  position: absolute !important;
  right: 28px !important;
  top: -6px !important;
}
.workspace-card-chartBox .set-card-plus-position {
  position: absolute !important;
  right: 28px !important;
}
.workspace-card-chartBox .workspace-card-body-chartBox {
  padding: 1rem;
  border: 2px solid #005d91;
}
.workspace-card-chartBox .chart-icon {
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  padding: 6px 2px;
  border-radius: 5px;
  color: #ffffff;
}
.workspace-card-chartBox .chart-icon:hover {
  background-color: #ffffff !important;
  color: #005d91 !important;
  transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/Component/AnalyzeFilter/Component/ChartBox/ChartBox.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,eAAA;EAEA,iDAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAAE;EACE,oCAAA;EACA,eAAA;EACA,sBAAA;EACA,0DAAA;AAEJ;AAAE;EACE,YAAA;EACA,kBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,6BAAA;EACA,sBAAA;EACA,oBAAA;AAEJ;AAAE;EACE,6BAAA;EACA,sBAAA;AAEJ;AAAE;EACE,aAAA;EACA,yBAAA;AAEJ;AAAE;EACE,6BAAA;EACA,8BAAA;EACA,+BAAA;EACA,gCAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AAAI;EACE,oCAAA;EACA,yBAAA;EACA,gBAAA;AAEN","sourcesContent":[".workspace-card-chartBox {\n  background-color: #fff;\n  padding: 1.5rem;\n  -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n  margin-bottom: 24px;\n  border-radius: 0.25rem;\n  .workspace-card-header-chartBox {\n    background-color: #005d91 !important;\n    padding: 1.5rem;\n    color: #fff !important;\n    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;\n  }\n  .close-minimize {\n    float: right;\n    position: relative;\n  }\n  #card-icons {\n    cursor: pointer;\n  }\n  .set-card-minimize-position {\n    position: absolute !important;\n    right: 28px !important;\n    top: -6px !important;\n  }\n  .set-card-plus-position {\n    position: absolute !important;\n    right: 28px !important;\n  }\n  .workspace-card-body-chartBox {\n    padding: 1rem;\n    border: 2px solid #005d91;\n  }\n  .chart-icon {\n    border-top: 1px solid #ffffff;\n    border-left: 1px solid #ffffff;\n    border-right: 2px solid #ffffff;\n    border-bottom: 2px solid #ffffff;\n    padding: 6px 2px;\n    border-radius: 5px;\n    color: #ffffff;\n\n    &:hover {\n      background-color: #ffffff !important;\n      color: #005d91 !important;\n      transition: 0.3s;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
