// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapseTable .ant-table-wrapper .ant-table-tbody > tr > td {
  white-space: nowrap;
  max-width: 420px !important;
  min-width: 180px !important;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 4px 10px;
}`, "",{"version":3,"sources":["webpack://./src/Component/CollapseDataTable/collapseTable.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,2BAAA;EACA,2BAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".collapseTable .ant-table-wrapper .ant-table-tbody>tr>td {\n    white-space: nowrap;\n    max-width: 420px !important;\n    min-width: 180px !important;\n    overflow-x: auto;\n    overflow-y: hidden;\n    padding: 4px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
