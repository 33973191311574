import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import "./DateRangePicker.scss";
import moment from "moment";
import { DATEFORMAT } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
const { RangePicker } = DatePicker;
const DateRangePicker = ({
  dateRange,
  setDateRange,
  isMarket,
  handleDateRange,
  compareDate,
}) => {
  const onRangeChange = (dates, dateStrings) => {
    if (!isMarket) {
      if (dateStrings !== null) {
        const startDate = moment(dateStrings[0]).format(DATEFORMAT?.format);
        const endDate = moment(dateStrings[1]).format(DATEFORMAT?.format);
        if (
          startDate < dateRange?.maxDateForEndMonth &&
          startDate >= dateRange?.minDateForDatePicker &&
          startDate < endDate &&
          endDate <= dateRange?.maxDateForEndMonth &&
          endDate > startDate &&
          endDate >= dateRange?.minDateForDatePicker
        ) {
          setDateRange({
            ...dateRange,
            startMonthDate: startDate,
            endMonthDate: endDate,
            monthRange: dates[2] ? dates[2] : 0,
          });
        } else {
          let _dateRange = manageDateRange(1);
          const startDate = moment(_dateRange[0]).format(DATEFORMAT?.format);
          const endDate = moment(_dateRange[1]).format(DATEFORMAT?.format);
          setDateRange({
            ...dateRange,
            startMonthDate: startDate,
            endMonthDate: endDate,
            monthRange: dates[2] ? dates[2] : 0,
          });
        }
      } else {
        let _dateRange = manageDateRange(1);
        const startDate = moment(_dateRange[0]).format(DATEFORMAT?.format);
        const endDate = moment(_dateRange[1]).format(DATEFORMAT?.format);
        setDateRange({
          ...dateRange,
          startMonthDate: startDate,
          endMonthDate: endDate,
          monthRange: dates[2] ? dates[2] : 0,
        });
      }
    } else {
      handleDateRange(dates, dateStrings, compareDate);
    }
  };
  const datePickerMonthTabDiv = document.querySelector(`.ant-picker-presets`);
  const getRange = (range) => {
    if (range === 1) {
      return 1;
    } else if (range === 3) {
      return 2;
    } else if (range === 6) {
      return 3;
    } else if (range === 12) {
      return 4;
    } else if (range === 11) {
      return 5;
    } else {
      return 6;
    }
  };
  useEffect(() => {
    if (
      dateRange?.startMonthDate !== "" &&
      dateRange?.endMonthDate !== "" &&
      datePickerMonthTabDiv &&
      !isMarket
    ) {
      const range = getRange(dateRange?.monthRange);
      let rangeArr = [1, 2, 3, 4, 5, 6];
      rangeArr.forEach((r) => {
        if (r === range) {
          document.querySelector(
            `.ant-picker-presets li:nth-child(${range})`
          ).style.color = "#FFFFFF";
          document.querySelector(
            `.ant-picker-presets li:nth-child(${range})`
          ).style.backgroundColor = "#005d91";
        } else {
          document.querySelector(
            `.ant-picker-presets li:nth-child(${r})`
          ).style.color = "black";
          document.querySelector(
            `.ant-picker-presets li:nth-child(${r})`
          ).style.backgroundColor = "white";
        }
      });
    }
  }, [dateRange]);

  const manageDateRange = (range) => {
    if (range === 11) {
      const currentDate = dayjs();
      const startDate = currentDate.subtract(1, "year").startOf("year");
      const endDate = currentDate.subtract(1, "year").endOf("year");

      return [startDate, endDate, 11];
    } else {
      let date = "";
      if (range === 0) {
        date = dayjs(dateRange?.maxDateForEndMonth).subtract(4, "month");
      } else {
        date = dayjs(dateRange?.maxDateForEndMonth).subtract(range, "month");
      }
      const _min = dayjs(dateRange?.minDateForDatePicker);
      const _max = dayjs(dateRange?.maxDateForEndMonth);

      const startDate = date < _min ? _min : date;
      const endDate = _max;
      return [startDate, endDate, range];
    }
  };
  const rangePresets = [
    {
      label: "Last 1 Month",
      value: isMarket ? "" : manageDateRange(1),
    },
    {
      label: "Last 3 Months",
      value: isMarket ? "" : manageDateRange(3),
    },
    {
      label: "Last 6 Months",
      value: isMarket ? "" : manageDateRange(6),
    },
    {
      label: "Last 1 Year",
      value: isMarket ? "" : manageDateRange(12),
    },
    {
      label: "Last  Year",
      value: isMarket ? "" : manageDateRange(11),
    },
    {
      label: "Custom",
      value: isMarket ? "" : manageDateRange(0),
    },
  ];
  const disabledDate = (current) => {
    const minDate = dayjs(dateRange?.minDateForDatePicker);
    const maxDate = dayjs(dateRange?.maxDateForEndMonth);
    return current && (current < minDate || current > maxDate);
  };

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        presets={isMarket ? false : rangePresets}
        onChange={onRangeChange}
        separator="|"
        format="MMM D, YYYY"
        disabledDate={disabledDate}
        value={
          isMarket && compareDate
            ? [
                dayjs(dateRange?.compareStartDate),
                dayjs(dateRange?.compareEndDate),
              ]
            : [dayjs(dateRange?.startMonthDate), dayjs(dateRange?.endMonthDate)]
        }
      />
    </Space>
  );
};

export default DateRangePicker;
