import { useEffect, useState } from "react";
import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";
import { logoutDetails } from "../../Services/service";
import "./logout.scss";
import Error from "../../Component/ErrorLoader/error";
import { useLocation } from "react-router";
import { useAppContext } from "../../Contexts/AppContext";
const LogOut = (props) => {
  const location = useLocation();
  const isAutoLogOut = location.state?.autoLogOut;
  const [error, setError] = useState(false);
  const [isAutoLogOutFlag, setAutoLogOutFlag] = useState(false);
  const { setPopOpen } = useAppContext();

  const errorMsg = {
    title: "Warning",
    content: "Session TimeOut , Please Login Again",
  };
  const deleteCookie = (name) => {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  useEffect(() => {
    if (isAutoLogOut && isAutoLogOut === "autoLogOut") {
      setError(true);
      setAutoLogOutFlag(true);
    } else {
      setError(false);
      setAutoLogOutFlag(false);
    }
    let data = {
      user_id: props.CLIENT_USER_ID,
    };
    deleteCookie("auth");
    deleteCookie("accountId");
    deleteCookie("user_id");
    deleteCookie("firstName");
    // deleteCookie("token");
    deleteCookie("tokenUI");
    deleteCookie("lastName");
    deleteCookie("email_id");
    localStorage.clear();
    sessionStorage.clear();
    setPopOpen(false);
    logoutDetails(data)
      .then((e) => {})
      .catch((e) => {});
  }, []);

  return (
    <div id="logout-page">
      {error && (
        <Error
          errorMsg={errorMsg}
          open={error}
          setOpen={setError}
          isAutoLogOutFlag={isAutoLogOutFlag}
        />
      )}
      <PasswordRecoveryComponent name="logout" data={props} />
    </div>
  );
};
export default LogOut;
