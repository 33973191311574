import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import "./CountrySelectModal.scss";
import { styled } from "@mui/system";
import SelectDropDownNew from "../../../Component/SelectDropDownNewUI/SelectDropDown";
import CountrySelector from "./CountrySelector";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputBox from "../../../Component/AnalyzeFilter/Component/InputBox";
import { BtnGreen } from "../../../Component/ButtonComponent/Button";
import { TRADE_TYPE_FOR_COUNTRY_MODAL } from "../mirrorData.enum";

const theme = createTheme();

const StyledRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 18,
  },
}));

function CountrySelectModal({
  allCountryData,
  handleChangeTradeType,
  selectedCountries,
  handleCountryChange,
  setSelectedCountries,
  handleSearchCountryInModal,
  regionWiseCountryData,
  searchQueryForCountryModal,
  handleUncheckAll,
  tradeTypeForCountryModal,
  isSearchInput,
  mirrorAccessCountryList,
  handleInaccessibleCountryPopUp,
}) {
  return (
    <>
      <div className="select-countryCode-modal-body">
        <div className="grouped-radio-dropdown">
          <div className="container">
            <div className="radio-group">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={tradeTypeForCountryModal}
                  name="radio-buttons-group"
                >
                  {TRADE_TYPE_FOR_COUNTRY_MODAL.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.toLowerCase()}
                        control={<StyledRadio />}
                        label={item}
                        onChange={handleChangeTradeType}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>

            <div className="search-country">
              <InputBox
                placeholder="Search Country"
                handleOnChange={handleSearchCountryInModal}
                value={searchQueryForCountryModal}
              />
            </div>
          </div>
          <div className="clearSelected">
            <BtnGreen
              name={"Clear Selected"}
              style={{ width: "100%", fontSize: "12px", height: "32px" }}
              btnClick={() => handleUncheckAll()}
              className="btn-background-green"
            />
          </div>
        </div>

        <div className="all-country">
          <ThemeProvider theme={theme}>
            <CountrySelector
              allCountryData={allCountryData}
              setSelectedCountries={setSelectedCountries}
              handleCountryChange={handleCountryChange}
              selectedCountries={selectedCountries}
              regionWiseCountryData={regionWiseCountryData}
              searchQueryForCountryModal={searchQueryForCountryModal}
              isSearchInput={isSearchInput}
              mirrorAccessCountryList={mirrorAccessCountryList}
              handleInaccessibleCountryPopUp={handleInaccessibleCountryPopUp}
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}

export default CountrySelectModal;
