import React, { memo } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Excel from "../../../../Asset/images/excel.png";
import {
  ButtonComponent,
  ButtonComponentWithToolTip,
} from "../../../../Component/ButtonComponent/Button";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  BUTTON_NAME,
  FILTER_OPTIONS,
} from "../../../ExploreCountryShipmentNewUI/Explore.enum";

const WorkSpaceButton = memo(
  ({
    handleDownloadExcel,
    filterOptionClick,
    dashboardLoading,
    currentCountry,
    ChangeRemoveDuplicateButton,
    currentTradeType,
  }) => {
    return (
      <>
        <div>
          <ButtonComponent
            name={BUTTON_NAME?.REPORT}
            style={{
              margin: "5px 5px 8px 5px",
              width: "120px",
              height: "50px",
            }}
            btnClick={() => {
              filterOptionClick(BUTTON_NAME?.REPORT);
            }}
            // isLoading={dashboardLoading}
          />
        </div>
        <div>
          {(currentCountry === "INDIA" || currentCountry === "INDONESIA") &&
            !ChangeRemoveDuplicateButton && (
              <ButtonComponentWithToolTip
                btnIcon={RemoveCircleIcon}
                style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
                btnClick={() => {
                  filterOptionClick(BUTTON_NAME?.REMOVE_DUPLICATE);
                }}
                tooltipTitle={BUTTON_NAME?.REMOVE_DUPLICATE}
              />
            )}
          {(currentCountry === "INDIA" || currentCountry === "INDONESIA") &&
            ChangeRemoveDuplicateButton && (
              <ButtonComponentWithToolTip
                btnIcon={AddCircleIcon}
                style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
                btnClick={() => {
                  filterOptionClick(BUTTON_NAME?.SET_DEFAULT);
                }}
                tooltipTitle={"Set Default"}
              />
            )}
          <ButtonComponentWithToolTip
            btnIcon={VisibilityIcon}
            style={{ margin: "5px 5px 8px 5px ", height: "50px" }}
            btnClick={() => {
              filterOptionClick(FILTER_OPTIONS?.VIEW_COLUMNS);
            }}
            tooltipTitle={FILTER_OPTIONS?.VIEW_COLUMNS}
          />
          <ButtonComponentWithToolTip
            style={{
              margin: "0px 5px 0px 5px ",
              height: "50px",
            }}
            btnClick={() => {
              handleDownloadExcel();
            }}
            tooltipTitle={BUTTON_NAME?.DOWNLOAD_EXCEL}
            name={<img src={Excel} alt="ExcelDownload" />}
            toUpperCase={true}
          />
        </div>
      </>
    );
  }
);

export default WorkSpaceButton;
