import React, { useEffect, useRef, useState } from "react";
import {
  addConsigneeRequest,
  getConsigneeShipmentDetails,
  getConsigneeShipmentRequestList,
  getIECSearchData,
  getTaxonomy,
} from "../../../Services/service";
import { INDIA_EXPORT_COLUMN_NAME } from "../../../IndiaTradeRecords/records";
import Loading from "../../../Component/SuccessLoader/success";
import StickyTable from "../../../Component/Table/Table";
import ModalComponent from "../../../Component/Modal/modal";
import ExploreDataTableModal from "../../../Component/ExploreDataTableModal/exploreModal";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import { Fetching_Requested_Buyers } from "../../../utils/LoadingMsg";
import { DefaultTableHeader } from "../../ExploreCountryShipmentNewUI/Explore.enum";
import throwError from "../../../utils/throwError";
import { translateButtonClick } from "../../../utils/CommanFunction";
import ErrorData from "../../../utils/ErrorHandling";
import { logger } from "../../../Logger/Logger";
import Error from "../../../Component/ErrorLoader/error";

export default function RequestedBuyers(props) {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const [error, setError] = useState(false);
  const PrintRef = useRef();
  const [consigneeShipmentReqList, setConsigneeShipmentReqList] = useState([]);
  const [dataTableHeader, setDataTableHeader] = useState([]);
  const [storeEyeModalRowData, setStoreEyeModalRowData] = useState({});
  const [IECDetails, setIECDetails] = useState([]);
  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [addConsigneeDetailsPayload, setAddConsigneeDetailsPayload] = useState(
    {}
  );
  const [modalHeaderArr, setModalArr] = useState([]);
  const [openCollapseModal, setOpenCollapseModal] = useState(false);
  const [prevOrNext, setPrevOrNext] = useState(0);
  const [collapseTable, setCollapseTable] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [isCollapseModelDetails, setIsCollapseModelDetails] = useState({
    companyDetails: true,
    productDetails: true,
    otherDetails: true,
    IECDetails: true,
    consigneeDetails: true,
  });

  const showErrForRequestedBuyer = (err) => {
    const errorForLogger = `Requested Buyer:- ${
      err?.msg ? err?.msg : err?.message
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? err?.msg
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(Fetching_Requested_Buyers);
    getConsigneeShipmentRequestList()
      .then((e) => {
        setRowsPerPage(e?.recordRow?.length);
        setConsigneeShipmentReqList(e?.recordRow);
        if (e?.status === 401) {
          const err = {
            status: 401,
            msg: e?.data?.data?.msg,
          };
          showErrForRequestedBuyer(err);
        }
      })
      .catch((err) => {
        showErrForRequestedBuyer(err);
      });
    let payload = {
      currentTradeType: "EXPORT",
      currentCountry: "INDIA",
    };
    getTaxonomy(payload)
      .then((e) => {
        setLoading(false);
        const filterTaxonomy =
          e &&
          e?.data &&
          e?.data?.length > 0 &&
          e?.data?.filter((e) => {
            // dataTable header is depend on filterTaxonomy
            return (
              e?.country?.toUpperCase() === "INDIA" &&
              e?.trade?.toUpperCase() === "EXPORT" &&
              e?.bl_flag === false
            );
          });
        dataTableValues(filterTaxonomy);
      })
      .catch((err) => {
        showErrForRequestedBuyer(err);
      });
  }, []);

  useEffect(() => {
    translateButtonClick();
  }, []);
  const ConsigneeShipmentDetailsAPIHandler = (shipmentDetailsPayload) => {
    getConsigneeShipmentDetails(shipmentDetailsPayload)
      .then((e) => {
        if (e.message === "Request Cosignee Data") {
          setConsigneeDetails({
            message: "Request Buyer",
            disabled: false,
            shipmentAllotedLimits: e?.shipmentAllotedLimits,
            shipmentConsumedLimits: e?.shipmentConsumedLimits,
          });
        } else if (e.message === "Shipment Data Request is in process .") {
          setConsigneeDetails({
            message: "Request Processing",
            disabled: true,
            shipmentAllotedLimits: e?.shipmentAllotedLimits,
            shipmentConsumedLimits: e?.shipmentConsumedLimits,
          });
        } else {
          setConsigneeDetails(e);
        }
      })
      .catch((err) => {
        showErrForRequestedBuyer(err);
      });
  };

  const previousDataTableRecords = async () => {
    setPrevOrNext(prevOrNext - 1);

    const row = consigneeShipmentReqList[prevOrNext - 1];
    setStoreEyeModalRowData(row);
    let arr = [];
    dataTableHeader.forEach((col) => {
      let obj = {
        [col.title]: row[col?.key],
      };
      arr.push(obj);
    });

    const IECArr = await getIECSearchDetails(row.IEC);
    setIECDetails(IECArr);

    const shipmentDetailsPayload = {
      userId: row?._id,
      shipment_number: row?.BILL_NO,
    };
    setAddConsigneeDetailsPayload({
      account_id: props?.CLIENT_ACCOUNT_ID,
      country_date: row?.EXP_DATE,
      country_port: row?.INDIAN_PORT,
      shipmentBillNumber: row?.BILL_NO,
      recordRow: row,
    });

    ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);

    setModalArr(arr);
  };

  const nextDataTableRecords = async () => {
    setPrevOrNext(prevOrNext + 1);
    const row = consigneeShipmentReqList[prevOrNext + 1];
    setStoreEyeModalRowData(row);
    let arr = [];
    dataTableHeader.forEach((col) => {
      let obj = {
        [col.title]: row[col?.key],
      };
      arr.push(obj);
    });

    const IECArr = await getIECSearchDetails(row.IEC);
    setIECDetails(IECArr);

    const shipmentDetailsPayload = {
      userId: row?._id,
      shipment_number: row?.BILL_NO,
    };
    setAddConsigneeDetailsPayload({
      account_id: props?.CLIENT_ACCOUNT_ID,
      country_date: row?.EXP_DATE,
      country_port: row?.INDIAN_PORT,
      shipmentBillNumber: row?.BILL_NO,
      recordRow: row,
    });

    ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);

    setModalArr(arr);
  };

  const handlePrintTableRecords = useReactToPrint({
    content: () => PrintRef.current,
  });

  const handleSummaryDataForTrade = () => {
    const rowData =
      consigneeShipmentReqList &&
      consigneeShipmentReqList?.length > 0 &&
      consigneeShipmentReqList?.filter((row, index) => {
        return row?._id === storeEyeModalRowData?._id;
      })[0];
    let currentSelector = "";
    let currentSearchField = "";

    currentSelector = rowData?.EXPORTER_NAME;
    currentSearchField = "EXPORTER_NAME";

    // navigate(`/trade/summary?${currentSearchField}=${currentSelector.split(" ").join("_")}&tradeType=${currentTradeType}&country=${currentCountry}`);
    window.open(
      `/trade/summary?${currentSearchField}=${currentSelector
        .split(" ")
        .join("_")}&tradeType=EXPORT&country=INDIA`,
      "_blank"
    );
  };

  const dataTableValues = (filterTaxonomy) => {
    let sequencedFields = [];
    let ValuesArr = [...DefaultTableHeader];
    filterTaxonomy &&
      filterTaxonomy?.length > 0 &&
      filterTaxonomy.forEach((e) => {
        e?.fields?.explore &&
          e?.fields?.explore?.length > 0 &&
          e?.fields?.explore.forEach((e) => {
            sequencedFields.push(e);
          });

        if (
          e?.country === "India" &&
          e?.trade === "EXPORT" &&
          e?.bl_flag === false
        ) {
          for (const [key, value] of Object.entries(INDIA_EXPORT_COLUMN_NAME)) {
            if (key.toUpperCase().trim() !== "RECORD_TAG") {
              if (!sequencedFields.includes(key)) {
                sequencedFields.push(key);
              }
            }
          }

          sequencedFields &&
            sequencedFields?.length > 0 &&
            sequencedFields.forEach((e) => {
              const obj = {
                key: e,
                title: INDIA_EXPORT_COLUMN_NAME[e].split("_").join(" "),
              };
              ValuesArr.push(obj);
            });
        } else {
          e?.fields?.all &&
            e?.fields?.all?.length > 0 &&
            e?.fields?.all.forEach((e) => {
              if (e?.toUpperCase().trim() !== "RECORD_TAG") {
                if (!sequencedFields.includes(e)) {
                  sequencedFields.push(e);
                }
              }
            });

          sequencedFields &&
            sequencedFields?.length > 0 &&
            sequencedFields.forEach((ele) => {
              const obj = {
                key: ele,
                title: ele?.split("_").join(" "),
              };
              ValuesArr.push(obj);
            });
        }

        setDataTableHeader(ValuesArr);
      });
  };

  const handleCollapseDetails = (e) => {
    const name = e?.target?.id;

    setIsCollapseModelDetails({
      ...isCollapseModelDetails,
      [name]: !isCollapseModelDetails[name],
    });
  };

  const getIECSearchDetails = async (iecNumber) => {
    const Arr = await getIECSearchData(iecNumber);
    return Arr?.data;
  };
  const handleCollapseOpenModal = async (column, row, index) => {
    setIsCollapseModelDetails({
      companyDetails: true,
      productDetails: true,
      otherDetails: true,
      IECDetails: true,
      consigneeDetails: true,
    });
    setStoreEyeModalRowData(row);

    const IECArr = await getIECSearchDetails(row.IEC);
    setIECDetails(IECArr);

    const shipmentDetailsPayload = {
      userId: row?._id,
      shipment_number: row?.BILL_NO,
    };
    setAddConsigneeDetailsPayload({
      account_id: props?.CLIENT_ACCOUNT_ID,
      country_date: row?.EXP_DATE,
      country_port: row?.INDIAN_PORT,
      shipmentBillNumber: row?.BILL_NO,
      recordRow: row,
    });

    ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);

    const companyIndex = column.findIndex((e) => e?.key === "isFavourite");
    const shipmentIndex = column.findIndex(
      (e) => e?.key === "isFavouriteShipment"
    );
    if (companyIndex !== -1) {
      column?.splice(companyIndex, 1);
    }
    if (shipmentIndex !== -1) {
      column?.splice(shipmentIndex, 1);
    }

    column.push(
      {
        title: "isFavourite",
        key: "isFavourite",
      },
      {
        title: "isFavouriteShipment",
        key: "isFavouriteShipment",
      }
    );
    let arr = [];
    column &&
      column?.length > 0 &&
      column.forEach((col) => {
        let obj = {
          [col.title]: row[col.key],
        };
        arr.push(obj);
        setModalArr(arr);
      });
    setOpenCollapseModal(true);
    setPrevOrNext(index);
  };

  const handleAddConsigneeRequest = () => {
    addConsigneeRequest(addConsigneeDetailsPayload)
      .then((e) => {
        if (e?.data === "Request Submitted Successfully.") {
          setConsigneeDetails({
            message: "Request Processing",
            disabled: true,
          });
        }
      })
      .catch((err) => {
        showErrForRequestedBuyer(err);
      });
  };

  return (
    <Container maxWidth="xxl">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
      <div className="row-dashbored" style={{ padding: "20px 10px" }}>
        <h4 className="text">
          Requested
          <span className="badge bg-soft-blue text-blue label-worksapce-name">
            Buyers
          </span>
        </h4>
      </div>
      <Box style={{ marginTop: "10px" }}>
        <StickyTable
          handleCollapseOpenModal={handleCollapseOpenModal}
          columns={dataTableHeader}
          rows={consigneeShipmentReqList}
          isRequestedBuyer={true}
        />
      </Box>

      <ModalComponent
        IsNextPrevBtnShown={true}
        open={openCollapseModal}
        setOpen={setOpenCollapseModal}
        header="DataTable"
        prevOrNext={prevOrNext}
        nextDataTableRecords={nextDataTableRecords}
        previousDataTableRecords={previousDataTableRecords}
        rowsPerPage={rowsPerPage}
        handlePrintTableRecords={handlePrintTableRecords}
        handleSummaryDataForTrade={handleSummaryDataForTrade}
        isFromRequestedBuyer={true}
        modalBody={
          <ExploreDataTableModal
            currentTradeType="EXPORT"
            currentCountry="INDIA"
            PrintRef={PrintRef}
            handleCollapseDetails={(e) => handleCollapseDetails(e)}
            collapseTable={collapseTable}
            setCollapseTable={setCollapseTable}
            modalheaderArr={modalHeaderArr}
            isCollapseModelDetails={isCollapseModelDetails}
            setIsCollapseModelDetails={setIsCollapseModelDetails}
            IECDetails={IECDetails}
            consigneeDetails={consigneeDetails}
            handleAddConsigneeRequest={handleAddConsigneeRequest}
          />
        }
        width="1000px"
        saveText="Save"
        saveButtonShown={false}
        saveButtonColor="#005d91"
      />
    </Container>
  );
}
