// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#auth-footer .footer-alt {
  text-align: center;
  background-color: transparent;
  padding-bottom: 20px;
}
#auth-footer .footer {
  width: 100%;
  margin: auto;
  bottom: 0;
  color: #98a6ad;
  left: 0;
}
#auth-footer .footer span {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Component/AuthFooter/authFooter.scss"],"names":[],"mappings":"AACE;EAEE,kBAAA;EACA,6BAAA;EACA,oBAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,SAAA;EACA,cAAA;EACA,OAAA;AADJ;AAGI;EACE,eAAA;AADN","sourcesContent":["#auth-footer{\n  .footer-alt {\n    // left: 0 !important;\n    text-align: center;\n    background-color: transparent;\n    padding-bottom: 20px;\n  }\n  .footer {\n    width: 100%;\n    margin: auto;\n    bottom: 0;\n    color: #98a6ad;\n    left: 0;\n  \n    span {\n      font-size: 14px;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
