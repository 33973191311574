import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import { logger } from "../../Logger/Logger";
import CompanyDetail from "./CompanyDetail/CompanyDetail";

const CompanyDetailWithError = () => {
  function ErrorHandler({ error }) {
    logger(`Company Detail ${error?.message}`);
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <CompanyDetail />
      </ErrorBoundary>
    </>
  );
};

export default CompanyDetailWithError;
