export const GETDATAFROMURL = {
  columnName: "columnName",
  columnField: "columnField",
  filter: "filter",
  countryCode: "countryCode",
  country: "country",
  tradeType: "tradeType",
  blFlag: "blFlag",
  id: "id",
  globalSearch: "globalSearch",
  title: "title",
  mirrorData: "mirrorData",
};
export const DEFAULT_OPERATOR = "In";
export const DataType = {
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
};
export const DATEFORMAT = {
  format: "YYYY-MM-DD",
};

export const SEARCHTYPE = {
  advancedSearch: "advancedSearch",
  search: "search",
};
export const RESULT_TYPE = {
  SEARCH_RECORDS: "SEARCH_RECORDS",
  FILTER: "FILTER",
  SEARCH_PAGINATED_RECORDS: "SEARCH_PAGINATED_RECORDS",
};
export const QUERYTYPE = {
  CHOOSE: "CHOOSE",
  SEARCH: "SEARCH",
};

export const ADVANCESEARCHFIELDNAME = {
  relation: "relation",
  operator: "operator",
  field: "field",
};
export const SHIPMENT_RESULT_TYPE_RECORDS = "RECORDS";
export const DefaultTableHeader = [
  {
    key: "add",
    title: "",
  },
  {
    key: "checkbox",
    title: "",
  },
];

export const SORTINGTYPE = {
  desc: "desc",
  asc: "asc",
  default: "default",
};

export const FILTER_OPTIONS = {
  HS_CODE: "HS_CODE",
  MONTH: "MONTH",
  QUANTITY: "QUANTITY",
  PRICE: "PRICE",
  VIEW_COLUMNS: "VIEW_COLUMNS",
  FILTER_COUNTRY: "FILTER_COUNTRY",
  FILTER_PORT: "FILTER_PORT",
  FILTER_FOREIGN_PORT: "FILTER_FOREIGN_PORT",
  FILTER_UNIT: "FILTER_UNIT",
  FILTER_QUANTITY: "FILTER_QUANTITY",
  FILTER_PRICE: "FILTER_PRICE",
  FILTER_HS_CODE: "FILTER_HS_CODE",
  FILTER_INDIAN_PORT: "FILTER_INDIAN_PORT",
  FILTER_DESTINATION_COUNTRY: "FILTER_DESTINATION_COUNTRY",
  FILTER_DESTINATION_PORT: "FILTER_DESTINATION_PORT",
  FILTER_ORIGIN_COUNTRY: "FILTER_ORIGIN_COUNTRY",
  FILTER_ORIGIN_PORT: "FILTER_ORIGIN_PORT",
  FILTER_REPORTING_COUNTRY: "FILTER_REPORTING_COUNTRY",
  FILTER_MONTH: "FILTER_MONTH",
  VALUE: "VALUE",
  SHIPMENT: "SHIPMENT",
  DATATYPE: "DATATYPE",
  FILTER_STATE: "FILTER_STATE",
  FILTER_PIN: "FILTER_PIN",
  SEARCH_MODE: "SEARCH_MODE",
  REMOVE_ORDER_OR_NULL: "REMOVE_ORDER_OR_NULL",
  TO_ORDER: "TO_ORDER",
  TO_NULL: "TO_NULL",
  FILTER_IMPORTER: "FILTER_IMPORTER",
  FILTER_EXPORTER: "FILTER_EXPORTER",
};

export const BUTTON_NAME = {
  ANALYZE: "ANALYZE",
  ADD_ALL_RECORDS_TO_WORKSPACE: "ADD ALL RECORDS TO WORKSPACE",
  ADD_SELECTED_RECORDS_TO_WORKSPACE: "ADD SELECTED RECORDS TO WORKSPACE",
  SAVE_QUERY: "SAVE_QUERY",
  UPDATE_QUERY: "UPDATE_QUERY",
  REPORT: "REPORT",
  REQUESTED_BUYERS: "REQUESTED BUYERS",
  DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
  REMOVE_DUPLICATE: "REMOVE_DUPLICATE",
  SET_DEFAULT: "SET_DEFAULT",
  CREATE_NEW_QUERY: "CREATE NEW QUERY",
};
export const defaultValue = {
  SEARCH_MONTH_RANGE: "SEARCH_MONTH_RANGE",
  PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
  SEARCH_BUYER: "SEARCH_BUYER",
  SEARCH_SELLER: "SEARCH_SELLER",
  RECORDS_SELECTIONS: "RECORDS_SELECTIONS",
  MATCH_EXPRESSIONS: "MATCH_EXPRESSIONS",
};
export const CloseSearchOption = [
  "PRODUCT_DESCRIPTION",
  "CUSTOMS_DESCRIPTION",
  "PRODUCT_DESCRIPTION_EN",
  "HS_CODE_DESCRIPTION",
  "HS_DESCRIPTION",
  "CMDT_CODE",
  "HS_CODE",
];

export const currentCountries = {
  INDIA: "INDIA",
  KAZAKHSTAN: "KAZAKHSTAN",
};
export const ADD_USER_SEARCH_IN_SEARCH_OPTIONS = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESC_EN",
  "PRODUCT_DESCRIPTION_EN",
  "PRODUCT_DESCRIPTION_NATIVE",
  "HS_CODE",
  "CMDT_CODE",
  "CUSTOMS_DESCRIPTION",
  "CONSIGNEE_NAME_NATIVE",
  "CONSIGNEE_NAME_EN",
  "SHIPPER_NAME_NATIVE",
  "SHIPPER_NAME_EN",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME",
  "BUYER_NAME_EN",
  "STD_EXPORTER_NAME",
  "BUYER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME",
  "SUPPLIER_NAME_EN",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
];
export const ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD = [
  "HS_CODE",
  "CMDT_CODE",
];
export const SELECT_OPTIONS_VALIDATE_FIELD = ["HS_CODE", "CMDT_CODE"];

export const analyzeBtn_in_explore_country = ["INDIA", "USA"];

export const MirrorDataPayload = {
  countryCode: "MDT",
  country: "Mirror_table",
  tradeType: "Import",
};

export const silkRoutePayload = {
  country: "SILKROUTE",
  tradeType: "EXIM",
};

export const HS_CODE_DIGIT_CLASSIFICATION_MIN = 5;

export const CHILD_USER_ROLES = ["MODERATOR", "SUPPORT"];

export const FILTER_IMPORTER_EXPORTER_IDENTIFIER = [
  "FILTER_IMPORTER",
  "FILTER_EXPORTER",
];

export const NOT_ACCESS_ERROR_MSG_COUNTRY =
  "You don't have Access to this Country. Contact Administrator to Upgrade Your Plan";
