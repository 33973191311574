// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-inner {
  background: white;
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  height: 308px;
  overflow-y: scroll;
}

#modal .view-more-modal {
  max-width: 1250px !important;
  min-width: 1250px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/CollapseFilterNewUI/Component/ViewMore/ViewMore.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAGE;EACE,4BAAA;EACA,4BAAA;AAAJ","sourcesContent":["#modal-inner {\n  background: white;\n  position: absolute;\n  width: 500px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  border-radius: 5px;\n  height: 308px;\n  overflow-y: scroll;\n}\n\n#modal {\n  .view-more-modal {\n    max-width: 1250px !important;\n    min-width: 1250px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
