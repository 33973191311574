// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-main {
  font-size: 14px;
  color: #676a6c;
}
.password-main .error-icons {
  margin-bottom: -5px;
  font-size: 20px !important;
  margin-right: 5px;
}
.password-main .text-danger {
  color: #f1556c;
  margin-top: 5px;
  padding-left: 3px;
  font-size: 14px;
}
.password-main .text-success {
  color: green;
  margin-top: 5px;
  padding-left: 3px;
  font-size: 14px;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Component/PasswordStrength/PasswordStrength.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;AAAE;EACE,mBAAA;EACA,0BAAA;EACA,iBAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;EAEA,iBAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,eAAA;EAEA,iBAAA;EACA,eAAA;AAAJ;;AAKA;EACE,cAAA;AAFF;;AAIA;EACE,aAAA;AADF","sourcesContent":[".password-main {\n  font-size: 14px;\n  color: #676a6c;\n  .error-icons {\n    margin-bottom: -5px;\n    font-size: 20px !important;\n    margin-right: 5px;\n  }\n  .text-danger {\n    color: #f1556c;\n    margin-top: 5px;\n    // margin-bottom: 6px;\n    padding-left: 3px;\n    font-size: 14px;\n  }\n  .text-success {\n    color: green;\n    margin-top: 5px;\n    // margin-bottom: 6px;\n    padding-left: 3px;\n    font-size: 14px;\n    // display: none;\n  }\n}\n\n.d-block {\n  display: block;\n}\n.d-none {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
