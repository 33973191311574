export const IsMultiSelectedField = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESC_EN",
  "PRODUCT_DESCRIPTION_EN",
  "PRODUCT_DESCRIPTION_NATIVE",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "HS_DESCRIPTION",
  "CMDT_CODE",
  "HS_CODE",
  "SHIPPER_NAME_NATIVE",
  "SHIPPER_NAME_EN",
  "CONSIGNEE_NAME_EN",
  "CONSIGNEE_NAME_NATIVE",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME",
  "BUYER_NAME",
  "STD_EXPORTER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME_EN",
  "SUPPLIER_NAME",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
  "ADDRESS",
  "SEARCH_ALL",
];
export const IsMultiSelectedFieldForAdvanceSearch = [
  "PRODUCT_DESCRIPTION",
  "PRODUCT_DESC_EN",
  "PRODUCT_DESCRIPTION_EN",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "HS_DESCRIPTION",
  "CMDT_CODE",
  "HS_CODE",
  "SHIPPER_NAME_EN",
  "SHIPPER_NAME_NATIVE",
  "CONSIGNEE_NAME_EN",
  "CONSIGNEE_NAME_NATIVE",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME",
  "BUYER_NAME",
  "STD_EXPORTER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME_EN",
  "SUPPLIER_NAME",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
  "ADDRESS",
];
