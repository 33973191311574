import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  IconButton,
  TableRow,
} from "@mui/material";
import { Pagination as MuiPagination } from "@mui/material";
import Pagination from "../PaginationTest/Pagination";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./IosTable.scss";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import TablePagionationDropdown from "../TablePaginationDropdown/tablePagionationDropdown";
import { STICKYCOLUMN, tableCellWidth } from "../StickyTable/enum/Table.enum";
import Sorting from "./Component/Sorting/Sorting";
import { tableEnum } from "./Table.enum";
import moment from "moment";
import { DATEFORMAT } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactToaster, { notify } from "../ReactToaster/ReactToaster";

export default function IosTable({
  columns,
  rows,
  handleSelectedShipments,
  getSelectedRow,
  tableScroll,
  height,
  isExplore,
  isWorkSpace,
  handleDescendingAndAscendingOrder,
  descendingAndAscending,
  handleCollapseOpenModal,
  showPagination,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalAvailableData,
  page,
  handleChangePage,
  isGlobalSearch,
  hashMarkColumns,
  availableCountry,
  handleOpenModel,
  currentCountry,
  countryDetails,
}) {
  useEffect(() => {
    const elem = document.getElementsByClassName("MuiTableContainer-root");
    let a = elem[1];
    let b = elem[0];
    if (a) {
      a.addEventListener("scroll", (e) => {
        b.scrollLeft = a.scrollLeft;
      });
    }
  }, []);

  const getLeftPosition = (key) => {
    let left = "";
    left =
      key === "add" || key === "entity"
        ? "0px"
        : key === "checkbox"
        ? "75px"
        : key === "HS_CODE" || key === "CMDT_CODE"
        ? "150px"
        : tableEnum?.scrollableColumns.includes(key)
        ? columns.filter((e) => e?.key === "HS_CODE" || e?.key === "CMDT_CODE")
            .length === 0
          ? "150px"
          : "300px"
        : "";

    return left;
  };
  function getColumnWidth(column) {
    if (tableCellWidth?.width_150.includes(column?.key)) return 150;
    if (tableCellWidth?.width_75.includes(column?.key)) return 75;
    if (tableCellWidth?.maxWidth_column?.includes(column?.key)) return 420;
    if (tableCellWidth?.width_300.includes(column?.key)) return 300;
    return 270;
  }

  const handleCopy = (row, exploreValue) => {
    navigator.clipboard
      .writeText(exploreValue ? row : row?.name)
      .then(() => {
        notify();
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Paper id="ios_dataTable-table" sx={{ width: "100%" }}>
      <ReactToaster />
      <TableContainer
        sx={{ height: "100%", overflowX: tableScroll ? "scroll" : "hidden" }}
      >
        <Table
          stickyHeader
          style={{ height: height, userSelect: "none" }}
          aria-label="sticky table"
          size="small"
        >
          <TableHead>
            <TableRow style={{ height: 61 }}>
              {columns &&
                !!columns?.length &&
                columns?.map((column, index) => {
                  if (
                    column?.title !== "isFavourite" &&
                    column?.title !== "isFavouriteShipment"
                  ) {
                    return (
                      <>
                        <TableCell
                          // id={ }
                          className="ios_tableHeader"
                          key={index}
                          style={{
                            position: STICKYCOLUMN.includes(column?.key)
                              ? "sticky"
                              : "",
                            left: getLeftPosition(column?.key),
                            zIndex: STICKYCOLUMN.includes(column?.key) ? 3 : "",

                            minWidth: getColumnWidth(column),
                            maxWidth: getColumnWidth(column),
                            borderRadius: index === 0 ? "8px 0px 0px 0px" : "",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            {column?.title}
                            {isExplore || isWorkSpace ? (
                              <>
                                {descendingAndAscending &&
                                descendingAndAscending.hasOwnProperty(
                                  column?.key
                                ) ? (
                                  <Sorting
                                    column={column?.key}
                                    handleDescendingAndAscendingOrder={
                                      handleDescendingAndAscendingOrder
                                    }
                                    descendingAndAscending={
                                      descendingAndAscending
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </TableCell>
                      </>
                    );
                  }
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              !!rows?.length &&
              rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      if (column !== undefined) {
                        if (column?.key === "add") {
                          return (
                            <>
                              <TableCell
                                style={{
                                  position: "sticky",
                                  left: "0px",
                                  zIndex: 2,
                                  minWidth: getColumnWidth(column),
                                  maxWidth: getColumnWidth(column),
                                }}
                                key={index}
                                className="ios_tableRow"
                              >
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    handleCollapseOpenModal(columns, row, index)
                                  }
                                >
                                  <RemoveRedEyeIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </>
                          );
                        } else if (column.key === "entityForComposition") {
                          return (
                            <TableCell
                              style={{
                                position: "sticky",
                                left: "0px",
                                zIndex: 2,
                                padding: "18px",
                                color: "#6c757d",
                                cursor: "pointer",
                                minWidth: getColumnWidth(column),
                                maxWidth: getColumnWidth(column),
                              }}
                              className="ios_tableRow"
                              onClick={() => handleCollapseOpenModal(row)}
                            >
                              {row?.entity}
                            </TableCell>
                          );
                        } else if (column?.key === "checkbox") {
                          return (
                            <>
                              <TableCell
                                style={{
                                  position: "sticky",
                                  left: "75px",
                                  zIndex: 2,
                                  minWidth: getColumnWidth(column),
                                  maxWidth: getColumnWidth(column),
                                }}
                                className="ios_tableRow"
                              >
                                <Checkbox
                                  onClick={(e) =>
                                    handleSelectedShipments(e, row?._id)
                                  }
                                  size="small"
                                  checked={
                                    getSelectedRow?.includes(row?._id)
                                      ? true
                                      : false
                                  }
                                />
                              </TableCell>
                            </>
                          );
                        } else {
                          let value = row[column?.key];
                          if (
                            tableEnum.formateDateColumns.includes(column?.key)
                          ) {
                            return (
                              <TableCell
                                key={column + index + row}
                                className={"ios_tableRow"}
                                style={{
                                  color: "black",
                                  minWidth: getColumnWidth(column),
                                  maxWidth: getColumnWidth(column),
                                }}
                              >
                                {moment(value).format(DATEFORMAT?.format)}
                              </TableCell>
                            );
                          } else if (
                            column?.title !== "isFavourite" &&
                            column?.title !== "isFavouriteShipment"
                          ) {
                            return (
                              <>
                                <TableCell
                                  className={
                                    column?.title === "GOODS DESCRIPTION" ||
                                    tableEnum?.scrollableColumns.includes(
                                      column?.key
                                    )
                                      ? "scroll ios_tableRow"
                                      : "ios_tableRow"
                                  }
                                  key={column?.key}
                                  style={{
                                    color: "black",
                                    position: STICKYCOLUMN.includes(column?.key)
                                      ? "sticky"
                                      : "",

                                    left: getLeftPosition(column?.key),
                                    zIndex: STICKYCOLUMN.includes(column?.key)
                                      ? 2
                                      : "",
                                    overflowX: "auto",
                                    cursor:
                                      isGlobalSearch &&
                                      hashMarkColumns?.length &&
                                      hashMarkColumns?.includes(column?.key)
                                        ? "pointer"
                                        : "",
                                    minWidth: getColumnWidth(column),
                                    maxWidth: getColumnWidth(column),
                                  }}
                                  onClick={() => {
                                    if (
                                      isGlobalSearch &&
                                      hashMarkColumns?.length &&
                                      hashMarkColumns?.includes(column?.key)
                                    ) {
                                      if (
                                        availableCountry &&
                                        availableCountry?.length > 0 &&
                                        availableCountry.filter(
                                          (e) =>
                                            e.country.toUpperCase() ===
                                            currentCountry.toUpperCase()
                                        )[0]
                                      ) {
                                        handleOpenModel(
                                          false,
                                          row[column?.key],
                                          currentCountry?.toUpperCase(),
                                          countryDetails?.tradeType,
                                          countryDetails?.bl_flag,
                                          countryDetails?.dateRange
                                        );
                                      } else {
                                        handleOpenModel(
                                          true,
                                          row[column?.key],
                                          currentCountry?.toUpperCase(),
                                          countryDetails?.tradeType,
                                          countryDetails?.bl_flag,
                                          countryDetails?.dateRange
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {isGlobalSearch ? (
                                    hashMarkColumns?.length &&
                                    hashMarkColumns?.includes(column?.key) ? (
                                      "#########"
                                    ) : (
                                      value
                                    )
                                  ) : (isExplore &&
                                      column?.key === "IMPORTER_NAME") ||
                                    column?.key === "SUPPLIER_NAME" ? (
                                    <>
                                      {value}{" "}
                                      <ContentCopyIcon
                                        onClick={() => handleCopy(value, true)}
                                        fontSize="18px"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "1.1rem",
                                          color: "#005d91",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              </>
                            );
                          }
                        }
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          display: "flex",
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
          alignItems: "center",
        }}
      >
        {showPagination === true && (
          <>
            <TablePagionationDropdown
              handleChange={handleChangeRowsPerPage}
              value={rowsPerPage}
            />
            {/* {!isExplore && <MuiPagination
                        count={Math.ceil(totalAvailableData / rowsPerPage)}
                        page={Number(page) + 1}
                        onChange={handleChangePage}
                    />} */}
            {totalAvailableData > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={Number(page) + 1}
                totalCount={Number(totalAvailableData)}
                pageSize={rowsPerPage}
                onPageChange={(page) => handleChangePage(page)}
              />
            )}
          </>
        )}
      </div>
    </Paper>
  );
}
