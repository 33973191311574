// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#searchComponentExplore .clear-search-input {
  font-style: normal;
  font-size: 1.4em;
  -webkit-user-select: none;
          user-select: none;
  color: #6c757d;
  margin-left: -65px !important;
  right: 30px;
  cursor: pointer;
}
@media (min-width: 458px) {
  #searchComponentExplore .clear-search-input {
    margin-left: -23px !important;
    margin-top: 5px;
  }
}
@media (max-width: 457px) {
  #searchComponentExplore .clear-search-input {
    right: 0;
    top: 1px;
    padding: 5px 10px;
  }
}
#searchComponentExplore .inline-container {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/Component/SearchComponent/search.scss"],"names":[],"mappings":"AACE;EAEE,kBAAA;EACA,gBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;EACA,6BAAA;EACA,WAAA;EACA,eAAA;AADJ;AAEI;EATF;IAUI,6BAAA;IACA,eAAA;EACJ;AACF;AAAI;EAbF;IAcI,QAAA;IACA,QAAA;IACA,iBAAA;EAGJ;AACF;AADE;EACE,qBAAA;AAGJ","sourcesContent":["#searchComponentExplore {\n  .clear-search-input {\n    // position: absolute;\n    font-style: normal;\n    font-size: 1.4em;\n    user-select: none;\n    color: #6c757d;\n    margin-left: -65px !important;\n    right: 30px;\n    cursor: pointer;\n    @media (min-width: 458px) {\n      margin-left: -23px !important;\n      margin-top: 5px;\n    }\n    @media (max-width: 457px) {\n      right: 0;\n      top: 1px;\n      padding: 5px 10px;\n    }\n  }\n  .inline-container {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
