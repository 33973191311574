import React, { useEffect, useState } from "react";
import "./VideoPlayList.scss";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export const VideoPlayList = ({
  data,
  onVideoClick,
  activeIndex,
  setActiveIndex,
  title,
  nameOfIndexVIdeo,
}) => {
  const handleVideoClick = (videoData, index) => {
    setActiveIndex(index);
    onVideoClick(videoData, index);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
        paddingRight: "15px",
      }}
    >
      {nameOfIndexVIdeo &&
        nameOfIndexVIdeo.length > 0 &&
        nameOfIndexVIdeo.map((e, index) => (
          <Box
            key={index}
            item
            onClick={() => handleVideoClick(e, title)}
            className={activeIndex[title] === e._id ? "active-video" : ""}
          >
            <div>
              <label
                htmlFor={`collapse-${index}`}
                className="collapse-header"
                style={{
                  background:
                    activeIndex[title] === e._id ? "#005d91" : "#ffffff",
                  color: activeIndex[title] === e._id ? "white" : "",
                }}
              >
                <Tooltip
                  title={e.blob_name.split(".").slice(0, -1).join(".")}
                  arrow
                >
                  <div
                    className="ellipsisName"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {activeIndex[title] === e._id ? (
                      <PauseIcon style={{ marginRight: "8px" }} />
                    ) : (
                      <PlayCircleOutlineIcon style={{ marginRight: "8px" }} />
                    )}
                    <h4
                      className="collapse__h6 ellipsisName"
                      style={{
                        marginRight: "auto",
                        color: activeIndex[title] === e._id ? "white" : "",
                      }}
                    >
                      {e.blob_name.split(".").slice(0, -1).join(".")}
                    </h4>
                  </div>
                </Tooltip>
                <div className="notranslate">
                  <span
                    style={{
                      color: activeIndex[title] === e._id ? "white" : "#005d91",
                    }}
                  >
                    {e?.timeStamp}
                  </span>
                </div>
              </label>
            </div>
          </Box>
        ))}
    </Box>
  );
};
