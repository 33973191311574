// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fav-company-card-container {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  gap: 25px !important;
  overflow-y: scroll;
  padding: 0.6%;
}
@media (min-width: 769px) and (max-width: 1199px) {
  .fav-company-card-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 769px) and (max-width: 1439px) {
  .fav-company-card-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 700px) and (max-width: 1135px) {
  .fav-company-card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 0px) and (max-width: 699px) {
  .fav-company-card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.fav-company-card-container .info-card {
  max-width: 100% !important;
}
.fav-company-card-container::-webkit-scrollbar {
  width: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/FavouriteCompanyTable/FavCompanyCard.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,gDAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;AACF;AACE;EAPF;IAQI,gDAAA;EAEF;AACF;AAAE;EAXF;IAYI,gDAAA;EAGF;AACF;AADE;EAfF;IAgBI,gDAAA;EAIF;AACF;AAHE;EAlBF;IAmBI,gDAAA;EAMF;AACF;AAJE;EACE,0BAAA;AAMJ;AAHE;EACE,UAAA;AAKJ","sourcesContent":[".fav-company-card-container {\n  display: grid !important;\n  grid-template-columns: repeat(5, 1fr) !important;\n  gap: 25px !important;\n  overflow-y: scroll;\n  padding: 0.6%;\n\n  @media (min-width: 769px) and (max-width: 1199px) {\n    grid-template-columns: repeat(3, 1fr) !important;\n  }\n\n  @media (min-width: 769px) and (max-width: 1439px) {\n    grid-template-columns: repeat(3, 1fr) !important;\n  }\n\n  @media (min-width: 700px) and (max-width: 1135px) {\n    grid-template-columns: repeat(2, 1fr) !important;\n  }\n  @media (min-width: 0px) and (max-width: 699px) {\n    grid-template-columns: repeat(1, 1fr) !important;\n  }\n\n  .info-card {\n    max-width: 100% !important;\n  }\n\n  &::-webkit-scrollbar {\n    width: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
