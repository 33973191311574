import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "../../Asset/images/close.png";
import WarningIcon from "../../Asset/images/warning.png";
import InfoIcon from "../../Asset/images/info.png";
import SuccessIcon from "../../Asset/images/success.png";
import "./error.scss";
import { useNavigate } from "react-router-dom";
import LockIcon from "../../Asset/images/lock.png";
import ErrorData from "../../utils/ErrorHandling";
import { Button } from "@mui/material";
import {
  NO_ACCESS_MIRROR_FOR_CHILD,
  not_access_mirrorData,
  not_access_silkRoute,
} from "../../utils/LoadingMsg";
const borderColorCode = {
  Error: "red",
  Warning: "#ffac15",
  Success: "#348f34",
  Access_Denied: "#276cab",
  Info: "#42c0fb",
  LlmModal: "#03A9F4",
};

export default function Error(props) {
  const [counter, setCounter] = useState(10);
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const redirectPage = () => {
    if (
      props?.errorMsg?.title?.toUpperCase() === "ERROR" &&
      props.errorMsg?.content === ErrorData.SOMETHING_WENT_WRONG
    ) {
      window.location.reload();
    } else if (
      (props?.mirrorData &&
        props.errorMsg?.content === not_access_mirrorData?.content) ||
      props.errorMsg?.content === NO_ACCESS_MIRROR_FOR_CHILD?.content
    ) {
      navigate("/consumers/dashboard");
    } else if (props.errorMsg?.content === ErrorData?.SESSION_ACTIVATE) {
      props.directLogin();
    } else if (props.errorMsg?.content === not_access_silkRoute?.content) {
      navigate("/consumers/dashboard");
    } else {
      if (counter !== 0) {
        navigate("/consumers/logout");
      } else {
        props.setOpen(false);
      }
    }
    props.setOpen(false);
  };

  useEffect(() => {
    if (props?.errorMsg.content === ErrorData?.SESSION_TIMEOUT && counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    if (
      props?.errorMsg.content === ErrorData?.SESSION_TIMEOUT &&
      counter === 0
    ) {
      navigate("/consumers/logout", { state: { autoLogOut: "autoLogOut" } });
    }
    if (props?.errorMsg?.content?.includes("Adx Token has Expired Now")) {
      props.setOpen(false);
    }
    if (
      props?.errorMsg?.content?.includes(
        "Please raise access for atleast one bl country !!"
      )
    ) {
      props.setOpen(false);
    }
  }, [counter, props?.errorMsg]);

  return (
    <div id="error-loader">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props?.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props?.open}>
          <Box
            id="error-box"
            style={{
              borderColor: props?.errorMsg?.title
                ? borderColorCode.hasOwnProperty(
                    props?.errorMsg?.title?.split(" ").join("_")
                  )
                  ? borderColorCode[
                      props?.errorMsg?.title?.split(" ").join("_")
                    ]
                  : borderColorCode?.Error
                : borderColorCode?.Warning,
            }}
          >
            {/* <div>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                {props.errorMsg.title === "Warning" ? (
                  <img src={WarningIcon} alt="Warning" width="100px" />
                ) : props.errorMsg.title === "Info" ? (
                  <img src={InfoIcon} alt="Info" width="90px" />
                ) : props.errorMsg.title === "Success" ? (
                  <img src={SuccessIcon} alt="Success" width="90px" />
                ) : props.errorMsg.title === "Not Access" ? (
                  <img src={LockIcon} alt="Success" width="90px" />
                ) : (
                  <img src={CloseIcon} alt="CloseIcon" />
                )}
              </Box>
              <div>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    fontSize: "28px",
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: "10px",
                    opacity: 0.9,
                    color: "#595959",
                  }}
                >
                  {props.errorMsg.title ? props.errorMsg.title : "error"}
                </Typography>
                <Typography
                  id="transition-modal-description"
                  sx={{
                    marginTop: "10px",
                    fontSize: "1.125em",
                    fontweight: 300,
                    wordwrap: "break-word",
                    textAlign: "center",
                    opacity: 0.6,
                  }}
                >
                  {props.errorMsg.content && props.errorMsg.content}
                </Typography>
              </div></div>
            <Box sx={{ textAlign: "center", mt: 3 }} id="err-button">
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <span
                  onClick={() =>
                    props.errorMsg?.content === ErrorData.SOMETHING_WENT_WRONG ||
                      props.errorMsg?.content === ErrorData.SESSION_TIMEOUT ||
                      props.errorMsg?.content === "User Details Updated Successfully , Please Login Again"
                      ? redirectPage()
                      : props.setOpen(false)
                  }
                  className="OK-button"
                >
                  OK
                </span>
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              {props.errorMsg?.content === ErrorData.SOMETHING_WENT_WRONG ||
                props.errorMsg?.content === ErrorData.SESSION_TIMEOUT ||
                props.errorMsg?.content === "User Details Updated Successfully , Please Login Again"
                ? ""
                : props.errorMsg?.title !== "Success" && (
                  <Box id="refreshWidth" sx={{ mt: 5, textAlign: "center" }}>
                    <a href="" id="anchor" rel="noopener noreferrer">
                      Refresh Page & Try Again
                    </a>
                  </Box>
                )}
            </Box> */}

            <div style={{}} className="all_model">
              <div style={{ margin: "10px" }}>
                {props?.errorMsg?.title === "Warning" ? (
                  <img
                    src={WarningIcon}
                    alt="Warning"
                    className="all_model_image"
                  />
                ) : props?.errorMsg?.title === "Info" ? (
                  <img src={InfoIcon} alt="Info" className="all_model_image" />
                ) : props?.errorMsg?.title === "Success" ? (
                  <img
                    src={SuccessIcon}
                    alt="Success"
                    className="all_model_image"
                  />
                ) : props?.errorMsg?.title === "Access Denied" ? (
                  <img
                    src={LockIcon}
                    alt="Success"
                    className="all_model_image"
                  />
                ) : (
                  <img src={CloseIcon} alt="CloseIcon" />
                )}
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", flex: "1" }}
              >
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "rgb(50, 58, 70)",
                  }}
                >
                  {props?.errorMsg?.title ? props?.errorMsg?.title : "error"}
                </span>
                <div style={{ fontSize: "14px", marginTop: "20px" }}>
                  {props?.errorMsg?.content === ErrorData?.SESSION_TIMEOUT ||
                  props?.errorMsg?.content === "Adx Token has Expired Now" ? (
                    <>
                      <div>{ErrorData?.SESSION_TIMEOUT}</div>
                      {!props?.isAutoLogOutFlag && (
                        <span>
                          You will be logged out in{" "}
                          <span style={{ margin: "0px 5px", color: "red" }}>
                            {counter}
                          </span>
                          seconds
                        </span>
                      )}
                    </>
                  ) : props?.errorMsg?.content ? (
                    props?.errorMsg?.content
                  ) : (
                    ErrorData?.SOMETHING_WENT_WRONG
                  )}
                </div>
              </div>
            </div>
            <hr style={{ margin: "0px" }} />
            <div style={{ textAlign: "end", padding: "10px" }}>
              {!props?.llmDataPopUp && (
                <Button
                  className=".OK-button"
                  style={{
                    backgroundColor: props?.errorMsg?.title
                      ? borderColorCode.hasOwnProperty(
                          props?.errorMsg?.title?.split(" ").join("_")
                        )
                        ? borderColorCode[
                            props?.errorMsg?.title?.split(" ").join("_")
                          ]
                        : borderColorCode?.Error
                      : borderColorCode?.Warning,
                    color: "white",
                    fontWeight: "600",
                  }}
                  onClick={() =>
                    props?.errorMsg?.content ===
                      ErrorData?.SOMETHING_WENT_WRONG ||
                    props?.errorMsg?.content === ErrorData?.SESSION_TIMEOUT ||
                    props?.errorMsg?.content === ErrorData?.SESSION_ACTIVATE ||
                    props.errorMsg?.content ===
                      not_access_mirrorData?.content ||
                    props.errorMsg?.content === not_access_silkRoute?.content ||
                    props?.errorMsg?.content ===
                      "User Details Updated Successfully , Please Login Again" ||
                    props.errorMsg?.content ===
                      NO_ACCESS_MIRROR_FOR_CHILD?.content
                      ? redirectPage()
                      : props?.setOpen(false)
                  }
                >
                  {props?.errorMsg?.content === ErrorData?.SESSION_ACTIVATE
                    ? "YES"
                    : "OK"}
                </Button>
              )}
              {props?.llmDataPopUp && (
                <>
                  <Button
                    style={{
                      backgroundColor: "#42c0fb",
                      color: "white",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      window.location.reload();
                      props?.setOpen(false);
                    }}
                  >
                    TRY AGAIN
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#42c0fb",
                      color: "white",
                      fontWeight: "600",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      props?.setRequestForData(true);
                    }}
                  >
                    REQUEST DATA
                  </Button>
                </>
              )}
              {props?.errorMsg?.content === ErrorData?.SESSION_ACTIVATE && (
                <Button
                  className=".OK-button"
                  style={{
                    marginLeft: "1rem",
                    backgroundColor: props?.errorMsg?.title
                      ? borderColorCode.hasOwnProperty(
                          props?.errorMsg?.title?.split(" ").join("_")
                        )
                        ? borderColorCode[
                            props?.errorMsg?.title?.split(" ").join("_")
                          ]
                        : borderColorCode?.Error
                      : borderColorCode?.Warning,
                    color: "white",
                    fontWeight: "600",
                  }}
                  onClick={() => props?.setOpen(false)}
                >
                  No
                </Button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
