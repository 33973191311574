import PasswordRecoveryComponent from "../../Component/PasswordRecoveryComponent/PasswordRecovery";

const PasswordRecovery = (props) => {
  
  return (
      <PasswordRecoveryComponent name="passwordRecovery" data={props}  />
  );
};

export default PasswordRecovery;
