// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combine-tab-1 #dataTable-table tbody {
  visibility: collapse;
}
.combine-tab-1 ::-webkit-scrollbar {
  display: none;
}

.combine-tab-2 #dataTable-table thead {
  visibility: collapse;
}

.ios_table_one #ios_dataTable-table tbody {
  display: none;
}
.ios_table_one ::-webkit-scrollbar {
  display: none;
}

.ios_table_two #ios_dataTable-table thead {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Component/Table/CombineTable.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;AAAJ;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,oBAAA;AAFF;;AASE;EACE,aAAA;AANJ;AASE;EACE,aAAA;AAPJ;;AAWA;EACE,aAAA;AARF","sourcesContent":[".combine-tab-1 {\n  #dataTable-table tbody {\n    visibility: collapse;\n  }\n\n  ::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.combine-tab-2 #dataTable-table thead {\n  visibility: collapse;\n}\n\n// .combine-tab-1-safari {\n//     display: none;\n// }\n.ios_table_one {\n  #ios_dataTable-table tbody {\n    display: none;\n  }\n\n  ::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.ios_table_two #ios_dataTable-table thead {\n  display: none;\n}\n\n// .combine-tab-1-safari {\n//     display: none;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
