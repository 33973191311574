// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  right: -6px;
  position: absolute;
  top: -11px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Component/Table/Component/Sorting/Sorting.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;AACF","sourcesContent":[".triangle-up {\n  width: 0;\n  height: 0;\n  border-left: 5px solid transparent;\n  border-right: 5px solid transparent;\n  right: -6px;\n  position: absolute;\n  top: -11px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
