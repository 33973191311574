export const tableEnum = {
  scrollableColumns: [
    "PRODUCT_DESCRIPTION",
    "PRODUCT_DESCRIPTION_EN",
    "HS_CODE_DESCRIPTION",
    "PRODUCT_DESC_EN",
    "CUSTOMS_DESCRIPTION",
  ],

  formateDateColumns: [
    "DATE_OF_ISSUE",
    "ACTUAL_ARRIVAL_DATE",
    "EXP_DATE",
    "REGISTRATION_DATE",
    "DATE",
    "EXPORT_DATE",
    "SHIPMENT_DATE",
    "SAILING_DATE",
    "IMP_DATE",
    "IMPORT_DATE",
    "SAILING_DT",
    "DECLARATION_DATE",
  ],
  COPY_ENABLE_FIELDS: [
    "EXPORTER_NAME",
    "BUYER_NAME",
    "IMPORTER_NAME_EN",
    "BUYER_NAME_EN",
    "EXPORTER_NAME_AND_ADDRESS",
    "SHIPPER_NAME",
    "SHIPPER_NAME_EN",
    "EXPORTER_NAME_VN",
    "EXPORTER_NAME_EN",
    "CONSIGNEE_NAME",
    "CONSIGNEE_NAME_EN",
    "NOTIFY_NAME",
    "NOTIFY_NAME_EN",
    "IMPORTER_NAME",
    "SUPPLIER_NAME",
  ],
};
