import React, { useCallback, useState } from "react";
import {
  Container,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";
import { useLocation, useNavigate } from "react-router-dom";
import "./country.scss";
import { useEffect } from "react";
import CountryRegion from "./components/CountryRegion";
import {
  getCustomCountryData,
  getBlCountryData,
  getSilkCountryData,
} from "../../Services/service";
import throwError from "../../utils/throwError";
import Countries from "./components/Countries";
import ErrorData from "../../utils/ErrorHandling";
import ToggleSwitch from "../../Component/ToggleSwitch/ToggleSwitch";
import {
  Retrieving_Countries,
  not_access_silkRoute,
} from "../../utils/LoadingMsg";
import { logger } from "../../Logger/Logger";
import ModalComponent from "../../Component/Modal/modal";
import RequestCompanyModal from "./components/RequestCompanyModal";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const getTradeTypeVar = {
  all: false,
  import: false,
  export: false,
  exim: false,
};

const Country = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [getTradeType, setTradeType] = useState({});
  const tradeType = new URLSearchParams(search).get("tradeType");
  const [countryRegion, setCountryRegion] = useState([
    "ASIA",
    "SOUTH AFRICA",
    "AMERICA",
    "SOUTH AMERICA",
    "CENTRAL AMERICA",
    "NORTH AMERICA",
  ]);
  const [currentRegion, setCurrentRegion] = useState([]);
  const [allCountryData, setAllCountryData] = useState([]);
  const [currentFilteredCountries, setCurrentFilteredCountries] = useState([]);
  const [userAccessibleCountry, setUserAccessibleCountry] = useState([]);

  // Search
  const [searchValue, setSearch] = useState("");

  // Switch between custom and bl countries
  const [switchValue, setSwitchValue] = useState(false);
  const [countryType, setCountryType] = useState({
    CUSTOM: true,
    BL: false,
    SILKROUTE: false,
  });

  //modal
  const [open, setOpen] = useState(false);
  const [countryDetail, setCountryDetail] = useState([]);

  const catchBlock = (err) => {
    const errorForLogger = `countriesList ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content: err?.msg,
    });
  };

  const getSelectedTradeType = () => {
    const trueCountryTypes = Object.keys(countryType).filter(
      (key) => countryType[key] === true
    );
    return trueCountryTypes.length > 0 ? trueCountryTypes[0] : "";
  };

  useEffect(() => {
    if (tradeType) {
      setTradeType({
        ...getTradeTypeVar,
        [tradeType]: true,
      });
      setError(false);

      const trueCountryTypes = Object.keys(countryType).filter(
        (key) => countryType[key] === true
      );
      return trueCountryTypes.length > 0
        ? getCountryList(trueCountryTypes[0])
        : "";
    }
  }, [tradeType]);

  const filterCountryData = (e) => {
    setTimeout(() => {
      if (e === "left") {
        setCountryType({
          CUSTOM: true,
        });
      } else if (e === "center") {
        setCountryType({
          SILKROUTE: true,
        });
      } else if (e === "right") {
        setCountryType({
          BL: true,
        });
      }
      getCountryList(
        e === "left"
          ? "CUSTOM"
          : e === "center"
          ? "SILKROUTE"
          : e === "right"
          ? "BL"
          : ""
      );
    }, 500);
  };

  // Clear input field
  function clearInput() {
    setSearch("");
    // setSpecificCountryData(getAllCountryData);
  }

  // search country
  useEffect(() => {
    filterCountrySearchValueAndRegionWise(allCountryData, currentRegion);
  }, [searchValue]);

  const filterCountrySearchValueAndRegionWise = (
    countryList,
    currentRegion
  ) => {
    let _currentFilteredCountries = [];

    if (searchValue !== "" && currentRegion && currentRegion?.length > 0) {
      _currentFilteredCountries =
        countryList &&
        countryList?.length > 0 &&
        countryList.filter((elem) => {
          return (
            currentRegion.includes(elem.region.toUpperCase()) &&
            elem?.country?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
    } else if (currentRegion && currentRegion?.length > 0) {
      _currentFilteredCountries =
        countryList &&
        countryList?.length > 0 &&
        countryList.filter((elem) => {
          return currentRegion.includes(elem.region.toUpperCase());
        });
    } else if (searchValue !== "") {
      _currentFilteredCountries =
        countryList &&
        countryList?.length > 0 &&
        countryList.filter((elem) => {
          return elem?.country
            ?.toLowerCase()
            .includes(searchValue.toLowerCase());
        });
    } else if (searchValue === "") {
      _currentFilteredCountries = countryList;
    }
    if (
      _currentFilteredCountries &&
      _currentFilteredCountries?.length <= 0 &&
      searchValue !== ""
    ) {
      setError(true);
      setErrorMsg({
        title: "Info",
        content: ErrorData.COUNTRY_NOT_FOUND,
      });
      setCurrentFilteredCountries([]);
    } else {
      setCurrentFilteredCountries(
        sortCountryWiseData(_currentFilteredCountries)
      );
    }
  };

  const getCountryList = useCallback(
    async (countryType) => {
      try {
        setLoading(true);
        setLoadingMsg(Retrieving_Countries);
        let taxonomyData = null;
        if (countryType === "BL") {
          taxonomyData = await getBlCountryData(
            tradeType === "all" ? "" : tradeType
          );
        } else if (countryType === "CUSTOM") {
          taxonomyData = await getCustomCountryData(
            tradeType === "all" ? "" : tradeType
          );
        } else if (countryType === "SILKROUTE") {
          taxonomyData = await getSilkCountryData("EXIM");
        }
        if (taxonomyData?.status === 500 || taxonomyData?.status === 401) {
          throw throwError(taxonomyData);
        } else {
          if (
            (taxonomyData && taxonomyData?.data) ||
            taxonomyData?.data?.countries?.length > 0 ||
            taxonomyData?.data?.blCountries?.length > 0
          ) {
            setLoading(false);
            let _allCountryData = null;
            if (countryType === "BL") {
              _allCountryData = taxonomyData?.data?.blCountries;
              if (
                !taxonomyData?.data?.blCountries?.length &&
                countryType === "BL"
              ) {
                handleNoAccess("BL Country");
              }
              setUserAccessibleCountry([]);
            } else if (countryType === "CUSTOM") {
              _allCountryData = taxonomyData?.data?.all_available_countries;
              setUserAccessibleCountry(taxonomyData?.data?.countries);
            } else if (countryType === "SILKROUTE") {
              _allCountryData = taxonomyData?.data?.countries;
              if (_allCountryData?.length === 0) {
                handleNoAccess("Silk Route");
              }
              setUserAccessibleCountry([]);
            }
            let _countryRegion = [];
            _allCountryData &&
              _allCountryData?.length > 0 &&
              _allCountryData?.forEach((e) => {
                if (!_countryRegion.includes(e?.region?.toUpperCase())) {
                  return _countryRegion.push(e?.region?.toUpperCase());
                }
              });
            let _currentRegion = [...currentRegion];
            _currentRegion = _currentRegion.filter((e) => {
              if (_countryRegion.includes(e)) {
                return e;
              }
            });
            setCurrentRegion(_currentRegion);
            setCountryRegion(_countryRegion.sort());
            setAllCountryData(_allCountryData);
            filterCountrySearchValueAndRegionWise(
              _allCountryData,
              _currentRegion
            );
          } else {
            // setError(true);
            // setErrorMsg(not_access_silkRoute);
            if (
              !taxonomyData?.data?.blCountries?.length &&
              countryType === "BL"
            ) {
              handleNoAccess("BL Country");
            } else if (
              countryType === "SILKROUTE" &&
              taxonomyData?.data?.countries?.length === 0
            ) {
              handleNoAccess("Silk Route");
            }
          }
        }
      } catch (err) {
        catchBlock(err);
      } finally {
        setLoading(false);
      }
    },
    [tradeType, switchValue, currentRegion]
  );

  const sortCountryWiseData = useCallback(
    (countries) => {
      let sortedArr =
        countries &&
        countries.length &&
        countries.sort(function (a, b) {
          return a.country.localeCompare(b.country);
        });
      return sortedArr && sortedArr?.length > 0 ? sortedArr : [];
    },
    [currentFilteredCountries]
  );

  const handleTicketManager = () => {
    navigate("/ticket-manager", {
      state: {
        countryName: countryDetail?.country ?? countryDetail,
        tradeType: countryDetail?.trade ?? "",
        upgradePlan: true,
      },
    });
  };

  const handleOkayClick = (value) => {
    if (countryType?.BL || countryType?.SILKROUTE) {
      navigate("/consumers/dashboard");
    } else {
      setOpen(false);
    }
  };

  const handleNoAccess = (countryDetail) => {
    setCountryDetail(countryDetail);
    setOpen(true);
  };

  return (
    <Container id="countryExplore" style={{ marginTop: "12px" }} maxWidth="xxl">
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      <div className="main_container">
        <div className="row_dashboard">
          <h4 className="text">
            Explore{" "}
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Country
            </span>
          </h4>
          <div className="trade_type_container">
            <Button
              className={`btn btn-all ${getTradeType.all ? "active" : ""}`}
              onClick={() => navigate(`/countries?tradeType=all`)}
            >
              All
            </Button>
            <div className="import_export_btn_container">
              <Button
                className={`import_export_btn ${
                  getTradeType.import ? "active" : ""
                }`}
                style={{
                  marginRight: "2px",
                  borderRadius: "0px",
                  borderRight: "1px solid #005d91",
                }}
                onClick={() => navigate(`/countries?tradeType=import`)}
              >
                Import
              </Button>
              <Button
                className={`import_export_btn ${
                  getTradeType.export ? "active" : ""
                }`}
                style={{ marginLeft: "2px" }}
                onClick={() => navigate(`/countries?tradeType=export`)}
              >
                Export
              </Button>
            </div>
          </div>
          <div className="country_switch">
            <Box className="dFlex">
              <ToggleSwitch
                onChange={(e) => filterCountryData(e)}
                currentChecked={
                  switchValue ? "BL Countries" : "Custom Countries"
                }
              />
            </Box>
          </div>
        </div>
        <div className="search_container">
          <div className="search_box">
            <FormControl
              sx={{ m: 1, width: "100%" }}
              variant="outlined"
              size="small"
              className="search_field"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search Country
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                value={searchValue}
                onChange={(e) => setSearch(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      {searchValue.length > 0 && (
                        <CloseIcon onClick={() => clearInput()} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search Country"
              />
            </FormControl>
          </div>
          <div className="country_region">
            <CountryRegion
              allCountryData={allCountryData}
              countryRegion={countryRegion}
              currentRegion={currentRegion}
              setCurrentRegion={setCurrentRegion}
              filterCountrySearchValueAndRegionWise={
                filterCountrySearchValueAndRegionWise
              }
            />
          </div>
        </div>
        <div className="country_container">
          <Countries
            currentFilteredCountries={currentFilteredCountries}
            countryType={countryType}
            userAccessibleCountry={userAccessibleCountry}
            handleNoAccess={handleNoAccess}
          />
          <ModalComponent
            open={open}
            setOpen={setOpen}
            modalBody={
              <RequestCompanyModal
                onClick={() => handleTicketManager()}
                countryName={
                  countryDetail?.country?.split("_")?.join(" ") ?? countryDetail
                }
                headerIcon={<LockOpenIcon style={{ fontSize: "30px" }} />}
                headerTitle={`Unlock ${
                  countryDetail?.country?.split("_")?.join(" ") ?? countryDetail
                } Trade Insights`}
                handleCancel={handleOkayClick}
              />
            }
            width="550px"
            isUpgradePlan={true}
          />
        </div>
      </div>
    </Container>
  );
};

export default React.memo(Country);
