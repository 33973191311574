// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse-header {
  padding-left: 25px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 7px 7px 0px;
  box-shadow: 1px 0px 3px 2px rgb(194, 216, 229);
}`, "",{"version":3,"sources":["webpack://./src/Containers/Training/Component/VideoPlayList/VideoPlayList.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,oCAAA;EACA,8BAAA;EACA,8CAAA;AACF","sourcesContent":[".collapse-header {\n  padding-left: 25px;\n  padding-right: 10px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n  border: 1px solid rgb(0 0 0 / 10%);\n  border-radius: 0px 7px 7px 0px;\n  box-shadow: 1px 0px 3px 2px rgba(194, 216, 229, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
