import React, { useEffect, useState } from "react";
import "./user.scss";
import {
  getAccountUsersList,
  getAccountDetails,
  updateUserDetails,
  deleteUser,
  addNewUser,
  addUserCredits,
  getRecordShipmentData,
} from "../../Services/service";
import StickyTable from "../../Component/Table/Table";
import Loading from "../../Component/SuccessLoader/success";
import ModalComponent from "../../Component/Modal/modal";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import DeleteIcon from "../../Asset/images/delete.png";
import Error from "../../Component/ErrorLoader/error";
import {
  Container,
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  Chip,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import OutlinedInput from "@mui/material/OutlinedInput";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import { ButtonComponent } from "../../Component/ButtonComponent/Button";
import {
  Add_User,
  Delete_User,
  Retrieving_Users,
} from "../../utils/LoadingMsg";
import {
  Modal_header,
  UserRole,
  roleType,
  userHeader,
} from "./ManageUser.enum";
import TextFieldComponent from "./Component/TextField";
import { RecordShipment_Payload } from "../../utils/DefaultPayload";
import moment from "moment";
import { translateButtonClick } from "../../utils/CommanFunction";
import { logger } from "../../Logger/Logger";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ManageUser(props) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [accountDetails, setAccountDetails] = useState();
  const [userLimit, setUserLimit] = useState({});
  const [handleEditModal, setHandleEditModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [editUserDetails, setEditUserDetails] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddCrediteModalOpen, setAddCrediteModalOpen] = useState(false);

  const [isNameEmpty, setIsNameEmpty] = useState({
    first_name: false,
    last_name: false,
    role: false,
    email_id: false,
  });
  const [addCreditEmpty, setAddCreditEmpty] = useState(false);

  const [clickedUserId, setClickedUserId] = useState("");
  const [userListTableHeader, setUserListTableHeader] = useState([]);
  const [availableCountry, setAvailableCountry] = useState([]);
  const [selectedAvailableCountry, setSelectedAvailableCountry] = useState([]);
  const [isAvailableCreaditChecked, setIsAvailableCreaditChecked] =
    useState(false);
  const [addCreditValue, setAddCreditValue] = useState("");

  const setErrorForDashboard = (err) => {
    const errorForLogger = `MangeUser:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? err?.msg
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  const getUserAccountList = () => {
    setLoading(true);
    setLoadingMsg(Retrieving_Users);
    getAccountUsersList(props?.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            const arr = [];
            e?.data &&
              e?.data?.length > 0 &&
              e?.data?.forEach((e) => {
                e.created_ts = moment(e?.created_ts).format("ddd MMM D YYYY");
                arr.push(e);
              });
            setUsersList(arr);
            setUserLimit({
              allotedLimit: e?.userCreationAllotedLimit,
              consumedLimit: e?.userCreationConsumedLimit,
            });
            let header = [...userHeader];
            if (e?.data[0]?.role === UserRole?.ADMINISTRATOR) {
              setUserListTableHeader(header);
            } else {
              header?.splice(7, 1);
              setUserListTableHeader(header);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  useEffect(() => {
    getUserAccountList();
    setLoading(true);

    getAccountDetails(props?.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            setAccountDetails(e?.data);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });

    getRecordShipmentData(RecordShipment_Payload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          let countryArr = e?.countryNames ? [...e?.countryNames] : [];
          countryArr.push({
            code_iso_3: "BL",
            country: "BL COUNTRIES",
          });
          setAvailableCountry(countryArr);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  }, []);

  const handleEditUser = (data) => {
    setModalHeader(Modal_header?.Edit_User_Details);
    let available_countries = data?.available_countries;
    if (data?.bl_selected) {
      if (!available_countries?.includes("BL")) {
        available_countries.push("BL");
      }
    }
    setSelectedAvailableCountry(available_countries);
    setHandleEditModal(true);
    setEditUserDetails({
      _id: data?._id,
      email_id: data?.email_id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      mobile_no: data?.mobile_no,
      // role: data._id !== props.CLIENT_USER_ID ? data.role : "",
      role: data?.role,
    });
  };

  const handleEditUserDetails = (e) => {
    setEditUserDetails({
      ...editUserDetails,
      [e.target.name]: e?.target?.value,
    });
    if (isNameEmpty) {
      if (e?.target?.value?.length > 0) {
        setIsNameEmpty({
          ...isNameEmpty,
          [e.target.name]: false,
        });
      }
    }
  };

  const validationForUpdateData = () => {
    if (
      editUserDetails?.first_name === "" ||
      editUserDetails?.first_name === undefined
    ) {
      setIsNameEmpty({
        ...isNameEmpty,
        first_name: true,
      });
      return false;
    }
    if (modalHeader === Modal_header?.Add_User_Details) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(
          editUserDetails?.email_id
        )
      ) {
      } else {
        setIsNameEmpty({
          ...isNameEmpty,
          email_id: true,
        });
        return false;
      }
    }
    if (
      editUserDetails?.last_name === "" ||
      editUserDetails?.last_name === undefined
    ) {
      setIsNameEmpty({
        ...isNameEmpty,
        last_name: true,
      });
      return false;
    }
    if (editUserDetails?.role === "" || editUserDetails?.role === undefined) {
      setIsNameEmpty({
        ...isNameEmpty,
        role: true,
      });
      return false;
    }
    return true;
  };

  const savedClicked = () => {
    if (validationForUpdateData() === false) {
      return;
    }
    if (modalHeader === Modal_header?.Edit_User_Details) {
      const _selectedAvailableCountry = [...selectedAvailableCountry];
      setHandleEditModal(false);
      setLoading(true);
      setLoadingMsg(Add_User);
      let savedPayload = { ...editUserDetails };
      savedPayload.allocated_countries =
        _selectedAvailableCountry?.length > 0 &&
        _selectedAvailableCountry.filter((country) => country !== "BL");
      savedPayload.bl_selected = _selectedAvailableCountry.includes("BL")
        ? true
        : _selectedAvailableCountry.length > 0
        ? false
        : true;
      const userId = savedPayload?._id;
      delete savedPayload?._id;
      savedPayload.account_id = props?.CLIENT_ACCOUNT_ID;
      updateUserDetails(userId, savedPayload)
        .then((e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw throwError(e);
          } else {
            if (e?.data) {
              setTimeout(() => {
                setLoading(false);
              }, 1500);
              getUserAccountList();
            }
          }
        })
        .catch((err) => {
          setErrorForDashboard(err);
        });
    } else {
      setHandleEditModal(false);
      setLoading(true);
      setLoadingMsg(Add_User);
      const _selectedAvailableCountry = [...selectedAvailableCountry, "MDT"];
      const _editUserDetails = { ...editUserDetails };
      _editUserDetails.is_credits_allotted = isAvailableCreaditChecked;
      _editUserDetails.allocated_countries =
        _selectedAvailableCountry.length > 0 &&
        _selectedAvailableCountry.filter((country) => country !== "BL");
      _editUserDetails.bl_selected = _selectedAvailableCountry.includes("BL")
        ? true
        : _selectedAvailableCountry.length > 0
        ? false
        : true;
      _editUserDetails.account_id = props?.CLIENT_ACCOUNT_ID;
      addNewUser(_editUserDetails)
        .then((e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw throwError(e);
          } else {
            if (e?.data) {
              setLoading(false);
              if (
                e?.data?.activation_email_id === undefined &&
                e?.data?.data?.type === "CONFLICT"
              ) {
                setHandleEditModal(false);
                setError(true);
                setErrorMsg({
                  title: e?.data?.data?.msg,
                  content: e?.data?.data?.desc,
                });
              } else if (e?.data?.message) {
                setLoading(false);
              } else {
                getUserAccountList();
                setTimeout(() => {
                  setLoading(false);
                }, 1500);
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setErrorForDashboard(err);
        });
    }
    setSelectedAvailableCountry([]);
  };

  const deleteClicked = () => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    setLoadingMsg(Delete_User);
    deleteUser(clickedUserId)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            if (e?.data?.msg) {
              setTimeout(() => {
                setLoading(false);
              }, 1500);
              getUserAccountList();
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  const handleDeleteUser = (data) => {
    setIsDeleteModalOpen(true);
    setClickedUserId(data?._id);
  };

  const handleAddCredit = (data) => {
    setAddCrediteModalOpen(true);
    setClickedUserId(data?._id);
  };
  const handleAddUser = () => {
    setSelectedAvailableCountry([]);
    setEditUserDetails({});
    setHandleEditModal(true);
    setModalHeader(Modal_header?.Add_User_Details);
  };

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value && value?.length && value[0] !== undefined) {
      setSelectedAvailableCountry(
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleAddUserCredit = () => {
    if (
      addCreditValue === undefined ||
      addCreditValue?.toString()?.length === 0
    ) {
      setAddCreditEmpty(true);
      return;
    }
    setAddCrediteModalOpen(false);
    setAddCreditEmpty(false);
    const payload = {
      account_id: props?.CLIENT_ACCOUNT_ID,
      allocated_credits: addCreditValue,
    };
    setLoading(true);
    setLoadingMsg();
    addUserCredits(payload, clickedUserId)
      .then((res) => {
        setLoading(false);
        if (res) {
          setAddCreditValue("");
          if (res?.message === "Points added successfully") {
            setError(true);
            setErrorMsg({
              title: "Success",
              content: res?.message,
            });
          } else {
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleSelectAll = () => {
    const _selectedCountry = [];
    availableCountry &&
      availableCountry.length &&
      availableCountry.forEach((country) => {
        _selectedCountry.push(country?.code_iso_3);
      });

    setSelectedAvailableCountry(_selectedCountry);
  };

  const handleDeselectAll = () => {
    setSelectedAvailableCountry([]);
  };

  return (
    <Container maxWidth="xxl" id="manage-users">
      {loading && (
        <Loading title={loadingMsg?.title} content={loadingMsg?.content} />
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      <div className="manage-users-header">
        <div className="row-dashbored">
          <h4 className="text-user">
            Manage
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              User
            </span>
          </h4>
        </div>

        <div className="manageuser-limit-btn">
          <div className="manageuser-limit">
            You have consumed{" "}
            <strong style={{ color: "red" }} className="notranslate">
              {userLimit?.consumedLimit}
            </strong>{" "}
            and left with
            <strong style={{ color: "green" }} className="notranslate">
              {" "}
              {userLimit?.allotedLimit - userLimit?.consumedLimit}
            </strong>{" "}
            limit to add Users
          </div>
          <div className="manageuser-user-btn">
            <ButtonComponent
              name={"Add User"}
              btnIcon={PersonAddAltRoundedIcon}
              style={{ fontWeight: "600" }}
              btnClick={handleAddUser}
            />
          </div>
        </div>
      </div>
      <StickyTable
        columns={userListTableHeader}
        rows={usersList}
        handleEditUser={handleEditUser}
        handleDeleteUser={handleDeleteUser}
        handleAddCredit={handleAddCredit}
        isFromManageUser={true}
      />

      {/* Edit User Modal */}
      <ModalComponent
        open={handleEditModal}
        setOpen={setHandleEditModal}
        header={modalHeader}
        modalBody={
          <div id="edit-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <TextFieldComponent
                label="First Name"
                name="first_name"
                onChange={handleEditUserDetails}
                value={editUserDetails?.first_name}
                error={isNameEmpty?.first_name}
                helperText={
                  isNameEmpty?.first_name && "This value is required."
                }
                width="48%"
              />

              <TextFieldComponent
                label="Last Name"
                name="last_name"
                onChange={handleEditUserDetails}
                error={isNameEmpty?.last_name}
                value={editUserDetails?.last_name}
                helperText={isNameEmpty?.last_name && "This value is required."}
                width="48%"
              />
              {modalHeader === Modal_header?.Add_User_Details && (
                <TextFieldComponent
                  label="Email Id"
                  name="email_id"
                  error={isNameEmpty?.email_id}
                  helperText={
                    isNameEmpty?.email_id && "Please Enter Valid Email"
                  }
                  onChange={handleEditUserDetails}
                  value={editUserDetails?.email_id}
                  width="48%"
                />
              )}

              <TextField
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                className="user-mt-5"
                name="mobile_no"
                onChange={handleEditUserDetails}
                value={editUserDetails?.mobile_no}
                size="small"
                style={{
                  width:
                    modalHeader === Modal_header?.Add_User_Details
                      ? "48%"
                      : "100%",
                }}
              />
            </div>

            {editUserDetails?.role !== UserRole?.ADMINISTRATOR && (
              <FormControl
                sx={{
                  m: 1,
                  width: "100%",
                  background: "white",
                  margin: "10px 0px",
                }}
                size="small"
                error={isNameEmpty?.role}
                helperText={isNameEmpty?.role && "This value is required."}
                required
              >
                <InputLabel id="demo-select-small">Role/Privilege</InputLabel>
                <Select
                  labelId="demo-select-smal"
                  id="demo-select-small"
                  value={editUserDetails?.role}
                  label="Role/Privilege"
                  onChange={handleEditUserDetails}
                  style={{
                    maxHeight: "100px !important",
                    overflowY: "auto !important",
                  }}
                  name="role"
                >
                  {roleType.length > 0 &&
                    roleType.map((e, index) => {
                      return (
                        <MenuItem value={e.title} key={index}>
                          {e.title.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}

            {modalHeader === Modal_header?.Add_User_Details && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) =>
                        setIsAvailableCreaditChecked(e?.target?.checked)
                      }
                    />
                  }
                  label="Add Credits Points"
                />

                {/* {isAvailableCreaditChecked && ( */}
                <TextField
                  id="outlined-basic standard-error-helper-text"
                  // label="Available Credits"
                  placeholder="Enter Available Credits"
                  variant="outlined"
                  className="mt-5"
                  name="allocated_credits"
                  onChange={handleEditUserDetails}
                  value={editUserDetails?.allocated_credits}
                  disabled={!isAvailableCreaditChecked}
                  size="small"
                />
                {/* )} */}
              </>
            )}

            <FormControl style={{ width: "100%" }} sx={{ mt: 2 }} size="small">
              <InputLabel id="demo-multiple-chip-label" htmlFor="name-multiple">
                Available Countries
              </InputLabel>

              <Select
                // style={{ height: "56px" }}
                multiple
                displayEmpty
                value={selectedAvailableCountry?.filter(
                  (item) => item !== "MDT"
                )}
                onChange={handleCountryChange}
                input={<OutlinedInput label="Available Countries" />}
                renderValue={(selected) => {
                  return selected?.join(" | ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <Box style={{ display: "flex" }}>
                  <Typography
                    id="select-unselect-btn"
                    onClick={handleSelectAll}
                  >
                    Select All
                  </Typography>
                  <Typography
                    id="select-unselect-btn"
                    onClick={handleDeselectAll}
                  >
                    Deselect All
                  </Typography>
                </Box>
                {availableCountry &&
                  availableCountry.length &&
                  availableCountry
                    .filter((item) => item?.code_iso_3 !== "MDT")
                    .map((ele, index) => (
                      <MenuItem
                        key={index}
                        value={ele?.code_iso_3}
                        style={getStyles(
                          ele?.country,
                          selectedAvailableCountry,
                          theme
                        )}
                      >
                        {ele?.country}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>
        }
        width="600px"
        saveText="Save"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={savedClicked}
      />

      <ConfirmationModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        modalBody={
          <div
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "10px" }}>
              <img src={DeleteIcon} alt="delete" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  color: "rgb(50, 58, 70)",
                }}
              >
                Delete
              </span>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                Are you sure you want to remove this user?
              </div>
            </div>
          </div>
        }
        width="600px"
        saveText="Delete"
        saveButtonShown={true}
        saveButtonColor="#f1556c"
        savedClicked={deleteClicked}
      />

      <ModalComponent
        open={isAddCrediteModalOpen}
        setOpen={setAddCrediteModalOpen}
        setAddCreditEmpty={setAddCreditEmpty}
        setAddCreditValue={setAddCreditValue}
        header="Add Credit"
        modalBody={
          <>
            <Box style={{ margin: "10px" }}>
              {/* <span>Add Credits : </span> */}
              <TextField
                error={false}
                id="outlined-basic standard-error-helper-text"
                variant="outlined"
                className="mt-5"
                label="Add Credits"
                name="add_credits"
                value={addCreditValue}
                onChange={(e) => {
                  setAddCreditEmpty(
                    e?.target?.value.length === 0 ? true : false
                  );
                  setAddCreditValue(e?.target?.value);
                }}
                style={{
                  width: "100%",
                }}
                helperText={
                  addCreditEmpty && (
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-13px",
                        fontSize: "15px",
                      }}
                    >
                      This value is required.
                    </span>
                  )
                }
                onBlur={(e) => {
                  setAddCreditEmpty(
                    e?.target?.value?.length === 0 ? true : false
                  );
                }}
              />
            </Box>
          </>
        }
        width="600px"
        saveText="Add Credit"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={handleAddUserCredit}
      />
    </Container>
  );
}
