// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ConfirmationModal .confirmationModal-styles {
  position: absolute;
  top: 50%;
  left: 50%;
  border-top: 3px solid red;
  border-radius: 6px 6px 6px 6px !important;
  font-family: "Rubik" !important;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24;
  outline: none;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
@media (max-width: 700px) {
  #ConfirmationModal .confirmationModal-styles {
    width: 92% !important;
  }
}
#ConfirmationModal .confirmationModal-modalBody {
  font-family: "Rubik" !important;
  padding: 15px 15px 15px 15px !important;
}
#ConfirmationModal .delete-close {
  font-family: "Rubik";
  color: #909090;
  background-color: #ffffff;
  border-color: #909090 !important;
  border-width: 0.5px 3px 3px 0.5px !important;
  border-style: solid !important;
  border-radius: 6px !important;
}
#ConfirmationModal .delete-close:hover {
  color: #ffffff;
  background-color: #909090;
  border-color: #909090;
}
#ConfirmationModal .hr {
  font-family: "Rubik";
  border: 1px solid #dee2e6;
}
#ConfirmationModal .footer-confirmationModal-content {
  font-family: "Rubik";
  padding: 10px 10px;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/Component/ConfirmationModal/ConfirmationModal.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,yBAAA;EACA,yCAAA;EACA,+BAAA;EACA,gCAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,+BAAA;AAAJ;AAGI;EAfF;IAgBI,qBAAA;EAAJ;AACF;AAGE;EACE,+BAAA;EACA,uCAAA;AADJ;AAIE;EACE,oBAAA;EACA,cAAA;EACA,yBAAA;EACA,gCAAA;EACA,4CAAA;EACA,8BAAA;EACA,6BAAA;AAFJ;AAGI;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AADN;AAKE;EACE,oBAAA;EACA,yBAAA;AAHJ;AAUE;EACE,oBAAA;EACA,kBAAA;EACA,YAAA;AARJ","sourcesContent":["#ConfirmationModal {\n  .confirmationModal-styles {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    border-top: 3px solid red;\n    border-radius: 6px 6px 6px 6px !important;\n    font-family: \"Rubik\" !important;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    box-shadow: 24;\n    outline: none;\n    border-top-left-radius: 0.2rem;\n    border-top-right-radius: 0.2rem;\n    // height: 80%;\n    // overflow: auto;\n    @media (max-width: 700px) {\n      width: 92% !important;\n    }\n  }\n\n  .confirmationModal-modalBody {\n    font-family: \"Rubik\" !important;\n    padding: 15px 15px 15px 15px !important;\n  }\n\n  .delete-close {\n    font-family: \"Rubik\";\n    color: #909090;\n    background-color: #ffffff;\n    border-color: #909090 !important;\n    border-width: 0.5px 3px 3px 0.5px !important;\n    border-style: solid !important;\n    border-radius: 6px !important;\n    &:hover {\n      color: #ffffff;\n      background-color: #909090;\n      border-color: #909090;\n    }\n  }\n\n  .hr {\n    font-family: \"Rubik\";\n    border: 1px solid #dee2e6;\n  }\n  // .footer-modal{\n  //   position: sticky;\n  //   bottom: 0;\n  //   background-color: white;\n  // }\n  .footer-confirmationModal-content {\n    font-family: \"Rubik\";\n    padding: 10px 10px;\n    float: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
