import Cookies from "js-cookie";
import {
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS,
  FILTER_OPTIONS,
  QUERYTYPE,
  HS_CODE_DIGIT_CLASSIFICATION_MIN,
  SORTINGTYPE,
} from "../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import {
  IsMultiSelectedField,
  IsMultiSelectedFieldForAdvanceSearch,
} from "./commanConditions";
import { divideDateRange } from "./CommanFunction";
import { SEARCH_ALL_OPTION } from "../enums/globalEnum";

const operator = {
  EXACT: 200,
  OR: 201,
  AND: 202,
  CONTAIN: 203,
  NOT_CONTAIN: 204,
  FULL_TEXT: 207,
};
const prodFlagOperator = {
  less_than: 400,
  greater_than: 401,
  less_equal_to_than: 402,
  greater_equal_to_than: 403,
};

const identifier = {
  SEARCH_HS_CODE: "SEARCH_HS_CODE",
  SEARCH_PRODUCT_DESCRIPTION: "SEARCH_PRODUCT_DESCRIPTION",
  SEARCH_PRODUCT_DESCRIPTION_EN: "SEARCH_PRODUCT_DESCRIPTION_EN",
  SEARCH_HS_CODE_DESCRIPTION: "SEARCH_HS_CODE_DESCRIPTION",
  SEARCH_MONTH_RANGE: "SEARCH_MONTH_RANGE",
  SEARCH_COUNTRY: "SEARCH_COUNTRY",
  REMOVE_DUPLICATE: "REMOVE_DUPLICATE",
  SEARCH_REPRESENTATIVE_PRINCIPAL: "SEARCH_REPRESENTATIVE_PRINCIPAL",
  SEARCH_SELLER_EN: "SEARCH_SELLER_EN",
  SEARCH_SELLER: "SEARCH_SELLER",
  SEARCH_BUYER_EN: "SEARCH_BUYER_EN",
  SEARCH_BUYER: "SEARCH_BUYER",
  SEARCH_PRODUCT_DESCRIPTION_NATIVE: "SEARCH_PRODUCT_DESCRIPTION_NATIVE",
};

const validIdentifiers = [
  identifier.SEARCH_PRODUCT_DESCRIPTION,
  identifier.SEARCH_PRODUCT_DESCRIPTION_EN,
  identifier.SEARCH_HS_CODE_DESCRIPTION,
  identifier.SEARCH_SELLER_EN,
  identifier.SEARCH_SELLER,
  identifier.SEARCH_BUYER_EN,
  identifier.SEARCH_BUYER,
  "SEARCH_PRODUCT_DESCRIPTION_NATIVE",
];

const validFieldTerms = [
  "PRODUCT_DESCRIPTION",
  "CUSTOMS_DESCRIPTION",
  "HS_CODE_DESCRIPTION",
  "SHIPPER_NAME_EN",
  "SHIPPER_NAME_NATIVE",
  "CONSIGNEE_NAME_EN",
  "CONSIGNEE_NAME_NATIVE",
  "SHIPPER_NAME",
  "CONSIGNEE_NAME",
  "EXPORTER_NAME",
  "BUYER_NAME",
  "STD_EXPORTER_NAME",
  "STD_BUYER_NAME",
  "EXPORTER_NAME_EN",
  "EXPORTER_NAME_VN",
  "IMPORTER_NAME",
  "EXPORTER_NAME_AND_ADDRESS",
  "SUPPLIER_NAME_EN",
  "SUPPLIER_NAME",
  "IMPORTER_NAME_EN",
  "STD_IMPORTER_NAME",
  "STD_SUPPLIER_NAME",
  "IMPORTER_NAME_VN",
  "BUYER_NAME_EN",
];
// Example usage:

export const convertDataIntoLeftAndRightValue = (
  currentTermValue,
  hs_code_digit_classification,
  mirrorData
) => {
  const fieldValue = [];
  const fieldValueArr = [];
  if (currentTermValue.length > 0) {
    currentTermValue.length &&
      currentTermValue.forEach((value) => {
        let selectedValue = value;
        if (typeof value !== "string") {
          selectedValue = selectedValue.toString();
        }
        fieldValue.push(selectedValue);
        let digitsCount = selectedValue?.length;
        let leftValue = selectedValue;
        let rightValue = selectedValue;
        if (mirrorData) {
          for (
            let index = digitsCount + 1;
            index <= hs_code_digit_classification;
            index++
          ) {
            if (leftValue.length <= HS_CODE_DIGIT_CLASSIFICATION_MIN) {
              leftValue += 0;
            }
            rightValue += 9;
          }
          fieldValueArr.push({
            fieldValueLeft: Number(leftValue),
            fieldValueRight: Number(rightValue),
          });
        } else {
          for (
            let index = digitsCount + 1;
            index <= hs_code_digit_classification;
            index++
          ) {
            leftValue += 0;
            rightValue += 9;
          }
          fieldValueArr.push({
            fieldValueLeft: Number(leftValue),
            fieldValueRight: Number(rightValue),
          });
        }
      });
  } else {
    let digitsCount = currentTermValue.length;
    let leftValue = "";
    let rightValue = "";
    for (
      let index = digitsCount + 1;
      index <= hs_code_digit_classification;
      index++
    ) {
      leftValue += 0;
      rightValue += 9;
    }

    fieldValueArr.push({
      fieldValueLeft: Number(leftValue),
      fieldValueRight: Number(rightValue),
    });
  }

  return { fieldValue, fieldValueArr };
};

const getExpressionsType = (selectedOperator) => {
  if (!Object.keys(selectedOperator)?.length > 0) {
    return 200;
  } else {
    if (operator?.hasOwnProperty(selectedOperator?.split(" ").join("_"))) {
      return operator[selectedOperator?.split(" ").join("_")];
    } else {
      return "";
    }
  }
};
const sortingColumns = (
  descendingAndAscending,
  sortedColumnList,
  findClauseSearchMonth
) => {
  let array = [];
  const sorting =
    descendingAndAscending &&
    Object.keys(descendingAndAscending).filter(
      (e) =>
        descendingAndAscending[e] === "desc" ||
        descendingAndAscending[e] === "asc"
    );
  if (sorting?.length > 0) {
    descendingAndAscending &&
      Object.keys(descendingAndAscending).length &&
      Object.keys(descendingAndAscending).forEach((e) => {
        if (descendingAndAscending[e] !== SORTINGTYPE?.default) {
          let obj =
            sortedColumnList &&
            sortedColumnList?.length &&
            sortedColumnList?.find((val) => val?.column === e);
          obj.sortType = descendingAndAscending[e];
          array.push(obj);
        }
      });
  } else {
    let obj = {
      sortField: findClauseSearchMonth?.fieldTerm,
      column: findClauseSearchMonth?.fieldTerm,
      defaultDataType: findClauseSearchMonth?.fieldTermTypeSuffix,
      sortType: SORTINGTYPE?.desc,
    };
    array.push(obj);
  }

  return array;
};

const formulateDataShipmentPayload = (
  shipmentResultType,
  isCheckedEmpty,
  isCheckedOnSearch,
  data,
  includeExclude,
  multiSelectFieldForPrimarySearch,
  multiSelectFieldForAdvanceSearch
) => {
  const {
    searchField,
    getSelectedOptionForSearchTerm,
    exploreShipmentSpecification,
    SHIPMENT_RESULT_TYPE_RECORDS,
    isBlFlag,
    currentCountry,
    dateRange,
    advanceSearchComponent,
    advanceSearchFieldOptions,
    filteredTaxonomy,
    onSearchClickFlag,
    descendingAndAscending,
    sortedColumnList,
    CheckedData,
    removeButtonState,
    lastSelected,
    workSpaceIntialCall,
    isWorkSpace,
    isExplore,
    mirrorData,
    isRemoveDuplicateButton,
    currentTradeType,
  } = data;

  let currentTermValue = [];
  if (workSpaceIntialCall && isWorkSpace) {
    if (
      searchField?.getValuesForSelectDropdown?.query === QUERYTYPE?.CHOOSE ||
      multiSelectFieldForPrimarySearch?.includes(
        searchField?.getValuesForSelectDropdown?.field
      )
    ) {
      const arr = [];
      if (
        getSelectedOptionForSearchTerm &&
        getSelectedOptionForSearchTerm?.length
      ) {
        getSelectedOptionForSearchTerm?.map((e) => {
          arr.push(e?._id);
          currentTermValue = arr;
        });
      }
    } else {
      currentTermValue = Array.isArray(getSelectedOptionForSearchTerm)
        ? getSelectedOptionForSearchTerm?.[0]?._id
        : getSelectedOptionForSearchTerm?._id;
    }
  } else if (isExplore) {
    if (
      searchField?.getValuesForSelectDropdown?.query === QUERYTYPE?.CHOOSE ||
      multiSelectFieldForPrimarySearch?.includes(
        searchField?.getValuesForSelectDropdown?.field
      )
    ) {
      const arr = [];
      if (
        getSelectedOptionForSearchTerm &&
        getSelectedOptionForSearchTerm?.length
      ) {
        getSelectedOptionForSearchTerm?.map((e) => {
          arr.push(e?._id);
          currentTermValue = arr;
        });
      }
    } else {
      currentTermValue = Array.isArray(getSelectedOptionForSearchTerm)
        ? getSelectedOptionForSearchTerm?.[0]?._id
        : getSelectedOptionForSearchTerm?._id;
    }
  }

  let payload = null;
  let searchSemantics = JSON.parse(
    JSON.stringify(exploreShipmentSpecification?.search_field_semantic)
  );
  if (shipmentResultType === SHIPMENT_RESULT_TYPE_RECORDS) {
    payload = JSON.parse(
      JSON.stringify(exploreShipmentSpecification?.explore_aggregation)
    );
  }
  if (shipmentResultType === "STATISTICS") {
    payload = JSON.parse(
      JSON.stringify(exploreShipmentSpecification.statistics_aggregation)
    );
  }

  let aggregationPayload = {
    allFields: exploreShipmentSpecification?.all_fields,
    isEngine: true,
    recordSetKey: payload?.recordSetKey,
    sortTerms: [],
    searchExpressions: [],
    matchExpressions: [],
    monthRange: dateRange?.monthRange,
    groupExpressions: [],
    projectionExpressions: [],
  };
  if (isWorkSpace) {
    aggregationPayload.indexNamePrefix = payload?.indexNamePrefix;
    aggregationPayload.sortTerm = payload?.sortTerm;
  }
  // advance Search
  let storeArr =
    advanceSearchComponent?.length > 0 ? [...advanceSearchComponent] : [];
  let reverseArr = storeArr.reverse();
  if (reverseArr?.length && reverseArr?.length > 0) {
    reverseArr?.forEach((queries, index) => {
      let arr = [];
      var selectedQuery;
      selectedQuery =
        advanceSearchFieldOptions &&
        advanceSearchFieldOptions?.length &&
        advanceSearchFieldOptions?.find(
          (semantic) => semantic?.field === queries?.field
        );
      var payloadQuery = {};
      if (currentCountry === "INDIA") {
        payloadQuery =
          filteredTaxonomy?.fields.explore_aggregation?.matchExpressions?.find(
            (expression) => {
              if (selectedQuery && Object.keys(selectedQuery)?.length) {
                return expression?.identifier === selectedQuery?.identifier;
              }
            }
          );
      } else {
        if (
          selectedQuery &&
          Object.keys(selectedQuery)?.length &&
          selectedQuery?.identifier === identifier?.SEARCH_HS_CODE
        ) {
          payloadQuery =
            filteredTaxonomy?.fields.explore_aggregation?.matchExpressions?.find(
              (expression) =>
                expression?.identifier === selectedQuery?.identifier
            );
        } else {
          if (selectedQuery?.field === "PRODUCT_DESC_EN") {
            payloadQuery =
              filteredTaxonomy?.fields.explore_aggregation?.matchExpressions.find(
                (expression) => {
                  if (selectedQuery && Object.keys(selectedQuery)?.length) {
                    return expression.fieldTerm === selectedQuery?.field;
                  }
                }
              );
          } else {
            payloadQuery =
              filteredTaxonomy?.fields.explore_aggregation?.matchExpressions.find(
                (expression) => {
                  if (selectedQuery && Object.keys(selectedQuery)?.length) {
                    return expression.identifier === selectedQuery?.identifier;
                  }
                }
              );
          }
        }
      }
      var prodFlag = true;
      if (
        (selectedQuery &&
          Object.keys(selectedQuery)?.length &&
          selectedQuery?.identifier ===
            identifier?.SEARCH_PRODUCT_DESCRIPTION) ||
        selectedQuery?.identifier ===
          identifier?.SEARCH_PRODUCT_DESCRIPTION_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_SELLER_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_SELLER ||
        selectedQuery?.identifier === identifier?.SEARCH_BUYER_EN ||
        selectedQuery?.identifier === identifier?.SEARCH_BUYER ||
        selectedQuery?.identifier === identifier?.SEARCH_HS_CODE_DESCRIPTION ||
        selectedQuery?.identifier ===
          identifier?.SEARCH_PRODUCT_DESCRIPTION_NATIVE
      ) {
        prodFlag = false;
        const operatorExpressionType = getExpressionsType(queries?.operator);
        if (operatorExpressionType !== "") {
          payloadQuery.expressionType = operatorExpressionType;
        }
      }
      if (prodFlag) {
        const queriesOperator = queries?.operator?.split(" ").join("_");
        if (prodFlagOperator?.hasOwnProperty(queriesOperator)) {
          payloadQuery.expressionType = prodFlagOperator[queriesOperator];
        }
      }
      if (onSearchClickFlag) {
        if (
          queries?.query === QUERYTYPE?.CHOOSE ||
          multiSelectFieldForAdvanceSearch?.includes(queries?.field) ||
          (queries?.query?.includes("PROD") && queries?.query?.includes("DESC"))
        ) {
          const arrOfQueries = [...queries.value];
          arrOfQueries &&
            arrOfQueries.length > 0 &&
            arrOfQueries.forEach((e) => {
              arr.push(e._id);
            });
          payloadQuery.fieldValue = arr;
        } else {
          payloadQuery.fieldValue = queries?.value?._id;
        }
      } else {
        payloadQuery.fieldValue = queries?.fieldValue;
      }

      payloadQuery.specifier = queries?.operator;
      payloadQuery.relation = queries?.relation;
      payloadQuery.advanceSearchType = true;
      if (
        selectedQuery &&
        Object.keys(selectedQuery)?.length &&
        selectedQuery?.identifier === identifier?.SEARCH_HS_CODE
      ) {
        const { fieldValueArr } = convertDataIntoLeftAndRightValue(
          arr,
          exploreShipmentSpecification?.hs_code_digit_classification,
          mirrorData
        );
        payloadQuery.fieldValueArr = fieldValueArr;
      }

      aggregationPayload.matchExpressions.push({ ...payloadQuery });
    });
  }

  function isAlphabetic(input) {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(input);
  }

  function createMatchExpressionForSearchAll(
    selectedOptions,
    currentCountryMatchExpressions,
    monthExpression
  ) {
    let result = [];
    const groupLabelToFieldTerm = {};

    const _expressionsTypeValue = getExpressionsType(
      searchField?.selectedOperator
    );

    const _userCustomSearchValue = selectedOptions?.filter(
      (item) => item?.groupLabel === "YOUR SEARCH"
    )?.[0]?._id;

    const _hasOneYourSearch =
      selectedOptions.length === 1 &&
      selectedOptions?.[0].groupLabel === "YOUR SEARCH";

    selectedOptions?.length &&
      selectedOptions.forEach((item) => {
        if (!groupLabelToFieldTerm[item.groupLabel]) {
          groupLabelToFieldTerm[item.groupLabel] = item?.groupLabel
            ?.toUpperCase()
            ?.replace(/\s+/g, "_");
        }
      });
    if (_hasOneYourSearch && _userCustomSearchValue) {
      let updatedData = currentCountryMatchExpressions
        .filter(
          (exp) =>
            !(
              searchField?.selectedOperator === "FULL TEXT" &&
              exp.identifier === identifier?.SEARCH_HS_CODE
            )
        )
        .map((item) => ({
          ...item,
          fieldValue: [_userCustomSearchValue],
          ...(item.identifier !== identifier?.SEARCH_HS_CODE && {
            expressionType: _expressionsTypeValue,
          }),
        }));

      if (isAlphabetic(_userCustomSearchValue)) {
        updatedData = updatedData.filter(
          (item) => item.identifier !== "SEARCH_HS_CODE"
        );
      }

      result.push(...updatedData);
    } else {
      selectedOptions.forEach((option) => {
        const fieldTerm = groupLabelToFieldTerm[option.groupLabel];
        if (!fieldTerm) return;
        let matchedExpression = currentCountryMatchExpressions.find(
          (exp) => exp.fieldTerm === fieldTerm
        );

        if (matchedExpression) {
          let existingEntry = result.find(
            (entry) => entry.identifier === matchedExpression.identifier
          );

          if (existingEntry) {
            if (Array.isArray(existingEntry.fieldValue)) {
              existingEntry.fieldValue.push(option._id);
            } else {
              existingEntry.fieldValue = [existingEntry.fieldValue, option._id];
            }
          } else {
            const isHSCodeExpression =
              matchedExpression.identifier === identifier.SEARCH_HS_CODE;

            const prepareMatchExpressionObj = {
              ...matchedExpression,
              fieldValue: [
                option._id,
                ...(_userCustomSearchValue ? [_userCustomSearchValue] : []),
              ],
              ...(!isHSCodeExpression && {
                expressionType: _expressionsTypeValue,
              }),
              isSearchAll: true,
            };
            result.push(prepareMatchExpressionObj);
          }
        }
      });
    }

    //1) Convert hs code field value left and right Arr
    //2) added relation key value pair in matchExpression
    // which skip the first expression and add key to rest of the objects in result
    result.forEach((exp, index) => {
      if (index !== 0) {
        exp.relation = "OR";
      }

      if (exp?.identifier === identifier.SEARCH_HS_CODE) {
        const { fieldValueArr, fieldValue } = convertDataIntoLeftAndRightValue(
          exp.fieldValue,
          exploreShipmentSpecification?.hs_code_digit_classification,
          mirrorData
        );
        exp.fieldValueArr = fieldValueArr;
        exp.fieldValue = fieldValue;
        exp.advanceSearchType = false;
      }
    });

    // Date Range Expression
    const _userSelectedStartDate = dateRange?.startMonthDate;
    const _userSelectedEndDate = dateRange?.endMonthDate;
    const _myEndDate = exploreShipmentSpecification?.data_end_date;
    const _dividedDateRange = divideDateRange(
      _userSelectedStartDate,
      _userSelectedEndDate,
      _myEndDate
    );
    const obj = _dividedDateRange?.[0];

    const _prepareMonthExpression = monthExpression?.map((monthExp) => ({
      ...monthExp,
      ...(!!_dividedDateRange && { dividedDateRange: _dividedDateRange }),
      dateExpression:
        _dividedDateRange.length === 2
          ? 2
          : obj?.hasOwnProperty("leftFieldvalueHot")
          ? 1
          : 0,
      fieldValueLeft: _userSelectedStartDate,
      fieldValueRight: _userSelectedEndDate,
      isSearchAll: true,
    }));
    result.unshift(..._prepareMonthExpression);
    return result;
  }

  // this is for normal search and there filter
  let findClauseSearchSemantic;
  if (searchField?.getSelectExploreField === SEARCH_ALL_OPTION) {
    findClauseSearchSemantic = {
      recordsAggregationFindPattern: "matchExpressions",
    };
  } else {
    findClauseSearchSemantic =
      searchSemantics &&
      searchSemantics?.find(
        (expression) =>
          expression?.identifier ===
          searchField?.getValuesForSelectDropdown?.identifier
      );
  }

  let findClauseSearchMonth = payload?.[
    findClauseSearchSemantic?.recordsAggregationFindPattern
  ]?.find(
    (expression) => expression?.identifier === identifier?.SEARCH_MONTH_RANGE
  );

  //Search All normal search
  if (searchField?.getSelectExploreField === SEARCH_ALL_OPTION) {
    const _allMatchExpressionArr = payload?.matchExpressions;
    const currentCountrySearchSemantics =
      filteredTaxonomy?.fields?.search_semantic;

    const _currentCountryMatchExpressions = _allMatchExpressionArr.filter(
      (exp) =>
        currentCountrySearchSemantics.some(
          (sem) => sem.identifier === exp.identifier
        )
    );

    const _monthExpression = _allMatchExpressionArr.filter(
      (exp) => exp.identifier === identifier?.SEARCH_MONTH_RANGE
    );

    const _completeMatchExpressionForSearchALL =
      createMatchExpressionForSearchAll(
        getSelectedOptionForSearchTerm,
        _currentCountryMatchExpressions,
        _monthExpression
      );

    aggregationPayload.matchExpressions.push(
      ..._completeMatchExpressionForSearchALL
    );
  } else {
    //normal search
    // let findClauseSearchSemantic =
    //   searchSemantics &&
    //   searchSemantics?.find(
    //     (expression) =>
    //       expression?.identifier ===
    //       searchField?.getValuesForSelectDropdown?.identifier
    //   );
    let findClauseSearchTerm = payload?.[
      findClauseSearchSemantic?.recordsAggregationFindPattern
    ]?.find(
      (expression) =>
        expression?.identifier ===
        searchField?.getValuesForSelectDropdown?.identifier
    );
    if (findClauseSearchTerm.identifier === identifier?.SEARCH_HS_CODE) {
      const { fieldValueArr, fieldValue } = convertDataIntoLeftAndRightValue(
        currentTermValue,
        exploreShipmentSpecification?.hs_code_digit_classification,
        mirrorData
      );
      findClauseSearchTerm.fieldValue = fieldValue;
      findClauseSearchTerm.fieldValueArr = fieldValueArr;
      findClauseSearchTerm.advanceSearchType = false;
      aggregationPayload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].push(findClauseSearchTerm);
    } else if (
      (validIdentifiers.includes(findClauseSearchTerm?.identifier) &&
        validFieldTerms.includes(findClauseSearchTerm?.fieldTerm)) ||
      (findClauseSearchTerm?.fieldTerm?.includes("PROD") &&
        findClauseSearchTerm?.fieldTerm?.includes("DESC"))
    ) {
      aggregationPayload.isEngine = true;
      findClauseSearchTerm.fieldValue = currentTermValue;
      findClauseSearchTerm.advanceSearchType = false;

      const expressionType = getExpressionsType(searchField?.selectedOperator);
      if (currentTermValue) {
        findClauseSearchTerm.expressionType = expressionType;
        aggregationPayload[
          findClauseSearchSemantic?.recordsAggregationFindPattern
        ].push(findClauseSearchTerm);
      }
    } else {
      findClauseSearchTerm.fieldValue = currentTermValue;
      findClauseSearchTerm.advanceSearchType = false;
      if (!isWorkSpace && currentTermValue) {
        aggregationPayload[
          findClauseSearchSemantic?.recordsAggregationFindPattern
        ].push(findClauseSearchTerm);
      }

      if (isWorkSpace && currentTermValue && currentTermValue.length > 0) {
        aggregationPayload[
          findClauseSearchSemantic.recordsAggregationFindPattern
        ].push(findClauseSearchTerm);
      } else if (isWorkSpace && findClauseSearchTerm.expressionType == 200) {
        findClauseSearchTerm.fieldValue = "*";
        aggregationPayload[
          findClauseSearchSemantic.recordsAggregationFindPattern
        ].push();
      }
    }
    if (
      isRemoveDuplicateButton &&
      (currentCountry === "INDIA" || currentCountry === "INDONESIA")
    ) {
      let removeDuplicateMatchExpression = payload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].find(
        (expression) => expression?.identifier === identifier?.REMOVE_DUPLICATE
      );
      aggregationPayload.matchExpressions.push(removeDuplicateMatchExpression);
    }
    if (!isWorkSpace) {
      const filterSEARCH_COUNTRY = {
        identifier: "SEARCH_COUNTRY",
        alias: "COUNTRY",
        clause: "MATCH",
        expressionType: 202,
        relation: "and",
        fieldTerm: "COUNTRY_DATA",
        fieldValue: [currentCountry.replaceAll("_", " ")],
        fieldTermTypeSuffix: "",
      };

      payload.matchExpressions.push(filterSEARCH_COUNTRY);

      let findClauseSearchCountry = payload[
        findClauseSearchSemantic?.recordsAggregationFindPattern
      ].find(
        (expression) => expression.identifier === identifier?.SEARCH_COUNTRY
      );

      if (isBlFlag === "true") {
        aggregationPayload[
          findClauseSearchSemantic?.recordsAggregationFindPattern
        ].push(findClauseSearchCountry);
      }
    }
    // let findClauseSearchMonth = payload[
    //   findClauseSearchSemantic?.recordsAggregationFindPattern
    // ].find(
    //   (expression) => expression?.identifier === identifier?.SEARCH_MONTH_RANGE
    // );
    findClauseSearchMonth.fieldValueLeft = dateRange?.startMonthDate;
    findClauseSearchMonth.fieldValueRight = dateRange?.endMonthDate;

    const userSelectedStartDate = dateRange?.startMonthDate;
    const userSelectedEndDate = dateRange?.endMonthDate;
    const myEndDate = exploreShipmentSpecification?.data_end_date;
    const dividedDateRange = divideDateRange(
      userSelectedStartDate,
      userSelectedEndDate,
      myEndDate
    );
    if (dividedDateRange) {
      findClauseSearchMonth.dividedDateRange = dividedDateRange;
      if (dividedDateRange.length === 2) {
        findClauseSearchMonth.dateExpression = 2;
      } else {
        if (dividedDateRange && dividedDateRange[0]) {
          const obj = dividedDateRange[0];
          if (obj.hasOwnProperty("leftFieldvalueHot")) {
            findClauseSearchMonth.dateExpression = 1;
          } else {
            findClauseSearchMonth.dateExpression = 0;
          }
        }
      }
    }
    aggregationPayload[
      findClauseSearchSemantic?.recordsAggregationFindPattern
    ].unshift(findClauseSearchMonth);
  }

  //---end of normal search

  aggregationPayload.groupExpressions = payload?.groupExpressions;
  aggregationPayload.projectionExpressions = payload?.projectionExpressions;

  let _CheckedData = { ...CheckedData };
  if (CheckedData && Object.keys(CheckedData)?.length) {
    for (const [key, value] of Object.entries(CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
      }
    }
  }
  if (isCheckedEmpty) {
    _CheckedData = {};
  }
  if (isCheckedOnSearch) {
    _CheckedData = {};
  }

  if (
    exploreShipmentSpecification?.filter_field_semantic != null &&
    exploreShipmentSpecification?.filter_field_semantic?.length > 0
  ) {
    exploreShipmentSpecification?.explore_aggregation?.matchExpressions &&
      exploreShipmentSpecification?.explore_aggregation?.matchExpressions
        ?.length &&
      exploreShipmentSpecification?.explore_aggregation?.matchExpressions?.forEach(
        (filterFieldSemantic) => {
          switch (filterFieldSemantic?.identifier) {
            case FILTER_OPTIONS?.FILTER_COUNTRY: {
              if (!removeButtonState?.FILTER_COUNTRY) {
                let findClauseFilterCountry = payload?.[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_COUNTRY
                );
                if (_CheckedData.FILTER_COUNTRY) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_COUNTRY?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_IMPORTER: {
              if (!removeButtonState?.FILTER_IMPORTER) {
                let findClauseFilterCountry = payload?.[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_IMPORTER
                );
                if (_CheckedData.FILTER_IMPORTER) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_IMPORTER?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_IMPORTER
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_STD_SUPPLIER_NAME: {
              if (!removeButtonState?.FILTER_STD_SUPPLIER_NAME) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_STD_SUPPLIER_NAME
                );
                if (_CheckedData.FILTER_STD_SUPPLIER_NAME) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_STD_SUPPLIER_NAME?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_STD_SUPPLIER_NAME
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_EXPORTER: {
              if (!removeButtonState?.FILTER_EXPORTER) {
                let findClauseFilterCountry = payload?.[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_EXPORTER
                );
                if (_CheckedData.FILTER_EXPORTER) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_EXPORTER?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_EXPORTER
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ]?.push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PIN: {
              if (!removeButtonState?.FILTER_PIN) {
                let findClauseFilterPin = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_PIN
                );
                if (_CheckedData.FILTER_PIN) {
                  const field = [];
                  findClauseFilterPin.isExclusion =
                    includeExclude?.FILTER_PIN?.exclude;
                  findClauseFilterPin.expressionType = 206;
                  findClauseFilterPin.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_PIN
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterPin.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterPin);
                  findClauseFilterPin.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_STATE: {
              if (!removeButtonState?.FILTER_STATE) {
                let findClauseFilterState = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_STATE
                );
                if (_CheckedData.FILTER_STATE) {
                  const field = [];
                  findClauseFilterState.isExclusion =
                    includeExclude?.FILTER_STATE?.exclude;
                  findClauseFilterState.expressionType = 206;
                  findClauseFilterState.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_STATE
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterState.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterState);
                  findClauseFilterState.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY: {
              if (!removeButtonState?.FILTER_DESTINATION_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY
                );
                if (_CheckedData.FILTER_DESTINATION_COUNTRY) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_DESTINATION_COUNTRY?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_DESTINATION_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_DESTINATION_PORT: {
              if (!removeButtonState?.FILTER_DESTINATION_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_DESTINATION_PORT
                );
                if (_CheckedData.FILTER_DESTINATION_PORT) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_DESTINATION_PORT?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_DESTINATION_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY: {
              if (!removeButtonState?.FILTER_ORIGIN_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY
                );
                if (_CheckedData.FILTER_ORIGIN_COUNTRY) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_ORIGIN_COUNTRY?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_ORIGIN_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_ORIGIN_PORT: {
              if (!removeButtonState?.FILTER_ORIGIN_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_ORIGIN_PORT
                );
                if (_CheckedData.FILTER_ORIGIN_PORT) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_ORIGIN_PORT?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_ORIGIN_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY: {
              if (!removeButtonState?.FILTER_REPORTING_COUNTRY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier ===
                    FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY
                );
                if (_CheckedData.FILTER_REPORTING_COUNTRY) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_REPORTING_COUNTRY?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData.FILTER_REPORTING_COUNTRY
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PORT: {
              if (!removeButtonState?.FILTER_PORT) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_PORT
                );
                if (_CheckedData?.FILTER_PORT) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_PORT?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData?.FILTER_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_FOREIGN_PORT: {
              if (!removeButtonState?.FILTER_FOREIGN_PORT) {
                let findClauseFilterCountry =
                  payload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ]?.find(
                    (expression) =>
                      expression?.identifier === "FILTER_FOREIGN_PORT"
                  ) ?? {};

                if (_CheckedData?.FILTER_FOREIGN_PORT) {
                  const field = [];
                  findClauseFilterCountry.isExclusion =
                    includeExclude?.FILTER_FOREIGN_PORT?.exclude;
                  findClauseFilterCountry.expressionType = 206;
                  findClauseFilterCountry.fieldTermTypeSuffix = "";
                  for (const [key, value] of Object.entries(
                    _CheckedData?.FILTER_FOREIGN_PORT
                  )) {
                    field.push(key.split("_").join(" "));
                  }
                  findClauseFilterCountry.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ]?.push(findClauseFilterCountry);
                  findClauseFilterCountry.filterType = true;
                }
              }

              break;
            }
            case FILTER_OPTIONS?.FILTER_UNIT: {
              if (!removeButtonState?.QUANTITY) {
                let findClauseFilterCountry = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_UNIT
                );

                if (_CheckedData?.QUANTITY) {
                  let field = "";
                  Object.keys(_CheckedData?.QUANTITY).map((e) => {
                    if (lastSelected.includes(e)) {
                      let field =
                        _CheckedData?.QUANTITY[e].value?.toUpperCase();
                      findClauseFilterCountry.fieldValue = field;
                      findClauseFilterCountry.filterType = true;
                      aggregationPayload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].push({ ...findClauseFilterCountry });
                    }
                  });
                }
              }

              break;
            }
            case FILTER_OPTIONS?.FILTER_QUANTITY: {
              if (!removeButtonState?.QUANTITY) {
                let findClauseFilterQuantity = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_QUANTITY
                );

                if (_CheckedData?.QUANTITY) {
                  Object.keys(_CheckedData?.QUANTITY).map((e) => {
                    if (lastSelected.includes(e)) {
                      let field =
                        _CheckedData?.QUANTITY[e].value?.toUpperCase();
                      findClauseFilterQuantity.fieldValueLeft =
                        _CheckedData?.QUANTITY[e]?.minRange;
                      findClauseFilterQuantity.fieldValueRight =
                        _CheckedData?.QUANTITY[e]?.maxRange;
                      findClauseFilterQuantity.unit = field;
                      findClauseFilterQuantity.filterType = true;
                      aggregationPayload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].push({ ...findClauseFilterQuantity });
                    }
                  });
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_PRICE: {
              if (!removeButtonState?.PRICE) {
                if (_CheckedData?.PRICE) {
                  if (
                    filterFieldSemantic?.metaTag === _CheckedData?.PRICE?.value
                  ) {
                    let findClauseFilterCurrencyPrices = payload[
                      findClauseSearchSemantic.recordsAggregationFindPattern
                    ]?.filter(
                      (expression) =>
                        expression?.identifier === FILTER_OPTIONS?.FILTER_PRICE
                    );
                    let findClauseFilterPrice =
                      findClauseFilterCurrencyPrices &&
                      findClauseFilterCurrencyPrices.length &&
                      findClauseFilterCurrencyPrices.find(
                        (currencyPrice) =>
                          currencyPrice.metaTag === _CheckedData?.PRICE?.value
                      );

                    findClauseFilterPrice.fieldValueLeft =
                      _CheckedData?.PRICE?.minRange;
                    findClauseFilterPrice.fieldValueRight =
                      _CheckedData?.PRICE?.maxRange;
                    findClauseFilterPrice.filterType = true;
                    aggregationPayload[
                      findClauseSearchSemantic?.recordsAggregationFindPattern
                    ].push(findClauseFilterPrice);
                  }
                }
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_HS_CODE: {
              if (!removeButtonState[FILTER_OPTIONS?.HS_CODE]) {
                let findClauseFilterHsCode = payload?.[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.FILTER_HS_CODE
                );

                if (_CheckedData[FILTER_OPTIONS?.HS_CODE]) {
                  let field = [];
                  findClauseFilterHsCode.isExclusion =
                    includeExclude?.HS_CODE?.exclude;
                  for (const [key, value] of Object.entries(
                    _CheckedData[FILTER_OPTIONS?.HS_CODE]
                  )) {
                    // const newkey = key.replace(/^0+/, "");
                    field.push(key);
                  }
                  findClauseFilterHsCode.fieldValue = field;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterHsCode);
                  //  isHsCodeFiltered = true;
                  findClauseFilterHsCode.filterType = true;
                }
              }
              break;
            }
            case FILTER_OPTIONS?.SEARCH_MODE: {
              if (
                !removeButtonState?.SEARCH_MODE &&
                _CheckedData?.SEARCH_MODE
              ) {
                let findClauseFilterSearchMode = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.SEARCH_MODE
                );
                findClauseFilterSearchMode.fieldValue = Object.keys(
                  _CheckedData?.SEARCH_MODE
                )[0];
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterSearchMode);
              }
              break;
            }
            case FILTER_OPTIONS?.TO_ORDER: {
              if (
                !removeButtonState?.REMOVE_ORDER_OR_NULL &&
                _CheckedData?.REMOVE_ORDER_OR_NULL?.TO_ORDER
              ) {
                let findClauseFilterToOrder = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.TO_ORDER
                );
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterToOrder);
              }
              break;
            }
            case FILTER_OPTIONS?.TO_NULL: {
              if (
                !removeButtonState?.REMOVE_ORDER_OR_NULL &&
                _CheckedData?.REMOVE_ORDER_OR_NULL?.TO_NULL
              ) {
                let findClauseFilterToNull = payload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ]?.find(
                  (expression) =>
                    expression?.identifier === FILTER_OPTIONS?.TO_NULL
                );
                aggregationPayload[
                  findClauseSearchSemantic?.recordsAggregationFindPattern
                ].push(findClauseFilterToNull);
              }
              break;
            }
            case FILTER_OPTIONS?.FILTER_MONTH: {
              if (!removeButtonState?.MONTH) {
                if (_CheckedData?.MONTH) {
                  let findClauseFilterMonth = JSON.parse(
                    JSON.stringify(
                      payload[
                        findClauseSearchSemantic?.recordsAggregationFindPattern
                      ].find(
                        (expression) =>
                          expression?.identifier === "FILTER_MONTH"
                      )
                    )
                  );

                  let field = [];
                  for (const [key, value] of Object.entries(
                    _CheckedData?.MONTH
                  )) {
                    field.push(key);
                  }
                  let findClauseFilterMonthOrArrs = [];
                  field &&
                    field?.length &&
                    field?.forEach((yearMonthValue) => {
                      let yearMonthValueArr = yearMonthValue.split("-");
                      let caliberatedEndMonthMaxDay = new Date(
                        yearMonthValueArr[0],
                        yearMonthValueArr[1],
                        0
                      ).getDate();
                      let fieldValues = {};
                      fieldValues.fieldValueLeft = yearMonthValueArr[0].concat(
                        "-",
                        yearMonthValueArr[1].toString().length > 1
                          ? yearMonthValueArr[1]?.toString()
                          : "0".concat(yearMonthValueArr[1].toString()),
                        "-",
                        "01"
                      );
                      fieldValues.fieldValueRight = yearMonthValueArr[0]
                        .toString()
                        .concat(
                          "-",
                          yearMonthValueArr[1].toString().length > 1
                            ? yearMonthValueArr[1]?.toString()
                            : "0".concat(yearMonthValueArr[1].toString()),
                          "-",
                          caliberatedEndMonthMaxDay.toString()
                        );

                      findClauseFilterMonthOrArrs.push(fieldValues);
                    });
                  findClauseFilterMonth.fieldValues =
                    findClauseFilterMonthOrArrs;
                  findClauseFilterMonth.filterType = true;
                  aggregationPayload[
                    findClauseSearchSemantic?.recordsAggregationFindPattern
                  ].push(findClauseFilterMonth);
                }
              }
              break;
            }
            default:
              break;
          }
        }
      );
  }

  //sorting
  const sortedColumn = sortingColumns(
    descendingAndAscending,
    sortedColumnList,
    findClauseSearchMonth
  );
  if (aggregationPayload && sortedColumn.length > 0) {
    aggregationPayload.sortTerms = sortedColumn;
  }

  return aggregationPayload;
};

export const packDTParams = (
  isCheckedEmpty,
  isCheckedOnSearch,
  data,
  includeExclude,
  multiSelectFieldForPrimarySearch,
  multiSelectFieldForAdvanceSearch,
  dropDownDataForSearchAll
) => {
  let paramsObj = formulateDataShipmentPayload(
    data?.SHIPMENT_RESULT_TYPE_RECORDS,
    isCheckedEmpty,
    isCheckedOnSearch,
    data,
    includeExclude,
    multiSelectFieldForPrimarySearch,
    multiSelectFieldForAdvanceSearch
  );
  const {
    isBlFlag,
    currentTradeType,
    currentCountryCode,
    currentCountry,
    filteredTaxonomy,
    rowsPerPage,
    estimateRecordsData,
    isWorkSpace,
    dateRange,
    workspaceTotalRecords,
    workspaceBucket,
    currentSelectedWorkSpace,
    searchField,
    getSelectedOptionForSearchTerm,
    getSearchOption,
    isRemoveDuplicateButton,
    isExplore,
  } = data;

  if (!isWorkSpace) {
    paramsObj.tradeType = currentTradeType;
    paramsObj.countryCode = currentCountryCode;

    if (isBlFlag === "true") {
      paramsObj.country = "bl";
    } else {
      paramsObj.country = currentCountry;
    }
    paramsObj.accountId = Cookies.get("accountId");
    paramsObj.userId = Cookies.get("user_id");
    paramsObj.tradeTotalRecords = estimateRecordsData;
    paramsObj.purchasable = filteredTaxonomy?.fields?.purchasable;
    //isSuggestion flag maintains here
    // let _getSelectedOptionForSearchKeys = getSelectedOptionForSearchTerm?.map(
    //   (e) => e?.key ?? e?._id
    // );

    let _getSelectedOptionForSearchKeys = Array.isArray(
      getSelectedOptionForSearchTerm
    )
      ? getSelectedOptionForSearchTerm.map((e) => e?.key ?? e?._id ?? "")
      : [];

    let isSuggestionValue;

    const _hasDashZeroAtTheEndOfSelectedOption =
      _getSelectedOptionForSearchKeys?.some((item) => /-0/.test(item));
    if (data?.isFromRecentSearch) {
      let userRecentSearchPayload = JSON.parse(
        localStorage.getItem("searchPayload")
      );
      isSuggestionValue = userRecentSearchPayload?.[0]?.isSuggestion;
    } else {
      if (searchField?.getSelectExploreField !== SEARCH_ALL_OPTION) {
        if (
          ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
            searchField?.getSelectExploreField
          )
        ) {
          if (getSearchOption && getSearchOption?.length > 0) {
            const _selectedValue = getSearchOption[0];

            let _getSelectedOptionForSearchTerm =
              getSelectedOptionForSearchTerm?.map((e) => {
                return e?._id;
              });

            if (
              _getSelectedOptionForSearchTerm &&
              _getSelectedOptionForSearchTerm?.length > 0
            ) {
              if (
                _getSelectedOptionForSearchTerm?.includes(
                  _selectedValue?._id
                ) &&
                _hasDashZeroAtTheEndOfSelectedOption
              ) {
                if (_getSelectedOptionForSearchTerm?.length === 1) {
                  isSuggestionValue = 1;
                } else {
                  isSuggestionValue = 0;
                }
              } else {
                isSuggestionValue = 0;
              }
            }
          }
        } else {
          isSuggestionValue = 0;
        }
      } else {
        const _selectedOptionLabels = getSelectedOptionForSearchTerm?.map(
          (item) => item.groupLabel
        );

        if (
          getSelectedOptionForSearchTerm?.length === 1 &&
          _selectedOptionLabels.includes("YOUR SEARCH") &&
          _hasDashZeroAtTheEndOfSelectedOption
        ) {
          isSuggestionValue = 1;
        } else {
          isSuggestionValue = 0;
        }
      }
    }

    paramsObj.isSuggestion = isSuggestionValue;

    // sendSuggetionFlagForRecentSearch
    paramsObj.matchExpressions[0].isSuggestion = isSuggestionValue;
  }

  //---end isSuggestion flag maintains

  // send selected dropdown value for search all query
  if (searchField?.getSelectExploreField === SEARCH_ALL_OPTION) {
    paramsObj.searchAllDropDownData = dropDownDataForSearchAll;
  }

  paramsObj.draw = 2;
  paramsObj.start = 0;
  paramsObj.length = 1000;
  if (isWorkSpace) {
    paramsObj.startDate = dateRange?.startMonthDate;
    paramsObj.endDate = dateRange?.endMonthDate;
    paramsObj.workspaceBucket = workspaceBucket;
    paramsObj.workspaceTotalRecords = workspaceTotalRecords;
    paramsObj.accountId = currentSelectedWorkSpace?.account_id;
    paramsObj.userId = currentSelectedWorkSpace?.user_id;
    paramsObj.workspaceId = currentSelectedWorkSpace?._id;
    paramsObj.country = currentSelectedWorkSpace?.country;
    paramsObj.tradeType = currentSelectedWorkSpace?.trade;
  }
  if (
    (currentCountry === "INDIA" || currentCountry === "INDONESIA") &&
    isRemoveDuplicateButton
  ) {
    paramsObj.removeDuplicateRelatedData =
      filteredTaxonomy?.fields?.remove_duplicate;
  }

  return paramsObj;
};
