// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toast {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 20%;
  z-index: 10000;
  border-radius: 10px;
  background-color: white;
}
#toast #cmp-name {
  font-size: 14px !important;
  font-weight: 600;
}
#toast #text-small {
  font-size: 14px !important;
}
#toast #toast-header {
  background: red;
  padding: 13px 9px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
}
#toast .toast-body {
  padding: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Toast/toast.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EAEA,cAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AACE;EACE,0BAAA;EACA,gBAAA;AACJ;AACE;EACE,0BAAA;AACJ;AACE;EACE,eAAA;EACA,iBAAA;EACA,2BAAA;EACA,4BAAA;EACA,YAAA;AACJ;AACE;EACC,wBAAA;AACH","sourcesContent":["#toast {\n  position: absolute;\n  top: 50px;\n  right: 10px;\n  width: 20%;\n//   padding: 20px;\n  z-index: 10000;\n  border-radius: 10px;\n  background-color: white;\n  #cmp-name {\n    font-size: 14px !important;\n    font-weight: 600;\n  }\n  #text-small {\n    font-size: 14px !important;\n  }\n  #toast-header{\n    background: red;\n    padding: 13px 9px;\n    border-top-left-radius: 9px;\n    border-top-right-radius: 9px;\n    color: white;\n  }\n  .toast-body{\n   padding: 10px !important;   \n  }\n//   .toast-header{\n//     padding: 10px;\n//   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
