// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerTitle {
  font-family: "Rubik" !important;
  margin-right: auto !important;
  color: #4c97e3 !important;
  font-weight: 800 !important;
  position: relative !important;
  overflow: hidden !important;
}
.headerTitle::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.8), transparent);
  transform: skewX(-25deg);
  animation: shine 2.5s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
.close-icon {
  cursor: pointer;
  font-family: "Rubik";
  float: right;
  font-size: 1.4rem;
  font-weight: 700 !important;
  line-height: 1;
  color: #000;
  text-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/Containers/ExploreCountryNewUI/components/RequestCompanyModal.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,6BAAA;EACA,yBAAA;EACA,2BAAA;EACA,6BAAA;EACA,2BAAA;AACF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,uFAAA;EAMA,wBAAA;EACA,8BAAA;AALJ;;AAUA;EACE;IACE,WAAA;EAPF;EASA;IACE,UAAA;EAPF;EASA;IACE,UAAA;EAPF;AACF;AAUA;EACE,eAAA;EACA,oBAAA;EACA,YAAA;EACA,iBAAA;EACA,2BAAA;EACA,cAAA;EACA,WAAA;EACA,iBAAA;AARF","sourcesContent":[".headerTitle {\n  font-family: \"Rubik\" !important;\n  margin-right: auto !important;\n  color: #4c97e3 !important;\n  font-weight: 800 !important;\n  position: relative !important;\n  overflow: hidden !important;\n\n  // Add gradient effect\n  &::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: -100%;\n    height: 100%;\n    width: 100%;\n    background: linear-gradient(\n      120deg,\n      transparent,\n      rgba(255, 255, 255, 0.8),\n      transparent\n    );\n    transform: skewX(-25deg);\n    animation: shine 2.5s infinite;\n  }\n}\n\n// Define animation\n@keyframes shine {\n  0% {\n    left: -100%;\n  }\n  50% {\n    left: 100%;\n  }\n  100% {\n    left: 100%;\n  }\n}\n\n.close-icon {\n  cursor: pointer;\n  font-family: \"Rubik\";\n  float: right;\n  font-size: 1.4rem;\n  font-weight: 700 !important;\n  line-height: 1;\n  color: #000;\n  text-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
