import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./PowerBiDashBoard.scss";
import { ButtonComponent } from "../ButtonComponent/Button";

const PowerBIReport = ({
  powerBiDashBoard,
  setIsRecordBtnChecked,
  isViewSummary,
}) => {
  const cssClassName = "report-style-class";

  const embedConfig = {
    type: "report",
    id: powerBiDashBoard?.reportId, // Replace with your Power BI report ID
    embedUrl: powerBiDashBoard?.embedUrl, // Replace with your Power BI report embed URL
    accessToken: powerBiDashBoard?.token, // Replace with your access token
    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  };

  const eventHandlers = new Map([
    [
      "loaded",
      function () {
        console.log("Report loaded");
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report rendered");
      },
    ],
    [
      "error",
      function (event) {
        console.log(event.detail);
      },
    ],
  ]);

  const getEmbeddedComponent = (embeddedReport) => {
    window.report = embeddedReport;
  };
  return (
    <div style={{ padding: "0px 20px" }}>
      {!isViewSummary && (
        <ButtonComponent
          name={"Records"}
          style={{ width: "120px", height: "36px", margin: " 20px 0px" }}
          btnClick={() => {
            setIsRecordBtnChecked(true);
          }}
        />
      )}
      <center>
        {powerBiDashBoard?.reportId &&
          powerBiDashBoard?.embedUrl &&
          powerBiDashBoard?.token && (
            <PowerBIEmbed
              embedConfig={embedConfig}
              eventHandlers={eventHandlers}
              cssClassName={cssClassName}
              getEmbeddedComponent={getEmbeddedComponent}
            />
          )}
      </center>
    </div>
  );
};

export default PowerBIReport;
