// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  width: 99%;
  float: right;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #ffffff !important;
  box-shadow: 1px 3px 3px 0px rgb(201, 201, 201) !important;
  margin-bottom: 10px;
}

.accordionSummary {
  padding: 10px;
  border-radius: 5px;
}

.flag {
  height: 25px;
  margin-right: 10px;
}

.countryName {
  margin-left: 0.5rem;
  font-size: 15px;
  font-weight: 300;
}

.importerIcon {
  color: #f62424;
}

.exporterIcon {
  color: #f62424;
}

.divider {
  display: inline-block;
  width: 0;
  height: 1.5em;
  border-left: 2px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.sellers-buyers-value {
  background-color: #c2d8e5;
  width: 70px;
  text-align: center;
  height: 1.8em;
  font-size: 17px;
  color: #005d91;
  padding-top: 5px;
  margin-left: 10px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/GlobalSearchCard/globalSearchCard.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,oCAAA;EAEA,yDAAA;EAEA,mBAAA;AADF;;AAKA;EACE,aAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,qBAAA;EACA,QAAA;EACA,aAAA;EACA,8BAAA;EACA,+BAAA;AAFF;;AAKA;EACE,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAFF","sourcesContent":[".accordion {\n  width: 99%;\n  float: right;\n  border-radius: 4px;\n  margin-right: 15px;\n  background-color: #ffffff !important;\n\n  box-shadow: 1px 3px 3px 0px rgb(201 201 201) !important;\n  //   border: none !important;\n  margin-bottom: 10px;\n  // padding-bottom: 10px;\n}\n\n.accordionSummary {\n  padding: 10px;\n  border-radius: 5px;\n}\n\n.flag {\n  height: 25px;\n  margin-right: 10px;\n}\n\n.countryName {\n  margin-left: 0.5rem;\n  font-size: 15px;\n  font-weight: 300;\n}\n\n.importerIcon {\n  color: #f62424;\n}\n\n.exporterIcon {\n  color: #f62424;\n}\n\n.divider {\n  display: inline-block;\n  width: 0;\n  height: 1.5em;\n  border-left: 2px solid #ffffff;\n  border-right: 1px solid #ffffff;\n}\n\n.sellers-buyers-value {\n  background-color: #c2d8e5;\n  width: 70px;\n  text-align: center;\n  height: 1.8em;\n  font-size: 17px;\n  color: #005d91;\n  padding-top: 5px;\n  margin-left: 10px;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
