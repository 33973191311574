// import "./Scss/login.scss";
import React, { useState } from "react";
import {
  getPasswordVerifyDetails,
  getPasswordVerifyForSales,
  manageUserRoleOnActivation,
} from "../../Services/service";
import { useLocation, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoginPassword from "../../Component/LoginPasswordComponent/loginPassword";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";
import AuthFooter from "../../Component/AuthFooter/authFooter";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import { useEffect } from "react";
import { SALES_LOGIN_URL } from "./verify.enum";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  otp: Yup.string().required("This value is required."),
});
const PasswordVerify = (props) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});

  const search = useLocation().search;

  const location = useLocation();
  const { userId, userid, adminUserId, staySubUser, isFromSales } =
    location?.state;

  // const userId = new URLSearchParams(search).get("id");

  // function verifyPassword(values) {
  //   setloading(true);
  //   let verifyPassword = {};
  //   verifyPassword.passwordId = userId;
  //   verifyPassword.otp = values.otp;

  //   getPasswordVerifyDetails(verifyPassword)
  //     .then((verifyPasswordResponse) => {
  //       if (
  //         verifyPasswordResponse.message === "Password updated successfully."
  //       ) {
  //         if (userid && adminUserId && staySubUser) {
  //           userRoleChangeOnActivation();
  //         } else {
  //           setTimeout(() => {
  //             navigate("/consumers/login");
  //             setloading(false);
  //           }, 1000);
  //         }
  //       } else if (verifyPasswordResponse?.data?.type === "UNAUTHORISED") {
  //         setloading(false);
  //         seterror(true);
  //         seterrorMsg({
  //           title: verifyPasswordResponse.data.desc,
  //           content: verifyPasswordResponse.data.msg,
  //           open: true,
  //         });
  //       }
  //     })
  //     .catch((e) => {});
  // }

  const handlePasswordUpdateSuccess = () => {
    if (userid && adminUserId && staySubUser) {
      userRoleChangeOnActivation();
    } else {
      if (isFromSales) {
        setTimeout(() => {
          window.location.href = SALES_LOGIN_URL;
          setloading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/consumers/login");
          setloading(false);
        }, 1000);
      }
    }
  };

  const handleError = (errorData) => {
    setloading(false);
    seterror(true);
    seterrorMsg({
      title: errorData?.desc,
      content: errorData?.msg,
      open: true,
    });
  };

  const userRoleChangeOnActivation = () => {
    let changeUserRole = {};
    changeUserRole.userid = userid;
    changeUserRole.adminuserid = adminUserId;
    changeUserRole.staysubuser = parseInt(staySubUser);
    manageUserRoleOnActivation(changeUserRole)
      .then((res) => {
        if (res?.status === 200) {
          setTimeout(() => {
            navigate("/consumers/login");
            setloading(false);
          }, 1500);
        } else if (res?.status === 400) {
          seterror(true);
          seterrorMsg({
            title: "Warning",
            content: res?.data?.message,
            open: true,
          });
          setTimeout(() => {
            navigate("/consumers/login");
            setloading(false);
            seterror(false);
          }, 2000);
        }
      })
      .catch((err) => {
        seterrorMsg({
          title: err?.status === 500 ? "Error" : "Warning",
          content: err?.data?.message,
          open: true,
        });
      });
  };

  function verifyPassword(values) {
    setloading(true);
    let verifyPassword = {};
    verifyPassword.passwordId = userId;
    verifyPassword.otp = values.otp;

    const apiPassWordVerify = isFromSales
      ? getPasswordVerifyForSales
      : getPasswordVerifyDetails;

    apiPassWordVerify(verifyPassword)
      .then((verifyPasswordResponse) => {
        if (
          verifyPasswordResponse.message === "Password updated successfully."
        ) {
          handlePasswordUpdateSuccess();
        } else if (verifyPasswordResponse?.data?.type === "UNAUTHORISED") {
          handleError(verifyPasswordResponse?.data);
        }
      })
      .catch((e) => {});
  }
  return (
    <div>
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      {loading && (
        <Loading title={"Please Wait..."} content={"Verifying your password"} />
      )}
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values) => {
          verifyPassword(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            {/* <button type="submit">Submit</button> */}
            <div className="background-content mb-5" id="forgot-password">
              <div className="container">
                <div className="rowCards">
                  <div
                    style={{ marginTop: "50px" }}
                    className="card-forgot bg-pattern"
                  >
                    <div className="card-body">
                      <div className="text-center w-75 m-auto">
                        <a href="/consumers/login" rel="noopener noreferrer">
                          <span>
                            <img
                              className="img-center"
                              src={EximpediaLogo}
                              alt=""
                              height="44"
                            />
                          </span>
                        </a>
                        <p className="text-muted mb-4 mt-3">
                          OTP sent successfully to your registered email. Please
                          enter otp to verify your password
                        </p>
                      </div>
                      {/* <form id="form-account-login" className="parsley-components"> */}
                      <div className="form-group">
                        <label>OTP</label>
                        <Field
                          type="text"
                          name="otp"
                          className="form-control-forgot"
                          placeholder="Enter your otp"
                        />
                        {touched.otp && errors.otp && (
                          <div className="error-message">{errors.otp}</div>
                        )}
                      </div>
                      <div className="form-group mb-0 text-center">
                        <button
                          type="submit"
                          id="cta-login-account"
                          style={{ marginTop: "20px" }}
                          className="set-submit-btn button-color"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AuthFooter />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default PasswordVerify;
