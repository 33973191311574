import "./App.scss";
import React, { useCallback, useState } from "react";
import {
  authRoutes,
  demoAccountPlanRoutes,
  expiredPlanRoutes,
  pageRoutes,
} from "./Routes/Routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./Component/ProtectedForPage/Protected";
import ProtectedForAuth from "./Component/ProtectedForAuth/protectedAuthRoute";
import axios from "axios";
import Error from "./Component/ErrorLoader/error";
import { getAdxToken, logoutDetails } from "./Services/service";
import { getSessionValue } from "./Helpers/axiosWithAuth";
import TelemetryProvider from "./Logger/TelemetryProvider";
import { AES, enc } from "crypto-js";
import Cookies from "js-cookie";
import { useAppContext } from "./Contexts/AppContext";
import { CustomPopNotificationModal } from "./Component/PopNotification/CustomPopNotification";

axios.defaults.withCredentials = true;
export const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_LIVE,
});

const App = () => {
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [getDefaultValue, setDefaultValue] = useState(false);
  const [isFromAutoLogOut, setIsFromAutoLogOut] = useState(false);
  const { isPlanExpire, popOpen, setPopOpen, popUpApiContent } =
    useAppContext();

  // const getLocalStorageData = (key) => {
  //   const encryptedIsActive = JSON.parse(localStorage.getItem(key));
  //   let decryptedIsActive = false;
  //   if (encryptedIsActive) {
  //     decryptedIsActive = AES.decrypt(encryptedIsActive, secretKey).toString(
  //       enc.Utf8
  //     );
  //   }
  //   const booleanValue = JSON.parse(decryptedIsActive);
  //   return booleanValue;
  // };

  // let isPlanExpire = {
  //   isActive: getLocalStorageData("isActive"),
  //   isExtended: getLocalStorageData("isExtended"),
  // };

  instance.interceptors.response.use(
    async function (response) {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        setIsFromAutoLogOut(true);
      } else {
        setIsFromAutoLogOut(false);
      }
      const originalRequest = error?.config;
      let errorData = error?.response?.data;
      if (
        error.response?.status === 401 &&
        errorData?.message !== "Plan Expired! Please reach out to provider" &&
        errorData?.message === "Adx Token has Expired Now" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const res = await getAdxToken();
        if (res && res?.data && res?.data?.accessToken) {
          const { timestamp, accessToken } = res?.data;
          sessionStorage.setItem("adxToken", JSON.stringify(accessToken));
          sessionStorage.setItem("time", JSON.stringify(timestamp));
          if (originalRequest?.headers) {
            originalRequest.headers.AdxToken = getSessionValue("adxToken");
            originalRequest.headers.AdxTokenExpireTime =
              getSessionValue("time");
            return instance(originalRequest);
          }
        }
      }
      if (error.response?.status === 409) {
        if (errorData?.message) {
          seterror(true);
          seterrorMsg({
            title: "Warning",
            content: errorData?.message,
          });
        }
      }
      let _error = error;
      _error.response.data = errorData;
      return Promise.reject(_error);
    }
  );
  // axios.interceptors.response.use(
  //   async (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     const { data, status, config } = error.response;
  //     if (status === 409) {
  //       if (data.message) {
  //         seterror(true);
  //         seterrorMsg({
  //           title: "Warning",
  //           content: data.message,
  //         });
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  const routeForMap =
    isPlanExpire?.isActive === true
      ? pageRoutes
      : isPlanExpire?.isExtended === true
      ? demoAccountPlanRoutes
      : expiredPlanRoutes;

  const popComponentWrap = useCallback(
    () => (
      <CustomPopNotificationModal
        open={popOpen}
        onClose={() => setPopOpen(null)}
        modalContent={popUpApiContent}
      />
    ),
    [popOpen, popUpApiContent]
  );

  return (
    <div className="app-container">
      <Router>
        <TelemetryProvider
          instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY}
        >
          {popComponentWrap()}
          {error &&
            !errorMsg?.content?.includes("Adx Token has Expired Now") && (
              <Error errorMsg={errorMsg} open={error} setOpen={seterror} />
            )}
          <Routes>
            {routeForMap &&
              routeForMap?.length > 0 &&
              routeForMap?.map((route, index) => {
                return (
                  route.element && (
                    <Route
                      key={index}
                      path={route.path}
                      name={route.name}
                      element={
                        <Protected
                          route={route.path}
                          cmp={route.element}
                          isPlanExpire={isPlanExpire}
                          setDefaultValue={setDefaultValue}
                          path={route.path}
                          isFromAutoLogOut={isFromAutoLogOut}
                        />
                      }
                    />
                  )
                );
              })}

            {authRoutes.map((route, idx) => {
              return (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <ProtectedForAuth
                      cmp={route.element}
                      setDefaultValue={setDefaultValue}
                      path={route.path}
                    />
                  }
                ></Route>
              );
            })}
          </Routes>
        </TelemetryProvider>
      </Router>
    </div>
  );
};
export default App;
