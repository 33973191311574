// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#monthTab_container {
  display: flex;
  align-items: center;
  background-color: #bfd6e3;
  border: 1px solid #bfd6e3;
  border-radius: 5px;
  height: 50px;
  padding: 0px 0.5%;
}
#monthTab_container .monthTab-div {
  display: flex;
  flex: 1 1;
  gap: 1%;
}
#monthTab_container .monthTab-div .tab-div {
  min-width: 60px;
}
#monthTab_container .monthTab-div .tab-div .monthTab-btn {
  border: none;
  background-color: #bfd6e3;
  box-shadow: none;
  height: 40px;
  width: 100%;
  font-size: 16px;
  color: black;
}
#monthTab_container .monthTab-div .tab-div .active {
  border: 1px solid #005d91;
  background: #005d91;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 16px;
}
#monthTab_container .monthTab-div .tab-div-dateRangePicker {
  position: relative;
  top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/MonthTab/MonthTab.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AACI;EACI,aAAA;EACA,SAAA;EACA,OAAA;AACR;AACQ;EACI,eAAA;AACZ;AACY;EACI,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AAChB;AAEY;EACI,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAAhB;AAIQ;EACI,kBAAA;EACA,QAAA;AAFZ","sourcesContent":["#monthTab_container {\n    display: flex;\n    align-items: center;\n    background-color: #bfd6e3;\n    border: 1px solid #bfd6e3;\n    border-radius: 5px;\n    height: 50px;\n    padding: 0px 0.5%;\n\n    .monthTab-div {\n        display: flex;\n        flex: 1;\n        gap: 1%;\n\n        .tab-div {\n            min-width: 60px;\n\n            .monthTab-btn {\n                border: none;\n                background-color: #bfd6e3;\n                box-shadow: none;\n                height: 40px;\n                width: 100%;\n                font-size: 16px;\n                color: black;\n            }\n\n            .active {\n                border: 1px solid #005d91;\n                background: #005d91;\n                color: white;\n                height: 40px;\n                width: 100%;\n                font-size: 16px;\n            }\n        }\n\n        .tab-div-dateRangePicker {\n            position: relative;\n            top: 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
