import * as React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import "./modal.scss";
import { experimentalStyled as styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PrintIcon from "@mui/icons-material/Print";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { ButtonComponent, DeleteButton } from "../ButtonComponent/Button";
import { translateButtonClick } from "../../utils/CommanFunction";

const useStyles = makeStyles({
  save: {
    fontFamily: "Rubik",
    marginRight: "10px",
    color: "white",
  },
});
const FilterButton = styled(Button)({
  backgroundColor: "#005d91 !important",
  borderColor: "transparent !important",
  color: "white",
});

export default function ModalComponent(props) {
  const navigate = useNavigate();
  const Nothing = () => {};
  const handleClose = () => {
    props?.setAddCreditEmpty && props?.setAddCreditEmpty(false);
    props?.setAddCreditValue && props?.setAddCreditValue("");
    props?.setOpen(false);
    props?.setErrorMsgForExistingWorkspace &&
      props?.setErrorMsgForExistingWorkspace(false);
    //remove Duplicate records
    props?.setIsRemoveDuplicateButton &&
      props?.setIsRemoveDuplicateButton(!props?.isRemoveDuplicateButton);
  };

  const handleSaveBtn = () => {
    if (props.isConfirmBoxOpen) {
      navigate(props?.navigateUrl);
    } else if (props?.fromRemoveDuplicate) {
      props.savedClicked();
    } else {
      props.savedClicked(true);
    }
  };

  const modalStyles = {
    borderRadius: props?.isUpgradePlan ? "10px" : "0px",
    borderTop: props?.isUpgradePlan && "2.8px solid #4c97e3",
  };

  return (
    <div>
      <Modal
        id="Modal"
        open={props.open}
        onClose={(event, reason) => {
          props?.setAddCreditEmpty && props?.setAddCreditEmpty(false);
          props?.setAddCreditValue && props?.setAddCreditValue("");
          !props?.isFromExploreUrl && props?.setOpen(false);
          props?.setErrorMsgForExistingWorkspace &&
            props?.setErrorMsgForExistingWorkspace(false);
          props?.isFromExploreUrl && props?.onClose(event, reason);
        }}
      >
        <Box
          style={{ ...modalStyles, width: props?.width, height: props?.height }}
          className={`modal-styles ${props?.className}`}
        >
          {!props?.isUpgradePlan && (
            <div className="d-flex main" style={modalStyles}>
              {props?.headerIcon && (
                <div className="header-icon">{props?.headerIcon}</div>
              )}
              <Typography
                className="headerText"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                {props?.header}
              </Typography>
              <div className="m_10">
                <span
                  className="close"
                  onClick={() => {
                    props?.setAddCreditEmpty && props?.setAddCreditEmpty(false);
                    props?.setAddCreditValue && props?.setAddCreditValue("");
                    props?.setOpen(false);
                    props?.setErrorMsgForExistingWorkspace &&
                      props?.setErrorMsgForExistingWorkspace(false);
                    props?.isUpgradePlan && props?.handleOkayClick();
                  }}
                >
                  ×
                </span>
              </div>
            </div>
          )}

          <div>
            <div className="modalBody">{props?.modalBody}</div>
            <div
              className="hr"
              style={{ display: props?.isUpgradePlan && "none" }}
            ></div>
            <div
              className="footer-modal"
              style={{ display: props?.isUpgradePlan && "none" }}
            >
              <div className="footer-modal-content">
                {props?.saveButtonShown ? (
                  props?.saveText === "Yes ,delete it" ? (
                    <DeleteButton
                      name={props?.saveText}
                      btnClick={() => {
                        handleSaveBtn();
                      }}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  ) : (
                    <ButtonComponent
                      name={props?.saveText}
                      btnClick={() => {
                        handleSaveBtn();
                      }}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  )
                ) : (
                  ""
                )}

                {props?.mirrorData || props?.requestForDataModal ? (
                  ""
                ) : (
                  <DeleteButton
                    name={"Close"}
                    style={{ margin: "0px 5px 0px 5px " }}
                    btnClick={() => {
                      handleClose();
                    }}
                  />
                )}
              </div>
              {props.IsNextPrevBtnShown && (
                <div className="next-prev">
                  {props.prevOrNext === 0 ? (
                    <ButtonComponent
                      name={"Previous"}
                      btnIcon={ArrowBackIosNewIcon}
                      btnClick={Nothing}
                      disabled={true}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  ) : (
                    <ButtonComponent
                      btnClick={() =>
                        props?.previousDataTableRecords("previous")
                      }
                      name={"Previous"}
                      btnIcon={ArrowBackIosNewIcon}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  )}

                  {props?.prevOrNext + 1 >= props?.rowsPerPage ? (
                    <ButtonComponent
                      name={"Next"}
                      btnIcon={ArrowForwardIosIcon}
                      btnClick={Nothing}
                      disabled={true}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  ) : (
                    <ButtonComponent
                      btnClick={() => props?.nextDataTableRecords("next")}
                      name={"Next"}
                      endIcon={ArrowForwardIosIcon}
                      style={{ margin: "0px 5px 0px 5px " }}
                    />
                  )}

                  <ButtonComponent
                    btnClick={() => props?.handlePrintTableRecords()}
                    btnIcon={PrintIcon}
                    name={"Print"}
                    style={{ margin: "0px 5px 0px 5px ", maxWidth: "7.9rem" }}
                  />
                  {!props?.isFromRequestedBuyer && (
                    <ButtonComponent
                      btnClick={() => props?.handleSummaryDataForTrade()}
                      btnIcon={RemoveRedEyeIcon}
                      name={" View Summary"}
                      style={{ margin: "0px 5px 0px 5px ", maxWidth: "12rem" }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
