import React, { memo } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import "./advanceSearch.scss";
import SearchIcon from "@mui/icons-material/Search";
import SelectSearch from "react-select";
import { IsMultiSelectedFieldForAdvanceSearch } from "../../utils/commanConditions";
import { ButtonComponent, DeleteButton } from "../ButtonComponent/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { RelationOptions } from "../../utils/DropDownOptions";
import { QUERYTYPE } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import SelectDropDown from "../SelectDropDownNewUI/SelectDropDown";

const SearchComponent = memo(
  ({
    index,
    reactSelectAdvanceLoading,
    addButton,
    advanceSearchComponent,
    advanceSearchValue,
    advanceSearchFieldOptions,
    handleAdvanceSearchOption,
    defaultOptionForSearchState,
    handleInputChangeForAdvanceSearch,
    deleteButton,
    onSearchClick,
    emptyErrorAdvanceSearch,
    workSpaceBtnClass,
    handleBlurSelectedOptionsForAdvanceSearch,
    isExplore,
  }) => {
    const zIndex = 2000 + (100 + index);
    const selectStyles = {
      singleValue: (base, state) => ({
        ...base,
        color: state.selectmenuIsOpen ? "transparent" : base.color,
        zIndex: zIndex,
      }),
      control: (base) => ({
        ...base,
        height: 42,
        minHeight: 42,
        whiteSpace: "nowrap",
      }),
      valueContainer: (base) => ({
        ...base,
        flexWrap: "nowrap",
      }),
    };
    const defaultSearchOptions = [{ value: "In", title: "In" }];
    const handleBlur = (index) => {
      if (isExplore) {
        handleBlurSelectedOptionsForAdvanceSearch(index);
      }
    };
    return (
      <div id="advanceSearch-container-explore">
        <div className="advanceSearch-container">
          <div className="advanceSearch-item">
            <label
              className="advanceSearch-item-label"
              style={{ marginLeft: "0px", marginBottom: "5px" }}
            >
              Relation
            </label>
            <SelectDropDown
              options={RelationOptions?.length > 0 && RelationOptions}
              value={advanceSearchValue?.relation}
              onChange={(e) => handleAdvanceSearchOption(e, index)}
              name={"relation"}
              styleIndex={{ isEnabled: false, value: `${zIndex + 1111111}` }}
            />
            {/* <FormControl
              sx={{
                m: 1,
                minWidth: "8vw",
                marginTop: "5px",
                marginLeft: "0px",
              }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                size="small"
                value={advanceSearchValue?.relation}
                onChange={(e) => handleAdvanceSearchOption(e, index)}
                name="relation"
              >
                {RelationOptions?.length > 0 &&
                  RelationOptions?.map((e, index) => (
                    <MenuItem value={e.value} key={index}>
                      {e.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
          </div>
          <div className="advanceSearch-item">
            <label
              className="advanceSearch-item-label"
              style={{ marginBottom: "5px" }}
            >
              Search On
            </label>
            <SelectDropDown
              options={
                advanceSearchFieldOptions?.length &&
                advanceSearchFieldOptions?.map((item) => {
                  item.title = item.field.replace(/_/g, " ");
                  item.value = item.field;
                  return item;
                })
              }
              value={advanceSearchValue?.field}
              onChange={(e) => handleAdvanceSearchOption(e, index)}
              name={"field"}
              styleIndex={{ isEnabled: true, value: `${zIndex + 1111111}` }}
            />
            {/* <FormControl
              sx={{
                m: 1,
                Width: "10vw",
                maxWidth: "10vw",
                minWidth: "10vw",
                marginTop: "5px",
              }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={advanceSearchValue?.field}
                name="field"
                onChange={(e) => handleAdvanceSearchOption(e, index)}
              >
                {advanceSearchFieldOptions?.length > 0 &&
                  advanceSearchFieldOptions?.map((e, index) => (
                    <MenuItem
                      alias={e?.alias}
                      query={e?.query}
                      identifier={e?.identifier}
                      template={e?.template}
                      value={e?.field}
                      key={index}
                    >
                      {e.field.split("_").join(" ")}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
          </div>
          <div className="advanceSearch-item" style={{ zIndex: 4000 - index }}>
            <label className="advanceSearch-item-label">
              Choose Search Term
            </label>
            <SelectSearch
              styles={selectStyles}
              isClearable={true}
              isLoading={reactSelectAdvanceLoading}
              cacheOptions
              closeMenuOnSelect={
                advanceSearchValue?.query === QUERYTYPE?.CHOOSE ||
                IsMultiSelectedFieldForAdvanceSearch?.includes(
                  advanceSearchValue?.field
                )
                  ? false
                  : true
              }
              defaultOptions={defaultOptionForSearchState?.searchValueState}
              value={advanceSearchComponent[index]?.value}
              getOptionLabel={(e) => e?._id}
              getOptionValue={(e) => e?._id}
              options={defaultOptionForSearchState?.searchValueState}
              onInputChange={(e, { action }) =>
                handleInputChangeForAdvanceSearch(
                  e,
                  index,
                  "input",
                  advanceSearchComponent[index]?.query,
                  action
                )
              }
              onChange={(e) =>
                handleAdvanceSearchOption(
                  e,
                  index,
                  advanceSearchComponent[index]?.query
                )
              }
              onBlur={() => handleBlur(index)}
              isMulti={
                advanceSearchComponent[index]?.query === QUERYTYPE?.CHOOSE ||
                IsMultiSelectedFieldForAdvanceSearch?.includes(
                  advanceSearchComponent[index]?.field
                )
                  ? true
                  : false
              }
              className="advanceSearch-form-control advanceSearch-async"
              hideSelectedOptions={false}
              isDisabled={
                advanceSearchComponent[index].field === "" ? true : false
              }
            />
            {emptyErrorAdvanceSearch[index]?.advancedSearch &&
            (advanceSearchComponent[index]?.value === "" ||
              (advanceSearchComponent[index]?.value &&
                advanceSearchComponent[index]?.value.length === 0)) ? (
              <span
                style={{
                  color: "#d32f2f",
                  fontSize: "15px",
                  letterSpacing: "1px",
                  position: "relative",
                  top: "5px",
                  margin: "0px 0px 0px 10px",
                }}
              >
                This field is required
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="advanceSearch-item">
            <label
              className="advanceSearch-item-label"
              style={{ marginBottom: "5px" }}
            >
              Operator
            </label>
            <SelectDropDown
              options={
                advanceSearchValue?.operatorOptions?.length > 0
                  ? advanceSearchValue?.operatorOptions?.map((item) => {
                      item.title = item.type;
                      item.value = item.type;
                      return item;
                    })
                  : defaultSearchOptions
              }
              value={advanceSearchValue?.operator}
              onChange={(e) => handleAdvanceSearchOption(e, index)}
              name={"operator"}
              styleIndex={{ isEnabled: true, value: `${zIndex + 11111111}` }}
              isDisabled={
                advanceSearchValue?.operatorOptions?.length > 1 ? false : true
              }
            />
            {/* <FormControl
              sx={{ m: 1, minWidth: "8vw", marginTop: "5px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                size="small"
                value={advanceSearchValue?.operator}
                name="operator"
                // disabled={searchField?.operatorOptions && searchField?.operatorOptions?.length > 0 ? false : true}
                onChange={(e) => handleAdvanceSearchOption(e, index)}
              >
                {advanceSearchValue?.operatorOptions?.length > 0 &&
                  advanceSearchValue?.operatorOptions?.map((e, index) => (
                    <MenuItem value={e.type} key={index}>
                      {e.type}
                    </MenuItem>
                  ))}
                {advanceSearchValue?.operatorOptions?.length <= 0 &&
                  defaultSearchOptions?.map((e, index) => (
                    <MenuItem value={e.value} key={index}>
                      {e.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
          </div>
          <div className={`advanceSearch-item ${workSpaceBtnClass}`}>
            <label className="advanceSearch-item-label">Actions</label>
            <div className="action-btn">
              {advanceSearchComponent?.length - 1 === index ? (
                <>
                  <ButtonComponent
                    name={"search"}
                    btnIcon={SearchIcon}
                    style={{ margin: "5px 8px 8px 8px " }}
                    btnClick={onSearchClick}
                  />
                  <ButtonComponent
                    name={""}
                    btnIcon={AddIcon}
                    style={{ margin: "5px 8px 8px 8px " }}
                    btnClick={addButton}
                  />
                  <DeleteButton
                    name={""}
                    btnIcon={DeleteForeverIcon}
                    style={{ margin: "5px 8px 8px 8px " }}
                    btnClick={() => {
                      deleteButton(index);
                    }}
                  />
                </>
              ) : (
                <DeleteButton
                  name={""}
                  btnIcon={DeleteForeverIcon}
                  style={{ margin: "5px 8px 8px 8px " }}
                  btnClick={() => {
                    deleteButton(index);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SearchComponent;
