// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `embed {
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}`, "",{"version":3,"sources":["webpack://./src/Component/IframeForMarketPlace/iframeDashboard.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;AACJ","sourcesContent":["embed{\n    border-width: 2px;\n    border-style: inset;\n    border-color: initial;\n    border-image: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
