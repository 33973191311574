import moment from "moment";
import {
  DATEFORMAT,
  DEFAULT_OPERATOR,
} from "../Containers/ExploreCountryShipmentNewUI/Explore.enum";

export const sanitizeHsCodeDigitBoundaries = (
  value,
  hs_code_digit_classification,
  val
) => {
  if (val) {
    return value;
  } else {
    if (hs_code_digit_classification < value.toString().length) {
      return "0".repeat(0).concat(value?.toString());
    } else {
      return "0"
        .repeat(hs_code_digit_classification - value?.toString()?.length)
        ?.concat(value.toString());
    }
  }
};

export const getMonthName = (monthNumber) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber - 1];
};

export const getMonthNumber = (monthName) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthNum = months.indexOf(monthName) + 1;
  if (monthNum.toString().length < 2) {
    monthNum = "0" + monthNum;
  }
  return monthNum;
};

export const getChildValue = (data) => {
  let a = data?.find((e) => e.isChecked === false);
  return a ? false : true;
};

export const getOperatorNameUsingExpressionType = (expressionType) => {
  const operator = {
    200: "EXACT",
    201: "OR",
    202: "AND",
    203: "CONTAIN",
    204: "NOT_CONTAIN",
    207: "FULL_TEXT",
  };

  return operator[expressionType];
};

export const createOperator = (operatorArr) => {
  const operator =
    operatorArr?.length > 0 &&
    operatorArr?.map((operator) => {
      if (operator.hasOwnProperty("type")) {
        return operator;
      } else {
        return { type: operator };
      }
    });
  return operator?.length > 0 ? operator : [{ type: DEFAULT_OPERATOR }];
};

export const appendZeroAtStart = (dataSearchTerm) => {
  dataSearchTerm &&
    dataSearchTerm.length &&
    dataSearchTerm?.forEach((value) => {
      if (value?._id.toString().length === 7) {
        value._id = "0" + value._id;
      }
    });

  return dataSearchTerm;
};

export const sortCountryWiseData = (selectedCountryData) => {
  let sortedArr = [];
  sortedArr =
    selectedCountryData &&
    selectedCountryData?.length &&
    selectedCountryData.sort(function (a, b) {
      return a?.value?.localeCompare(b?.value);
    });

  return sortedArr;
};

export function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export const getRangeFromOption = (value) => {
  let min = 0;
  let max = 0;
  switch (value) {
    case "DEFAULT ALL":
      min = 0;
      max = 0;
      break;
    case "GREATER THAN (0-1 M)":
      min = 0;
      max = 1000000;
      break;
    case "GREATER THAN (1-5 M)":
      min = 1000000;
      max = 5000000;
      break;
    case "GREATER THAN (5-10 M)":
      min = 5000000;
      max = 10000000;
      break;
    case "GREATER THAN (10-50 M)":
      min = 10000000;
      max = 50000000;
      break;
    case "GREATER THAN (50 M-1 B)":
      min = 50000000;
      max = 1000000000;
      break;
    case "GREATER THAN (0-10 B)":
      min = 0;
      max = 10000000000;
      break;
    case "GREATER THAN (10-50 B)":
      min = 10000000000;
      max = 50000000000;
      break;
    case "GREATER THAN (50 B)":
      min = 50000000000;
      max = 100000000000;
      break;
    default:
      min = 0;
      max = 0;
      break;
  }
  return { min, max };
};

export const getFilterWidth = () => {
  const width = window.innerWidth;
  if (width > 1070) {
    return 2.5;
  } else if (width <= 1070 && width >= 900) {
    return 3;
  } else {
    return 4;
  }
};

export const getFilterWidthNew = () => {
  const width = window.innerWidth;
  if (width >= 1500 && width <= 1600) {
    return 1.8;
  } else {
    return 2;
  }
};

export const getTableGridWidth = () => {
  const width = window.innerWidth;
  if (width >= 1500 && width < 1600) {
    return 10.2;
  } else {
    return 10;
  }
};

export const getRecordWidth = () => {
  const width = window.innerWidth;
  if (width > 1070) {
    return 9.5;
  } else if (width <= 1070 && width >= 900) {
    return 9;
  } else {
    return 8;
  }
};
export const translateButtonClick = () => {
  try {
    const currentLangCookie = getCookie("googtrans");
    if (currentLangCookie) {
      let ele = document.querySelector(
        "#google_translate_element>div>div>select"
      );
      if (ele) {
        let e = new Event("change");
        let currentLang = ele?.value;
        ele.value = currentLang;
        ele.dispatchEvent(e);
      }
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const divideDateRange = (
  userSelectedStartDate,
  userSelectedEndDate,
  myEndDate
) => {
  // const { exploreShipmentSpecification, dateRange } = data;

  const result = [];

  // Convert strings to Date objects
  const startDate = new Date(userSelectedStartDate);
  const endDate = new Date(userSelectedEndDate);
  const myEnd = new Date(myEndDate);

  // Get month and year from myEndDate
  const myEndMonth = myEnd.getMonth();
  const myEndYear = myEnd.getFullYear();

  // Set myStartDate to the first day of the month based on myEndDate
  const myStart = new Date(myEndYear, myEndMonth, 1);

  // Check if the user-selected range falls within my range
  if (startDate <= myEnd && endDate >= myStart) {
    // Part 1: User-selected range within my range
    const part1StartDate = startDate < myStart ? myStart : startDate;
    const part1EndDate = endDate > myEnd ? myEnd : endDate;
    result.push({
      leftFieldvalueHot: moment(part1StartDate).format(DATEFORMAT?.format),
      rightFieldValueHot: moment(part1EndDate).format(DATEFORMAT?.format),
    });

    // Part 2: User-selected range outside my range
    if (startDate < myStart) {
      const part2StartDate = startDate;
      const part2EndDate = new Date(part1StartDate);
      part2EndDate.setDate(part2EndDate.getDate() - 1); // Set end date to one day before myStart
      result.push({
        leftFieldvalue: moment(part2StartDate).format(DATEFORMAT?.format),
        rightFieldValue: moment(part2EndDate).format(DATEFORMAT?.format),
      });
    }
  } else {
    // User-selected range entirely outside my range
    result.push({
      leftFieldvalue: moment(startDate).format(DATEFORMAT?.format),
      rightFieldValue: moment(endDate).format(DATEFORMAT?.format),
    });
  }

  return result;
};

export const getDateExpressionUsingDividedDateRange = (dividedDateRange) => {
  let dateExpression;

  if (dividedDateRange) {
    if (dividedDateRange.length === 2) {
      dateExpression = 2;
    } else {
      if (dividedDateRange && dividedDateRange[0]) {
        const obj = dividedDateRange[0];
        if (obj.hasOwnProperty("leftFieldvalueHot")) {
          dateExpression = 1;
        } else {
          dateExpression = 0;
        }
      }
    }
  }
  return dateExpression;
};
export const getDateExpressionUsingDividedDateRangeForMarketPlace = () => {
  return 22;
};

export const checkAndRemoveExpiredSessions = () => {
  const keysToRemove = [];

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (key.includes("powerBiResponse_")) {
      // Filter for specific keys if needed
      const storedData = JSON.parse(sessionStorage.getItem(key));
      const storedTimestamp = storedData.storedTimestamp || 0;
      if (storedTimestamp) {
        const currentTime = new Date().getTime();
        const differenceInMs = currentTime - storedTimestamp;
        const differenceInHours = differenceInMs / (1000 * 60 * 60);

        if (differenceInHours > 1) {
          keysToRemove.push(key);
        }
      }
    }
  }
  keysToRemove.forEach((key) => {
    sessionStorage.removeItem(key);
  });
};
export const getCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
};

export const capitalizeSentences = (text) => {
  return text?.replace(/(^\s*\w|[.!?]\s*\w)/g, (match) => match.toUpperCase());
};
