// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker-clear {
  display: none;
}

.ant-picker-presets > ul > li {
  margin: 10px 0px;
}

.ant-picker-presets > ul > li:first-child {
  margin: 50% 0px 10px 0px;
}

.ant-space-item > .ant-picker {
  height: 40px !important;
  top: -5px;
}

.ant-picker-cell-disabled > .ant-picker-cell-inner {
  text-decoration: line-through !important;
}

.ant-picker-cell-selected > .ant-picker-cell-inner {
  background: #005d91 !important;
}

.ant-picker-cell-range-end > .ant-picker-cell-inner {
  background: #005d91 !important;
}

.ant-picker-presets li:nth-child(1) {
  background: #005d91;
  color: white;
}

.ant-picker-presets li:hover {
  background-color: #005d91 !important;
  color: #ffffff !important;
  transition: 0.3s;
}

.ant-picker-dropdown .ant-picker-year-panel .ant-picker-content td {
  color: rgba(0, 0, 0, 0.88) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/DateRangePicker/DateRangePicker.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,wCAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;AACF;;AAGE;EACE,oCAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;;AAKA;EACE,qCAAA;AAFF;;AAKA;EACE,uBAAA;AAFF","sourcesContent":[".ant-picker-clear {\n  display: none;\n}\n\n.ant-picker-presets>ul>li {\n  margin: 10px 0px;\n}\n\n.ant-picker-presets>ul>li:first-child {\n  margin: 50% 0px 10px 0px;\n}\n\n.ant-space-item>.ant-picker {\n  height: 40px !important;\n  top: -5px;\n}\n\n.ant-picker-cell-disabled>.ant-picker-cell-inner {\n  text-decoration: line-through !important;\n}\n\n.ant-picker-cell-selected>.ant-picker-cell-inner {\n  background: #005d91 !important;\n}\n\n.ant-picker-cell-range-end>.ant-picker-cell-inner {\n  background: #005d91 !important;\n}\n\n.ant-picker-presets li:nth-child(1) {\n  background: #005d91;\n  color: white;\n}\n\n.ant-picker-presets li {\n  &:hover {\n    background-color: #005d91 !important;\n    color: #ffffff !important;\n    transition: 0.3s;\n  }\n}\n\n\n.ant-picker-dropdown .ant-picker-year-panel .ant-picker-content td {\n  color: rgba(0, 0, 0, 0.88) !important\n}\n\n.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {\n  border: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
