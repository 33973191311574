import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";

import SummaryDetailsForTrade from "./summaryDetailsForTrade";

const SummaryDetailsForTradeWithError = () => {
  function ErrorHandler({ error }) {
    return <div role="alert">{error && <ErrorPopup errorMsg={"Something Went Wrong , Please Login Again"} open={error} />}</div>;
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <SummaryDetailsForTrade />
      </ErrorBoundary>
    </>
  );
};

export default SummaryDetailsForTradeWithError;
