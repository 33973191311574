import React, { useCallback, useEffect, useState } from "react";
import "./Training.scss";
import StarRateIcon from "@mui/icons-material/StarRate";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ModalComponent from "../../Component/Modal/modal";
import ErrorLoading from "../../Component/ErrorLoader/error";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ButtonComponent } from "../../Component/ButtonComponent/Button";
import { getCookie } from "../../utils/CommanFunction";
import ApprovedSuccess from "../../Component/ApprovedSuccessModal/ApprovedSuccess";
import { LOADING, VideoFeedback } from "../../utils/LoadingMsg";
import Loading from "../../Component/SuccessLoader/success";
import ErrorData from "../../utils/ErrorHandling";
import { videoContent } from "./Training.enum";
import {
  getVideoRelatedFeedback,
  getVideoIndex,
  getSasToken,
} from "../../Services/service";
import { VideoPlayList } from "./Component/VideoPlayList/VideoPlayList";
import throwError from "../../utils/throwError";
// import VideoSvg from "./Component/VideoIndexSvg/VideoSvg";
import VideoSvg from "./Component/VideoIndexSvg/VideoSvg";
import { logger } from "../../Logger/Logger";
import { useLocation } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASE_LIVE;
const containerName = process.env.REACT_APP_CONTAINER_NAME;
const Training = () => {
  const theme = useTheme();
  const search = useLocation().search;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [showVideo, setShowVideo] = useState({
    fullTrainingVideo: true,
    videoIndex: true,
  });
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");

  const [activeIndex, setActiveIndex] = useState({
    selectedFullTrainingVideo: null,
    dividedVideo: null,
  });
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [approveSuccessModalData, setApproveSuccessModalData] = useState({});
  const [approveSuccessModal, setApproveSuccessModal] = useState(false);
  const [nameOfIndexVIdeo, setNameOfIndexVideo] = useState([]);
  const [fullTrainingVideoData, setFullTrainingVideoData] = useState([]);
  const [thumbnailOfVideo, setThumbnailOfVideo] = useState();
  const [key, setKey] = useState(Math.random());
  const [sasToken, setSasToken] = useState();
  const videoNameFromDashboard = new URLSearchParams(search).get("videoName");

  const getThumbnail = (thumbnailName, token) => {
    let src;
    if (token !== "" && token !== undefined) {
      src = `https://steximpediaciprod.blob.core.windows.net/${containerName}/${thumbnailName}?${token}`;
    } else {
      src = `https://steximpediaciprod.blob.core.windows.net/${containerName}/${thumbnailName}?${sasToken}`;
    }
    setThumbnailOfVideo(src);
  };
  useEffect(async () => {
    function fetchData() {
      setLoading(true);
      setLoadingMsg(LOADING);
      fetchVideoListAndToken()
        .then((res) => {
          setLoading(false);
          if (res?.videoList?.status === 200) {
            if (res?.videoList?.data) {
              let data = res?.videoList?.data?.data || [];
              if (data?.length > 0) {
                const _fullTrainingVideoData = data?.find(
                  (item) => item?.blob_name === "intro-eximpedia.mp4"
                );
                let _indexVideo = [];
                data?.forEach((item) => {
                  if (item?.blob_name !== "intro-eximpedia.mp4") {
                    _indexVideo.push(item);
                  }
                });
                setFullTrainingVideoData([_fullTrainingVideoData] || []);
                setNameOfIndexVideo(_indexVideo || []);
                getThumbnail(
                  _fullTrainingVideoData?.thumbnail,
                  res?.thumbnailToken?.data?.token
                );
                setVideoSource(
                  `${baseURL}/video/${_fullTrainingVideoData?._id}`
                );
                setKey(Math.random());
                setActiveIndex({
                  selectedFullTrainingVideo: _fullTrainingVideoData?._id,
                  dividedVideo: null,
                });
              }
            } else {
              throw throwError(res?.videoList);
            }
          } else {
            throw throwError(res?.videoList);
          }
          if (res?.thumbnailToken?.status === 200) {
            if (res?.thumbnailToken?.data) {
              if (res?.thumbnailToken?.data?.token) {
                setSasToken(res?.thumbnailToken?.data?.token);
              } else {
                setLoading(false);
                setError(true);
                setErrorMsg({
                  title: "Error",
                  content: ErrorData.SOMETHING_WENT_WRONG,
                });
              }
            } else {
              throw throwError(res?.videoList);
            }
          } else {
            throw throwError(res?.thumbnailToken);
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
    fetchData();
  }, []);

  const fetchVideoListAndToken = async () => {
    const [videoList, thumbnailToken] = await Promise.all([
      getVideoIndex(),
      getSasToken(),
    ]);
    return {
      videoList: videoList,
      thumbnailToken: thumbnailToken,
    };
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVideoSource("http://example.com/another-video-source");
    }, 500);

    return () => clearTimeout(timeout);
  }, [videoSource]);

  useEffect(() => {
    document.onkeydown = function (e) {
      if (
        e.keyCode == 123 || // F12 key
        (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) || // Ctrl+Shift+I
        (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) || // Ctrl+Shift+J
        (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) || // Ctrl+U
        (e.ctrlKey && e.keyCode == "S".charCodeAt(0)) || // Ctrl+S
        (e.ctrlKey && e.keyCode == "H".charCodeAt(0)) || // Ctrl+H
        (e.ctrlKey && e.keyCode == "A".charCodeAt(0)) || // Ctrl+A
        (e.ctrlKey && e.keyCode == "F".charCodeAt(0)) || // Ctrl+F
        (e.ctrlKey && e.keyCode == "E".charCodeAt(0)) || // Ctrl+E
        (e.ctrlKey && e.keyCode == "C".charCodeAt(0)) || // Ctrl+C
        (e.metaKey && e.altKey && e.keyCode == "I".charCodeAt(0)) || // Cmd+Option+I (Mac)
        (e.metaKey && e.altKey && e.keyCode == "J".charCodeAt(0)) || // Cmd+Option+J (Mac)
        (e.metaKey && e.altKey && e.keyCode == "U".charCodeAt(0)) || // Cmd+Option+U (Mac)
        (e.metaKey && e.altKey && e.keyCode == "S".charCodeAt(0)) || // Cmd+Option+S (Mac)
        (e.metaKey && e.altKey && e.keyCode == "H".charCodeAt(0)) || // Cmd+Option+H (Mac)
        (e.metaKey && e.altKey && e.keyCode == "A".charCodeAt(0)) || // Cmd+Option+A (Mac)
        (e.metaKey && e.altKey && e.keyCode == "F".charCodeAt(0)) || // Cmd+Option+F (Mac)
        (e.metaKey && e.altKey && e.keyCode == "C".charCodeAt(0)) || // Cmd+Option+C (Mac)
        (e.metaKey && e.altKey && e.keyCode == "E".charCodeAt(0)) || // Cmd+Option+E (Mac)
        (e.shiftKey && e.metaKey && e.keyCode == "C".charCodeAt(0)) || // Shift+Cmd+C (Mac)
        (e.altKey && e.metaKey && e.keyCode == "C".charCodeAt(0)) // Alt+Cmd+C (Mac)
      ) {
        return false;
      }
    };
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault(); // Disable right-click
    });
  }, []);

  const handleExpandClick = (videoType) => {
    setShowVideo(() => ({
      ...showVideo,
      [videoType]: !showVideo[videoType],
    }));
  };
  const handleJumpToChapterWiseVideo = (videoType) => {
    setActiveIndex({
      selectedFullTrainingVideo: null,
      dividedVideo: nameOfIndexVIdeo[0]._id,
    });
    getThumbnail(nameOfIndexVIdeo[0]?.thumbnail);
    setVideoSource(`${baseURL}/video/${nameOfIndexVIdeo[0]._id}`);
    setKey(Math.random());
  };

  const handleFeedBack = () => {
    setFeedBackModal(true);
  };
  const catchBlock = (err) => {
    const errorForLogger = `Training:- ${err?.msg ? err?.msg : err?.message}`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const handleFeedbackSubmit = async (feedbackText) => {
    if (feedbackText === "") {
      const blankFeedbackWarning = document.getElementById(
        "blank_feedback_warning"
      );
      blankFeedbackWarning.style.display = "block";
    } else {
      let feedbackObj = {};
      const currentSelectedVideoIndex =
        activeIndex?.dividedVideo === null
          ? activeIndex.selectedFullTrainingVideo
          : activeIndex.dividedVideo;

      let currentSelectedVideo = nameOfIndexVIdeo.find(
        (element) => element._id === currentSelectedVideoIndex
      );
      if (currentSelectedVideo === undefined) {
        currentSelectedVideo = fullTrainingVideoData.find(
          (element) => element._id === currentSelectedVideoIndex
        );
      }
      feedbackObj.video_name = currentSelectedVideo?.blob_name;
      feedbackObj = {
        feedback: feedbackText,
        video_name: currentSelectedVideo?.blob_name,
        email_id: getCookie("email_id"),
        user_id: getCookie("user_id"),
        account_id: getCookie("accountId"),
      };
      await getVideoRelatedFeedback(feedbackObj)
        .then((res) => {
          if (res?.status !== 200) {
            throw throwError(res);
          } else {
            setApproveSuccessModal(true);
            setApproveSuccessModalData(VideoFeedback);
            setFeedBackModal(false);
            setTimeout(() => {
              setApproveSuccessModal(false);
            }, 2000);
            setFeedbackText("");
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
  };

  useEffect(() => {
    if (videoNameFromDashboard && sasToken) {
      handleVideoIndexClickFromImage(
        videoNameFromDashboard,
        videoNameFromDashboard
      );
    }
  }, [videoNameFromDashboard, sasToken]);

  const handleVideoIndexClickFromImage = async (data, title) => {
    setLoading(true);
    setLoadingMsg(LOADING);

    let currentSelectedVideo = nameOfIndexVIdeo.find((element) =>
      element?.blob_name.includes(data)
    );
    setLoading(true);
    setLoadingMsg(LOADING);
    if (currentSelectedVideo) {
      getThumbnail(currentSelectedVideo?.thumbnail);

      setVideoSource(`${baseURL}/video/${currentSelectedVideo?._id}`);
      setKey(Math.random());
      let videoTitle = "dividedVideo";
      setActiveIndex({
        ...activeIndex,
        [videoTitle]: currentSelectedVideo?._id,
        selectedFullTrainingVideo: null,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData.SOMETHING_WENT_WRONG,
      });
    }
  };
  const handleVideoIndexClick = async (data, title) => {
    setLoading(true);
    setLoadingMsg(LOADING);

    let currentSelectedVideo = nameOfIndexVIdeo.find(
      (element) => element?.blob_name === data?.blob_name
    );
    if (currentSelectedVideo === undefined) {
      currentSelectedVideo = fullTrainingVideoData.find(
        (element) => element?.blob_name === data?.blob_name
      );
    }
    if (currentSelectedVideo) {
      if (currentSelectedVideo?.blob_name === "intro-eximpedia.mp4") {
        getThumbnail(currentSelectedVideo?.thumbnail, sasToken);
      } else {
        getThumbnail(currentSelectedVideo?.thumbnail);
      }
      setVideoSource(`${baseURL}/video/${currentSelectedVideo?._id}`);
      setKey(Math.random());
      setLoading(false);
      if (title === "dividedVideo") {
        setActiveIndex({
          ...activeIndex,
          [title]: currentSelectedVideo._id,
          selectedFullTrainingVideo: null,
        });
      } else {
        setActiveIndex({
          ...activeIndex,
          [title]: currentSelectedVideo._id,
          dividedVideo: null,
        });
      }
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData.SOMETHING_WENT_WRONG,
      });
    }
  };
  const handleNextVideo = async () => {
    let title = "";
    let data = [];
    if (activeIndex.dividedVideo === null) {
      title = "selectedFullTrainingVideo";
      data = fullTrainingVideoData;
    } else {
      title = "dividedVideo";
      data = nameOfIndexVIdeo;
    }
    const arrayLength = data.length;
    const obj = data.find((item) => item._id === activeIndex[title]);
    const selectedIndex = data.indexOf(obj);
    if (selectedIndex + 1 <= arrayLength) {
      setActiveIndex({
        ...activeIndex,
        [title]: data[selectedIndex + 1]._id,
      });
      setLoading(true);
      setLoadingMsg(LOADING);
      getThumbnail(data[selectedIndex + 1]?.thumbnail);

      setVideoSource(`${baseURL}/video/${data[selectedIndex + 1]._id}`);
      setKey(Math.random());

      if (title === "dividedVideo") {
        setActiveIndex({
          ...activeIndex,
          [title]: data[selectedIndex + 1]._id,
          selectedFullTrainingVideo: null,
        });
      } else {
        setActiveIndex({
          ...activeIndex,
          [title]: data[selectedIndex + 1]._id,
          dividedVideo: null,
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData.SOMETHING_WENT_WRONG,
      });
    }
  };

  const handlePreviousVideo = async () => {
    let title = "";
    let data = [];
    if (activeIndex.dividedVideo === null) {
      title = "selectedFullTrainingVideo";
      data = fullTrainingVideoData;
    } else {
      title = "dividedVideo";
      data = nameOfIndexVIdeo;
    }
    const arrayLength = data.length;
    const obj = data.find((item) => item._id === activeIndex[title]);
    const selectedIndex = data.indexOf(obj);
    const previousIndex = selectedIndex - 1;
    if (previousIndex <= arrayLength && previousIndex >= 0) {
      setActiveIndex({
        ...activeIndex,
        [title]: data[selectedIndex - 1]._id,
      });
      setLoading(true);
      setLoadingMsg(LOADING);
      if (data) {
        getThumbnail(data[selectedIndex - 1]?.thumbnail);

        setVideoSource(`${baseURL}/video/${data[selectedIndex - 1]._id}`);
        setKey(Math.random());
        if (title === "dividedVideo") {
          setActiveIndex({
            ...activeIndex,
            [title]: data[selectedIndex - 1]._id,
            selectedFullTrainingVideo: null,
          });
        } else {
          setActiveIndex({
            ...activeIndex,
            [title]: data[selectedIndex - 1]._id,
            dividedVideo: null,
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
        setErrorMsg({
          title: "Error",
          content: ErrorData.SOMETHING_WENT_WRONG,
        });
      }
    }
  };
  const handleToggleSidebar = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const getHtml = useCallback(() => {
    let obj;
    if (activeIndex.dividedVideo === null) {
      obj = fullTrainingVideoData.find(
        (item) => item._id === activeIndex.selectedFullTrainingVideo
      );
    } else {
      obj = nameOfIndexVIdeo.find(
        (item) => item._id === activeIndex.dividedVideo
      );
    }
    const modifiedFileName = obj?.blob_name.split(" ").join("_");
    const content = "content_";
    const fileNameWithNumber = content + modifiedFileName;
    const videoFileName = fileNameWithNumber.replace(".mp4", "");
    const html = videoContent[videoFileName] || `<span></span>`;
    return html;
  }, [activeIndex]);

  const disableButton = (name) => {
    let title = "";
    let data = [];
    if (activeIndex.dividedVideo === null) {
      title = "selectedFullTrainingVideo";
      data = fullTrainingVideoData;
    } else {
      title = "dividedVideo";
      data = nameOfIndexVIdeo;
    }
    const arrayLength = data.length;
    let disable = false;
    if (name === "Next") {
      if (data.length > 0 && data[arrayLength - 1]._id === activeIndex[title]) {
        disable = true;
      }
    } else {
      if (data.length > 0 && data[0]._id === activeIndex[title]) {
        disable = true;
      }
    }
    return disable;
  };

  return (
    <Grid
      container
      className="paddingAllLeftRight_training"
      id="training_module"
      sx={{ position: "relative" }}
    >
      {approveSuccessModal && (
        <ApprovedSuccess
          Open={approveSuccessModal}
          title={approveSuccessModalData.title}
          // footerTitle={approveSuccessModalData.footerTitle}
        />
      )}
      {!toggleSidebar ? (
        <div class="collar" onClick={handleToggleSidebar}>
          <ChevronRightIcon
            style={{
              float: "inline-end",
            }}
            fontSize="large"
            onClick={handleToggleSidebar}
          />
        </div>
      ) : (
        ""
      )}
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}

      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}

      <Grid
        item
        lg={toggleSidebar ? 2.5 : 0}
        md={toggleSidebar ? 3 : 0}
        sm={4}
        className={`training_sidebar ${
          isMobile ? "mobile_screen_sidebar" : ""
        }`}
        style={{ display: toggleSidebar ? "block" : "none" }}
      >
        <div className="toggle_sidebar">
          <div class="collar_left" onClick={handleToggleSidebar}>
            <ChevronLeftIcon fontSize="large" onClick={handleToggleSidebar} />
          </div>
        </div>

        <Grid
          style={{
            background: "#d8e3e9",
          }}
          id="collapse-tree-video"
          container
          gap={1}
        >
          <Grid
            item
            xs={12}
            className="video_index"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            sx={{ pl: 1 }}
            onClick={() => handleExpandClick("fullTrainingVideo")}
          >
            <StarRateIcon style={{ marginRight: "7px", color: "#005d91" }} />
            <h4 style={{ margin: "0", marginRight: "auto", color: "#005d91" }}>
              Full Training Video
            </h4>
            {showVideo?.fullTrainingVideo ? (
              <ExpandLessIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  color: "#005d91",
                  marginRight: "10px",
                }}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  color: "#005d91",
                  marginRight: "10px",
                }}
              />
            )}
          </Grid>
          {showVideo.fullTrainingVideo && fullTrainingVideoData?.length > 0 && (
            <VideoPlayList
              onVideoClick={handleVideoIndexClick}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              nameOfIndexVIdeo={fullTrainingVideoData}
              title="selectedFullTrainingVideo"
            />
          )}
          <Grid
            item
            className="full_training_video"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            sx={{ pl: 1 }}
            onClick={() => handleExpandClick("videoIndex")}
          >
            <StarRateIcon style={{ marginRight: "7px", color: "#005d91" }} />
            <h4 style={{ margin: "0", marginRight: "auto", color: "#0c6496" }}>
              Video Index
            </h4>
            {showVideo?.videoIndex ? (
              <ExpandLessIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  color: "#005d91",
                  marginRight: "10px",
                }}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  color: "#005d91",
                  marginRight: "10px",
                }}
              />
            )}
          </Grid>
          {showVideo.videoIndex && nameOfIndexVIdeo?.length > 0 && (
            <VideoPlayList
              data={nameOfIndexVIdeo}
              onVideoClick={handleVideoIndexClick}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              nameOfIndexVIdeo={nameOfIndexVIdeo}
              title="dividedVideo"
            />
          )}
        </Grid>
      </Grid>

      <Grid
        item
        lg={toggleSidebar ? 9.5 : 12}
        md={toggleSidebar ? 9 : 12}
        sm={toggleSidebar ? 8 : 12}
        // xs={toggleSidebar ? 12 : 12}
        sx={{ p: "0px 5%" }}
        className="margin-top"
      >
        {loading && <Loading title={LOADING.title} content={LOADING.content} />}
        <center>
          <div
            className="title-div-training"
            style={{
              padding: "20px 0px",
            }}
          >
            <h4
              className="text set-explore-title"
              style={{ textAlign: "left" }}
            >
              <span style={{ fontSize: "23px" }}>Help </span>
              <span
                className="badge bg-soft-blue text-blue label-worksapce-name"
                style={{ height: "27px" }}
              >
                Module
              </span>
            </h4>
          </div>
          {activeIndex?.selectedFullTrainingVideo ? (
            <div className="video-index-image">
              <VideoSvg
                handleClickVideoClickFromImage={handleVideoIndexClickFromImage}
              />
            </div>
          ) : (
            ""
          )}
          <video
            className={
              !toggleSidebar ? "closed-sidebar-video" : "training_video"
            }
            id="videoTag"
            controlsList="nodownload"
            disablepictureinpicture="false"
            controls
            key={key}
            poster={thumbnailOfVideo}
          >
            <source type="video/mp4" key={key + 1} src={videoSource} />
          </video>
        </center>
        <Grid item>
          {feedBackModal && (
            <ModalComponent
              open={feedBackModal}
              setOpen={setFeedBackModal}
              header="Provide Feedback"
              modalBody={
                <div>
                  <textarea
                    id="feedbackTextarea"
                    value={feedbackText}
                    rows={6}
                    cols={50}
                    style={{
                      resize: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    placeholder="Enter your feedback here..."
                    onChange={(e) => setFeedbackText(e.target.value)}
                  />

                  <p
                    id="blank_feedback_warning"
                    style={{ color: "red", display: "none" }}
                  >
                    Please Provide Some Feedback
                  </p>
                </div>
              }
              saveButtonShown
              saveText="Submit"
              savedClicked={() => handleFeedbackSubmit(feedbackText)}
            />
          )}
          <Grid item>
            <div className="video_data styled-div">
              <div
                className="video_data_content"
                dangerouslySetInnerHTML={{ __html: getHtml() }}
              ></div>
              <div
                className="content_feedback_text"
                style={{ display: "flex" }}
              >
                <h2>Was this helpful?</h2>
              </div>
              <div className="content_buttons">
                <div className="feedback_button">
                  <ButtonComponent name={"Yes"} btnClick={console.log()} />
                  <ButtonComponent name={"No"} btnClick={handleFeedBack} />
                </div>

                {activeIndex?.selectedFullTrainingVideo ? (
                  <div>
                    <ButtonComponent
                      name={"Jump to ChapterWise Videos"}
                      btnClick={() =>
                        handleJumpToChapterWiseVideo("videoIndex")
                      }
                    />
                  </div>
                ) : (
                  <div className="next_previous_button">
                    <ButtonComponent
                      btnClick={handlePreviousVideo}
                      name={"Previous"}
                      disabled={disableButton("Previous")}
                    />
                    <ButtonComponent
                      name={"Next"}
                      btnClick={handleNextVideo}
                      disabled={disableButton("Next")}
                    />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Training;
