import React from "react";
import StickyTable from "../StickyTable/Table";
import "./countryShipment.scss";
import { Table, TableCell, TableRow  } from "@mui/material";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import info from "../../Asset/images/info.png";
export default function Countryshipment(props) {
  const totalRecords = props?.countryShipmentModelData?.totalRecords;
  const purchasableRecords =
    props?.countryShipmentModelData?.purchasableRecords;
  const purchasableCost = props?.countryShipmentModelData?.purchasableCost;
  const availableCredits = props?.countryShipmentModelData?.availableCredits;
  const consumableCredits = purchasableRecords * 1;
  const balanceCredits = availableCredits - purchasableCost;
  const consumedLimit = props?.countryShipmentModelData?.consumedLimit;
  const leftLimit =
    props?.countryShipmentModelData?.allotedLimit - consumedLimit;
  const totalRecordsIndia = props?.countryShipmentModelData?.totalRecordsIndia;
  const totalRecordsOthers =
    props?.countryShipmentModelData?.totalRecordsOthers;
  let Data = [
    {
      key: "WorkSpace",
      value: props?.getNameForWorkSpace,
    },
    {
      key: "Purchase Points Cost",
      value: purchasableCost,
    },
    {
      key: "Total Records",
      value: totalRecords,
    },
    {
      key: "Available Credit Points",
      value: availableCredits,
    },
    {
      key: "Balance Credit Points",
      value: balanceCredits,
    },
  ];
  if (props?.mirrorData) {
    Data.splice(
      1,
      0,
      {
        key: "Indian Record",
        value: totalRecordsIndia,
      },
      {
        key: "Point Deduction for India Record",
        value: totalRecordsIndia,
      },
      {
        key: "Out-Side India Record",
        value: totalRecordsOthers,
      },
      {
        key: "Point Deduction Out-Side India Record",
        value: 5 * totalRecordsOthers,
      }
    );
  }

  let table_header = [
    {
      key: "SelectedRecords",
      title: "Selected Records",
    },
    {
      key: "PreviouslyPurchased",
      title: "Previously Purchased",
    },
    {
      key: "CurrentlyPurchasable",
      title: "Currently Purchasable",
    },
    {
      key: "PointsRecord",
      title: "Points / Record",
    },
    {
      key: "TotalPointsCost",
      title: "Total Points Cost",
    },
  ];
  if (props?.mirrorData) {
    table_header.push({
      key: "PointRecordIn",
      title: "Points / Record - IND",
    });
  }

  return (
    <div id="country-shipment">
      <StickyTable
        columns={table_header}
        rows={[
          {
            SelectedRecords: totalRecords,
            PreviouslyPurchased: totalRecords - purchasableRecords,
            CurrentlyPurchasable: purchasableRecords,
            PointsRecord: props?.currentCountry === "INDIA" ? 1 : 5,
            TotalPointsCost: purchasableCost,
            PointRecordIn: 1,
          },
        ]}
      />
      <div id="shipment-records">
        <div id="title">
          <AccountCircleIcon />{" "}
          <span id="sub-title"> RECORDS PURCHASE DETAILS </span>
        </div>
        <div id="record-data">
          <Table>
            {Data.length > 0 &&
              Data.map((e) => {
                return (
                  <>
                    <TableRow>
                      {e.key.split(" ").join("") === "BalanceCreditPoints" ? (
                        <>
                          <TableCell className="workSpace_tablecell">
                            {e.key}
                          </TableCell>
                          <TableCell className="workSpace_tablecell">
                            {e.value}
                            {e.value < 0 ? (
                              <ArrowDownwardIcon
                                fontSize="small"
                                id="downArrow"
                              />
                            ) : (
                              <ArrowUpwardIcon fontSize="small" id="upArrow" />
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className="workSpace_tablecell">
                            {e.key}
                          </TableCell>
                          <TableCell className="workSpace_tablecell">
                            {e.value}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </>
                );
              })}
          </Table>
        </div>
      </div>

      <div id="shipment-info">
        <span>
          <img
            src={info}
            alt="info"
            width="20px"
            height="20px"
            style={{ marginRight: "5px" }}
          />
        </span>
        <span id="limit-info">
          You have consumed{" "}
          <strong className="notranslate" style={{ color: "red" }}>
            {consumedLimit}
          </strong>{" "}
          and left with{" "}
          <strong className="notranslate" style={{ color: "green" }}>
            {leftLimit}
          </strong>{" "}
          limit to create workspaces
        </span>
      </div>
    </div>
  );
}
