// import React from "react";
// import "./DropDown.scss";

// export default function SelectDropdown(props) {
//     // const title = props?.optionValue;
//     return (
//         <div className="select-drop">
//             <select value={props?.value} onChange={(e) => props?.handleChange(e)} name={props?.name}>
//                 {props?.option.length > 0 &&
//                     props?.option.map((e, index) => {
//                         return (
//                             <option value={e?.value} key={index} >
//                                 {e?.title && e?.title.split("_").join(" ")}
//                             </option>
//                         );
//                     })}
//             </select>
//         </div>
//     );
// }

import React, { useEffect, useMemo } from "react";
import "./DropDown.scss";
import { useAppContext } from "../../../Contexts/AppContext";

// Utility function for formatting country names
const formatCountryName = (name) => name.split("_").join(" ");

export default function SelectDropdown({
  option,
  name,
  selectTrade,
  setSelectTrade,
  value,
  handleChange,
}) {
  const { defaultCountries } = useAppContext();

  // Memoizing the filtered options to avoid recalculating on every render
  const filteredOptions = useMemo(() => {
    if (
      name !== "country" ||
      !selectTrade ||
      !defaultCountries ||
      option.length <= 1
    )
      return option;

    let countries = [];
    const countryData =
      selectTrade.dataType === "true"
        ? selectTrade.tradeType === "EXPORT"
          ? defaultCountries.blExportCountries
          : defaultCountries.blImportCountries
        : selectTrade.tradeType === "EXPORT"
        ? defaultCountries.exportCountries
        : defaultCountries.importCountries;

    countries = countryData.map((country) => ({
      title: formatCountryName(country),
      value: country,
    }));

    return countries;
  }, [name, selectTrade, defaultCountries, option]);

  useEffect(() => {
    if (name === "country") {
      const countryData =
        selectTrade.dataType === "true"
          ? selectTrade.tradeType === "EXPORT"
            ? defaultCountries.blExportCountries
            : defaultCountries.blImportCountries
          : selectTrade.tradeType === "EXPORT"
          ? defaultCountries.exportCountries
          : defaultCountries.importCountries;
      if (
        countryData?.length &&
        filteredOptions?.length &&
        selectTrade?.country &&
        !countryData.includes(selectTrade?.country)
      ) {
        setSelectTrade((prev) => ({
          ...prev,
          country: countryData[0],
        }));
      }
    }
  }, [filteredOptions]);

  return (
    <div className="select-drop">
      <select value={value} onChange={handleChange} name={name}>
        {filteredOptions.length > 0 &&
          filteredOptions.map((e, index) => (
            <option value={e?.value} key={index}>
              {e?.title}
            </option>
          ))}
      </select>
    </div>
  );
}
