// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SummaryDetails-card-chartBox {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 24px;
  border-radius: 0.25rem;
}
.SummaryDetails-card-chartBox .SummaryDetails-card-header-chartBox {
  background-color: #005d91 !important;
  padding: 1.5rem;
  color: #fff !important;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.SummaryDetails-card-chartBox .SummaryDetails-card-body-chartBox {
  padding: 1rem;
  border: 2px solid #005d91;
  background: "#fff";
}`, "",{"version":3,"sources":["webpack://./src/Containers/SummaryDetailsForTrade/Component/ChartBox/ChartBox.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,eAAA;EAEA,iDAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAAE;EACE,oCAAA;EACA,eAAA;EACA,sBAAA;EACA,0DAAA;AAEJ;AACE;EACE,aAAA;EACA,yBAAA;EACA,kBAAA;AACJ","sourcesContent":[".SummaryDetails-card-chartBox {\n  background-color: #fff;\n  padding: 1.5rem;\n  -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);\n  margin-bottom: 24px;\n  border-radius: 0.25rem;\n  .SummaryDetails-card-header-chartBox {\n    background-color: #005d91 !important;\n    padding: 1.5rem;\n    color: #fff !important;\n    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;\n  }\n\n  .SummaryDetails-card-body-chartBox {\n    padding: 1rem;\n    border: 2px solid #005d91;\n    background: \"#fff\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
