// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashBoardComponent .row-dashbored {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
}
#dashBoardComponent #savedQueries {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Rubik" !important;
  color: black !important;
}
#dashBoardComponent #demo-1 {
  border-radius: 6px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Containers/DashboardNewUI/Component/DashboardCards/DashboardCardsUi.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AAAJ;AAEE;EACE,iBAAA;EACA,iBAAA;EACA,+BAAA;EACA,uBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADJ","sourcesContent":["#dashBoardComponent {\n  .row-dashbored {\n    display: flex;\n    align-items: center;\n    margin-bottom: -20px;\n  }\n  #savedQueries {\n    font-size: 1.5rem;\n    font-weight: bold;\n    font-family: \"Rubik\" !important;\n    color: black !important;\n  }\n\n  #demo-1 {\n    border-radius: 6px;\n    height: 80px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
