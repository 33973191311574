export const SWITCHVALUE = {
  SEARCH_ACTIVITY: "SEARCH ACTIVITY",
  WORKSPACE_ACTIVITY: "POINTS ACTIVITY",
};

export const SEARCH_ACTIVITY_TABLE_HEADER = [
  { key: "email_id", title: "Email Id" },
  { key: "role", title: "Role" },
  { key: "activity", title: "Activity" },
  { key: "country", title: "Country" },
  { key: "tradeType", title: "TradeType" },
  { key: "query", title: "Query" },
  { key: "creditLimitDescription", title: "Description" },
  { key: "queryResponseTime", title: "Query Time" },
  { key: "queryCreatedAt", title: "CreatedAt" },
];

export const POINTS_ACTIVITY_TABLE_HEADER = [
  { key: "email_id", title: "Email Id" },
  { key: "role", title: "Role" },
  { key: "activity", title: "Activity" },
  { key: "country", title: "Country" },
  { key: "tradeType", title: "TradeType" },
  { key: "query", title: "Query" },
  { key: "tradeRecords", title: "Trade Records" },
  { key: "points", title: "Deducted Points" },
  { key: "creditLimitDescription", title: "Credit Left" },
  { key: "queryResponseTime", title: "Query Time" },
  { key: "queryCreatedAt", title: "CreatedAt" },
];
