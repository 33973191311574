// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#view-column .sidebar-close {
  position: absolute;
  right: 12px;
  cursor: pointer;
}
#view-column .sidebar-title {
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #005d91;
  padding: 22px 25px;
  color: #fff;
}
#view-column .modal-sub-title {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: flex-end;
  position: sticky;
  top: 76px;
  background-color: white;
  padding: 0px 24px;
  z-index: 500;
}
#view-column .modal-sub-title .modal-checkbox-header-div {
  display: flex;
  width: 100%;
  height: 40px;
}
#view-column .modal-sub-title .modal-checkbox-header-div .modal-check-btn {
  background-color: #c2d8e5;
  width: 20%;
  height: 100%;
  margin-right: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
#view-column .modal-sub-title .modal-checkbox-header-div .modal-check-lable {
  background-color: #c2d8e5;
  width: 80%;
  height: 100%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}
#view-column .modal-sub-title .modal-checkbox-header-div .modal-check-lable .check-btn-lable {
  color: #005d91;
  font-size: 16px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Component/ViewColumnsModalNewUI/viewColumns.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,eAAA;AAAJ;AAEE;EACE,gBAAA;EACA,QAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,SAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;AADJ;AAEI;EACE,aAAA;EACA,WAAA;EACA,YAAA;AAAN;AACM;EACE,yBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;AACR;AACM;EACE,yBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;AACR;AAAQ;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEV","sourcesContent":["#view-column {\n  .sidebar-close {\n    position: absolute;\n    right: 12px;\n    cursor: pointer;\n  }\n  .sidebar-title {\n    position: sticky;\n    top: 0px;\n    z-index: 100;\n    background-color: #005d91;\n    padding: 22px 25px;\n    color: #fff;\n  }\n\n  .modal-sub-title {\n    display: flex;\n    height: 60px;\n    width: 100%;\n    align-items: flex-end;\n    position: sticky;\n    top: 76px;\n    background-color: white;\n    padding: 0px 24px;\n    z-index: 500;\n    .modal-checkbox-header-div {\n      display: flex;\n      width: 100%;\n      height: 40px;\n      .modal-check-btn {\n        background-color: #c2d8e5;\n        width: 20%;\n        height: 100%;\n        margin-right: 5px;\n        border-radius: 5px;\n        display: flex;\n        justify-content: center;\n      }\n      .modal-check-lable {\n        background-color: #c2d8e5;\n        width: 80%;\n        height: 100%;\n        margin-left: 5px;\n        display: flex;\n        align-items: center;\n        justify-content: space-around;\n        border-radius: 5px;\n        .check-btn-lable {\n          color: #005d91;\n          font-size: 16px;\n          font-weight: 500;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
