// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-for-mirrorData-selected-country-modal-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 402px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.tooltip-for-mirrorData-selected-country-modal-container.show {
  opacity: 1;
  transform: translateY(1px);
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container {
  height: 35vh;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  overflow-y: scroll !important;
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container::-webkit-scrollbar {
  width: 5px !important;
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container::-webkit-scrollbar-thumb {
  background-color: rgba(158, 165, 171, 0.46) !important;
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container .selected-country-span {
  display: flex;
  align-items: center;
  height: 20px;
  width: 106px;
  margin: 10px;
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container .selected-country-span .selected-country-flag {
  width: 30px;
  margin-right: 1px;
  border-radius: 3px;
}
.tooltip-for-mirrorData-selected-country-modal-container .tooltip-container .selected-country-span .country-name {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
  margin-left: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Containers/MirrorData/Component/SelectedCountryHoverCard/SelectedCountryHoverCard.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,mBAAA;EACA,2CAAA;EACA,YAAA;EAEA,UAAA;EACA,4BAAA;EACA,kDAAA;AAAF;AAEE;EACE,UAAA;EACA,0BAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,6BAAA;AADJ;AAGI;EACE,qBAAA;AADN;AAII;EACE,sDAAA;AAFN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AAHN;AAKM;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;AAHR;AAMM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAJR","sourcesContent":[".tooltip-for-mirrorData-selected-country-modal-container {\n  background-color: white;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\n  width: 402px;\n\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: opacity 0.3s ease, transform 0.3s ease;\n\n  &.show {\n    opacity: 1;\n    transform: translateY(1px);\n  }\n\n  .tooltip-container {\n    height: 35vh;\n    display: flex;\n    flex-wrap: wrap;\n    padding: 8px;\n    overflow-y: scroll !important;\n\n    &::-webkit-scrollbar {\n      width: 5px !important;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background-color: rgb(158 165 171 / 46%) !important;\n    }\n\n    .selected-country-span {\n      display: flex;\n      align-items: center;\n      height: 20px;\n      width: 106px;\n      margin: 10px;\n\n      .selected-country-flag {\n        width: 30px;\n        margin-right: 1px;\n        border-radius: 3px;\n      }\n\n      .country-name {\n        font-size: 12px;\n        font-weight: 400;\n        color: #6c757d;\n        margin-left: 6px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
