import "./App.scss";
import React, { useCallback, useState } from "react";
import {
  authRoutes,
  demoAccountPlanRoutes,
  expiredPlanRoutes,
  pageRoutes,
  tradePageRoutes,
} from "./Routes/Routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./Component/ProtectedForPage/Protected";
import ProtectedForAuth from "./Component/ProtectedForAuth/protectedAuthRoute";
import axios from "axios";
import Error from "./Component/ErrorLoader/error";
import { getAdxToken, logoutDetails } from "./Services/service";
import { getSessionValue } from "./Helpers/axiosWithAuth";
import TelemetryProvider from "./Logger/TelemetryProvider";
import { AES, enc } from "crypto-js";
import Cookies from "js-cookie";
import { useAppContext } from "./Contexts/AppContext";
import { CustomPopNotificationModal } from "./Component/PopNotification/CustomPopNotification";
import ReactToaster from "./Component/ReactToaster/ReactToaster";
import { USER_TYPE } from "./enums/userEnum";
import { LOADING } from "./utils/LoadingMsg";
import Loading from "./Component/SuccessLoader/success";
import { createTheme, ThemeProvider } from "@mui/material";
import { logger } from "./Logger/Logger";
import CryptoJS from "crypto-js";

axios.defaults.withCredentials = true;
export const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_LIVE,
});

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(0, 93, 145, 0.8)",
          color: "#ffffff",
          fontSize: "12px",
          padding: "5px 10px",
          borderRadius: "5px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        },
        arrow: {
          color: "rgba(0, 93, 145, 0.8)",
        },
      },
    },
  },
});

const STATUS_CODES = {
  UNAUTHORIZED: 401,
  BAD_GATEWAY: 502,
  CONFLICT: 409,
};

const MESSAGES = {
  TOKEN_EXPIRED: "Adx Token has Expired Now",
  PLAN_EXPIRED: "Plan Expired! Please reach out to provider",
};

const App = () => {
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  const KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const MODE = process.env.REACT_APP_MODE;
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [getDefaultValue, setDefaultValue] = useState(false);
  const [isFromAutoLogOut, setIsFromAutoLogOut] = useState(false);
  const {
    isPlanExpire,
    popOpen,
    setPopOpen,
    popUpApiContent,
    userType,
    userTypeLoading,
  } = useAppContext();

  // const getLocalStorageData = (key) => {
  //   const encryptedIsActive = JSON.parse(localStorage.getItem(key));
  //   let decryptedIsActive = false;
  //   if (encryptedIsActive) {
  //     decryptedIsActive = AES.decrypt(encryptedIsActive, secretKey).toString(
  //       enc.Utf8
  //     );
  //   }
  //   const booleanValue = JSON.parse(decryptedIsActive);
  //   return booleanValue;
  // };

  // let isPlanExpire = {
  //   isActive: getLocalStorageData("isActive"),
  //   isExtended: getLocalStorageData("isExtended"),
  // };

  const retryRequest = async (
    originalRequest,
    instance,
    retryCount = 0,
    maxRetries = 3,
    statusCode
  ) => {
    if (retryCount < maxRetries) {
      await new Promise((resolve) =>
        setTimeout(resolve, Math.pow(2, retryCount) * 1000)
      );
      return instance(originalRequest);
    } else {
      const errorForLogger = `:- ${statusCode} bad getWay after ${retryCount} retry`;
      logger(errorForLogger);
      console.error("Max retries reached");
      throw new Error("Retry limit exceeded");
    }
  };

  function processEximData(inputData) {
    const [segmentA, segmentB] = inputData.split(":");
    const partA = CryptoJS.enc.Base64.parse(segmentA);
    const partB = CryptoJS.enc.Base64.parse(segmentB);

    const result = CryptoJS.AES.decrypt(
      { ciphertext: partB },
      CryptoJS.enc.Base64.parse(KEY),
      { iv: partA, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return result.toString(CryptoJS.enc.Utf8);
  }

  instance.interceptors.response.use(
    async (response) => {
      if (MODE === "production") {
        if (response.data?.cryptexim) {
          try {
            const decryptedData = processEximData(response.data.cryptexim);
            response.data = JSON.parse(decryptedData);
          } catch (error) {
            const errorForLogger = `Error decrypting response:, ${error}`;
            logger(errorForLogger);
            console.error("Error decrypting response:", error);
            throw new Error("Failed to decrypt response");
          }
        }
      }

      return response;
    },

    async (error) => {
      const originalRequest = error?.config;

      if (!originalRequest) return Promise.reject(error);

      const status = error?.response?.status;
      if (MODE === "production") {
        if (error?.response?.data?.cryptexim) {
          try {
            const decryptedData = processEximData(
              error?.response?.data?.cryptexim
            );
            error.response.data = JSON.parse(decryptedData);
          } catch (err) {
            const errorForLogger = `Error decrypting response:, ${err}`;
            logger(errorForLogger);
            console.error("Error decrypting response:", err);
            throw new Error("Failed to decrypt response");
          }
        }
      }

      const errorData = error?.response?.data;

      if (
        status === STATUS_CODES.UNAUTHORIZED &&
        errorData?.message !== MESSAGES.TOKEN_EXPIRED
      ) {
        setIsFromAutoLogOut(true);
      } else {
        setIsFromAutoLogOut(false);
      }

      // Handle 401 errors
      if (status === STATUS_CODES.UNAUTHORIZED) {
        setIsFromAutoLogOut(errorData?.message !== MESSAGES.TOKEN_EXPIRED);
        if (
          errorData?.message === MESSAGES.TOKEN_EXPIRED &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          try {
            const res = await getAdxToken();
            if (res?.data?.accessToken) {
              sessionStorage.setItem(
                "adxToken",
                JSON.stringify(res.data.accessToken)
              );
              sessionStorage.setItem(
                "time",
                JSON.stringify(res.data.timestamp)
              );

              originalRequest.headers.AdxToken = getSessionValue("adxToken");
              originalRequest.headers.AdxTokenExpireTime =
                getSessionValue("time");

              return instance(originalRequest);
            }
          } catch (tokenError) {
            console.error("Token refresh failed", tokenError);
            return Promise.reject(tokenError);
          }
        }
      }

      // Handle 502 retry logic
      if (status === STATUS_CODES.BAD_GATEWAY) {
        if (!originalRequest._retryCount) originalRequest._retryCount = 0;
        return retryRequest(
          originalRequest,
          instance,
          originalRequest._retryCount++,
          status
        );
      }

      // Handle 409 conflict errors
      if (status === STATUS_CODES.CONFLICT && errorData?.message) {
        seterror(true);
        seterrorMsg({
          title: "Warning",
          content: errorData?.message,
        });
      }

      // Attach error data and reject
      let _error = error;
      _error.response.data = errorData;
      return Promise.reject(_error);
    }
  );

  const routeForMap =
    userType === USER_TYPE.API_USER
      ? tradePageRoutes
      : isPlanExpire?.isActive === true
      ? pageRoutes
      : isPlanExpire?.isExtended === true
      ? demoAccountPlanRoutes
      : expiredPlanRoutes;

  const popComponentWrap = useCallback(
    () => (
      <CustomPopNotificationModal
        open={popOpen}
        onClose={() => setPopOpen(null)}
        modalContent={popUpApiContent}
      />
    ),
    [popOpen, popUpApiContent]
  );

  if (userTypeLoading) {
    return <Loading title={LOADING.title} content={LOADING.content} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="app-container">
        <Router>
          <TelemetryProvider
            instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY}
          >
            <ReactToaster />
            {popComponentWrap()}
            {error &&
              !errorMsg?.content?.includes("Adx Token has Expired Now") && (
                <Error errorMsg={errorMsg} open={error} setOpen={seterror} />
              )}
            <Routes>
              {routeForMap &&
                routeForMap?.length > 0 &&
                routeForMap?.map((route, index) => {
                  return (
                    route.element && (
                      <Route
                        key={index}
                        path={route.path}
                        name={route.name}
                        element={
                          <Protected
                            route={route.path}
                            cmp={route.element}
                            isPlanExpire={isPlanExpire}
                            setDefaultValue={setDefaultValue}
                            path={route.path}
                            isFromAutoLogOut={isFromAutoLogOut}
                          />
                        }
                      />
                    )
                  );
                })}

              {authRoutes.map((route, idx) => {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    element={
                      <ProtectedForAuth
                        cmp={route.element}
                        setDefaultValue={setDefaultValue}
                        path={route.path}
                      />
                    }
                  ></Route>
                );
              })}
            </Routes>
          </TelemetryProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
};
export default App;
