// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleButtonGroup {
  display: flex !important;
  justify-content: flex-start !important;
  padding: 10px 20px !important;
  gap: 12px !important;
}
.toggleButtonGroup .toggleButton {
  background-color: #f1f3f4 !important;
  color: #707070 !important;
  padding: 5px 15px !important;
  text-transform: none !important;
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
}
.toggleButtonGroup .toggleButton:hover {
  background-color: #e2e6ea !important;
}
.toggleButtonGroup .toggleButton .countBadge {
  background-color: #2f2f2f !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  padding: 0px 8px !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.toggleButtonGroup .toggleButton .unreadCountBadge {
  background-color: #005d91 !important;
  color: white !important;
  border-radius: 12px !important;
  padding: 0px 8px !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.toggleButtonGroup .activeButton {
  background-color: #bfd6e3 !important;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/Navbar/Notification/NotificationButtons.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,sCAAA;EACA,6BAAA;EACA,oBAAA;AACF;AACE;EACE,oCAAA;EACA,yBAAA;EACA,4BAAA;EACA,+BAAA;EACA,gBAAA;EACA,wBAAA;EACA,8BAAA;EACA,mBAAA;EACA,0BAAA;AACJ;AACI;EACE,oCAAA;AACN;AAEI;EACE,oCAAA;EACA,yBAAA;EACA,8BAAA;EACA,2BAAA;EACA,0BAAA;EACA,8BAAA;AAAN;AAGI;EACE,oCAAA;EACA,uBAAA;EACA,8BAAA;EACA,2BAAA;EACA,0BAAA;EACA,8BAAA;AADN;AAKE;EACE,oCAAA;EACA,uBAAA;AAHJ","sourcesContent":[".toggleButtonGroup {\n  display: flex !important;\n  justify-content: flex-start !important;\n  padding: 10px 20px !important;\n  gap: 12px !important;\n\n  .toggleButton {\n    background-color: #f1f3f4 !important;\n    color: #707070 !important;\n    padding: 5px 15px !important;\n    text-transform: none !important;\n    font-weight: 600;\n    display: flex !important;\n    align-items: center !important;\n    gap: 8px !important;\n    cursor: pointer !important;\n\n    &:hover {\n      background-color: #e2e6ea !important;\n    }\n\n    .countBadge {\n      background-color: #2f2f2f !important;\n      color: #ffffff !important;\n      border-radius: 12px !important;\n      padding: 0px 8px !important;\n      font-size: 12px !important;\n      font-weight: normal !important;\n    }\n\n    .unreadCountBadge {\n      background-color: #005d91 !important;\n      color: white !important;\n      border-radius: 12px !important;\n      padding: 0px 8px !important;\n      font-size: 12px !important;\n      font-weight: normal !important;\n    }\n  }\n\n  .activeButton {\n    background-color: #bfd6e3 !important;\n    color: black !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
