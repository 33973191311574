// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#explore-filter-box #filters-btn {
  margin: 7px 15px;
}
#explore-filter-box .apply-filter-label {
  margin-left: 4px !important;
  font-size: 12px !important;
}
#explore-filter-box .content-between-border {
  margin-top: 22px;
  margin-bottom: 53px;
}
#explore-filter-box .set-border {
  border-bottom: 1px solid rgb(214, 214, 214);
  margin-top: 23px;
}
#explore-filter-box .filter-title {
  padding-left: 0px;
  padding: 15.5px 18px;
  background: #c2d8e5;
  border-radius: 0px 8px 0px 0px !important;
}
#explore-filter-box .filter-title #filter-text {
  color: rgb(108, 117, 125);
}
#explore-filter-box .filter-title #filter-title {
  display: flex;
  align-items: center;
  color: #005d91;
  margin: 0;
}
#explore-filter-box #applied-filter {
  margin-left: auto;
  margin-right: 21px;
}
#explore-filter-box .filter-btn {
  padding-top: 20px !important;
  margin-top: -15px !important;
  padding-left: 0px;
  padding-right: 5px;
}
#explore-filter-box .apply-filter-btn {
  margin-top: -15px !important;
  padding-top: 20px !important;
  padding-left: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/CollapseFilterNewUI/Component/ExploreFilter/ExploreFilter.scss"],"names":[],"mappings":"AAKE;EACE,gBAAA;AAJJ;AAOE;EACE,2BAAA;EACA,0BAAA;AALJ;AAQE;EACE,gBAAA;EACA,mBAAA;AANJ;AASE;EACE,2CAAA;EACA,gBAAA;AAPJ;AAUE;EAEE,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,yCAAA;AATJ;AAWI;EACE,yBAAA;AATN;AAYI;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;AAVN;AAcE;EACE,iBAAA;EACA,kBAAA;AAZJ;AAeE;EACE,4BAAA;EACA,4BAAA;EACA,iBAAA;EACA,kBAAA;AAbJ;AAgBE;EACE,4BAAA;EACA,4BAAA;EACA,6BAAA;AAdJ","sourcesContent":["#explore-filter-box {\n  // padding: 0px 16px;\n  // margin-bottom: 25px;\n  // padding-bottom: 8px;\n  // border-bottom: 1px solid #0000001a;\n  #filters-btn {\n    margin: 7px 15px;\n  }\n\n  .apply-filter-label {\n    margin-left: 4px !important;\n    font-size: 12px !important;\n  }\n\n  .content-between-border {\n    margin-top: 22px;\n    margin-bottom: 53px;\n  }\n\n  .set-border {\n    border-bottom: 1px solid rgb(214, 214, 214);\n    margin-top: 23px;\n  }\n\n  .filter-title {\n    // padding-top: 12px !important;\n    padding-left: 0px;\n    padding: 15.5px 18px;\n    background: #c2d8e5;\n    border-radius: 0px 8px 0px 0px !important;\n\n    #filter-text {\n      color: rgb(108, 117, 125);\n    }\n\n    #filter-title {\n      display: flex;\n      align-items: center;\n      color: #005d91;\n      margin: 0;\n    }\n  }\n\n  #applied-filter {\n    margin-left: auto;\n    margin-right: 21px;\n  }\n\n  .filter-btn {\n    padding-top: 20px !important;\n    margin-top: -15px !important;\n    padding-left: 0px;\n    padding-right: 5px;\n  }\n\n  .apply-filter-btn {\n    margin-top: -15px !important;\n    padding-top: 20px !important;\n    padding-left: 12px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
