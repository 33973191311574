// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#exploreModal {
  max-height: 600px;
  overflow: scroll;
}
#exploreModal .details {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  font-weight: 800;
  cursor: pointer;
  height: 40px;
  background: #c2d8e5;
  margin-top: 15px;
  color: #005d91;
}
#exploreModal .table-head {
  font-weight: 700;
  color: #303336;
  max-width: 14vw;
}
#exploreModal .cmp-table-head {
  font-weight: 700;
  color: #303336;
}
#exploreModal .set-border {
  border: 1px solid lightgrey;
  background-color: #ecf3f7;
}
#exploreModal .fav-icon {
  cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/ExploreDataTableModal/exploreModal.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAGE;EACE,gBAAA;EACA,cAAA;EAEA,eAAA;AAFJ;AAKE;EACE,gBAAA;EACA,cAAA;AAHJ;AAME;EACE,2BAAA;EACA,yBAAA;AAJJ;AAOE;EACE,0BAAA;AALJ","sourcesContent":["#exploreModal {\n  max-height: 600px;\n  overflow: scroll;\n\n  .details {\n    display: flex;\n    justify-content: space-between;\n    padding: 0px 10px;\n    align-items: center;\n    font-weight: 800;\n    cursor: pointer;\n    height: 40px;\n    background: #c2d8e5;\n    margin-top: 15px;\n    color: #005d91;\n\n  }\n\n  .table-head {\n    font-weight: 700;\n    color: #303336;\n    // width: 18vw;\n    max-width: 14vw;\n  }\n\n  .cmp-table-head {\n    font-weight: 700;\n    color: #303336;\n  }\n\n  .set-border {\n    border: 1px solid lightgrey;\n    background-color: #ecf3f7;\n  }\n\n  .fav-icon {\n    cursor: pointer !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
