// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-countryCode-modal-body {
  display: flex;
  flex-direction: column;
}
.select-countryCode-modal-body .grouped-radio-dropdown {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.select-countryCode-modal-body .grouped-radio-dropdown .container {
  display: flex;
}
.select-countryCode-modal-body .grouped-radio-dropdown .container .region-dropdown .MuiFormControl-root .MuiInputBase-root {
  width: 200px;
  height: 35px;
}
.select-countryCode-modal-body .all-country {
  color: aliceblue;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Containers/MirrorData/Component/CountrySelectModal.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAEJ;AAAI;EACE,aAAA;AAEN;AAEU;EACE,YAAA;EACA,YAAA;AAAZ;AAOE;EAEE,gBAAA;EACA,gBAAA;AANJ","sourcesContent":[".select-countryCode-modal-body {\n  display: flex;\n  flex-direction: column;\n  .grouped-radio-dropdown {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n\n    .container {\n      display: flex;\n\n      .region-dropdown {\n        .MuiFormControl-root {\n          .MuiInputBase-root {\n            width: 200px;\n            height: 35px;\n          }\n        }\n      }\n    }\n  }\n\n  .all-country {\n    // border-radius: 8px;\n    color: aliceblue;\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
