// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#advanceSearch-container-explore .advanceSearch-container {
  display: flex;
  flex-direction: row;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-item-label {
  margin-left: 10px;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .action-btn {
  display: flex;
  flex-direction: row;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-form-control {
  height: 40px !important;
  width: 13rem;
  margin: 5px 8px 8px 8px;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async {
  width: 20vw !important;
}
@media (max-width: 800px) and (min-width: 500px) {
  #advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async {
    width: 40vw !important;
  }
}
@media (max-width: 500px) {
  #advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async {
    width: 45vw !important;
  }
}
@media (max-width: 420px) {
  #advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async {
    width: 80vw !important;
  }
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async .css-xb97g8 {
  cursor: pointer;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async .css-15pchde-control {
  height: 40px !important;
  min-height: 40px !important;
}
#advanceSearch-container-explore .advanceSearch-container .advanceSearch-item .advanceSearch-async #react-select-7-listbox {
  z-index: 12000 !important;
}
#advanceSearch-container-explore .advanceSearch-container .workSpaceBtnClass {
  margin-left: auto;
}
#advanceSearch-container-explore .advanceSearch-container .workSpaceBtnClass .action-btn > button:last-child {
  margin-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/AdvanceSearchComponentNewUI/advanceSearch.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AAAN;AAEM;EACE,iBAAA;AAAR;AAGM;EACE,aAAA;EACA,mBAAA;AADR;AAIM;EACE,uBAAA;EACA,YAAA;EACA,uBAAA;AAFR;AAKM;EACE,sBAAA;AAHR;AAMQ;EAJF;IAKI,sBAAA;EAHR;AACF;AAKQ;EARF;IASI,sBAAA;EAFR;AACF;AAIQ;EAZF;IAaI,sBAAA;EADR;AACF;AAGQ;EACE,eAAA;AADV;AAIQ;EACE,uBAAA;EACA,2BAAA;AAFV;AAKQ;EACE,yBAAA;AAHV;AAQI;EACE,iBAAA;AANN;AASI;EACE,4BAAA;AAPN","sourcesContent":["#advanceSearch-container-explore {\n  .advanceSearch-container {\n    display: flex;\n    flex-direction: row;\n\n    .advanceSearch-item {\n      display: flex;\n      flex-direction: column;\n      flex-wrap: wrap;\n\n      .advanceSearch-item-label {\n        margin-left: 10px;\n      }\n\n      .action-btn {\n        display: flex;\n        flex-direction: row;\n      }\n\n      .advanceSearch-form-control {\n        height: 40px !important;\n        width: 13rem;\n        margin: 5px 8px 8px 8px;\n      }\n\n      .advanceSearch-async {\n        width: 20vw !important;\n        // z-index: 2000 !important;\n\n        @media (max-width: 800px) and (min-width: 500px) {\n          width: 40vw !important;\n        }\n\n        @media (max-width: 500px) {\n          width: 45vw !important;\n        }\n\n        @media (max-width: 420px) {\n          width: 80vw !important;\n        }\n\n        .css-xb97g8 {\n          cursor: pointer;\n        }\n\n        .css-15pchde-control {\n          height: 40px !important;\n          min-height: 40px !important;\n        }\n\n        #react-select-7-listbox {\n          z-index: 12000 !important;\n        }\n      }\n    }\n\n    .workSpaceBtnClass {\n      margin-left: auto;\n    }\n\n    .workSpaceBtnClass .action-btn>button:last-child {\n      margin-right: 0px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
