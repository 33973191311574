import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getBlCountryData,
  getCustomCountryData,
  getPlanDetail,
  getSilkCountryData,
} from "../Services/service";
import { getCookie } from "../utils/CommanFunction";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isPlanExpire, setIsPlanExpire] = useState({
    isActive: true,
    isExtended: true,
  });
  const [popOpen, setPopOpen] = useState(false);
  const [popUpApiContent, setPopUpApiContent] = useState([]);
  const [importLoading, setImportLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(true);
  const [blExportLoading, setBlExportLoading] = useState(true);
  const [blImportLoading, setBlImportLoading] = useState(true);
  const [silkLoading, setSilkLoading] = useState(true);
  const [defaultCountries, setDefaultCountries] = useState({
    importCountries: [],
    exportCountries: [],
    blExportCountries: [],
    blImportCountries: [],
    allimportCountries: [],
    allexportCountries: [],
    allblImportCountries: [],
    allblExportCountries: [],
    silkRoute: [],
  });

  useEffect(() => {
    const accountId = getCookie("accountId");
    if (accountId) {
      getPlanDetail()
        .then((res) => {
          if (res) {
            setIsPlanExpire({
              isActive: res?.isactive,
              isExtended: res?.isextended,
            });
          }
        })
        .catch((err) => {
          console.log("err contect ", err);
        });
    }
  }, []);

  const fetchCountryData = async (type, dataKey, apiCall, setLoading) => {
    setLoading(true);
    try {
      const res = await apiCall(type);
      const countries = res?.data?.countries || res?.data?.blCountries;
      const formattedCountries = countries
        ?.map((countryData) => countryData?.country?.toUpperCase())
        .filter(Boolean);
      setDefaultCountries((prev) => ({
        ...prev,
        [dataKey]: formattedCountries,
        [`all${dataKey}`]: countries,
      }));
    } catch (err) {
      setDefaultCountries((prev) => ({ ...prev, [dataKey]: [] }));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    const accountId = getCookie("accountId");
    if (accountId) {
      await fetchCountryData(
        "import",
        "importCountries",
        getCustomCountryData,
        setImportLoading
      );
      await fetchCountryData(
        "export",
        "exportCountries",
        getCustomCountryData,
        setExportLoading
      );
      await fetchCountryData(
        "export",
        "blExportCountries",
        getBlCountryData,
        setBlExportLoading
      );
      await fetchCountryData(
        "import",
        "blImportCountries",
        getBlCountryData,
        setBlImportLoading
      );
      await fetchCountryData(
        "EXIM",
        "silkRoute",
        getSilkCountryData,
        setSilkLoading
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isPlanExpire,
        setIsPlanExpire,
        popOpen,
        setPopOpen,
        popUpApiContent,
        setPopUpApiContent,
        defaultCountries,
        importLoading,
        exportLoading,
        silkLoading,
        blImportLoading,
        blExportLoading,
        fetchData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
