import React, { useState, useEffect, createContext, useCallback } from "react";
import "./navbar.scss";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import EximpediaSmallLogo from "../../Asset/images/logo-sm-og.png";
import UserAvatar from "../../Asset/images/user-5.jpg";
import Cookies from "js-cookie";
import {
  getNotificationDetails,
  updateNotification,
} from "../../Services/service";
import {
  Typography,
  Badge,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Menu,
  Avatar,
} from "@mui/material";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Notification from "./Notification/Notification";
import throwError from "../../utils/throwError";
import ErrorLoading from "../../Component/ErrorLoader/error";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import ErrorData from "../../utils/ErrorHandling";
import { getCurrentYear } from "../../utils/CommanFunction";
import { planExpiredWarning } from "../../utils/LoadingMsg";
import PointDeductionBar from "./PointDeductionBar/PointDeductionBar";
import { AES, enc } from "crypto-js";
import { useAppContext } from "../../Contexts/AppContext";

const useStyles = makeStyles({
  notiHeader: {
    display: "block",
    fontSize: "15px !important",
    fontWeight: 600,
    color: "#343A40 !important",
    position: "sticky",
    top: "0px",
    zIndex: 10,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    background: "white",
    margin: "0px",
  },
  dflex: {
    display: "flex",
  },
  notiData: {
    borderBottom: 1,
    backgroundColor: "#f5f6f8",
    borderColor: "lightgray",
    padding: "10px",
    position: "relative",
    borderTop: "1px solid #C5C5C5",
    margin: "2px",
  },
  notiIcon: {
    verticalAlign: "middle",
    position: "absolute",
    top: "35%",
  },
  heading: {
    fontSize: "0.875rem !important",
    fontWeight: 200,
    color: "#343a40 !important",
    marginLeft: "50px !important",
  },
  description: {
    display: "inline-block",
    fontSize: "12px !important",
    fontWeight: 400,
    marginLeft: "50px !important",
    color: "#98a6ad !important",
  },
  navbox: {
    // position: "sticky",
    top: "0px",
    zIndex: 4100,
  },
  navhead: {
    backgroundColor: "white !important",
    color: "#6c757d !important",
    position: "sticky !important",
    top: 0,
    zIndex: "5800 !important",
  },
  navAvatar: {
    maxBlockSize: 40,
    marginRight: "5px",
    width: "30px !important",
    height: "30px !important",
  },
  dashIcon: {
    position: "relative",
    top: "4px",
  },
  setNavbar: {
    height: "60px !important",
    background: "#005d91 !important",
    zIndex: "5700 !important",
  },
  footerStyles: {
    width: "100%",
    backgroundColor: "#eeeff3",
    padding: "20px 25px",
    color: "#98a6ad",
    fontSize: "14px",
    display: "flex",
    zIndex: 1000,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
export const UserContext = createContext();

export default function PrimarySearchAppBar({ children }) {
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  const [notificationDetail, setNotificationDetils] = useState({
    readNoti: [],
    unReadNoti: [],
  });
  const [inititalNotification, setInititalNotification] = useState({});
  const classes = useStyles();
  const [notification, setNotifiction] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  // const [planDetail, setPlanDetail] = useState({
  //   isActive: null,
  //   isExtended: null,
  // });
  const [progressBarValue, setProgressBarValue] = useState();
  const [isFromCompanyDetailPage, setIsFromCompanyDetailPage] = useState(false);
  const { isPlanExpire: planDetail } = useAppContext();

  useEffect(() => {
    let encryptedCompanyCreditsString = localStorage?.getItem(
      "company_detail_credit"
    );
    let encryptedCompanyCredits = encryptedCompanyCreditsString
      ? JSON.parse(encryptedCompanyCreditsString)
      : null;
    const decryptedCompanyDetailCredit = AES.decrypt(
      encryptedCompanyCredits ?? "",
      secretKey
    ).toString(enc.Utf8);
    setProgressBarValue(decryptedCompanyDetailCredit ?? 0);
  }, [progressBarValue]);

  let CLIENT_FIRSTNAME = Cookies.get("firstName");
  let CLIENT_LASTNAME = Cookies.get("lastName");

  // const CLIENT_FIRSTNAME = localStorage.getItem("firstName");
  // const CLIENT_LASTNAME = localStorage.getItem("lastName");

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const setstyleNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.style.left = "0";
  };

  const setArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks?.classList.toggle("show1");
  };

  const setJsArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show3");
  };

  const setUserArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show4");
  };

  const setFavouriteArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show5");
  };
  const setMarketArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show6");
  };

  const setRequestArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show7");
  };

  const CloseMenuNavbar = (e) => {
    setIsFromCompanyDetailPage(false);
    let navLinks = document.querySelector(".nav-links");
    navLinks.style.left = "-600px";
    if (
      e.target.innerText === "All Trade Data" ||
      e.target.innerText === "Import Trade Data" ||
      e.target.innerText === "Export Trade Data"
    ) {
      navLinks.classList.toggle("show1");
    } else if (e.target.innerText === "Manage Workspace") {
      navLinks.classList.toggle("show3");
    } else if (e.target.innerText === "Manage Users") {
      navLinks.classList.toggle("show4");
    } else if (
      e.target.innerText === "Favourite Shipment" ||
      e.target.innerText === "Favourite Country"
    ) {
      navLinks.classList.toggle("show5");
    } else if (
      e.target.innerText === "Country Search" ||
      e.target.innerText === "Company Search" ||
      e.target.innerText === "Product Search"
    ) {
      navLinks.classList.toggle("show6");
    } else if (
      e.target.innerText === "Requested Buyer Data" ||
      e.target.innerText === "Requested Company Data"
    ) {
      navLinks.classList.toggle("show7");
    }
  };

  // function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let noteInterval = null;

  const sortingBasedOnDate = (arr) => {
    arr.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  const handleNotificationDetails = (arr, readArr, unReadArr) => {
    arr &&
      arr.length &&
      arr.forEach((e) => {
        if (e?.view) {
          readArr.push(e);
        } else {
          unReadArr.push(e);
        }
      });
  };
  const setNotification = () => {
    const readArr = [];
    const unReadArr = [];
    getNotificationDetails()
      .then((notificationDetail) => {
        if (notificationDetail?.status === 401) {
          setError(true);
          setErrorMsg({
            title: notificationDetail?.status === 401 ? "Warning" : "Error",
            content:
              notificationDetail?.status === 500
                ? ErrorData.INTERNAL_SERVER
                : notificationDetail?.status === 401
                ? ErrorData.SESSION_TIMEOUT
                : ErrorData.SOMETHING_WENT_WRONG,
          });
        }
        setInititalNotification(notificationDetail);
        handleNotificationDetails(
          notificationDetail?.accountNotification,
          readArr,
          unReadArr
        );
        handleNotificationDetails(
          notificationDetail?.generalNotification,
          readArr,
          unReadArr
        );
        handleNotificationDetails(
          notificationDetail?.userNotification,
          readArr,
          unReadArr
        );

        if (unReadArr && unReadArr.length) {
          sortingBasedOnDate(unReadArr);
        }

        if (readArr && readArr.length) {
          sortingBasedOnDate(readArr);
        }
        const notificationObj = {
          readNoti: readArr,
          unReadNoti: unReadArr,
        };

        setNotificationDetils(notificationObj);
      })
      .catch((err) => {
        setError(true);
        setErrorMsg({
          title: err.status === 401 ? "Warning" : "Error",
          content:
            err.status === 500
              ? ErrorData.INTERNAL_SERVER
              : err.status === 401
              ? ErrorData.SESSION_TIMEOUT
              : ErrorData.SOMETHING_WENT_WRONG,
        });
      });
  };
  const getNotificationAPIHandler = () => {
    noteInterval = setInterval(function () {
      setNotification();
    }, 20000);
  };

  useEffect(() => {
    setNotification();
    getNotificationAPIHandler();
    return () => {
      clearInterval(noteInterval);
    };
  }, []);

  const updateNotificationAPIHandler = () => {
    updateNotification(inititalNotification)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.message === "Noifications Updated Successfully") {
            setNotification();
          }
        }
      })
      .catch((err) => {});
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    try {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      addScript.addEventListener("error", (error) => {
        console.error("Error loading Google Translate script:", error);
      });
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    } catch (error) {
      console.log("Script error:", error);
    }
  }, []);
  // useEffect(() => {
  //   setPlanDetail({
  //     isActive: children?.props?.isPlanExpire?.isActive,
  //     isExtended: children?.props?.isPlanExpire?.isExtended,
  //   });
  // }, []);
  const showError = () => {
    setError(true);
    setErrorMsg({
      title: planExpiredWarning?.title,
      content: planExpiredWarning?.content,
    });
  };
  const pointDeductionProgressBar = useCallback(() => {
    return (
      isFromCompanyDetailPage && (
        <PointDeductionBar value={progressBarValue} max={200} />
      )
    );
  }, [progressBarValue, isFromCompanyDetailPage]);

  return (
    <>
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}
      <Box sx={{ flexGrow: 1, zIndex: 5 }} id="navbarComponent">
        <Box>
          <Box
            className={classes.navbox}
            style={{ position: children?.props?.isExplore ? "" : "sticky" }}
          >
            <AppBar className={classes.navhead} style={{ height: "60px" }}>
              <Toolbar style={{ minHeight: "0px" }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: "block", sm: "block" } }}
                >
                  <span className="header-logo-lg">
                    {windowDimensions.width > 750 ? (
                      <img src={EximpediaLogo} alt="logo" />
                    ) : (
                      <img src={EximpediaSmallLogo} alt="smlogo" />
                    )}
                  </span>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  style={{
                    marginRight: "45px",
                  }}
                >
                  {pointDeductionProgressBar()}
                </Box>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      sm: "inline-flex",
                      xs: "inline-flex",
                    },
                  }}
                >
                  <div id="google_translate_element" />
                  <IconButton
                    className="notranslate"
                    sx={{
                      mr: -4,
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                    }}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => setNotifiction(!notification)}
                  >
                    {notificationDetail &&
                    !!notificationDetail?.unReadNoti.length ? (
                      <Badge
                        badgeContent={
                          notificationDetail &&
                          !!notificationDetail?.unReadNoti.length &&
                          notificationDetail?.unReadNoti.length
                        }
                        color="error"
                      >
                        <NotificationsIcon />
                      </Badge>
                    ) : (
                      <NotificationsIcon />
                    )}
                  </IconButton>

                  <ul style={{ marginRight: "40px" }}>
                    <li>
                      <Link
                        to=""
                        className="set-position"
                        id="user-avatar"
                        rel="noopener noreferrer"
                      >
                        {windowDimensions.width > 750 ? (
                          <>
                            <Avatar
                              alt="User"
                              src={UserAvatar}
                              className={classes.navAvatar}
                            />
                            <span
                              style={{ color: "black", marginTop: "0px" }}
                              className="userInfo"
                            >
                              {CLIENT_FIRSTNAME} {CLIENT_LASTNAME}
                              <KeyboardArrowDownOutlinedIcon
                                className="bx bxs-chevron-down account-arrow arrow"
                                fontSize="small"
                                style={{ position: "relative", top: "4px" }}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <Avatar
                              alt="User"
                              src={UserAvatar}
                              sx={{ maxBlockSize: 40, width: 30, height: 30 }}
                            />
                          </>
                        )}
                      </Link>

                      <ul className="dropdown" id="user-dropdown">
                        <li>
                          <Link
                            to="/accounts/profile"
                            className="dropdownItem"
                            rel="noopener noreferrer"
                            style={{
                              paddingTop: planDetail?.isActive ? "30px" : "",
                              display: "flex",
                            }}
                          >
                            <PersonOutlineOutlinedIcon
                              fontSize="small"
                              className="set-user-position"
                            />
                            <Typography className="userInfoDetails">
                              Account
                            </Typography>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/subscriptions"
                            className="dropdownItem"
                            rel="noopener noreferrer"
                          >
                            <PaidOutlinedIcon
                              fontSize="small"
                              className="set-user-position"
                            />
                            <Typography className="userInfoDetails">
                              Subscription
                            </Typography>
                          </Link>
                        </li>
                        <li>
                          {planDetail?.isActive === true ||
                          planDetail?.isExtended === true ? (
                            <Link
                              to="/consumers/activity-tracker"
                              rel="noopener noreferrer"
                              className="dropdownItem"
                            >
                              <AccessTimeOutlinedIcon
                                fontSize="small"
                                className="set-user-position"
                              />
                              <Typography className="userInfoDetails">
                                Activity Tracker
                              </Typography>
                            </Link>
                          ) : (
                            <a
                              rel="noopener noreferrer"
                              className="dropdownItem"
                              onClick={showError}
                              style={{ cursor: "pointer" }}
                            >
                              <AccessTimeOutlinedIcon
                                fontSize="small"
                                className="set-user-position"
                              />
                              <Typography className="userInfoDetails">
                                Activity Tracker
                              </Typography>
                            </a>
                          )}
                        </li>
                        <li>
                          <Link
                            to="/ticket-manager"
                            rel="noopener noreferrer"
                            className="dropdownItem"
                          >
                            <ConfirmationNumberOutlinedIcon
                              fontSize="small"
                              className="set-user-position"
                            />
                            <Typography className="userInfoDetails">
                              Ticket Manager
                            </Typography>
                          </Link>
                        </li>
                        <li id="logout">
                          <Link
                            to="/consumers/logout"
                            rel="noopener noreferrer"
                            className="dropdownItem"
                          >
                            <LogoutOutlinedIcon
                              fontSize="small"
                              className="set-user-position"
                            />
                            <Typography className="userInfoDetails">
                              Logout
                            </Typography>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Box>
              </Toolbar>
            </AppBar>
            <AppBar position="sticky" className={classes.setNavbar}>
              <nav>
                <div className="navbar">
                  <MenuIcon className="bx bx-menu" onClick={setstyleNavbar} />
                  <div className="nav-links">
                    <div className="sidebar-logo">
                      <CloseIcon onClick={CloseMenuNavbar} />
                    </div>
                    <ul className="links" id="navbar-hover-item">
                      <div style={{ flex: "1" }}>
                        <li>
                          <Link
                            to="/consumers/dashboard"
                            rel="noopener noreferrer"
                            className="set-position change-hover-color"
                            onClick={CloseMenuNavbar}
                          >
                            <span className="dash-position">
                              <DashboardOutlinedIcon
                                fontSize="small"
                                className={classes.dashIcon}
                              />
                              <span style={{ padding: "0 4px" }}>
                                Dashboard
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span id="nav-icons" onClick={setArrowNavbar}>
                              <LanguageSharpIcon
                                fontSize="small"
                                className="navbar-icons"
                              />
                              <span className="manage-position">
                                Explore Country Data
                              </span>
                              <ExpandMoreIcon className="bx bxs-chevron-down country-nav-arrow arrow" />
                            </span>
                          </a>

                          <ul className="htmlCss-sub-menu sub-menu">
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/countries?tradeType=all"
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  All Trade Data
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  All Trade Data
                                </span>
                              )}
                            </li>
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/countries?tradeType=import"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Import Trade Data
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Import Trade Data
                                </span>
                              )}
                            </li>
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/countries?tradeType=export"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Export Trade Data
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Export Trade Data
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span id="nav-icons" onClick={setJsArrowNavbar}>
                              <LayersOutlinedIcon
                                fontSize="small"
                                className="navbar-icons"
                              />
                              <span className="manage-position">Workspace</span>
                              <ExpandMoreIcon className="bx bxs-chevron-down workspace-nav-arrow arrow " />
                            </span>
                          </a>

                          <ul className="js-sub-menu sub-menu">
                            <li className="px-py">
                              {planDetail?.isActive === true ||
                              planDetail?.isExtended ? (
                                <Link
                                  to="/workspaces"
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Manage Workspace
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div 
                                  font-size-14px"
                                  onClick={showError}
                                >
                                  Manage Workspace
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span id="nav-icons" onClick={setUserArrowNavbar}>
                              <PermIdentityOutlinedIcon
                                fontSize="small"
                                className="navbar-icons"
                              />
                              <span className="manage-position">Users</span>
                              <ExpandMoreIcon
                                className="bx bxs-chevron-down user-arrow arrow"
                                fontSize="small"
                              />
                            </span>
                          </a>

                          <ul className="user-sub-menu sub-menu">
                            <li className="px-py">
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/users"
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Manage Users
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div 
                                  font-size-15px"
                                  onClick={showError}
                                >
                                  Manage Users
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span
                              id="nav-icons"
                              style={{ display: "inline-block" }}
                              onClick={(e) => setFavouriteArrowNavbar(e)}
                            >
                              <StarOutlineOutlinedIcon
                                fontSize="small"
                                className="navbar-icons"
                              />
                              <span className="manage-position">Favourite</span>
                              <ExpandMoreIcon
                                className="bx bxs-chevron-down favourite-arrow arrow"
                                fontSize="small"
                              />
                            </span>
                          </a>

                          <ul className="favourite-sub-menu sub-menu">
                            <li
                              className={
                                planDetail?.isActive === false ||
                                planDetail?.isActive === undefined ||
                                !planDetail?.isActive
                                  ? "remove-padding"
                                  : ""
                              }
                            >
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/favorite/company"
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Favourite Company
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Favourite Company
                                </span>
                              )}
                            </li>
                            <li
                              className={
                                planDetail?.isActive === false ||
                                planDetail?.isActive === undefined ||
                                !planDetail?.isActive
                                  ? "remove-padding"
                                  : ""
                              }
                            >
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/favorite/shipment"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  value="shipment"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Favourite Shipment
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem dropdownItem-div font-size-15px"
                                  value="shipment"
                                  onClick={showError}
                                >
                                  Favourite Shipment
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        {/* <li>
                        <Link to="/consumers/market" rel="noopener noreferrer" className="set-position change-hover-color" onClick={CloseMenuNavbar}>
                          <span className="dash-position">
                            <CalendarViewMonthSharpIcon fontSize="small" className={classes.dashIcon} />
                            <span style={{ padding: "0 4px" }}>Market</span>
                          </span>
                        </Link>
                      </li> */}
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span id="nav-icons" onClick={setMarketArrowNavbar}>
                              <PollOutlinedIcon
                                fontSize="small"
                                className="navbar-icons"
                              />
                              <span className="manage-position">Market</span>
                              <ExpandMoreIcon className="bx bxs-chevron-down market-nav-arrow arrow" />
                            </span>
                          </a>

                          <ul className="market-sub-menu sub-menu">
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to={
                                    planDetail?.isActive === true
                                      ? "/market/country"
                                      : "#"
                                  }
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 "
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Country Search
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Country Search
                                </span>
                              )}
                            </li>
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/market/company"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Company Search
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Company Search
                                </span>
                              )}
                            </li>
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to="/market/product"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Product Search
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem dropdownItem-div font-size-15px "
                                  onClick={showError}
                                >
                                  Product Search
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        <li>
                          {planDetail?.isActive === true ? (
                            <Link
                              to="/globalsearch"
                              rel="noopener noreferrer"
                              className="set-position change-hover-color"
                              onClick={CloseMenuNavbar}
                            >
                              <span className="dash-position">
                                <TravelExploreOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span style={{ padding: "0 4px" }}>
                                  Global Search
                                </span>
                              </span>
                            </Link>
                          ) : (
                            <a
                              rel="noopener noreferrer"
                              className="set-position change-hover-color"
                              onClick={showError}
                            >
                              <span className="dash-position dash-position-span">
                                <TravelExploreOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span
                                  style={{ padding: "0 4px" }}
                                  className="font-size-15px"
                                >
                                  Global Search
                                </span>
                              </span>
                            </a>
                          )}
                        </li>
                        {/* <li>
                          {planDetail?.isActive === true ||
                          planDetail?.isExtended ? (
                            <Link
                              to="/requested/buyers"
                              rel="noopener noreferrer"
                              className="set-position change-hover-color"
                              onClick={CloseMenuNavbar}
                            >
                              <span className="dash-position">
                                <BusinessCenterOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span style={{ padding: "0 4px" }}>
                                  Requested Buyers
                                </span>
                              </span>
                            </Link>
                          ) : (
                            <a
                              rel="noopener noreferrer"
                              className="set-position change-hover-color dash-position-span"
                              onClick={showError}
                            >
                              <span className="dash-position">
                                <BusinessCenterOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span
                                  style={{ padding: "0 4px" }}
                                  className="font-size-15px"
                                >
                                  Requested Buyers
                                </span>
                              </span>
                            </a>
                          )}
                        </li> */}
                        <li>
                          <a
                            href="#"
                            className="set-position change-hover-color"
                            rel="noopener noreferrer"
                          >
                            <span
                              id="nav-icons"
                              onClick={setRequestArrowNavbar}
                            >
                              <BusinessCenterOutlinedIcon fontSize="small" />
                              <span className="manage-position">Request</span>
                              <ExpandMoreIcon className="bx bxs-chevron-down request-nav-arrow arrow" />
                            </span>
                          </a>

                          <ul className="request-sub-menu sub-menu">
                            <li>
                              {planDetail?.isActive === true ? (
                                <Link
                                  to={
                                    planDetail?.isActive === true
                                      ? "/requested/buyers"
                                      : "#"
                                  }
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 "
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Requested Buyer Data
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem pt-15 dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Requested Buyer Data
                                </span>
                              )}
                            </li>
                            <li>
                              {planDetail?.isActive === true ||
                              planDetail?.isExtended === true ? (
                                <Link
                                  to="/requested/companyData"
                                  rel="noopener noreferrer"
                                  className="dropdownItem"
                                  onClick={(e) => CloseMenuNavbar(e)}
                                >
                                  Requested Company Data
                                </Link>
                              ) : (
                                <span
                                  rel="noopener noreferrer"
                                  className="dropdownItem-div font-size-15px"
                                  onClick={showError}
                                >
                                  Requested Company Data
                                </span>
                              )}
                            </li>
                          </ul>
                        </li>
                        <li>
                          {planDetail?.isActive === true ? (
                            <Link
                              to="/consumers/mirrorData"
                              rel="noopener noreferrer"
                              className="set-position change-hover-color"
                              onClick={CloseMenuNavbar}
                            >
                              <span className="dash-position">
                                <PublicIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span style={{ padding: "0 4px" }}>
                                  Mirror Data
                                </span>
                              </span>
                            </Link>
                          ) : (
                            <a
                              rel="noopener noreferrer"
                              className="set-position change-hover-color dash-position-span"
                              onClick={showError}
                            >
                              <span className="dash-position">
                                <PublicIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span
                                  style={{ padding: "0 4px" }}
                                  className="font-size-15px"
                                >
                                  Mirror Data
                                </span>
                              </span>
                            </a>
                          )}
                        </li>
                      </div>
                      <div>
                        <li>
                          {planDetail?.isActive === true ? (
                            <Link
                              to="/help"
                              rel="noopener noreferrer"
                              className="set-position change-hover-color"
                              onClick={CloseMenuNavbar}
                            >
                              <span className="dash-position">
                                <OndemandVideoOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span style={{ padding: "0 4px" }}>Help</span>
                              </span>
                            </Link>
                          ) : (
                            <a
                              rel="noopener noreferrer"
                              className="set-position change-hover-color dash-position-span"
                              onClick={showError}
                            >
                              <span className="dash-position">
                                <OndemandVideoOutlinedIcon
                                  fontSize="small"
                                  className={classes.dashIcon}
                                />
                                <span
                                  style={{ padding: "0 4px" }}
                                  className="font-size-15px"
                                >
                                  Help
                                </span>
                              </span>
                            </a>
                          )}
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </nav>
            </AppBar>
            <Notification
              Open={notification}
              setOpen={setNotifiction}
              notificationDetail={notificationDetail}
              updateNotificationAPIHandler={updateNotificationAPIHandler}
            />
          </Box>
          <Box
            key={children.key}
            style={{ position: "relative" }}
            id={children?.props?.isExplore ? "" : "middle-content"}
            className={
              children?.props?.isExplore
                ? "navbarWithScroll "
                : "navbarWithStickyHeader middle-content-height"
            }
          >
            <UserContext.Provider
              value={{
                setProgressBarValue,
                setIsFromCompanyDetailPage,
                progressBarValue,
              }}
            >
              {children}
            </UserContext.Provider>
          </Box>
        </Box>
        <Box className={classes.footerStyles} id="foot">
          <Typography style={{ fontSize: "14px" }} id="devlop-parent">
            <Typography
              className="devloped"
              style={{ display: "inline-block", fontSize: "14px" }}
            >
              2020 - {getCurrentYear()} © Developed by
            </Typography>
            <a
              href="https://www.eximpedia.app/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "4px" }}
              id="footer-anchor"
            >
              Eximpedia
            </a>
          </Typography>

          <Box className="about-contact">
            <Typography style={{ marginLeft: "10px", fontSize: "14px" }}>
              <a
                href="https://www.eximpedia.app/"
                style={{ color: "#98a6ad" }}
                className="footer-content"
                id="about"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Us
              </a>
            </Typography>
            <Typography id="contact">
              <a
                href="https://www.eximpedia.app/"
                style={{ color: "#98a6ad" }}
                className="footer-content"
                id="contact-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
