import React, { useEffect, useState } from "react";
import Loading from "../../Component/SuccessLoader/success";
import {
  getAccountDetails,
  getUsersDetails,
  updateUserDetails,
  updateAccountDetails,
  getTaxonomyCountryList,
} from "../../Services/service";
import ApprovedSuccess from "../../Component/ApprovedSuccessModal/ApprovedSuccess";
import ModalComponent from "../../Component/Modal/modal";
import "./account.scss";
import AccountDetails from "./Componant/accountDetails";
import EditUserDetails from "./Componant/editUserDetails";
import EditCompanyDetails from "./Componant/editCompanyDetails";
import { Modal } from "@mui/material";
import Error from "../../Component/ErrorLoader/error";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import {
  Company_Update,
  Company_Update_Successfully,
  Retrieving_Profile,
  Successfully_update_user,
  Successfully_update_user_info,
  Update_User,
} from "../../utils/LoadingMsg";
export default function UserAccountProfile(props) {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [taxonomyCountryList, setTaxonomyCountryList] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [userAvailableCountry, seUserAvailableCountry] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editUserDetails, setEditUserDetails] = useState({});
  const [editCompanyDetails, setEditCompanyDetails] = useState({});
  const [isUserEditModal, setIsUserEditModal] = useState(false);
  const [isCompanyEditModal, setIsCompanyEditModal] = useState(false);
  const [isEditUserNameEmpty, setIsEditUserNameEmpty] = useState({
    first_name: false,
    last_name: false,
  });
  const [isEditCompanyNameEmpty, setIsEditCompanyNameEmpty] = useState({
    name: false,
    address: false,
    pin_code: false,
    country: false,
  });
  const [approveSuccessModal, setApproveSuccessModal] = useState(false);
  const [approveSuccessModalData, setApproveSuccessModalData] = useState({});

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(Retrieving_Profile);

    getTaxonomyCountryListDetails();
    fetchAccountDetails(props.CLIENT_ACCOUNT_ID);
    fetchUserDetails(props.CLIENT_USER_ID);
  }, []);

  useEffect(() => {
    const _userAvailableCountry = [...userAvailableCountry];
    if (
      taxonomyCountryList &&
      taxonomyCountryList.length &&
      accountDetails &&
      accountDetails.length
    ) {
      accountDetails[0]?.plan_constraints?.countries_available &&
        accountDetails[0].plan_constraints?.countries_available?.length &&
        accountDetails[0].plan_constraints?.countries_available?.forEach(
          (e) => {
            taxonomyCountryList.forEach((taxonomy) => {
              if (taxonomy?.code_iso_3 === e) {
                if (
                  !_userAvailableCountry.includes(
                    taxonomy?.country?.toUpperCase()?.split("_")?.join(" ")
                  )
                ) {
                  _userAvailableCountry.push(
                    taxonomy?.country?.toUpperCase()?.split("_")?.join(" ")
                  );
                }
              }
            });
          }
        );
      seUserAvailableCountry(_userAvailableCountry);
    }
  }, [taxonomyCountryList, accountDetails]);

  const setErrorForDashboard = (err) => {
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };
  useEffect(() => {
    const _userAvailableCountry = [...userAvailableCountry];
    if (
      taxonomyCountryList &&
      taxonomyCountryList.length &&
      accountDetails &&
      accountDetails.length
    ) {
      accountDetails[0]?.plan_constraints?.countries_available &&
        accountDetails[0].plan_constraints?.countries_available?.length &&
        accountDetails[0].plan_constraints?.countries_available?.forEach(
          (e) => {
            taxonomyCountryList.forEach((taxonomy) => {
              if (taxonomy?.code_iso_3 === e) {
                if (
                  !_userAvailableCountry.includes(
                    taxonomy?.country?.toUpperCase()?.split("_")?.join(" ")
                  )
                ) {
                  _userAvailableCountry.push(
                    taxonomy?.country?.toUpperCase()?.split("_")?.join(" ")
                  );
                }
              }
            });
          }
        );
      seUserAvailableCountry(_userAvailableCountry);
    }
  }, [taxonomyCountryList, accountDetails]);

  const getTaxonomyCountryListDetails = async () => {
    getTaxonomyCountryList()
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          setLoading(false);
          setTaxonomyCountryList(e?.data ?? []);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  const fetchAccountDetails = (accountId) => {
    getAccountDetails(accountId)
      .then((successPayload) => {
        if (successPayload?.status === 500 || successPayload?.status === 401) {
          throw throwError(successPayload);
        } else {
          // setLoading(false);
          let _accountDetails = [];
          if (successPayload?.data) {
            _accountDetails.push(successPayload?.data);
            setAccountDetails(_accountDetails);
            setEditCompanyDetails(successPayload?.data?.company);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const fetchUserDetails = (userId) => {
    getUsersDetails(userId)
      .then((user) => {
        if (user?.status === 500 || user?.status === 401) {
          throw throwError(user);
        } else {
          if (user?.data) {
            let _userDetails = [];
            _userDetails.push(user?.data);
            setUserDetails(_userDetails);
            setEditUserDetails({
              first_name: user?.data?.first_name,
              last_name: user?.data?.last_name,
              mobile_no: user?.data?.mobile_no,
            });
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  const handleChangeEditUserDetails = (e) => {
    setEditUserDetails({
      ...editUserDetails,
      [e.target.name]: e.target.value,
    });
    if (isEditUserNameEmpty) {
      if (e.target.value.length > 0) {
        setIsEditUserNameEmpty({
          ...isEditUserNameEmpty,
          [e.target.name]: false,
        });
      }
    }
  };
  const handleEditUserDetails = () => {
    setIsUserEditModal(true);
  };

  const handleEditCompanyDetails = () => {
    setIsCompanyEditModal(true);
  };

  const validationForEditUserDetails = () => {
    if (
      editUserDetails.first_name === "" ||
      editUserDetails.first_name === undefined
    ) {
      setIsEditUserNameEmpty({
        ...isEditUserNameEmpty,
        first_name: true,
      });
      return false;
    }

    if (
      editUserDetails.last_name === "" ||
      editUserDetails.last_name === undefined
    ) {
      setIsEditUserNameEmpty({
        ...isEditUserNameEmpty,
        last_name: true,
      });
      return false;
    }

    return true;
  };

  const savedEditUserDetails = () => {
    if (validationForEditUserDetails() === false) {
      return;
    }
    setIsUserEditModal(false);
    setLoading(true);
    setLoadingMsg(Update_User);

    updateUserDetails(props.CLIENT_USER_ID, editUserDetails)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          fetchUserDetails(props.CLIENT_USER_ID);
          setLoading(false);
          setApproveSuccessModal(true);
          setApproveSuccessModalData(Successfully_update_user);
          setTimeout(() => {
            setApproveSuccessModal(false);
            setError(true);
            setErrorMsg(Successfully_update_user_info);
          }, 2000);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleChangeEditCompanyDetails = (e) => {
    setEditCompanyDetails({
      ...editCompanyDetails,
      [e.target.name]: e.target.value,
    });
    if (isEditCompanyNameEmpty) {
      if (e.target.value.length > 0) {
        setIsEditCompanyNameEmpty({
          ...isEditCompanyNameEmpty,
          [e.target.name]: false,
        });
      }
    }
  };

  const validationForEditCompanyDetails = () => {
    if (
      editCompanyDetails.name === "" ||
      editCompanyDetails.name === undefined
    ) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        name: true,
      });
      return false;
    }

    if (
      editCompanyDetails.address === "" ||
      editCompanyDetails.address === undefined
    ) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        address: true,
      });
      return false;
    }

    if (
      editCompanyDetails.pin_code === "" ||
      editCompanyDetails.pin_code === undefined
    ) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        pin_code: true,
      });
      return false;
    }
    return true;
  };
  const savedEditCompanyDetails = () => {
    if (validationForEditCompanyDetails() === false) {
      return;
    }
    setIsCompanyEditModal(false);
    setLoading(true);
    setLoadingMsg(Company_Update);

    const companyDetails = {
      company: editCompanyDetails,
    };

    updateAccountDetails(props.CLIENT_ACCOUNT_ID, companyDetails)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          fetchAccountDetails(props.CLIENT_ACCOUNT_ID);
          setLoading(false);
          setApproveSuccessModal(true);
          setApproveSuccessModalData(Company_Update_Successfully);
          setTimeout(() => {
            setApproveSuccessModal(false);
          }, 2000);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  return (
    <div>
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      <AccountDetails
        userDetails={userDetails}
        accountDetails={accountDetails}
        userAvailableCountry={userAvailableCountry}
        handleEditUserDetails={handleEditUserDetails}
        handleEditCompanyDetails={handleEditCompanyDetails}
      />
      <ModalComponent
        open={isUserEditModal}
        setOpen={setIsUserEditModal}
        header="Edit User Details"
        modalBody={
          <EditUserDetails
            editUserDetails={editUserDetails}
            handleChangeEditUserDetails={handleChangeEditUserDetails}
            isEditUserNameEmpty={isEditUserNameEmpty}
          />
        }
        saveText="Submit"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={savedEditUserDetails}
      />
      <ModalComponent
        open={isCompanyEditModal}
        setOpen={setIsCompanyEditModal}
        header="Edit Company Details"
        modalBody={
          <EditCompanyDetails
            editCompanyDetails={editCompanyDetails}
            handleChangeEditCompanyDetails={handleChangeEditCompanyDetails}
            isEditCompanyNameEmpty={isEditCompanyNameEmpty}
          />
        }
        width="870px"
        saveText="Submit"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={savedEditCompanyDetails}
      />
      <ApprovedSuccess
        Open={approveSuccessModal}
        title={approveSuccessModalData.title}
        footerTitle={approveSuccessModalData.footerTitle}
      />
    </div>
  );
}
