// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyzeField-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.analyzeField-container .search-async {
  z-index: 100;
}
.analyzeField-container .analyzeBtn {
  padding: 20px;
  margin-right: -15px;
  display: flex;
  margin-left: auto;
}
@media (max-width: 1344px) {
  .analyzeField-container .analyzeBtn {
    align-items: center;
    justify-content: center;
  }
}
.analyzeField-container .analyzeBtn .saveBtn {
  display: flex !important;
  margin-left: auto !important;
}
.analyzeField-container .analyzeBtn .btn-back {
  color: #fff !important;
  background-color: #0a6da6 !important;
  border-color: #005d91 !important;
  padding: 7px !important;
  margin-left: 10px;
}
.analyzeField-container .analyzeBtn .btn-back:hover {
  color: #fff;
  background-color: #07527d !important;
  border-color: #07527d !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/AnalyzeFilter/Component/analyzeField.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;AAEJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;AACJ;AACI;EANF;IAOI,mBAAA;IACA,uBAAA;EAEJ;AACF;AAAI;EACE,wBAAA;EACA,4BAAA;AAEN;AAAI;EACE,sBAAA;EACA,oCAAA;EACA,gCAAA;EACA,uBAAA;EACA,iBAAA;AAEN;AAAM;EACE,WAAA;EACA,oCAAA;EACA,gCAAA;AAER","sourcesContent":[".analyzeField-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .search-async {\n    z-index: 100;\n  }\n\n  .analyzeBtn {\n    padding: 20px;\n    margin-right: -15px;\n    display: flex;\n    margin-left: auto;\n\n    @media (max-width: 1344px) {\n      align-items: center;\n      justify-content: center;\n    }\n\n    .saveBtn {\n      display: flex !important;\n      margin-left: auto !important;\n    }\n    .btn-back {\n      color: #fff !important;\n      background-color: #0a6da6 !important;\n      border-color: #005d91 !important;\n      padding: 7px !important;\n      margin-left: 10px;\n\n      &:hover {\n        color: #fff;\n        background-color: #07527d !important;\n        border-color: #07527d !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
