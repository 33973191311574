// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-style-class {
  height: calc(100vh - 257px);
}
@media (max-width: 1720px) and (min-width: 1600px) {
  .report-style-class {
    zoom: 1.064 !important;
  }
}
@media (max-width: 1599px) {
  .report-style-class {
    zoom: 1.4 !important;
    height: calc(100vh - 4px);
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/PowerBiDashBoard/PowerBiDashBoard.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AAAE;EAFF;IAGI,sBAAA;EAGF;AACF;AAFE;EALF;IAMI,oBAAA;IACA,yBAAA;EAKF;AACF","sourcesContent":[".report-style-class {\n  height: calc(100vh - 257px);\n  @media (max-width: 1720px) and (min-width: 1600px) {\n    zoom: 1.064 !important;\n  }\n  @media (max-width: 1599px) {\n    zoom: 1.4 !important;\n    height: calc(100vh - 4px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
