// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-detail-page-container {
  padding: 0px 2% 2% 2%;
}`, "",{"version":3,"sources":["webpack://./src/Containers/CompanyDetails/CompanyDetail/CompanyDetail.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".company-detail-page-container {\n  padding: 0px 2% 2% 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
