import React, { memo } from "react";
import "./ChartBox.scss";
import { Grid } from "@mui/material";
const ChartBox = memo(({ chart, chartData, index, classMainDiv }) => {
  return (
    <Grid item xs={chartData?.length - 1 === index ? 12 : 6} lg={chartData?.length - 1 === index ? 12 : 6} className="SummaryDetails-card-chartBox" key={index}>
      <div
        className="SummaryDetails-card-header-chartBox"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 20px",
        }}
      >
        <p>{chart?.title}</p>
      </div>

      {chartData?.length - 1 === index ?
        <div id="imp-buyerVsSupplier" className="set-drilldown-style SummaryDetails-card-body-chartBox" ref={chart?.reference}></div>
        : <div className="SummaryDetails-card-body-chartBox">
          <div id={chart?.id} className={chart?.className} ref={chart?.reference}></div>
        </div>}

    </Grid>
  );
});

export default ChartBox;
