import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import millify from "millify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const TradeChart = ({ data }) => {
  const chartComponentRef = useRef(null);
  const colors = ["#5DADE2", "#F7DC6F", "#58D68D", "#EB984E", "#AF7AC5"];

  const series = data?.res?.map((tradeEntry, index) => ({
    name: `${tradeEntry?.country} - ${tradeEntry?.tradeType}`,
    data: tradeEntry?.trades
      ?.map((trade) => ({
        x: new Date(trade?.Month)?.getTime(),
        y: trade?.TOTAL_VALUE,
        info: {
          tradeType: tradeEntry?.tradeType,
          country: tradeEntry?.country,
          fieldValues: tradeEntry?.matchExpressions
            .map((expr) => expr?.fieldValue)
            .flat(),
          fieldTerm: tradeEntry?.matchExpressions[0]?.fieldTerm
            ?.split("_")
            ?.join(" "),
        },
      }))
      ?.sort((a, b) => b.x - a.x),
    color: colors[index % colors?.length],
    visible: true,
  }));

  const getMonthNameWithYear = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  const exportDataAsPDF = () => {
    const doc = new jsPDF();

    // Create an object to hold data grouped by company
    const companyDataMap = {};

    // Group the data by company
    series.forEach((series) => {
      const companyName = series.name;
      series.data.forEach((point) => {
        const month = getMonthNameWithYear(point.x);
        const totalValue = millify(point.y, { precision: 2 });

        if (!companyDataMap[companyName]) {
          companyDataMap[companyName] = [];
        }

        companyDataMap[companyName].push({
          Month: month,
          TotalValue: totalValue,
        });
      });
    });

    // Convert the companyDataMap into an array suitable for the PDF table
    const dataForPDF = [];
    Object.keys(companyDataMap).forEach((companyName) => {
      const monthsData = companyDataMap[companyName];
      monthsData.forEach((data) => {
        dataForPDF.push({
          Company: companyName,
          ...data,
        });
      });
    });

    // Define table columns
    const columns = [
      { header: "Company", dataKey: "Company" },
      { header: "Month", dataKey: "Month" },
      { header: "Total Value (In Millions)", dataKey: "TotalValue" },
    ];

    // Add table to PDF
    doc.autoTable({
      head: [columns.map((col) => col.header)],
      body: dataForPDF.map((row) => columns.map((col) => row[col.dataKey])),
      startY: 20,
      columnStyles: {
        Company: { cellWidth: "auto" },
        Month: { cellWidth: "auto" },
        TotalValue: { cellWidth: "auto" },
      },
    });

    // Save the PDF
    doc.save("trade-analysis-data.pdf");
  };

  const exportChartAsJPEG = () => {
    const chart = chartComponentRef.current.chart;
    chart.exportChart({
      type: "image/jpeg",
      filename: "trade-analysis-chart",
    });
  };

  const options = {
    chart: {
      type: "column",
      events: {
        load: function () {
          const chart = this;
          chart.update({
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Download PDF",
                      onclick: exportDataAsPDF,
                    },
                    {
                      text: "Download JPEG",
                      onclick: exportChartAsJPEG,
                    },
                  ],
                },
              },
            },
          });
        },
      },
    },
    title: {
      text: "Search Analysis",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%Y-%m}",
      },
      title: {
        text: "Month",
      },
      reversed: true,
      minPadding: 0.05,
      maxPadding: 0.05,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Value (In Millions)",
      },
      labels: {
        formatter: function () {
          return millify(this.value, { precision: 2 });
        },
      },
    },
    tooltip: {
      formatter: function () {
        const info = this.point.info;
        const fieldValues = Array.from(new Set(info?.fieldValues))?.join(", ");
        return `
          <b>${Highcharts.dateFormat("%Y-%m", this.x)}</b><br/>
          Total Value: $${millify(this.y, { precision: 2 })}<br/>
          Trade Type: ${info?.tradeType}<br/>
          Country: ${info?.country}<br/>
          ${info?.fieldTerm}: ${fieldValues}
        `;
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function () {
            const selectedSeries = this;
            const chart = this.chart;
            const allSeriesVisible = chart.series.every(
              (series) => series.visible
            );
            const isSelectedSeriesVisible = selectedSeries.visible;

            if (isSelectedSeriesVisible && !allSeriesVisible) {
              chart.series.forEach((series) => {
                series.setVisible(true, false);
              });
              chart.redraw();
            } else {
              chart.series.forEach((series) => {
                series.setVisible(false, false);
              });
              selectedSeries.setVisible(true, false);
              chart.redraw();
            }

            return false;
          },
        },
      },
    },
    animation: {
      duration: 0,
    },
    series: series,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: "Download PDF",
              onclick: exportDataAsPDF,
            },
            {
              text: "Download JPEG",
              onclick: exportChartAsJPEG,
            },
          ],
        },
      },
    },
  };

  return (
    <HighchartsReact
      ref={chartComponentRef}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default TradeChart;
