import React, { memo } from "react";
import "./shipment.scss";
import { Box, Grid } from "@mui/material";
import CollapseFilter from "../../../../Component/CollapseFilterNewUI/CollapseFilter";
import ExploreFilter from "../../../../Component/CollapseFilterNewUI/Component/ExploreFilter/ExploreFilter";

import ModalComponent from "../../../../Component/Modal/modal";
import ExploreDataTableModal from "../../../../Component/ExploreDataTableModal/exploreModal";
import { useState } from "react";
import { useRef } from "react";
import {
  getFilterWidth,
  getRecordWidth,
  sanitizeHsCodeDigitBoundaries,
} from "../../../../utils/CommanFunction";
import {
  getConsigneeShipmentDetails,
  getIECSearchData,
} from "../../../../Services/service";
import { FetchData } from "../../../../utils/LoadingMsg";
import ErrorData from "../../../../utils/ErrorHandling";
import { useReactToPrint } from "react-to-print";
import Filter from "../../../../Asset/images/filter-white.png";
import CombineTable from "../../../../Component/Table/CombineTable";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ViewColumnModal from "../../../../Component/ViewColumnsModalNewUI/ViewColumns";
import { useEffect } from "react";
const ShipmentNewUI = memo(
  ({
    dataEndDate,
    currentTradeType,
    currentCountry,
    CLIENT_ACCOUNT_ID,
    getSearchValueData,
    dataTableHeader,
    filteredTaxonomy,
    dateRange,
    searchField,
    isFiltersShown,
    rowsPerPage,
    headerTitle,
    filterOptionClick,
    setIsFiltersShown,
    handleApplyFilter,
    keyValuePairForTreeview,
    allFilterValuesWithCountsArr,
    getAllFilterValues,
    getFilterValues,
    setFilterValue,
    getMaxMinRange,
    setMaxMinRange,
    rangeValue,
    setRangeValue,
    selectedQuantityOrPriceValue,
    setSelectedQuantityOrPrice,
    lastSelected,
    setLastSelected,
    storeFilteredValues,
    setStoreFilterValues,
    CheckedData,
    appliedFilterData,
    setPairForTreeView,
    isFilterLoader,
    RemoveAppliedFilter,
    apply,
    handleToggleFilters,
    dataTableRowValues,
    page,
    descendingAndAscending,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDescendingAndAscendingOrder,
    handleOpenFilterModal,
    setHandleOpenFilterModal,
    setDataTableHeader,
    accountTableHeader,
    allHeader,
    addViewColumn,
    viewHeader,
    setViewHeader,
    summaryData,
    isVisibleData,
    setError,
    setErrorMsg,
    setLoadingMsg,
    setLoading,
    mirrorData,
    isFromWorkspace,
  }) => {
    //view icon

    const [isCollapseModelDetails, setIsCollapseModelDetails] = useState({
      companyDetails: true,
      productDetails: true,
      otherDetails: true,
      IECDetails: true,
      consigneeDetails: true,
    });
    const [storeEyeModalRowData, setStoreEyeModalRowData] = useState({});
    const [IECDetails, setIECDetails] = useState([]);
    const [addConsigneeDetailsPayload, setAddConsigneeDetailsPayload] =
      useState({});
    const [openCollpaseModal, setOpenCollapseModal] = useState(false);
    const [modalheaderArr, setModalArr] = useState([]);
    const [consigneeDetails, setConsigneeDetails] = useState({});
    const [prevOrNext, setPrevOrNext] = useState(0);
    const [collapseTable, setCollapseTable] = useState(true);
    const PrintRef = useRef();

    useEffect(() => {
      const width = window.innerWidth;
      if (width < 1070) {
        setIsFiltersShown(false);
      }
    }, []);
    const getIECSearchDetails = async (iecNumber) => {
      let iec = iecNumber;
      if (typeof iecNumber == "number" && iecNumber.toString().length < 10) {
        iec = sanitizeHsCodeDigitBoundaries(iecNumber, 10);
      }
      const Arr = await getIECSearchData(iec);
      return Arr.data;
    };

    const handleCollapseOpenModal = async (column, row, index) => {
      setLoading(true);
      setLoadingMsg(FetchData);
      setIsCollapseModelDetails({
        companyDetails: true,
        productDetails: true,
        otherDetails: true,
        IECDetails: true,
        consigneeDetails: true,
      });
      setStoreEyeModalRowData(row);
      if (currentCountry === "INDIA") {
        const IECArr = await getIECSearchDetails(row?.IEC);
        setIECDetails(IECArr);
      }

      if (currentCountry === "INDIA" && currentTradeType === "EXPORT") {
        const shipmentDetailsPayload = {
          userId: row?._id,
          shipment_number: row?.BILL_NO,
        };
        setAddConsigneeDetailsPayload({
          account_id: CLIENT_ACCOUNT_ID,
          country_date: row?.EXP_DATE,
          country_port: row?.INDIAN_PORT,
          shipmentBillNumber: row?.BILL_NO,
          recordRow: row,
        });

        ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);
      }
      const companyIndex =
        column &&
        column?.length &&
        column?.findIndex((e) => e?.key === "isFavourite");
      const shipmentIndex =
        column &&
        column?.length &&
        column?.findIndex((e) => e?.key === "isFavouriteShipment");
      if (column && companyIndex !== -1) {
        column.splice(companyIndex, 1);
      }
      if (column && shipmentIndex !== -1) {
        column.splice(shipmentIndex, 1);
      }

      if (column) {
        column?.push(
          {
            title: "isFavourite",
            key: "isFavourite",
          },
          {
            title: "isFavouriteShipment",
            key: "isFavouriteShipment",
          }
        );
      }
      let arr = [];
      column &&
        column?.length &&
        column?.map((col) => {
          let obj = {
            [col?.title]: row[col?.key],
          };
          arr.push(obj);
        });
      if (arr && arr?.length) {
        setModalArr(arr);
      }
      setLoading(false);
      setOpenCollapseModal(true);
      setPrevOrNext(index);
    };

    const ConsigneeShipmentDetailsAPIHandler = (shipmentDetailsPayload) => {
      getConsigneeShipmentDetails(shipmentDetailsPayload)
        .then((e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw e?.status;
          } else {
            if (e) {
              if (e.message === "Request Cosignee Data") {
                setConsigneeDetails({
                  message: "Request Buyer",
                  shipmentAllotedLimits: e?.shipmentAllotedLimits,
                  shipmentConsumedLimits: e?.shipmentConsumedLimits,
                  disabled: false,
                });
              } else if (
                e.message === "Shipment Data Request is in process ."
              ) {
                setConsigneeDetails({
                  message: "Request Processing",
                  shipmentAllotedLimits: e?.shipmentAllotedLimits,
                  shipmentConsumedLimits: e?.shipmentConsumedLimits,
                  disabled: true,
                });
              } else {
                setConsigneeDetails(e);
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          // addErrorLog(err?.msg ? err?.msg : err, 2617);
          catchBlock(err);
        });
    };
    const catchBlock = (err) => {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: err?.status === 401 ? "Warning" : "Error",
        content:
          err?.status === 500
            ? ErrorData?.INTERNAL_SERVER
            : err?.status === 401
            ? ErrorData?.SESSION_TIMEOUT
            : ErrorData?.SOMETHING_WENT_WRONG,
      });
    };
    const previousDataTableRecords = async (direction) => {
      nextDataTableRecords(direction);
    };

    const handlePrintTableRecords = useReactToPrint({
      content: () => PrintRef?.current,
    });
    const nextDataTableRecords = async (direction) => {
      let _prevOrNext = prevOrNext;
      if (direction === "next") {
        _prevOrNext = prevOrNext + 1;
      } else {
        _prevOrNext = prevOrNext - 1;
      }
      setLoading(true);
      setLoadingMsg(FetchData);
      setPrevOrNext(_prevOrNext);
      if (
        getSearchValueData &&
        getSearchValueData?.data &&
        getSearchValueData?.data?.length
      ) {
        const row = getSearchValueData.data[_prevOrNext];
        setStoreEyeModalRowData(row);
        let arr = [];
        dataTableHeader?.length > 0 &&
          dataTableHeader.forEach((col) => {
            let obj = {
              [col?.title]: row[col?.key],
            };
            arr.push(obj);
          });

        if (currentCountry === "INDIA") {
          const IECArr = await getIECSearchDetails(row?.IEC);
          setIECDetails(IECArr);
        }

        if (currentCountry === "INDIA" && currentTradeType === "EXPORT") {
          const shipmentDetailsPayload = {
            userId: row?._id,
            shipment_number: row?.BILL_NO,
          };
          setAddConsigneeDetailsPayload({
            account_id: CLIENT_ACCOUNT_ID,
            country_date: row?.EXP_DATE,
            country_port: row?.INDIAN_PORT,
            shipmentBillNumber: row?.BILL_NO,
            recordRow: row,
          });

          ConsigneeShipmentDetailsAPIHandler(shipmentDetailsPayload);
        }
        setModalArr(arr);
      }
      setLoading(false);
    };
    const handleCollapseDetails = (e) => {
      const name = e?.target?.id;

      if (name) {
        setIsCollapseModelDetails({
          ...isCollapseModelDetails,
          [name]: !isCollapseModelDetails[name],
        });
      }
    };
    const handleSummaryDataForTrade = () => {
      const rowData =
        getSearchValueData &&
        getSearchValueData?.data &&
        getSearchValueData?.data?.filter((row, index) => {
          return row?._id === storeEyeModalRowData?._id;
        })[0];
      let currentSelector = "";
      let currentSearchField = "";
      if (currentTradeType === "IMPORT") {
        currentSelector = rowData?.IMPORTER_NAME;
        currentSearchField = "IMPORTER_NAME";
      } else if (currentTradeType === "EXPORT") {
        currentSelector = rowData?.EXPORTER_NAME;
        currentSearchField = "EXPORTER_NAME";
      }
      // navigate(`/trade/summary?${currentSearchField}=${currentSelector.split(" ").join("_")}&tradeType=${currentTradeType}&country=${currentCountry}`);

      if (
        currentSearchField &&
        currentSelector &&
        currentTradeType &&
        currentCountry &&
        dateRange?.startMonthDate &&
        dateRange?.endMonthDate &&
        filteredTaxonomy?._id
      )
        if (currentCountry === "INDIA") {
          window.open(
            `/trade/summary/powerBi?${currentSearchField}=${currentSelector
              .split(" ")
              .join(
                "_"
              )}&tradeType=${currentTradeType}&country=${currentCountry}&startDate=${
              dateRange?.startMonthDate
            }&endDate=${dateRange?.endMonthDate}&taxonomy_id=${
              filteredTaxonomy?._id
            }&dateEndDate=${dataEndDate}`,
            "_blank"
          );
        } else {
          window.open(
            `/trade/summary?${currentSearchField}=${currentSelector
              .split(" ")
              .join(
                "_"
              )}&tradeType=${currentTradeType}&country=${currentCountry}&startDate=${
              dateRange?.startMonthDate
            }&endDate=${dateRange?.endMonthDate}&taxonomy_id=${
              filteredTaxonomy?._id
            }&dataEndDate=${dataEndDate}`,
            "_blank"
          );
        }
    };
    return (
      <>
        <div className="workSpace-filter">
          <Grid container spacing={2}>
            <Grid
              className="workSpaceFilter-div"
              item
              xs={isFiltersShown ? getFilterWidth() : 0}
              display={isFiltersShown ? "" : "none"}
            >
              <Box width="100%" id="workSpaceFilter">
                <CollapseFilter
                  isFiltersShown={isFiltersShown}
                  handleApplyFilter={handleApplyFilter}
                  setIsFiltersShown={setIsFiltersShown}
                  filterOptionClick={filterOptionClick}
                  headerTitle={headerTitle}
                  keyValuePairForTreeview={keyValuePairForTreeview}
                  allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
                  getAllFilterValues={getAllFilterValues}
                  getFilterValues={getFilterValues}
                  isFromExplore={true}
                  setFilterValue={setFilterValue}
                  currentCountry={currentCountry}
                  getMaxMinRange={getMaxMinRange}
                  setMaxMinRange={setMaxMinRange}
                  rangeValue={rangeValue}
                  setRangeValue={setRangeValue}
                  selectedQuantityOrPriceValue={selectedQuantityOrPriceValue}
                  setSelectedQuantityOrPrice={setSelectedQuantityOrPrice}
                  lastSelected={lastSelected}
                  setLastSelected={setLastSelected}
                  storeFilteredValues={storeFilteredValues}
                  setStoreFilterValues={setStoreFilterValues}
                  CheckedData={CheckedData}
                  appliedFilterData={appliedFilterData}
                  setPairForTreeView={setPairForTreeView}
                  isFilterLoader={isFilterLoader}
                  mirrorData={mirrorData}
                  isFromWorkspace={isFromWorkspace}
                  currentTradeType={currentTradeType}
                  appliedFilterComp={
                    <ExploreFilter
                      CheckedData={CheckedData}
                      isFromExplore={true}
                      RemoveAppliedFilter={RemoveAppliedFilter}
                      apply={apply}
                      quantityData={[]}
                      handleToggleFilters={handleToggleFilters}
                    />
                  }
                  RemoveAppliedFilter={RemoveAppliedFilter}
                  apply={apply}
                />
              </Box>
            </Grid>
            {!isFiltersShown && (
              <Box className="filter-label-off-workspace">
                <Box
                  id="set-filters-showing-arrow-workspace"
                  style={{
                    zIndex: isVisibleData && !isFiltersShown ? 10000 : "",
                  }}
                  onClick={() => setIsFiltersShown(!isFiltersShown)}
                >
                  <img
                    src={Filter}
                    alt="filter"
                    width="20px"
                    height="20px"
                  ></img>

                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Grid item xs={isFiltersShown ? getRecordWidth() : 12}>
              <CombineTable
                columns={dataTableHeader}
                handleCollapseOpenModal={handleCollapseOpenModal}
                isAtBottom={true}
                rows={dataTableRowValues}
                rowsPerPage={rowsPerPage}
                showPagination={true}
                totalAvailableData={summaryData?.SUMMARY_RECORDS}
                page={page}
                descendingAndAscending={descendingAndAscending}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDescendingAndAscendingOrder={
                  handleDescendingAndAscendingOrder
                }
                isWorkSpace={true}
              />
            </Grid>
            {handleOpenFilterModal && (
              <ViewColumnModal
                open={handleOpenFilterModal}
                setOpen={setHandleOpenFilterModal}
                setDataTableHeader={setDataTableHeader}
                dataTableHeader={dataTableHeader}
                taxonomyId={filteredTaxonomy._id}
                accountTableHeader={accountTableHeader}
                allHeader={allHeader}
                addViewColumn={addViewColumn}
                viewHeader={viewHeader}
                setViewHeader={setViewHeader}
                isWorkSpace={true}
              />
            )}
            <ModalComponent
              IsNextPrevBtnShown={true}
              open={openCollpaseModal}
              setOpen={setOpenCollapseModal}
              header="DataTable"
              prevOrNext={prevOrNext}
              nextDataTableRecords={nextDataTableRecords}
              previousDataTableRecords={previousDataTableRecords}
              rowsPerPage={rowsPerPage}
              handlePrintTableRecords={handlePrintTableRecords}
              handleSummaryDataForTrade={handleSummaryDataForTrade}
              modalBody={
                <ExploreDataTableModal
                  currentTradeType={currentTradeType}
                  currentCountry={currentCountry}
                  PrintRef={PrintRef}
                  handleCollapseDetails={(e) => handleCollapseDetails(e)}
                  collapseTable={collapseTable}
                  setCollapseTable={setCollapseTable}
                  modalheaderArr={modalheaderArr}
                  isCollapseModelDetails={isCollapseModelDetails}
                  setIsCollapseModelDetails={setIsCollapseModelDetails}
                  IECDetails={IECDetails}
                  consigneeDetails={consigneeDetails}
                  Favourite={false}
                  searchOption={searchField?.searchOption}
                />
              }
              width="1000px"
              saveText="Save"
              saveButtonShown={false}
              saveButtonColor="#005d91"
            />
          </Grid>
        </div>
      </>
    );
  }
);

export default ShipmentNewUI;
