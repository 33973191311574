// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highcharts-menu li {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  cursor: pointer;
}

.highcharts-menu li:hover {
  background-color: #f0f0f0;
  color: #003366;
}

.highcharts-contextbutton .highcharts-button-symbol {
  fill: #333;
}

.highcharts-container {
  overflow: hidden;
}

.highcharts-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
}

.highcharts-legend-item {
  cursor: pointer;
}

.highcharts-legend-item-hidden {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/Containers/DashboardNewUI/Component/AreaChart/MyAreaChart.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAAF;;AAGA;EACE,yBAAA;EACA,cAAA;AAAF;;AAIA;EACE,UAAA;AADF;;AAKA;EACE,gBAAA;AAFF;;AAMA;EACE,oCAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;AAHF;;AAOA;EACE,eAAA;AAJF;;AAQA;EACE,YAAA;AALF","sourcesContent":["// Ensure menu items are displayed vertically\n.highcharts-menu li {\n  display: block;\n  width: 100%;\n  text-align: left;\n  padding: 8px 15px;\n  cursor: pointer;\n}\n// Hover state for menu items\n.highcharts-menu li:hover {\n  background-color: #f0f0f0;\n  color: #003366;\n}\n\n// Customize the context menu button (optional)\n.highcharts-contextbutton .highcharts-button-symbol {\n  fill: #333;\n}\n\n// Optional: Style the entire chart container to prevent overflow issues\n.highcharts-container {\n  overflow: hidden;\n}\n\n// Optional: Style for tooltip for a better appearance\n.highcharts-tooltip {\n  background-color: rgba(0, 0, 0, 0.8);\n  color: #fff;\n  border-radius: 4px;\n  padding: 10px;\n  font-size: 12px;\n}\n\n// Optional: Style for legend items when clicked\n.highcharts-legend-item {\n  cursor: pointer;\n}\n\n// Optional: Style for legend items when active (visible)\n.highcharts-legend-item-hidden {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
