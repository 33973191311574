import React, { memo, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import "./searchComponent.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SelectSearch from "react-select";
import { ButtonComponent } from "../ButtonComponent/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { IsMultiSelectedField } from "../../utils/commanConditions";
import { QUERYTYPE } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { useEffect } from "react";
import SelectedCountryHoverCard from "../../Containers/MirrorData/Component/SelectedCountryHoverCard/SelectedCountryHoverCard";
import SelectDropDown from "../SelectDropDownNewUI/SelectDropDown";
import { SEARCH_ALL_OPTION } from "../../enums/globalEnum";

const SearchComponent = ({
  handleSelectChange,
  handleChangeForSelectedDropdownValue,
  advanceSearchFieldOptions,
  handleInputChangeForSearchTerm,
  searchField,
  setSearchField,
  reactSelectLoading,
  getSearchOption,
  getSelectedOptionForSearchTerm,
  addButton,
  advanceSearchComponent,
  currentCountry,
  isBlFlag,
  currentTradeType,
  selectTrade,
  onSearchClick,
  isSearchValueEmpty,
  isExplore,
  workSpaceBtnClass,
  setDateRange,
  dateRange,
  isWorkSpace,
  mirrorData,
  handleBlurSelectedOptions,
  handleCountryModalOpen,
  tooltipData,
  handleMouseEnterOnSelectedCountry,
  handleMouseLeaveOnSelectedCountry,
  showClass,
  showSelectedCountryModal,
  handleClearCheckedFilterData,
  multiSelectFieldForPrimarySearch,
  setSelectedOptionForSearchTerm,
}) => {
  const [getUniqueOption, setUniqueOption] = useState([]);

  const handleAddUniqueId = (options) => {
    if (searchField?.getSelectExploreField === SEARCH_ALL_OPTION) {
      return options.map((group) => ({
        label: group.label,
        options: group.options.map((item, index) => {
          return {
            _id: item.value,
            key: `${item.value}-${
              group.label === "YOUR SEARCH" ? 0 : index + 1
            }`,
          };
        }),
      }));
    } else {
      return options.map((item, index) => ({
        _id: item?._id,
        key: `${item?._id}-${index}`,
      }));
    }
  };

  useEffect(() => {
    const data = handleAddUniqueId(getSearchOption);
    setUniqueOption(data);
  }, [getSearchOption]);

  const selectStyles = {
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? "transparent" : base.color,
    }),
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 42,
      whiteSpace: "nowrap",
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
    }),
    groupHeading: (base, state) => ({
      ...base,
      color: state.data.label === "YOUR SEARCH" ? "#005d91" : "#ffffff",
      backgroundColor:
        state.data.label === "YOUR SEARCH" ? "#005d9140" : "#005d91d1",
      fontSize: "13px",
      fontWeight: "bold",
      padding: "5px 12px",
      borderRadius: "12px",
      margin: "2px 7px",
      textTransform: "uppercase",
      display: "inline-block",
    }),
  };
  const defaultSearchOptions = [{ value: "EXACT", title: "EXACT" }];

  const checkChooseSearchTermDisable = () => {
    let disabled = false;
    if (mirrorData || currentCountry === "SILKROUTE") {
      disabled = false;
    } else {
      disabled = isExplore
        ? currentTradeType !== selectTrade?.tradeType ||
          currentCountry !== selectTrade?.country ||
          selectTrade?.dataType !== isBlFlag
          ? true
          : false
        : false;
    }
    return disabled;
  };

  const checkBtnISDisable = () => {
    let disabled = false;
    if (mirrorData || currentCountry === "SILKROUTE") {
      disabled = false;
    } else {
      disabled = isExplore
        ? currentTradeType !== selectTrade?.tradeType ||
          currentCountry !== selectTrade?.country ||
          selectTrade?.dataType !== isBlFlag
          ? true
          : false
        : false;
    }
    return disabled;
  };

  const getFirstOptionOnBlurForSearchAll = () => {
    const firstGroup =
      getUniqueOption?.length &&
      getUniqueOption?.find((group) => group.options.length > 0);
    const firstOption = firstGroup?.options?.[0];

    if (!firstOption) return null;

    return {
      _id: firstOption._id,
      key: firstOption.key,
      groupLabel: firstGroup.label,
    };
  };

  const handleBlur = () => {
    if (isExplore) {
      if (
        (searchField?.getSelectExploreField === SEARCH_ALL_OPTION &&
          getSelectedOptionForSearchTerm === null) ||
        (Array.isArray(getSelectedOptionForSearchTerm) &&
          getSelectedOptionForSearchTerm?.length === 0)
      ) {
        const firstOption = getFirstOptionOnBlurForSearchAll();
        if (firstOption) {
          setSelectedOptionForSearchTerm([firstOption]);
        }
      } else {
        handleBlurSelectedOptions();
      }
    }
  };
  return (
    <div id="search-container-explore">
      <div className="search-container">
        {isWorkSpace && (
          <div className="search-item">
            <label className="search-item-label" style={{ marginLeft: "0px" }}>
              DateRange
            </label>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
        )}
        <div className="search-item">
          <label
            className="search-item-label"
            style={{ marginLeft: "0px", marginBottom: "5px" }}
          >
            Search On
          </label>
          <SelectDropDown
            options={[
              ...(searchField?.searchOption?.length
                ? searchField?.searchOption?.map((item) => {
                    item.title = item.field?.replace(/_/g, " ");
                    item.value = item?.field;
                    return item;
                  })
                : []),
              ...(!mirrorData
                ? [
                    {
                      title: SEARCH_ALL_OPTION.replace(/_/g, " "),
                      value: SEARCH_ALL_OPTION,
                    },
                  ]
                : []),
            ]}
            value={
              Array.isArray(searchField?.getSelectExploreField)
                ? searchField?.getSelectExploreField[0]
                : searchField?.getSelectExploreField
            }
            onChange={handleSelectChange}
            name={"searchOn"}
            styleIndex={{ isEnabled: true, value: "11111" }}
          />

          {/* <FormControl
            sx={{
              m: 1,
              Width: "14vw",
              maxWidth: "14vw",
              minWidth: "14vw",
              marginLeft: "0px",
              marginTop: "5px",
            }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={searchField?.getSelectExploreField}
              onChange={handleSelectChange}
            >
              {searchField?.searchOption?.length > 0 &&
                searchField?.searchOption?.map((e, index) => (
                  <MenuItem
                    alias={e?.alias}
                    query={e?.query}
                    identifier={e?.identifier}
                    template={e?.template}
                    value={e?.field}
                    key={index}
                  >
                    {e?.field?.split("_").join(" ")}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
        </div>
        <div className="search-item">
          <label className="search-item-label">Choose Search Term</label>
          {/* <SelectSearch
            styles={selectStyles}
            isClearable={true}
            isLoading={reactSelectLoading}
            cacheOptions
            closeMenuOnSelect={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? false
                : true
            }
            defaultOptions={getSearchOption}
            value={getSelectedOptionForSearchTerm}
            getOptionLabel={(e) => e._id}
            getOptionValue={(e) => e._id}
            options={getSearchOption}
            onInputChange={(e, { action }) =>
              handleInputChangeForSearchTerm(e, action)
            }
            onChange={(e) =>
              handleChangeForSelectedDropdownValue(e, "search", "")
            }
            onBlur={handleBlur}
            isMulti={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              IsMultiSelectedField?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? true
                : false
            }
            className="search-form-control search-async"
            hideSelectedOptions={false}
            isDisabled={checkChooseSearchTermDisable()}
          /> */}
          <SelectSearch
            styles={selectStyles}
            isClearable={true}
            isLoading={reactSelectLoading}
            cacheOptions
            closeMenuOnSelect={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              multiSelectFieldForPrimarySearch?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? false
                : searchField?.getSelectExploreField === SEARCH_ALL_OPTION
                ? false
                : true
            }
            defaultOptions={getUniqueOption}
            value={getSelectedOptionForSearchTerm}
            getOptionLabel={(option) => option?._id || ""}
            getOptionValue={(option) => option?.key || ""}
            options={getUniqueOption}
            onInputChange={(e, { action }) =>
              handleInputChangeForSearchTerm(e, action)
            }
            onChange={(selectedOptions, event) => {
              if (searchField?.getSelectExploreField === SEARCH_ALL_OPTION) {
                const selectedArray = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];

                const selectedValues = selectedArray.map((option) => {
                  const parentLabel = getUniqueOption.find((group) =>
                    group.options.some((opt) => opt.key === option.key)
                  )?.label;

                  return {
                    _id: option?._id, // Keep `_id` as is for display
                    key: option?.key, // Keep `key` for uniqueness
                    groupLabel: parentLabel, // The parent group label
                  };
                });

                handleChangeForSelectedDropdownValue(selectedValues, event);
              } else {
                handleChangeForSelectedDropdownValue(
                  Array.isArray(selectedOptions)
                    ? selectedOptions?.map((option) => ({
                        _id: option?._id, // Keep `_id` as is for display
                        key: option?.key, // Keep `key` for uniqueness
                      }))
                    : [
                        {
                          _id: selectedOptions?._id,
                          key: selectedOptions?.key,
                        },
                      ]
                );
              }
            }}
            onBlur={handleBlur}
            isMulti={
              searchField?.getValuesForSelectDropdown?.query ===
                QUERYTYPE?.CHOOSE ||
              multiSelectFieldForPrimarySearch?.includes(
                searchField?.getValuesForSelectDropdown?.field
              ) ||
              (searchField?.getValuesForSelectDropdown?.field?.includes(
                "PROD"
              ) &&
                searchField?.getValuesForSelectDropdown?.field?.includes(
                  "DESC"
                ))
                ? true
                : searchField?.getSelectExploreField === SEARCH_ALL_OPTION
                ? true
                : false
            }
            className="search-form-control search-async"
            hideSelectedOptions={false}
            isDisabled={checkChooseSearchTermDisable()}
          />
          {isSearchValueEmpty.searchTerm &&
          (getSelectedOptionForSearchTerm === null ||
            (getSelectedOptionForSearchTerm &&
              getSelectedOptionForSearchTerm?.length === 0)) ? (
            <span
              style={{
                color: "#d32f2f",
                fontSize: "15px",
                letterSpacing: "1px",
                position: "relative",
                top: "0px",
                marginLeft: "10px",
              }}
            >
              This field is required
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="search-item">
          <label className="search-item-label" style={{ marginBottom: "5px" }}>
            Operator
          </label>
          <SelectDropDown
            options={
              searchField?.operatorOptions?.length > 0
                ? searchField?.operatorOptions?.length &&
                  searchField?.operatorOptions?.map((item) => {
                    item.title = item?.value;
                    item.value = item?.value;
                    return item;
                  })
                : defaultSearchOptions?.map((item) => {
                    item.title = item.value;
                    item.value = item.value;
                    return item;
                  })
            }
            value={
              searchField?.operatorOptions?.length > 0
                ? searchField?.selectedOperator
                : "EXACT"
            }
            onChange={(e) =>
              setSearchField({
                ...searchField,
                selectedOperator: e?.target?.value,
              })
            }
            name={"selectedOperator"}
            isDisabled={searchField?.operatorOptions?.length > 1 ? false : true}
            styleIndex={{ isEnabled: true, value: "11111" }}
          />
          {/* <FormControl
            sx={{ m: 1, minWidth: "8vw", marginTop: "5px" }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              size="small"
              value={
                searchField?.operatorOptions?.length > 0
                  ? searchField?.selectedOperator
                  : "EXACT"
              }
              disabled={
                searchField?.operatorOptions &&
                searchField?.operatorOptions?.length > 0
                  ? false
                  : true
              }
              onChange={(e) =>
                setSearchField({
                  ...searchField,
                  selectedOperator: e?.target?.value,
                })
              }
            >
              {searchField?.operatorOptions?.length > 0 &&
                searchField?.operatorOptions?.map((e, index) => (
                  <MenuItem value={e.value} key={index}>
                    {e.value}
                  </MenuItem>
                ))}
              {searchField?.operatorOptions?.length <= 0 &&
                defaultSearchOptions?.map((e, index) => (
                  <MenuItem value={e.value} key={index}>
                    {e.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
        </div>

        {/* MirrorData country selecter button and modal on hover  */}
        <div className="search-item tooltip-position-relative">
          {mirrorData && (
            <>
              <label className="search-item-label">Select Countries</label>
              <ButtonComponent
                style={{
                  marginTop: "5px",
                }}
                btnIcon={FilterAltOutlinedIcon}
                name="SELECT COUNTRY"
                btnClick={() => handleCountryModalOpen()}
                onMouseEnter={() => handleMouseEnterOnSelectedCountry()}
                onMouseLeave={() => handleMouseLeaveOnSelectedCountry()}
              />
            </>
          )}
          {showSelectedCountryModal && (
            <SelectedCountryHoverCard
              tooltipData={tooltipData}
              handleMouseEnterOnSelectedCountry={
                handleMouseEnterOnSelectedCountry
              }
              handleMouseLeaveOnSelectedCountry={
                handleMouseLeaveOnSelectedCountry
              }
              showClass={showClass}
            />
          )}
        </div>
        <div className={`search-item ${workSpaceBtnClass}`}>
          <label className="search-item-label">Actions</label>
          <div className="action-btn">
            {advanceSearchComponent?.length === 0 && (
              <>
                <ButtonComponent
                  name={"search"}
                  btnIcon={SearchIcon}
                  style={{ margin: "5px 8px 8px 8px " }}
                  btnClick={() => {
                    onSearchClick({}, false, false);
                    handleClearCheckedFilterData();
                  }}
                  disabled={checkBtnISDisable()}
                />
                {advanceSearchFieldOptions?.length > 0 && (
                  <ButtonComponent
                    name={""}
                    btnIcon={AddIcon}
                    style={{ margin: "5px 8px 8px 8px " }}
                    btnClick={addButton}
                    disabled={checkBtnISDisable()}
                  />
                )}
              </>
            )}
            <ButtonComponent
              name={""}
              btnIcon={RefreshIcon}
              style={{ margin: "5px 8px 8px 8px " }}
              btnClick={() => window.location.reload()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
