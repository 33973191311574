import React, { memo, useCallback, useEffect, useRef } from "react";
import StyledTreeItem from "./Component/StyledTreeItem/StyledTreeItem";
import {
  Box,
  Button,
  FormControlLabel,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import { FILTER_OPTIONS } from "../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BpCheckbox from "../CheckBox/BpCheckBox";
import SearchIcon from "@mui/icons-material/Search";
import "../../Component/SearchComponent/search.scss";
import SelectDropdown from "../SelectDropDown/selectDropdown";
import "./CollapseFilter.scss";
import Slider from "../RangeSlider/slider";
import millify from "millify";
import { useState } from "react";
import { BtnGreen, DeleteButton } from "../ButtonComponent/Button";
import {
  getMonthName,
  getMonthNumber,
  translateButtonClick,
} from "../../utils/CommanFunction";
import ViewMoreModal from "./Component/ViewMore/ViewMore";
import valueFilter from "../../utils/ValueFiltersOptions";
import ShipmentFilter from "../../utils/ShipmentFilterOptions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Remove_Order_Or_Null_Option } from "../../utils/DropDownOptions";
import ErrorLoading from "../../Component/ErrorLoader/error";
import { SearchRequireWarning } from "../../utils/LoadingMsg";
import { FixedSizeList } from "react-window";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import _ from "lodash";
import EximDottedLoader from "../EximDottedLoader/EximDottedLoader.js";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "9px",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#CED4DA",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "40px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#6C757D",
    border: "1.5px solid #CED4DA",
    textOverflow: "ellipsis",
    borderRadius: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "65ch",
      height: "20px",
    },
  },
}));

const FilterButton = styled(Button)({
  color: "black",
  backgroundColor: "#C2D8E5 !important",
  borderColor: "#C2D8E5 !important",
  padding: "6px 8px",
  height: "32px",
  fontSize: "12px",
  maxWidth: "100%",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#C2D8E5 !important",
    borderColor: "#C2D8E5 !important",
    boxShadow: "none",
  },
});

export const _headerTitle_countryAndPort = [
  "FILTER_COUNTRY",
  "FILTER_PORT",
  "FILTER_FOREIGN_PORT",
  "FILTER_INDIAN_PORT",
  "FILTER_STATE",
  "FILTER_PIN",
  "FILTER_DESTINATION_COUNTRY",
  "FILTER_DESTINATION_PORT",
  "FILTER_ORIGIN_COUNTRY",
  "FILTER_ORIGIN_PORT",
  "FILTER_REPORTING_COUNTRY",
];

export const _headerTitle_importAndExport = [
  "FILTER_IMPORTER",
  "FILTER_EXPORTER",
  "FILTER_STD_SUPPLIER_NAME",
];

const defaultOptions = {
  HS_CODE: false,
  FILTER_PIN: false,
  FILTER_COUNTRY: false,
  DATATYPE: false,
  FILTER_PORT: false,
  QUANTITY: false,
  PRICE: false,
  MONTH: false,
  FILTER_FOREIGN_PORT: false,
  FILTER_DESTINATION_COUNTRY: false,
  FILTER_DESTINATION_PORT: false,
  FILTER_ORIGIN_COUNTRY: false,
  FILTER_INDIAN_PORT: false,
  FILTER_ORIGIN_PORT: false,
  FILTER_REPORTING_COUNTRY: false,
  VALUE: false,
  SHIPMENT: false,
  FILTER_STATE: false,
  SEARCH_MODE: false,
  REMOVE_ORDER_OR_NULL: false,
  FILTER_IMPORTER: false,
  FILTER_EXPORTER: false,
  FILTER_STD_SUPPLIER_NAME: false,
};

const CollapseFilter = memo(
  ({
    isFiltersShown,
    collapseAllFilters,
    keyValuePairForTreeview,
    allFilterValuesWithCountsArr,
    headerTitle,
    isFromExplore,
    appliedFilterComp,
    filterOptionClick,
    getFilterValues,
    getAllFilterValues,
    setFilterValue,
    CheckedData,
    currentCountry,
    rangeValue,
    getMaxMinRange,
    setRangeValue,
    selectedQuantityOrPriceValue,
    setSelectedQuantityOrPrice,
    setMaxMinRange,
    lastSelected,
    setLastSelected,
    storeFilteredValues,
    setStoreFilterValues,
    handleApplyFilter,
    appliedFilterData,
    setPairForTreeView,
    isFilterLoader,
    isFromMarket,
    isMarketCompany,
    isMarketProduct,
    isGlobalSearch,
    mirrorData,
    currentTradeType,
    getSelectedOptionForSearchTerm,
    isFromWorkspace,
    importerExporterName,
    handleIncludeExclude,
    includeExclude,
    apply,
    RemoveAppliedFilter,
    isExplore,
  }) => {
    const keyArr = keyValuePairForTreeview[headerTitle];
    const [currentSelectedQuantity, setCurrentSelectedQuantity] = useState("");
    const [storeFilteredValuesChild, setStoreFilteredValuesChild] = useState(
      {}
    );
    const [searchCountryOrPort, setCountryOrPort] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showItems, setshowItems] = useState(10);
    const [errorMsg, setErrorMsg] = useState({});
    const [error, setError] = useState(false);
    const [isViewedFilter, setIsViewedFilter] = useState(defaultOptions);
    const [filterLoading, setFilterLoading] = useState(false);

    const typingTimeoutRef = useRef(null);
    const debounceTimeoutRef = useRef(null);

    const handleInput = () => {
      const inputValue = document.getElementById("fast-input").value;

      if (inputValue === "") {
        return;
      }

      clearTimeout(typingTimeoutRef.current);
      clearTimeout(debounceTimeoutRef.current);

      typingTimeoutRef.current = setTimeout(() => {
        setFilterLoading(true);
      }, 150);

      debounceTimeoutRef.current = setTimeout(() => {
        setCountryOrPort(inputValue);
        setFilterLoading(false);
      }, 450);
    };

    const UILoaderWrapper = () => (
      <div
        style={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EximDottedLoader />
      </div>
    );

    useEffect(() => {
      handleApplyFilter();
    }, [storeFilteredValues]);

    useEffect(() => {
      setStoreFilteredValuesChild(appliedFilterData);
    }, [appliedFilterData]);

    // const handleRemoveFilter = (index, value, key, subKey) => {
    //   setStoreFilteredValuesChild((prev) => {
    //     if (key === "PRICE") {
    //       const { [key]: _, ...remainingKeys } = prev;
    //       return remainingKeys;
    //     }

    //     if (key === "QUANTITY") {
    //       const updatedQuantity = Object.entries(prev[key])
    //         .filter(([subKey, subValue]) => subValue.value !== value)
    //         .reduce((acc, [subKey, subValue]) => {
    //           acc[subKey] = subValue;
    //           return acc;
    //         }, {});

    //       return {
    //         ...prev,
    //         [key]: updatedQuantity,
    //       };
    //     }

    //     return {
    //       ...prev,
    //       [key]: {
    //         ...prev[key],
    //         [value]: false,
    //       },
    //     };
    //   });
    // };

    const handleRemoveFilter = (filterName, isRemoveAllBtn, subKeyName) => {
      RemoveAppliedFilter(filterName, isRemoveAllBtn, subKeyName);
      setIsOpenModal(false);
    };

    useEffect(() => {
      if (!isOpenModal) {
        setIsViewedFilter(defaultOptions);
      }
    }, [isOpenModal]);

    const handleToggleFilter = (
      name,
      shouldCallViewMore = false,
      isModalCloseClick = false
    ) => {
      setCountryOrPort("");
      setIsViewedFilter((prevFilters) =>
        Object.keys(prevFilters).reduce((acc, key) => {
          acc[key] = key === name ? !prevFilters[key] : false;
          return acc;
        }, {})
      );

      if (shouldCallViewMore) {
        filterOptionClick(name);
        handleViewMore();
      }

      if (isModalCloseClick) {
        setStoreFilteredValuesChild(CheckedData);
      }
    };

    const renderAppliedFilterButton = (
      filterName,
      isRemoveAllBtn,
      subKeyName,
      subKeyIndex
    ) => {
      if (!isRemoveAllBtn) {
        return (
          <Grid
            item
            className="filter-btn"
            key={`${filterName}-${subKeyIndex}`}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "2px",
                backgroundColor: "#005d91",
                color: "white",
                padding: "5px",
                borderRadius: "8px",
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer !important",
                }}
              >
                <CancelIcon
                  sx={{ color: "white", fontSize: 20 }}
                  onClick={() =>
                    handleRemoveFilter(filterName, isRemoveAllBtn, subKeyName)
                  }
                />
              </Box>
              <Typography
                className="apply-filter-label"
                sx={{ textAlign: "center", fontSize: "12px" }}
              >
                {subKeyName}
              </Typography>
            </Box>
          </Grid>
        );
      } else if (isRemoveAllBtn) {
        return (
          <DeleteButton
            name={"Remove Filters"}
            style={{ fontSize: "18px" }}
            btnClick={() =>
              handleRemoveFilter(filterName, isRemoveAllBtn, subKeyName)
            }
            className="grayButton"
          />
        );
      }
    };

    // const renderAppliedFilterItems = useCallback(
    //   (headerTitle) => {
    //     return Object.keys(storeFilteredValuesChild)?.map(
    //       (filterName, index) => {
    //         if (
    //           storeFilteredValuesChild?.[filterName] &&
    //           filterName === headerTitle
    //         ) {
    //           const filterKeys = Object.entries(
    //             storeFilteredValuesChild?.[filterName]
    //           )
    //             .filter(([_, value]) => value)
    //             .map(([key]) => key);

    //           if (filterName === "QUANTITY") {
    //             return filterKeys?.map((subKey, subIndex) =>
    //               renderAppliedFilterButton(
    //                 subKey,
    //                 subIndex,
    //                 filterName,
    //                 subKey
    //               )
    //             );
    //           } else if (filterName === "PRICE") {
    //             return renderAppliedFilterButton(
    //               filterKeys?.[0],
    //               index,
    //               filterName,
    //               filterKeys?.[0]
    //             );
    //           } else {
    //             return filterKeys?.map((appliedFilterValue) =>
    //               renderAppliedFilterButton(
    //                 appliedFilterValue,
    //                 index,
    //                 filterName
    //               )
    //             );
    //           }
    //         }
    //         return null;
    //       }
    //     );
    //   },
    //   [storeFilteredValuesChild]
    // );

    const renderAppliedFilterItems = (headerTitle, isRemoveAllBtn = false) => {
      return Object.keys(CheckedData)?.map((filterName) => {
        if (
          apply?.[filterName] &&
          CheckedData?.[filterName] &&
          filterName === headerTitle
        ) {
          const filterKeys = Object.keys(CheckedData?.[filterName]);

          if (filterName === "QUANTITY" && !isRemoveAllBtn) {
            return filterKeys?.map((subKeyName, subKeyIndex) =>
              renderAppliedFilterButton(
                filterName,
                isRemoveAllBtn,
                subKeyName,
                subKeyIndex
              )
            );
          } else if (isRemoveAllBtn) {
            return renderAppliedFilterButton(filterName, isRemoveAllBtn, " ");
          }
        }
        return null;
      });
    };

    const handleApply = () => {
      let last = lastSelected;
      if (last && !last?.includes(currentSelectedQuantity)) {
        last.push(currentSelectedQuantity);
        setLastSelected(last);
      }

      setStoreFilterValues(storeFilteredValuesChild);
      if (storeFilteredValuesChild === storeFilteredValues) {
        handleApplyFilter();
      }
      setIsOpenModal(false);
    };

    const renderApplyBtnForViewMore = () => {
      return (
        <Box className="btn-parent" sx={{ display: "flex", gap: "12px" }}>
          {renderAppliedFilterItems(headerTitle, true)}
          <BtnGreen
            name={"Apply"}
            btnClick={handleApply}
            style={{ width: "135px", fontSize: "18px" }}
            className="btn-Apply-Filter"
          />
        </Box>
      );
    };
    const getChildValue = (data) => {
      let a = data?.find((e) => e.isChecked === false);
      return a ? false : true;
    };
    const getHacodeValue = (data) => {
      if (headerTitle === FILTER_OPTIONS?.MONTH) {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children?.length &&
              parent?.children?.forEach((e) => {
                if (e?.isChecked) {
                  let month = getMonthNumber(e?._id);
                  let str = `${parent?._id}-${month}`;
                  a[str] = e?.isChecked;
                }
              });
          });
        return a;
      } else if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children &&
              parent?.children?.length > 0 &&
              parent?.children?.forEach((child) => {
                child?.children &&
                  child?.children?.length > 0 &&
                  child?.children?.forEach((subChild) => {
                    if (subChild?.isChecked) {
                      a[subChild._id] = subChild?.isChecked;
                    }
                  });
              });
          });
        return a;
      } else {
        let a = {};
        data?.length > 0 &&
          data?.forEach((parent) => {
            parent?.children &&
              parent?.children?.length > 0 &&
              parent?.children?.forEach((child) => {
                if (child?.isChecked) {
                  a[child?._id] = child?.isChecked;
                }
              });
          });
        return a;
      }
    };

    const handleCheckedInput = (
      e,
      isChildCheckAll,
      isParentCheckAll,
      parentId,
      childId
    ) => {
      if (
        isFromWorkspace ||
        isFromMarket ||
        isMarketCompany ||
        isMarketProduct ||
        isGlobalSearch ||
        currentCountry === "SILKROUTE"
      ) {
        const keyArr = keyValuePairForTreeview[headerTitle];
        let arr =
          keyArr &&
          keyArr?.length > 0 &&
          keyArr?.map((ele) => {
            if (isParentCheckAll) {
              if (ele?._id === e?.target?.value) {
                ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    child.isChecked = !ele?.isChecked;
                    child?.children &&
                      child?.children?.length > 0 &&
                      child?.children?.map((subChild) => {
                        subChild.isChecked = !ele?.isChecked;
                        return subChild;
                      });
                    return child;
                  });
                ele.isChecked = !ele?.isChecked;

                return ele;
              } else {
                return ele;
              }
            } else if (isChildCheckAll) {
              if (ele?._id === parentId) {
                ele?.children &&
                  ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    if (child?._id === e?.target?.value) {
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          subChild.isChecked = !child?.isChecked;
                          return subChild;
                        });
                      child.isChecked = !child?.isChecked;
                      return child;
                    } else {
                      return child;
                    }
                  });
                ele.isChecked = getChildValue(ele?.children);
                return ele;
              } else {
                return ele;
              }
            } else {
              if (ele?._id === parentId) {
                ele?.children &&
                  ele?.children?.length > 0 &&
                  ele?.children?.map((child) => {
                    if (child?._id === childId) {
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          if (subChild?._id === e?.target?.value) {
                            subChild.isChecked = !subChild?.isChecked;
                            return subChild;
                          } else {
                            return subChild;
                          }
                        });
                      child.isChecked = getChildValue(child?.children);
                      return child;
                    } else {
                      return child;
                    }
                  });
                ele.isChecked = getChildValue(ele?.children) ? true : false;
                return ele;
              } else {
                return ele;
              }
            }
          });
        setPairForTreeView({
          ...keyValuePairForTreeview,
          [headerTitle]: arr,
        });
        let _storeFilteredValues = { ...storeFilteredValuesChild };
        _storeFilteredValues[headerTitle] = getHacodeValue(arr);
        setStoreFilteredValuesChild(_storeFilteredValues);
      } else {
        if (
          (getSelectedOptionForSearchTerm &&
            getSelectedOptionForSearchTerm.length > 0) ||
          getSelectedOptionForSearchTerm
        ) {
          const keyArr = keyValuePairForTreeview[headerTitle];
          let arr =
            keyArr &&
            keyArr?.length > 0 &&
            keyArr?.map((ele) => {
              if (isParentCheckAll) {
                if (ele?._id === e?.target?.value) {
                  ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      child.isChecked = !ele?.isChecked;
                      child?.children &&
                        child?.children?.length > 0 &&
                        child?.children?.map((subChild) => {
                          subChild.isChecked = !ele?.isChecked;
                          return subChild;
                        });
                      return child;
                    });
                  ele.isChecked = !ele?.isChecked;

                  return ele;
                } else {
                  return ele;
                }
              } else if (isChildCheckAll) {
                if (ele?._id === parentId) {
                  ele?.children &&
                    ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      if (child?._id === e?.target?.value) {
                        child?.children &&
                          child?.children?.length > 0 &&
                          child?.children?.map((subChild) => {
                            subChild.isChecked = !child?.isChecked;
                            return subChild;
                          });
                        child.isChecked = !child?.isChecked;
                        return child;
                      } else {
                        return child;
                      }
                    });
                  ele.isChecked = getChildValue(ele?.children);
                  return ele;
                } else {
                  return ele;
                }
              } else {
                if (ele?._id === parentId) {
                  ele?.children &&
                    ele?.children?.length > 0 &&
                    ele?.children?.map((child) => {
                      if (child?._id === childId) {
                        child?.children &&
                          child?.children?.length > 0 &&
                          child?.children?.map((subChild) => {
                            if (subChild?._id === e?.target?.value) {
                              subChild.isChecked = !subChild?.isChecked;
                              return subChild;
                            } else {
                              return subChild;
                            }
                          });
                        child.isChecked = getChildValue(child?.children);
                        return child;
                      } else {
                        return child;
                      }
                    });
                  ele.isChecked = getChildValue(ele?.children) ? true : false;
                  return ele;
                } else {
                  return ele;
                }
              }
            });
          setPairForTreeView({
            ...keyValuePairForTreeview,
            [headerTitle]: arr,
          });
          let _storeFilteredValues = { ...storeFilteredValuesChild };
          _storeFilteredValues[headerTitle] = getHacodeValue(arr);
          setStoreFilteredValuesChild(_storeFilteredValues);
        } else {
          setError(true);
          setErrorMsg(SearchRequireWarning);
        }
      }
    };

    const sortingHsCodeData = (data) => {
      data.sort((a, b) => {
        const idA = parseInt(a?.count);
        const idB = parseInt(b?.count);

        if (idA < idB) {
          return 1;
        }
        if (idA > idB) {
          return -1;
        }
        return 0;
      });
      return data;
    };
    const hsCodeOrMonthUI = (type) => {
      if (
        headerTitle === FILTER_OPTIONS?.HS_CODE ||
        headerTitle === FILTER_OPTIONS?.MONTH ||
        headerTitle === FILTER_OPTIONS?.DATATYPE
      ) {
        let sortingData = keyArr;
        if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
          sortingData = sortingHsCodeData(keyArr);
        }
        let data = sortingData;
        if (type !== "VIEW_MORE" && sortingData?.length > 0) {
          data = sortingData.slice(0, 10);
        }
        return (
          <Box
            sx={{
              minHeight: "247px !important",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {headerTitle === FILTER_OPTIONS?.HS_CODE &&
              (isExplore || mirrorData) && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      marginLeft: "8px",
                      marginBottom: "35px",
                    }}
                  >
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      How would you like to filter the results:
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="include-exclude-radio-btn-group"
                        defaultValue="include"
                        name="include-exclude-radio-btn-group"
                        onChange={(e) => handleIncludeExclude(e, headerTitle)}
                      >
                        <FormControlLabel
                          value="include"
                          control={<Radio />}
                          label="Include Selection"
                          checked={includeExclude?.[headerTitle]?.include}
                        />
                        <FormControlLabel
                          value="exclude"
                          control={<Radio />}
                          label="Exclude Selection"
                          checked={includeExclude?.[headerTitle]?.exclude}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </>
              )}
            <Box
              style={{
                maxHeight: type === "VIEW_MORE" ? "400px" : "",
                overflow: type === "VIEW_MORE" ? "overlay" : "",
              }}
            >
              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                style={{ marginLeft: "0px" }}
              >
                <Box
                  className={
                    type === "VIEW_MORE"
                      ? "list_container notranslate"
                      : "notranslate"
                  }
                >
                  {data !== undefined &&
                    data?.length > 0 &&
                    data?.map((e, index) => {
                      let parentData = e?.children;
                      if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
                        parentData = sortingHsCodeData(parentData);
                      }
                      if (type !== "VIEW_MORE") {
                        parentData = e?.children
                          ? e?.children.slice(0, 10)
                          : [];
                      }
                      return (
                        <>
                          <Box style={{ position: "relative" }} key={e?._id}>
                            {/* //************** First */}

                            <TreeItem
                              nodeId={e?._id}
                              label={
                                <FormControlLabel
                                  label={
                                    isFromMarket
                                      ? e?._id
                                      : `${e?._id} (${
                                          Object.keys(CheckedData).length > 0
                                            ? `${e?.subCount}/${e?.count}`
                                            : `${e?.count}`
                                        })`
                                  }
                                  control={
                                    <BpCheckbox
                                      checked={e?.isChecked}
                                      value={e?._id}
                                      onChange={(e) =>
                                        handleCheckedInput(e, false, true)
                                      }
                                      className="parent-hscode-month"
                                    />
                                  }
                                />
                              }
                              style={{ display: "block" }}
                            >
                              {/* {e?.children && e?.children?.length > 0 && treeItemData(e?.children)} */}
                              {/* //************** second */}

                              {parentData &&
                                parentData?.length > 0 &&
                                parentData?.map((ele) => {
                                  let childData = ele?.children;
                                  if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
                                    childData = sortingHsCodeData(childData);
                                  }
                                  if (type !== "VIEW_MORE") {
                                    childData = ele?.children
                                      ? ele?.children.slice(0, 10)
                                      : [];
                                  }
                                  return (
                                    <Box
                                      style={{ position: "relative" }}
                                      key={ele?._id}
                                    >
                                      <TreeItem
                                        nodeId={ele?._id}
                                        label={
                                          <FormControlLabel
                                            checked={ele?.isChecked}
                                            label={
                                              isFromMarket
                                                ? ele?._id
                                                : `${ele?._id} (${
                                                    Object.keys(CheckedData)
                                                      .length > 0
                                                      ? `${ele?.subCount}/${ele?.count}`
                                                      : `${ele?.count}`
                                                  })`
                                            }
                                            value={ele?._id}
                                            onChange={(val) =>
                                              handleCheckedInput(
                                                val,
                                                true,
                                                false,
                                                e?._id
                                              )
                                            }
                                            control={
                                              <BpCheckbox className="children-hscode-month" />
                                            }
                                          />
                                        }
                                        style={{ display: "block" }}
                                      >
                                        {childData &&
                                          childData?.length > 0 &&
                                          childData?.map(
                                            (child, childIndex) => {
                                              return (
                                                <Box
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                  key={child?._id}
                                                >
                                                  {/* //************** third */}

                                                  <TreeItem
                                                    style={{ display: "block" }}
                                                    nodeId={child?._id}
                                                    label={
                                                      <FormControlLabel
                                                        className={child?._id}
                                                        label={
                                                          isFromMarket
                                                            ? child?._id
                                                            : `${child?._id} (${
                                                                Object.keys(
                                                                  CheckedData
                                                                ).length > 0
                                                                  ? `${child?.subCount}/${child?.count}`
                                                                  : `${child?.count}`
                                                              })`
                                                        }
                                                        checked={
                                                          child?.isChecked
                                                        }
                                                        value={child?._id}
                                                        onChange={(val) =>
                                                          handleCheckedInput(
                                                            val,
                                                            false,
                                                            false,
                                                            e?._id,
                                                            ele?._id
                                                          )
                                                        }
                                                        control={<BpCheckbox />}
                                                      />
                                                    }
                                                  />
                                                  {!isFromMarket && (
                                                    <p
                                                      className="total-sum-parent"
                                                      style={{
                                                        position: "absolute",
                                                        top: "-8px",
                                                        right: "20px",
                                                      }}
                                                      s
                                                    >{`$${millify(
                                                      child?.totalSum
                                                    )}`}</p>
                                                  )}
                                                </Box>
                                              );
                                            }
                                          )}
                                        {type !== "VIEW_MORE" &&
                                          ele?.children?.length &&
                                          ele?.children?.length > 10 && (
                                            <p
                                              onClick={handleViewMore}
                                              className="btn-viewmore"
                                            >
                                              View More
                                            </p>
                                          )}
                                      </TreeItem>
                                      {!isFromMarket && (
                                        <p
                                          className="total-sum-parent"
                                          style={{
                                            position: "absolute",
                                            top: "-8px",
                                            right: "20px",
                                          }}
                                          s
                                        >{`$${millify(ele?.totalSum)}`}</p>
                                      )}
                                    </Box>
                                  );
                                })}
                              {type !== "VIEW_MORE" &&
                                e?.children?.length &&
                                e?.children?.length > 10 && (
                                  <p
                                    onClick={handleViewMore}
                                    className="btn-viewmore"
                                  >
                                    View More
                                  </p>
                                )}
                            </TreeItem>
                            {!isFromMarket && (
                              <p
                                className="total-sum-parent"
                                style={{
                                  position: "absolute",
                                  top: "-8px",
                                  right: "20px",
                                }}
                                s
                              >{`$${millify(e?.totalSum)}`}</p>
                            )}
                          </Box>
                        </>
                      );
                    })}
                </Box>
              </TreeView>
              {type !== "VIEW_MORE" &&
                keyArr?.length &&
                keyArr?.length > 10 && (
                  <p onClick={handleViewMore} className="btn-viewmore">
                    View More
                  </p>
                )}
            </Box>
          </Box>
        );
      }
    };

    const clearSearchField = () => {
      document.getElementById("fast-input").value = "";
      setCountryOrPort("");
      setFilterLoading(true);
      setFilterValue(getAllFilterValues);
      setTimeout(() => {
        setFilterLoading(false);
      }, 200);
    };
    const handleViewMore = () => {
      translateButtonClick();
      setIsOpenModal(true);
      setshowItems(-1);
    };

    useEffect(() => {
      if (
        _headerTitle_countryAndPort?.includes(headerTitle) ||
        _headerTitle_importAndExport?.includes(headerTitle)
      ) {
        let filterData =
          getAllFilterValues &&
          getAllFilterValues.length > 0 &&
          getAllFilterValues.filter((e) =>
            e?._id?.toLowerCase().includes(searchCountryOrPort?.toLowerCase())
          );
        setFilterValue(filterData);
      }
    }, [searchCountryOrPort]);

    const changeAndStoreFilterOption = (event, range) => {
      if (
        isFromWorkspace ||
        isFromMarket ||
        isMarketCompany ||
        isMarketProduct ||
        isGlobalSearch ||
        currentCountry === "SILKROUTE"
      ) {
        setCurrentSelectedQuantity(event?.toString());
        if (
          _headerTitle_countryAndPort.includes(headerTitle) ||
          _headerTitle_importAndExport.includes(headerTitle)
        ) {
          setStoreFilteredValuesChild({
            ...storeFilteredValuesChild,
            [headerTitle]: {
              ...storeFilteredValuesChild[headerTitle],
              [event.target.value.split(" ").join("_")]: event?.target?.checked,
            },
          });
        } else if (
          headerTitle === FILTER_OPTIONS?.QUANTITY ||
          headerTitle === FILTER_OPTIONS?.PRICE
        ) {
          let range = [];
          getFilterValues &&
            getFilterValues.length > 0 &&
            getFilterValues.map((e) => {
              if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
                if (e?._id === event) {
                  setRangeValue({
                    ...rangeValue,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                  range = [e.minRange, e.maxRange];
                  setMaxMinRange({
                    ...getMaxMinRange,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                }
              } else {
                if (e?.metaTag[0]?.currency === event) {
                  range = [e?.minRange, e?.maxRange];
                  setRangeValue({
                    ...rangeValue,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                  setMaxMinRange({
                    ...getMaxMinRange,
                    [headerTitle]: [e?.minRange, e?.maxRange],
                  });
                }
              }
            });
          setSelectedQuantityOrPrice({
            ...selectedQuantityOrPriceValue,
            [headerTitle]: event,
          });

          if (headerTitle === "QUANTITY") {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                // ...storeFilteredValuesChild[props.headerTitle],
                ...storeFilteredValuesChild[headerTitle],
                [event]: {
                  [event]: event,
                  value: event,
                  maxRange: range[1],
                  minRange: range[0],
                },
              },
            });
          } else {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                // ...storeFilteredValuesChild[props.headerTitle],
                [event]: event,
                value: event,
                maxRange: range[1],
                minRange: range[0],
              },
            });
          }

          setRangeValue({ ...rangeValue, [headerTitle]: [range[0], range[1]] });
        } else if (headerTitle === FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL) {
          setStoreFilteredValuesChild({
            ...storeFilteredValuesChild,
            [headerTitle]: {
              ...storeFilteredValuesChild[headerTitle],
              [event?.target?.value]: event?.target?.checked,
            },
          });
        } else {
          setStoreFilteredValuesChild({
            [headerTitle]: {
              [event?.target?.value]: true,
            },
          });
        }
      } else {
        if (
          (getSelectedOptionForSearchTerm &&
            getSelectedOptionForSearchTerm.length > 0) ||
          getSelectedOptionForSearchTerm
        ) {
          setCurrentSelectedQuantity(event?.toString());
          if (
            _headerTitle_countryAndPort.includes(headerTitle) ||
            _headerTitle_importAndExport.includes(headerTitle)
          ) {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                ...storeFilteredValuesChild[headerTitle],
                [event.target.value.split(" ").join("_")]:
                  event?.target?.checked,
              },
            });
          } else if (
            headerTitle === FILTER_OPTIONS?.QUANTITY ||
            headerTitle === FILTER_OPTIONS?.PRICE
          ) {
            let range = [];
            getFilterValues &&
              getFilterValues.length > 0 &&
              getFilterValues.map((e) => {
                if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
                  if (e?._id === event) {
                    setRangeValue({
                      ...rangeValue,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                    range = [e.minRange, e.maxRange];
                    setMaxMinRange({
                      ...getMaxMinRange,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                  }
                } else {
                  if (e?.metaTag[0]?.currency === event) {
                    range = [e?.minRange, e?.maxRange];
                    setRangeValue({
                      ...rangeValue,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                    setMaxMinRange({
                      ...getMaxMinRange,
                      [headerTitle]: [e?.minRange, e?.maxRange],
                    });
                  }
                }
              });
            setSelectedQuantityOrPrice({
              ...selectedQuantityOrPriceValue,
              [headerTitle]: event,
            });

            if (headerTitle === "QUANTITY") {
              setStoreFilteredValuesChild({
                ...storeFilteredValuesChild,
                [headerTitle]: {
                  // ...storeFilteredValuesChild[props.headerTitle],
                  ...storeFilteredValuesChild[headerTitle],
                  [event]: {
                    [event]: event,
                    value: event,
                    maxRange: range[1],
                    minRange: range[0],
                  },
                },
              });
            } else {
              setStoreFilteredValuesChild({
                ...storeFilteredValuesChild,
                [headerTitle]: {
                  // ...storeFilteredValuesChild[props.headerTitle],
                  [event]: event,
                  value: event,
                  maxRange: range[1],
                  minRange: range[0],
                },
              });
            }

            setRangeValue({
              ...rangeValue,
              [headerTitle]: [range[0], range[1]],
            });
          } else if (headerTitle === FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL) {
            setStoreFilteredValuesChild({
              ...storeFilteredValuesChild,
              [headerTitle]: {
                ...storeFilteredValuesChild[headerTitle],
                [event?.target?.value]: event?.target?.checked,
              },
            });
          } else {
            setStoreFilteredValuesChild({
              [headerTitle]: {
                [event?.target?.value]: true,
              },
            });
          }
        } else {
          setError(true);
          setErrorMsg(SearchRequireWarning);
        }
      }
    };

    const ImporterAndExporterUI = useCallback(
      (type) => {
        const searchTitle = {
          FILTER_IMPORTER: "Search Importer...",
          FILTER_EXPORTER: "Search Exporter...",
          FILTER_STD_SUPPLIER_NAME: "Search STD Supplier Name...",
        };

        let data = [];
        if (getFilterValues?.length > 0) {
          data = [...getFilterValues].sort(
            (item1, item2) => item2?.count - item1?.count
          );
        }

        if (allFilterValuesWithCountsArr[headerTitle]?.length > 0) {
          const _allFilterValuesWithCountsArr_headerTitle = [
            ...allFilterValuesWithCountsArr[headerTitle],
          ];
          const ids = _allFilterValuesWithCountsArr_headerTitle.map(
            (e) => e?._id
          );
          const checkedCountryOrPort = data.filter((e) => ids.includes(e?._id));
          data = data.filter((e) => !ids.includes(e?._id));
          data = [
            ...checkedCountryOrPort.sort(
              (item1, item2) => item2?.count - item1?.count
            ),
            ...data,
          ];
        }

        const renderRow = ({ index, style }) => {
          const e = data[index];
          if (!e || e._id === "NULL" || e._id === "") return null;

          const filterCountData = allFilterValuesWithCountsArr[
            headerTitle
          ]?.find(({ _id }) => _id === e?._id);
          const country = e._id.toUpperCase().split(" ").join("_");
          const isFilterCountData =
            Object.keys(CheckedData).length === 0
              ? e?.totalSum
              : filterCountData?.totalSum;

          return (
            <Box key={e._id} style={{ ...style, paddingRight: "20px" }}>
              <span>
                <FormControlLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "0px",
                    marginLeft: "0px",
                  }}
                  value={e._id?.toUpperCase()}
                  onChange={(e) => changeAndStoreFilterOption(e, [])}
                  checked={
                    storeFilteredValuesChild[headerTitle]?.[country] ?? false
                  }
                  control={<BpCheckbox />}
                  label={
                    <Typography
                      title={e._id.toUpperCase()}
                      style={{
                        maxWidth: `calc(100vw - 200px)`,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "black",
                      }}
                    >
                      {e._id.toUpperCase()}
                    </Typography>
                  }
                />
              </span>
              <span
                className="notranslate"
                style={{
                  minWidth: "70px",
                  fontSize: "11px !important",
                  textAlign: "right",
                  marginTop: "8px",
                  float: "right",
                }}
              >
                {isFromMarket
                  ? ""
                  : isFilterCountData === undefined
                  ? e?.totalSum !== undefined
                    ? "$0"
                    : ""
                  : `$${millify(e?.totalSum)}`}
              </span>
              {!isFromMarket && (
                <span
                  className="notranslate"
                  style={{
                    float: "right",
                    marginTop: "9px",
                    color: "#6c7585",
                    fontSize: "14px",
                  }}
                >
                  <span>{"("}</span>
                  {!filterCountData && Object.keys(CheckedData).length > 0 && (
                    <span>0/</span>
                  )}
                  {filterCountData && Object.keys(CheckedData).length > 0 && (
                    <span>{filterCountData.count}/</span>
                  )}
                  <span>{e.count}</span>
                  <span>{")"}</span>
                </span>
              )}
            </Box>
          );
        };

        if (_headerTitle_importAndExport?.includes(headerTitle)) {
          return (
            <Box>
              {getFilterValues !== undefined && (
                <>
                  <Box
                    id="searchComponentExplore"
                    sx={{ marginBottom: "25px" }}
                  >
                    {(isExplore || mirrorData) && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          marginLeft: "8px",
                        }}
                      >
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          sx={{ color: "black" }}
                        >
                          How would you like to filter the results:
                        </FormLabel>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="include-exclude-radio-btn-group"
                            defaultValue="include"
                            name="include-exclude-radio-btn-group"
                            onChange={(e) =>
                              handleIncludeExclude(e, headerTitle)
                            }
                          >
                            <FormControlLabel
                              value="include"
                              control={<Radio />}
                              label="Include Selection"
                              checked={includeExclude?.[headerTitle]?.include}
                            />
                            <FormControlLabel
                              value="exclude"
                              control={<Radio />}
                              label="Exclude Selection"
                              checked={includeExclude?.[headerTitle]?.exclude}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    <Search className="inline-container">
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        className="input-flex"
                        id="fast-input"
                        onInput={handleInput}
                        placeholder={
                          _headerTitle_importAndExport?.includes(headerTitle)
                            ? searchTitle[headerTitle]
                            : "Search..."
                        }
                        inputProps={{ "aria-label": "search" }}
                        sx={{ width: "100%" }}
                      />
                      <i
                        onClick={() => clearSearchField()}
                        className="clear-search-input"
                        style={{ position: "absolute", left: "97%" }}
                      >
                        &times;
                      </i>
                    </Search>
                  </Box>
                </>
              )}
              <Box style={{ marginTop: "10px" }}>
                {filterLoading
                  ? UILoaderWrapper()
                  : type === "VIEW_MORE" &&
                    data?.length > 0 && (
                      <FixedSizeList
                        height={400}
                        itemCount={data.length}
                        itemSize={40}
                        width="100%"
                      >
                        {renderRow}
                      </FixedSizeList>
                    )}
                {!filterLoading && data?.length === 0 && (
                  <Typography>
                    No {headerTitle?.replace(/_/g, " ")?.toLowerCase()} Filter
                    Available
                  </Typography>
                )}
              </Box>
            </Box>
          );
        }
      },
      [
        getFilterValues,
        allFilterValuesWithCountsArr,
        headerTitle,
        CheckedData,
        storeFilteredValuesChild,
        isFromMarket,
        includeExclude,
        searchCountryOrPort,
        setCountryOrPort,
        clearSearchField,
        handleIncludeExclude,
        renderApplyBtnForViewMore,
      ]
    );

    const countryOrPortUI = (type) => {
      const searchTitle = {
        FILTER_COUNTRY: "Search Country...",
        FILTER_PORT: "Search Ports...",
        FILTER_INDIAN_PORT: "Search Indian Ports...",
        FILTER_FOREIGN_PORT: "Search Foreign Ports...",
        FILTER_REPORTING_COUNTRY: "Search Reporting Country...",
        FILTER_ORIGIN_PORT: "Search Origin Ports...",
        FILTER_DESTINATION_PORT: "Search Destination Ports...",
        FILTER_DESTINATION_COUNTRY: "Search Destination Country...",
        FILTER_ORIGIN_COUNTRY: "Search Origin Country...",
        FILTER_STATE: "Search State...",
        FILTER_PIN: "Search PIN...",
        FILTER_IMPORTER: "Search Importer...",
        FILTER_EXPORTER: "Search Exporter...",
      };

      const prepareData = () => {
        if (getFilterValues?.length) {
          let sortedData = getFilterValues.sort((a, b) => b?.count - a?.count);

          return sortedData;
        }

        return [];
      };

      const adjustDataWithChecked = (data) => {
        if (allFilterValuesWithCountsArr?.[headerTitle]?.length) {
          let headerData = allFilterValuesWithCountsArr[headerTitle] || [];
          const checkedIds = headerData.map((item) => item?._id);

          let checkedItems = data.filter((item) =>
            checkedIds.includes(item?._id)
          );
          let remainingItems = data.filter(
            (item) => !checkedIds.includes(item?._id)
          );

          return [
            ...checkedItems.sort((a, b) => b?.count - a?.count),
            ...remainingItems,
          ];
        }

        return data;
      };

      const renderSearchComponent = () => (
        <Box>
          <Box
            id="searchComponentExplore"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "25px",
            }}
          >
            {(isExplore || mirrorData) && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <FormLabel sx={{ color: "black" }}>
                  How would you like to filter the results:
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="include"
                    name="include-exclude-radio-btn-group"
                    onChange={(e) => handleIncludeExclude(e, headerTitle)}
                  >
                    <FormControlLabel
                      value="include"
                      control={<Radio />}
                      label="Include Selection"
                      checked={includeExclude?.[headerTitle]?.include}
                    />
                    <FormControlLabel
                      value="exclude"
                      control={<Radio />}
                      label="Exclude Selection"
                      checked={includeExclude?.[headerTitle]?.exclude}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <div className="inline-container">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <div style={{ position: "relative" }}>
                <StyledInputBase
                  className="input-flex"
                  id="fast-input"
                  onInput={handleInput}
                  placeholder={
                    _headerTitle_countryAndPort.includes(headerTitle)
                      ? searchTitle[headerTitle]
                      : "Search..."
                  }
                  inputProps={{ "aria-label": "search" }}
                  sx={{ width: "100%" }}
                />
                <i
                  onClick={clearSearchField}
                  className="clear-search-input"
                  style={{ position: "absolute", left: "620px" }}
                >
                  &times;
                </i>
              </div>
            </div>
          </Box>
        </Box>
      );

      const renderDataItems = (data) =>
        data.map((item) => {
          if (item?._id === "NULL" || item?._id === "") return null;

          const filterCountData = allFilterValuesWithCountsArr?.[
            headerTitle
          ]?.find(({ _id }) => _id === item?._id);
          const countryKey = item._id.toUpperCase().replace(/\s+/g, "_");

          const isFilterCountData =
            Object.keys(CheckedData).length === 0
              ? item?.totalSum
              : filterCountData?.totalSum;

          return (
            <Box key={item?._id} sx={{ paddingRight: "15px" }}>
              <span>
                <FormControlLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "0px",
                    marginLeft: "0px",
                  }}
                  value={countryKey}
                  onChange={(e) => changeAndStoreFilterOption(e, [])}
                  checked={
                    storeFilteredValuesChild[headerTitle]?.[countryKey] || false
                  }
                  control={<BpCheckbox />}
                  label={
                    <Typography
                      title={item?._id.toUpperCase()}
                      style={{
                        maxWidth: "180px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "black",
                        fontWeight: "500",
                      }}
                    >
                      {item?._id.toUpperCase()}
                    </Typography>
                  }
                />
              </span>
              <span
                className="notranslate"
                style={{
                  minWidth: "70px",
                  fontSize: "11px",
                  textAlign: "right",
                  marginTop: "8px",
                  float: "right",
                }}
              >
                {isFromMarket
                  ? ""
                  : isFilterCountData
                  ? `$${millify(isFilterCountData)}`
                  : item?.totalSum !== undefined
                  ? "$0"
                  : ""}
              </span>
              {!isFromMarket && (
                <span
                  className="notranslate"
                  style={{
                    float: "right",
                    marginTop: "9px",
                    color: "#6c7585",
                    fontSize: "14px",
                  }}
                >
                  <span>{"("}</span>
                  {filterCountData && <span>{filterCountData.count}/</span>}
                  <span>{item.count}</span>
                  <span>{")"}</span>
                </span>
              )}
            </Box>
          );
        });

      const data = adjustDataWithChecked(prepareData());

      return (
        <Box>
          {renderSearchComponent()}
          {filterLoading ? (
            UILoaderWrapper()
          ) : (
            <Box className="list_container_country" sx={{ marginTop: "10px" }}>
              {data?.length && !filterLoading
                ? renderDataItems(data)
                : `No ${headerTitle
                    ?.replace(/_/g, " ")
                    .toLowerCase()} Available`}
            </Box>
          )}
        </Box>
      );
    };

    const handleChangeRange = (event) => {
      const _rangeValue = [];
      if (event?.minValue) {
        _rangeValue.push(event?.min, rangeValue[headerTitle][1]);
      } else if (event?.maxValue) {
        _rangeValue.push(rangeValue[headerTitle][0], event?.max);
      }

      // in function range params is not in use so
      // changeAndStoreFilterOption(arr[props.headerTitle].value, event.target.value);
      if (headerTitle === FILTER_OPTIONS?.QUANTITY) {
        setStoreFilteredValuesChild({
          ...storeFilteredValuesChild,
          [headerTitle]: {
            ...storeFilteredValuesChild[headerTitle],
            [currentSelectedQuantity]: {
              [currentSelectedQuantity]:
                storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                  ?.value,
              value:
                storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                  ?.value,
              maxRange: event?.minValue
                ? rangeValue[headerTitle][1]
                : event?.max,
              minRange: event?.maxValue
                ? rangeValue[headerTitle][0]
                : event?.min,
            },
          },
        });
      } else {
        setStoreFilteredValuesChild({
          ...storeFilteredValuesChild,
          [headerTitle]: {
            ...storeFilteredValuesChild[headerTitle],
            [storeFilteredValuesChild[headerTitle].value]:
              storeFilteredValuesChild[headerTitle]?.value,
            value: storeFilteredValuesChild[headerTitle]?.value,
            maxRange: event?.minValue ? rangeValue[headerTitle][1] : event?.max,
            minRange: event?.maxValue ? rangeValue[headerTitle][0] : event?.min,
          },
        });
      }

      setRangeValue({
        ...rangeValue,
        [headerTitle]: _rangeValue,
      });
    };
    const qauntityOrPriceUI = () => {
      if (
        headerTitle === FILTER_OPTIONS?.QUANTITY ||
        headerTitle === FILTER_OPTIONS?.PRICE
      ) {
        return (
          <Box
            sx={{
              minHeight: "247px !important",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                borderBottom: "1px solid #0000001a",
                width: "100%",
                paddingBottom: "12px",
                marginBottom: "10px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "15px",
                flexWrap: "wrap",
              }}
            >
              {headerTitle === FILTER_OPTIONS?.QUANTITY &&
                renderAppliedFilterItems(headerTitle, false)}
            </Box>
            <Box sx={{ pb: 5 }}>
              {getFilterValues && getFilterValues.length ? (
                <SelectDropdown
                  width="50%"
                  value={selectedQuantityOrPriceValue[headerTitle]}
                  setValue={(val) =>
                    changeAndStoreFilterOption(val?.target?.value, rangeValue)
                  }
                  option={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? getFilterValues
                      : headerTitle === FILTER_OPTIONS?.PRICE
                      ? getFilterValues &&
                        getFilterValues.map((e, index) => {
                          return {
                            currency: e?.metaTag[0]?.currency,
                            totalSum: e?.totalSum,
                          };
                        })
                      : ""
                  }
                  optionValue={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "_id"
                      : "currency"
                  }
                  text={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                  label={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                  intialOption={true}
                  intialOptionLable={
                    headerTitle === FILTER_OPTIONS?.QUANTITY
                      ? "Select Unit"
                      : "Select Currency"
                  }
                />
              ) : (
                ""
                // <SelectDropdown width="64%" value="None Selected" option={[{ _id: "None Selected" }]} optionValue="_id" text="QUANTITY" label="QUANTITY" />
              )}
              {headerTitle === FILTER_OPTIONS?.QUANTITY &&
              storeFilteredValuesChild[headerTitle] &&
              storeFilteredValuesChild[headerTitle][currentSelectedQuantity]
                ?.value ? (
                // sx={{ width: 300 }}
                <Box className="mt-25">
                  <hr className="hrStyles"></hr>
                  <Box style={{ color: "#6e777f", marginBottom: "10px" }}>
                    Select {headerTitle} Range{" "}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      id="outlined-number"
                      label="Min"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][0]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        const value = Math.min(
                          Number(event?.target?.value),
                          getMaxMinRange[headerTitle][1] - 1
                        );
                        handleChangeRange({
                          min: value,
                          max: rangeValue[headerTitle][1],
                          minValue: true,
                        });
                      }}
                    />
                    <TextField
                      id="outlined-number"
                      label="Max"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][1]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        if (
                          event?.target?.value <= getMaxMinRange[headerTitle][1]
                        ) {
                          let value = "";
                          if (
                            event?.target?.value === "0" ||
                            event?.target?.value === 0 ||
                            event?.target?.value === ""
                          ) {
                            value = 0;
                          } else {
                            value = Math.max(Number(event?.target?.value));
                          }
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: value,
                            maxValue: true,
                          });
                        } else {
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: getMaxMinRange[headerTitle][1],
                            maxValue: true,
                          });
                        }
                      }}
                      // max={5000}
                    />
                  </Box>
                  <Slider
                    min={getMaxMinRange[headerTitle][0]}
                    max={getMaxMinRange[headerTitle][1]}
                    handleChangeRange={handleChangeRange}
                    rangeValue={rangeValue[headerTitle]}
                  />
                </Box>
              ) : (
                ""
              )}
              {headerTitle === FILTER_OPTIONS?.PRICE &&
              storeFilteredValuesChild[headerTitle]?.value ? (
                // sx={{ width: 300 }}
                <Box className="mt-25">
                  <hr className="hrStyles"></hr>
                  <Box style={{ color: "#6e777f", marginBottom: "10px" }}>
                    Select {headerTitle} Range{" "}
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      id="outlined-number"
                      label="Min"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][0]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        const value = Math.min(
                          Number(event?.target?.value),
                          getMaxMinRange[headerTitle][1] - 1
                        );
                        handleChangeRange({
                          min: value,
                          max: rangeValue[headerTitle][1],
                          minValue: true,
                        });
                      }}
                    />
                    <TextField
                      id="outlined-number"
                      label="Max"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={rangeValue[headerTitle][1]}
                      style={{ width: "48%" }}
                      onChange={(event) => {
                        if (
                          event?.target?.value <= getMaxMinRange[headerTitle][1]
                        ) {
                          let value = "";
                          if (
                            event?.target?.value === "0" ||
                            event?.target?.value === 0 ||
                            event?.target?.value === ""
                          ) {
                            value = 0;
                          } else {
                            value = Math.max(Number(event?.target?.value));
                          }
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: value,
                            maxValue: true,
                          });
                        } else {
                          handleChangeRange({
                            min: rangeValue[headerTitle][0],
                            max: getMaxMinRange[headerTitle][1],
                            maxValue: true,
                          });
                        }
                      }}
                    />
                  </Box>
                  <Slider
                    min={getMaxMinRange[headerTitle][0]}
                    max={getMaxMinRange[headerTitle][1]}
                    handleChangeRange={handleChangeRange}
                    rangeValue={rangeValue[headerTitle]}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        );
      }
    };

    const handelSelectValueOrShipment = (event) => {
      setStoreFilteredValuesChild({
        ...storeFilteredValuesChild,
        [headerTitle]: {
          ...storeFilteredValuesChild[headerTitle],
          [event.target.value]: event?.target?.checked,
        },
      });
    };

    const valueOrShipmentUI = () => {
      let arr = [];
      if (headerTitle === "VALUE") {
        arr = valueFilter?.options;
      } else if (headerTitle === "SHIPMENT") {
        arr = ShipmentFilter?.options;
      }
      return (
        <Box style={{ marginTop: "10px" }}>
          <hr className="hrStyles"></hr>
          <Box>Select {headerTitle} Range </Box>
          <hr className="hrStylesBottom"></hr>
          {arr &&
            arr?.length > 0 &&
            arr?.slice(0, 10).map((e, index) => {
              return (
                <Box key={index}>
                  <span>
                    <FormControlLabel
                      style={{
                        marginTop: "2px",
                        marginRight: "0px",
                        marginLeft: "0px",
                      }}
                      value={e?.title !== undefined && e?.title.toUpperCase()}
                      onChange={(event) => handelSelectValueOrShipment(event)}
                      checked={
                        storeFilteredValuesChild[headerTitle]
                          ? storeFilteredValuesChild[headerTitle][e?.value]
                          : false
                      }
                      control={<BpCheckbox />}
                      label={
                        <Typography
                          title={e.value.toUpperCase()}
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {e.title.toUpperCase()}
                        </Typography>
                      }
                    />
                  </span>
                </Box>
              );
            })}
        </Box>
      );
    };

    const removeOrderAndNull = () => {
      return (
        <Box style={{ padding: "10px" }}>
          <Box>
            {Remove_Order_Or_Null_Option?.map((e, index) => {
              return (
                <Box key={index}>
                  <span>
                    <FormControlLabel
                      style={{
                        marginTop: "2px",
                        marginRight: "0px",
                        marginLeft: "0px",
                      }}
                      value={e?.value}
                      onChange={(e) => changeAndStoreFilterOption(e)}
                      checked={
                        storeFilteredValuesChild[headerTitle]
                          ? storeFilteredValuesChild[headerTitle][e?.value]
                          : false
                      }
                      control={<BpCheckbox />}
                      label={
                        <Typography
                          title={e.value.toUpperCase()}
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {e.title.toUpperCase()}
                        </Typography>
                      }
                    />
                  </span>
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    };

    const selectMode = () => {
      return (
        <Box style={{ padding: "10px" }}>
          <Box style={{ cursor: "pointer" }}>
            <select
              id="action-dropdown"
              style={{ padding: "10px", width: "100%" }}
              onChange={(e) => changeAndStoreFilterOption(e)}
            >
              <option value="" style={{ padding: "10px", fontSize: "1rem" }}>
                SELECT MODE
              </option>
              <option
                value="import"
                style={{ padding: "10px", fontSize: "1rem" }}
              >
                IMPORT
              </option>
              <option
                value="export"
                style={{ padding: "10px", fontSize: "1rem" }}
              >
                EXPORT
              </option>
            </select>
          </Box>
        </Box>
      );
    };

    const renderViewMoreModal = useCallback(() => {
      if (!isOpenModal) return null;

      return (
        <ViewMoreModal
          headerTitle={headerTitle}
          open={isOpenModal}
          setOpen={setIsOpenModal}
          hsCodeOrMonthUI={hsCodeOrMonthUI}
          countryOrPortUI={countryOrPortUI}
          setshowItems={setshowItems}
          ImporterAndExporterUI={ImporterAndExporterUI}
          selectMode={selectMode}
          valueOrShipmentUI={valueOrShipmentUI}
          qauntityOrPriceUI={qauntityOrPriceUI}
          removeOrderAndNull={removeOrderAndNull}
          handleToggleFilter={handleToggleFilter}
          renderApplyBtnForViewMore={renderApplyBtnForViewMore}
        />
      );
    }, [
      isOpenModal,
      headerTitle,
      setIsOpenModal,
      hsCodeOrMonthUI,
      countryOrPortUI,
      setshowItems,
      ImporterAndExporterUI,
      selectMode,
      valueOrShipmentUI,
      qauntityOrPriceUI,
      removeOrderAndNull,
      handleToggleFilter,
      setStoreFilteredValuesChild,
      CheckedData,
    ]);

    return (
      <div id="collapse-filter">
        {isFiltersShown && <Box>{appliedFilterComp}</Box>}
        {error && (
          <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
        )}
        <StyledTreeItem
          isFromExplore={isFromExplore}
          allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
          filterOptionClick={filterOptionClick}
          collapseAllFilters={collapseAllFilters}
          hsCodeOrMonthUI={hsCodeOrMonthUI}
          countryOrPortUI={countryOrPortUI}
          setCountryOrPort={setCountryOrPort}
          valueOrShipmentUI={valueOrShipmentUI}
          currentCountry={currentCountry}
          qauntityOrPriceUI={qauntityOrPriceUI}
          height={isFromExplore ? "" : "517px"}
          isFilterLoader={isFilterLoader}
          isMarketCompany={isMarketCompany}
          isMarketProduct={isMarketProduct}
          isGlobalSearch={isGlobalSearch}
          mirrorData={mirrorData}
          selectMode={selectMode}
          removeOrderAndNull={removeOrderAndNull}
          currentTradeType={currentTradeType}
          isFromWorkspace={isFromWorkspace}
          ImporterAndExporterUI={ImporterAndExporterUI}
          importerExporterName={importerExporterName}
          handleViewMore={handleViewMore}
          CheckedData={CheckedData}
          setIsViewedFilter={setIsViewedFilter}
          isViewedFilter={isViewedFilter}
          handleToggleFilter={handleToggleFilter}
          isOpenModal={isOpenModal}
        />
        {renderViewMoreModal()}
      </div>
    );
  }
);

export default CollapseFilter;
