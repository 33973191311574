// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ActivityTracker .title-date-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#ActivityTracker .title-date-div .activity-tracker-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
#ActivityTracker .title-date-div .activity-tracker-date-container:nth-of-type(3) {
  width: 10rem !important;
}
#ActivityTracker .title-date-div .activity-tracker-date-container .css-b62m3t-container {
  width: 20rem !important;
  z-index: 100 !important;
}
@media (min-width: 425px) and (max-width: 767px) {
  #ActivityTracker .title-date-div .activity-tracker-date-container .css-b62m3t-container {
    width: 10rem !important;
  }
}
@media (min-width: 768px) and (max-width: 970px) {
  #ActivityTracker .title-date-div .activity-tracker-date-container .css-b62m3t-container {
    width: 15rem !important;
  }
}
#ActivityTracker .title-date-div .activity-tracker-date-container .ant-space-item > .ant-picker {
  top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Containers/ActivityTracker/ActivityTracker.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAAN;AAEM;EACE,uBAAA;AAAR;AAEM;EACE,uBAAA;EAOA,uBAAA;AANR;AAAQ;EAFF;IAGI,uBAAA;EAGR;AACF;AAFQ;EALF;IAMI,uBAAA;EAKR;AACF;AADM;EACE,QAAA;AAGR","sourcesContent":["#ActivityTracker {\n  .title-date-div {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .activity-tracker-date-container {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      gap: 10px;\n\n      &:nth-of-type(3) {\n        width: 10rem !important;\n      }\n      .css-b62m3t-container {\n        width: 20rem !important;\n        @media (min-width: 425px) and (max-width: 767px) {\n          width: 10rem !important;\n        }\n        @media (min-width: 768px) and (max-width: 970px) {\n          width: 15rem !important;\n        }\n        z-index: 100 !important;\n      }\n\n      .ant-space-item > .ant-picker {\n        top: 0px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
