import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import AnalyzeWorkSpace from "./AnalyzeWorkSpace";
import ErrorPopup from "../../../../Component/ErrorLoader/ErrorPopup";
import { logger } from "../../../../Logger/Logger";

const AnalyzeWorkSpaceWithError = (props) => {
  function ErrorHandler({ error }) {
    logger(`AnalyzeWorkSpace ${error?.message}`);
    return (
      <div role="alert">
        {error && <ErrorPopup errorMsg={error.message} open={error} />}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <AnalyzeWorkSpace
          CLIENT_ACCOUNT_ID={props.CLIENT_ACCOUNT_ID}
          CLIENT_USER_ID={props.CLIENT_USER_ID}
        />
      </ErrorBoundary>
    </>
  );
};

export default AnalyzeWorkSpaceWithError;
