// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#exploreFilter {
  position: sticky;
  top: 0px;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/Containers/Workspace/Component/Shipement/shipment.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,QAAA;EACA,aAAA;AACF","sourcesContent":["#exploreFilter {\n  position: sticky;\n  top: 0px;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
