export const TradeOption = [
  { value: "IMPORT", title: "IMPORT" },
  { value: "EXPORT", title: "EXPORT" },
];
export const TradeOption_IMPORT = [{ value: "IMPORT", title: "IMPORT" }];
export const TradeOption_EXPORT = [{ value: "EXPORT", title: "EXPORT" }];

export const DataTypeOptions = [
  {
    value: "false",
    title: "Custom Country",
  },
  {
    value: "true",
    title: "BL Country",
  },
];
export const DataTypeOptions_custom = [
  {
    value: "false",
    title: "Custom Country",
  },
];
export const DataTypeOptions_bl = [
  {
    value: "true",
    title: "BL Country",
  },
];

export const MonthRangeOptions = [
  {
    title: "LAST 1 MONTH",
    value: 1,
  },
  {
    title: "LAST 3 MONTHS",
    value: 3,
  },
  {
    title: "LAST 6 MONTHS",
    value: 6,
  },
  {
    title: "LAST 1 YEAR",
    value: 12,
  },
  {
    title: "LAST YEAR",
    value: 11,
  },
  {
    title: "CUSTOM",
    value: 0,
  },
];

export const RelationOptions = [
  { value: "AND", title: "AND" },
  { value: "OR", title: "OR" },
  { value: "NOT", title: "NOT" },
];

export const selectCountry = [
  {
    value: "SELECT COUNTRY",
    title: "SELECT COUNTRY",
  },
];

export const CountryOptionsForMarket = [
  {
    value: "INDIA",
    title: "INDIA",
  },
];

export const valueDropdownArr = [
  {
    value: "DEFAULT ALL",
    title: "DEFAULT ALL",
  },
  {
    value: "GREATER THAN (0-1 M)",
    title: "GREATER THAN (0-1 M)",
  },
  {
    value: "GREATER THAN (1-5 M)",
    title: "GREATER THAN (1-5 M)",
  },
  {
    value: "GREATER THAN (5-10 M)",
    title: "GREATER THAN (5-10 M)",
  },
  {
    value: "GREATER THAN (10-50 M)",
    title: "GREATER THAN (10-50 M)",
  },
  {
    value: "GREATER THAN (50 M-1 B)",
    title: "GREATER THAN (50 M-1 B)",
  },
  {
    value: "GREATER THAN (0-10 B)",
    title: "GREATER THAN (0-10 B)",
  },
  {
    value: "GREATER THAN (10-50 B)",
    title: "GREATER THAN (10-50 B)",
  },
  {
    value: "GREATER THAN (50 B)",
    title: "GREATER THAN (50 B)",
  },
];

export const hsCodeOption = [
  {
    value: 2,
    title: "2 DIGIT",
  },
  {
    value: 4,
    title: "4 DIGIT",
  },
  {
    value: 8,
    title: "8 DIGIT",
  },
];
export const groupByOption = [
  {
    value: "SELECT",
    title: "SELECT",
  },
  {
    value: "COUNTRY",
    title: "COUNTRY",
  },
  {
    value: "PORT",
    title: "PORT",
  },
];

export const globalSearchSearchField = [
  {
    alias: "HS_CODE",
    field: "HS_CODE",
    identifier: "SEARCH_HS_CODE",
    query: "SEARCH",
    recordsAggregationFindPattern: "matchExpressions",
    searchType: [],
    template: "RECORDS",
  },
  {
    alias: "PRODUCT_DESCRIPTION",
    field: "PRODUCT_DESCRIPTION",
    identifier: "SEARCH_PRODUCT_DESCRIPTION",
    query: "SEARCH",
    recordsAggregationFindPattern: "matchExpressions",
    searchType: [
      { type: "EXACT", desc: " Matches exact words in sequence and order" },
      { type: "OR", desc: " Matches one or more words in any order" },
      { type: "AND", desc: " Matches all words in any order" },
      {
        type: "CONTAIN",
        desc: " Matches all character occurences in any order",
      },
      {
        type: "NOT CONTAIN",
        desc: " Not Matches all character occurences in any order",
      },
    ],
    template: "RECORDS",
  },
];

export const Remove_Order_Or_Null_Option = [
  { title: "Order", value: "TO_ORDER" },
  { title: "Null", value: "TO_NULL" },
];
