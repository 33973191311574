// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-user {
  margin: 0px;
}

.user-mt-5 {
  margin: 10px 0px !important;
}

#edit-body {
  display: flex;
  flex-direction: column;
}

#select-unselect-btn {
  background-color: #f1f5f7;
  width: 50%;
  text-align: center;
  color: #323a46;
  padding: 0.28rem 0.8rem;
  cursor: pointer;
}
#select-unselect-btn:hover {
  background-color: #d1dee4;
}

#manage-users .manage-users-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}
#manage-users .manage-users-header .manageuser-limit-btn {
  display: flex;
  align-items: center;
}
#manage-users .manage-users-header .manageuser-limit-btn .manageuser-limit {
  margin-right: 10px;
}

@media (max-width: 770px) {
  #manage-users .manage-users-header {
    display: block;
    align-items: center;
    margin: 20px 0px;
  }
  #manage-users .manage-users-header .manageuser-limit-btn {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  #manage-users .manage-users-header .manageuser-limit-btn .manageuser-limit {
    margin: 10px 0px;
  }
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(25, 118, 210, 0.4784313725) !important;
}`, "",{"version":3,"sources":["webpack://./src/Containers/User/user.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,eAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAIE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;AADN;AAGM;EACE,kBAAA;AADR;;AAOA;EAEI;IACE,cAAA;IACA,mBAAA;IACA,gBAAA;EALJ;EAOI;IACE,cAAA;IACA,sBAAA;IACA,mBAAA;IACA,8BAAA;EALN;EAOM;IACE,gBAAA;EALR;AACF;AAWA;EACE,6DAAA;AATF","sourcesContent":[".text-user {\n  margin: 0px;\n}\n\n.user-mt-5 {\n  margin: 10px 0px !important;\n}\n\n#edit-body {\n  display: flex;\n  flex-direction: column;\n}\n\n#select-unselect-btn {\n  background-color: #f1f5f7;\n  width: 50%;\n  text-align: center;\n  color: #323a46;\n  padding: 0.28rem 0.8rem;\n  cursor: pointer;\n\n  &:hover {\n    background-color: #d1dee4;\n  }\n}\n\n#manage-users {\n  .manage-users-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px 10px;\n\n    .manageuser-limit-btn {\n      display: flex;\n      align-items: center;\n\n      .manageuser-limit {\n        margin-right: 10px;\n      }\n    }\n  }\n}\n\n@media (max-width: 770px) {\n  #manage-users {\n    .manage-users-header {\n      display: block;\n      align-items: center;\n      margin: 20px 0px;\n\n      .manageuser-limit-btn {\n        display: block;\n        flex-direction: column;\n        align-items: center;\n        justify-content: space-between;\n\n        .manageuser-limit {\n          margin: 10px 0px;\n        }\n      }\n    }\n  }\n}\n\n.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {\n  background-color: #1976d27a !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
