import React, { memo, useEffect, useState } from "react";
import StickyTable from "./Table";
import AntdTable from "../StickyTable/Table";
import "./CombineTable.scss";
import { userBrowser } from "../../Helpers/useBrowser";
import IosTable from "./IosTable";
import GridHandler from "./AgGridComponent/GridHandler";

const CombineTable = memo(
  ({
    handleCollapseOpenModal,
    rowsPerPage,
    totalAvailableData,
    page,
    descendingAndAscending,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDescendingAndAscendingOrder,
    getSelectedRow,
    handleSelectedShipments,
    columns,
    rows,
    isWorkSpace,
    isExplore,
    isGlobalSearch,
    hashMarkColumns,
    availableCountry,
    handleOpenModel,
    currentCountry,
    countryDetails,
    showPagination,
    isRequestedBuyer,
    mirrorData,
    isRequestedData,
    setLoading,
    loading,
    allRecord,
    getSelectedOptionForSearchTerm,
    searchField,
  }) => {
    const [renderTableForviewColumn, setRenderTableForviewColumn] = useState(0);
    useEffect(() => {
      setRenderTableForviewColumn(columns);
    }, [columns]);
    return (
      <>
        {/* userBrowser() === "Safari" && isExplore */}
        {false ? (
          <>
            <div
              className="ios_table_one"
              style={{
                position: isExplore ? "sticky" : "",
                top: isExplore ? "80px" : "",
                zIndex: isExplore ? "1000" : "",
              }}
            >
              <IosTable
                key={renderTableForviewColumn}
                columns={columns}
                handleCollapseOpenModal={handleCollapseOpenModal}
                rows={rows}
                rowsPerPage={rowsPerPage}
                showPagination={false}
                totalAvailableData={totalAvailableData || 0}
                page={page}
                descendingAndAscending={descendingAndAscending}
                isExplore={isExplore}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDescendingAndAscendingOrder={
                  handleDescendingAndAscendingOrder
                }
                getSelectedRow={getSelectedRow}
                handleSelectedShipments={handleSelectedShipments}
                tableScroll={false}
                isWorkSpace={isWorkSpace}
                isGlobalSearch={isGlobalSearch}
                hashMarkColumns={hashMarkColumns}
                availableCountry={availableCountry}
                handleOpenModel={handleOpenModel}
                currentCountry={currentCountry}
                countryDetails={countryDetails}
                isRequestedBuyer={isRequestedBuyer}
                isRequestedData={isRequestedData}
              />
            </div>
            <div className="ios_table_two">
              <IosTable
                key={renderTableForviewColumn}
                columns={columns}
                handleCollapseOpenModal={handleCollapseOpenModal}
                rows={rows}
                rowsPerPage={rowsPerPage}
                showPagination={showPagination}
                totalAvailableData={totalAvailableData || 0}
                page={page}
                descendingAndAscending={descendingAndAscending}
                isExplore={isExplore}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDescendingAndAscendingOrder={
                  handleDescendingAndAscendingOrder
                }
                getSelectedRow={getSelectedRow}
                handleSelectedShipments={handleSelectedShipments}
                tableScroll={true}
                isWorkSpace={isWorkSpace}
                isGlobalSearch={isGlobalSearch}
                hashMarkColumns={hashMarkColumns}
                availableCountry={availableCountry}
                handleOpenModel={handleOpenModel}
                currentCountry={currentCountry}
                countryDetails={countryDetails}
                isRequestedBuyer={isRequestedBuyer}
                isRequestedData={isRequestedData}
              />
            </div>
          </>
        ) : (
          <>
            {(isExplore || isWorkSpace) && (
              <div
                className={"combine-tab-1"}
                style={{
                  position: isExplore || isWorkSpace ? "sticky" : "",
                  top: isExplore ? "80px" : isWorkSpace ? "0px" : "",
                  zIndex: isExplore || isWorkSpace ? "1000" : "",
                  userSelect: "none",
                }}
              >
                {/* <StickyTable
                  key={renderTableForviewColumn}
                  columns={columns}
                  handleCollapseOpenModal={handleCollapseOpenModal}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                  showPagination={false}
                  totalAvailableData={totalAvailableData || 0}
                  page={page}
                  descendingAndAscending={descendingAndAscending}
                  isExplore={isExplore}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDescendingAndAscendingOrder={
                    handleDescendingAndAscendingOrder
                  }
                  getSelectedRow={getSelectedRow}
                  handleSelectedShipments={handleSelectedShipments}
                  tableScroll={false}
                  isWorkSpace={isWorkSpace}
                  isGlobalSearch={isGlobalSearch}
                  hashMarkColumns={hashMarkColumns}
                  availableCountry={availableCountry}
                  handleOpenModel={handleOpenModel}
                  currentCountry={currentCountry}
                  countryDetails={countryDetails}
                  isRequestedBuyer={isRequestedBuyer}
                  mirrorData={mirrorData}
                  isRequestedData={isRequestedData}
                  setLoading={setLoading}
                  loading={loading}
                /> */}
              </div>
            )}
            {/* <div className={isExplore || isWorkSpace ? "combine-tab-2" : ""}>
              <StickyTable
                key={renderTableForviewColumn}
                columns={columns}
                handleCollapseOpenModal={handleCollapseOpenModal}
                rows={rows}
                rowsPerPage={rowsPerPage}
                showPagination={showPagination}
                totalAvailableData={totalAvailableData || 0}
                page={page}
                descendingAndAscending={descendingAndAscending}
                isExplore={isExplore}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDescendingAndAscendingOrder={
                  handleDescendingAndAscendingOrder
                }
                getSelectedRow={getSelectedRow}
                handleSelectedShipments={handleSelectedShipments}
                tableScroll={true}
                isWorkSpace={isWorkSpace}
                isGlobalSearch={isGlobalSearch}
                hashMarkColumns={hashMarkColumns}
                availableCountry={availableCountry}
                handleOpenModel={handleOpenModel}
                currentCountry={currentCountry}
                countryDetails={countryDetails}
                isRequestedBuyer={isRequestedBuyer}
                mirrorData={mirrorData}
                isRequestedData={isRequestedData}
                setLoading={setLoading}
                loading={loading}
              />
            </div> */}
            <GridHandler
              columns={columns}
              rows={rows}
              showPagination={showPagination}
              handleCollapseOpenModal={handleCollapseOpenModal}
              getSelectedRow={getSelectedRow}
              handleSelectedShipments={handleSelectedShipments}
              descendingAndAscending={descendingAndAscending}
              handleDescendingAndAscendingOrder={
                handleDescendingAndAscendingOrder
              }
              isWorkSpace={isWorkSpace}
              getSelectedOptionForSearchTerm={getSelectedOptionForSearchTerm}
              searchField={searchField}
            />
          </>
        )}
      </>
    );
  }
);

export default CombineTable;
