// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
  position: relative;
  background-color: #fff;
}
.select ::-webkit-scrollbar {
  position: absolute;
  width: 10px !important;
  height: 5px !important;
}
.select select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.9rem + 2px);
  padding: 0.45rem 1.9rem 0.45rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat right 0.75rem center/8px 10px;
}
.select select:focus {
  border-color: #b1bbc4;
  outline: 0;
}
.select select option {
  font-size: 16px;
}
.select .select-border-redius {
  border-radius: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/Component/SelectDropDown/selectDropdown.scss"],"names":[],"mappings":"AAAA;EAOE,kBAAA;EACA,sBAAA;AALF;AAFE;EAEE,kBAAA;EACA,sBAAA;EACA,sBAAA;AAGJ;AAEE;EACE,qBAAA;EACA,WAAA;EACA,kCAAA;EACA,sCAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,yBAAA;EACA,sBAAA;EAGA,gBAAA;EACA,2FAAA;AAAJ;AAGI;EACE,qBAAA;EACA,UAAA;AADN;AAII;EACE,eAAA;AAFN;AAME;EACE,6BAAA;AAJJ","sourcesContent":[".select {\n  ::-webkit-scrollbar {\n    // display: block;\n    position: absolute;\n    width: 10px !important;\n    height: 5px !important;\n  }\n  position: relative;\n  background-color: #fff;\n\n  select {\n    display: inline-block;\n    width: 100%;\n    height: calc(1.5em + 0.9rem + 2px);\n    padding: 0.45rem 1.9rem 0.45rem 0.9rem;\n    font-size: 0.875rem;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #495057;\n    vertical-align: middle;\n    background-color: #fff;\n    border: 1px solid #ced4da;\n    border-radius: 0.25rem;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e\")\n      no-repeat right 0.75rem center/8px 10px;\n\n    &:focus {\n      border-color: #b1bbc4;\n      outline: 0;\n    }\n\n    option {\n      font-size: 16px;\n    }\n  }\n\n  .select-border-redius {\n    border-radius: 6px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
