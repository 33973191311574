// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#chart-Table .chart-table-header {
  background-color: #c2d8e5;
  color: #005d91;
}
#chart-Table .chart-table-header-1 {
  background-color: #c2d8e5;
  color: #005d91;
}`, "",{"version":3,"sources":["webpack://./src/Component/AnalyzeFilter/Component/ChartBoxTable/ChartBoxTable.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,cAAA;AAAJ;AAEE;EACE,yBAAA;EACA,cAAA;AAAJ","sourcesContent":["#chart-Table {\n  .chart-table-header {\n    background-color: #c2d8e5;\n    color: #005d91;\n  }\n  .chart-table-header-1 {\n    background-color: #c2d8e5;\n    color: #005d91;\n  }\n  //   .chart-table-header::before {\n  //     content: \"\";\n  //     position: absolute;\n  //     background-color: #ffffff;\n  //     width: 3px;\n  //     height: 35px;\n  //     margin: -3px -18px;\n  //     border-radius: 50px;\n  //   }\n  //   .chart-row::before {\n  //     content: \"\";\n  //     position: absolute;\n  //     background-color: #7a7a7a;\n  //     width: 2px;\n  //     height: 25px;\n  //     margin: 0px -18px;\n  //   }\n}\n// ::-webkit-scrollbar {\n//   position: absolute;\n//   width: 5px !important;\n//   height: 5px !important;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
