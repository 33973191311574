import React, { useEffect, useRef, useState } from "react";
import "./styledTreeItem.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FILTER_OPTIONS } from "../../../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import Badge from "@mui/material/Badge";
import { Box, Tooltip } from "@mui/material";
import { styled } from "@mui/styles";

export default function StyledTreeItem({
  currentCountry,
  hsCodeOrMonthUI,
  countryOrPortUI,
  qauntityOrPriceUI,
  filterOptionClick,
  setCountryOrPort,
  isFromExplore,
  collapseAllFilters,
  allFilterValuesWithCountsArr,
  height,
  isFilterLoader,
  isMarketCompany,
  isMarketProduct,
  valueOrShipmentUI,
  isGlobalSearch,
  selectMode,
  mirrorData,
  removeOrderAndNull,
  currentTradeType,
  isFromWorkspace,
  ImporterAndExporterUI,
  importerExporterName,
  handleViewMore,
  CheckedData,
  setIsViewedFilter,
  isViewedFilter,
  handleToggleFilter,
  isOpenModal,
}) {
  const tooltipRef = useRef(null);
  const [[x, y], setTooltipOffset] = useState([0, 0]);

  const handleMouseEnter = () => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const dynamicOffset = [rect.x, -10];
      setTooltipOffset(dynamicOffset);
    }
  };

  const CustomStyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#005D91",
      color: "#FFFFFF",
      fontSize: "16px",
      textAlign: "center",
      borderRadius: "5px",
      padding: "5px 8px",
      whiteSpace: "pre-line",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#005D91",
    },
  }));

  useEffect(() => {
    setIsViewedFilter({
      HS_CODE: false,
      FILTER_PIN: false,
      FILTER_COUNTRY: false,
      DATATYPE: false,
      FILTER_PORT: false,
      QUANTITY: false,
      PRICE: false,
      MONTH: false,
      FILTER_FOREIGN_PORT: false,
      FILTER_DESTINATION_COUNTRY: false,
      FILTER_DESTINATION_PORT: false,
      FILTER_ORIGIN_COUNTRY: false,
      FILTER_INDIAN_PORT: false,
      FILTER_ORIGIN_PORT: false,
      FILTER_REPORTING_COUNTRY: false,
      VALUE: false,
      SHIPMENT: false,
      FILTER_STATE: false,
      SEARCH_MODE: false,
      REMOVE_ORDER_OR_NULL: false,
      FILTER_IMPORTER: false,
      FILTER_EXPORTER: false,
    });
  }, [collapseAllFilters]);

  const appliedFilterCountUi = (filterName) => {
    const keyNames = CheckedData?.[filterName]
      ? Object.keys(CheckedData?.[filterName])
      : [];

    if (!keyNames.length) return null;

    return (
      <Badge
        badgeContent={filterName === "PRICE" ? 1 : keyNames.length}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: "#005d91",
            color: "white",
            borderRadius: "50%",
            minWidth: 26,
            height: 26,
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
    );
  };

  const appliedFilterCountTooltipValue = (filterName) => {
    const keyNames = CheckedData?.[filterName]
      ? Object.keys(CheckedData?.[filterName])
      : [];

    if (!keyNames.length) return "";

    return (
      <Box
        className="tooltip-container"
        sx={{ maxHeight: "200px !important", overflow: "auto !important" }}
      >
        {keyNames?.map((item) => item.replace(/_/g, " ")).join(",\n")}
      </Box>
    );
  };

  return (
    <>
      <div
        id="collapse-tree"
        style={{ height: isFromExplore ? height : "560px" }}
      >
        <div
          className="collapse-tree"
          style={!isFromExplore ? { paddingLeft: "0px" } : {}}
        >
          {!isFilterLoader ? (
            <>
              {allFilterValuesWithCountsArr?.FILTER_HS_CODE && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("HS_CODE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(FILTER_OPTIONS?.HS_CODE, true)
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">HS CODE</span>
                          <span>{appliedFilterCountUi("HS_CODE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.HS_CODE ? (
                          <KeyboardArrowLeftIcon
                            style={{ pointerEvents: "none" }}
                          />
                        ) : (
                          <KeyboardArrowRightIcon
                            style={{ pointerEvents: "none" }}
                          />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {/* {isViewedFilter.HS_CODE && hsCodeOrMonthUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_COUNTRY && (
                <>
                  <CustomStyledTooltip
                    title={appliedFilterCountTooltipValue("FILTER_COUNTRY")}
                    placement="top"
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [x, y],
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      className="collapse__item"
                      ref={tooltipRef}
                      style={{ position: "relative" }}
                      onMouseEnter={handleMouseEnter}
                    >
                      <label
                        htmlFor="collapse-1"
                        className="collapse-header"
                        onClick={(e) =>
                          handleToggleFilter(
                            FILTER_OPTIONS?.FILTER_COUNTRY,
                            true
                          )
                        }
                      >
                        <div className="applied-bage">
                          <h4 className="collapse__h6">
                            <span className="ellipsisName">COUNTRY</span>
                            <span>
                              {appliedFilterCountUi("FILTER_COUNTRY")}
                            </span>
                          </h4>
                          {isOpenModal && isViewedFilter.FILTER_COUNTRY ? (
                            <KeyboardArrowLeftIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </label>
                      <div style={{ padding: "10px 10px 10px 20px" }}>
                        {" "}
                        {/* {isViewedFilter.FILTER_COUNTRY && countryOrPortUI()} */}
                      </div>
                    </div>
                  </CustomStyledTooltip>
                </>
              )}
              {allFilterValuesWithCountsArr?.FILTER_IMPORTER && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_IMPORTER")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_IMPORTER,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">
                            {" "}
                            {importerExporterName?.importer}
                          </span>
                          <span>{appliedFilterCountUi("FILTER_IMPORTER")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_IMPORTER ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_IMPORTER && ImporterAndExporterUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_EXPORTER && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_EXPORTER")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_EXPORTER,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">
                            {importerExporterName?.exporter}
                          </span>
                          <span>{appliedFilterCountUi("FILTER_EXPORTER")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_EXPORTER ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_EXPORTER && ImporterAndExporterUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_DESTINATION_COUNTRY && (
                <div className="collapse__item">
                  <label
                    htmlFor="collapse-1"
                    className="collapse-header"
                    onClick={(e) =>
                      handleToggleFilter(
                        FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY,
                        true
                      )
                    }
                  >
                    <div className="applied-bage">
                      <h4 className="collapse__h6">
                        <span className="ellipsisName">
                          DESTINATION COUNTRY
                        </span>
                        <span>
                          {appliedFilterCountUi("FILTER_DESTINATION_COUNTRY")}
                        </span>
                      </h4>
                      {isOpenModal &&
                      isViewedFilter.FILTER_DESTINATION_COUNTRY ? (
                        <KeyboardArrowLeftIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </div>
                  </label>
                  <div style={{ padding: "10px 10px 10px 20px" }}>
                    {" "}
                    {/* {isViewedFilter.FILTER_DESTINATION_COUNTRY &&
                      countryOrPortUI()} */}
                  </div>
                </div>
              )}
              {allFilterValuesWithCountsArr?.FILTER_DESTINATION_PORT && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue(
                    "FILTER_DESTINATION_PORT"
                  )}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_DESTINATION_PORT,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">DESTINATION PORT</span>
                          <span>
                            {appliedFilterCountUi("FILTER_DESTINATION_PORT")}
                          </span>
                        </h4>
                        {isOpenModal &&
                        isViewedFilter.FILTER_DESTINATION_PORT ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_DESTINATION_PORT &&
                      countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_ORIGIN_COUNTRY && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue(
                    "FILTER_ORIGIN_COUNTRY"
                  )}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">ORIGIN COUNTRY</span>
                          <span>
                            {appliedFilterCountUi("FILTER_ORIGIN_COUNTRY")}
                          </span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_ORIGIN_COUNTRY ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_ORIGIN_COUNTRY && countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_ORIGIN_PORT && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_ORIGIN_PORT")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_ORIGIN_PORT,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">ORIGIN PORT</span>
                          <span>
                            {appliedFilterCountUi("FILTER_ORIGIN_PORT")}
                          </span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_ORIGIN_PORT ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_ORIGIN_PORT && countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_REPORTING_COUNTRY && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue(
                    "FILTER_REPORTING_COUNTRY"
                  )}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">
                            REPORTING COUNTRY
                          </span>
                          <span>
                            {appliedFilterCountUi("FILTER_REPORTING_COUNTRY")}
                          </span>
                        </h4>
                        {isOpenModal &&
                        isViewedFilter.FILTER_REPORTING_COUNTRY ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_REPORTING_COUNTRY &&
                      countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_STATE && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_STATE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(FILTER_OPTIONS?.FILTER_STATE, true)
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">STATE</span>
                          <span>{appliedFilterCountUi("FILTER_STATE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_STATE ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {/* {isViewedFilter.FILTER_STATE && countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_PORT && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_PORT")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(FILTER_OPTIONS?.FILTER_PORT, true)
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">PORTS</span>
                          <span>{appliedFilterCountUi("FILTER_PORT")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_PORT ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_PORT && countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_STD_SUPPLIER_NAME && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue(
                    "FILTER_STD_SUPPLIER_NAME"
                  )}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(
                          FILTER_OPTIONS?.FILTER_STD_SUPPLIER_NAME,
                          true
                        )
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">
                            STD SUPPLIER NAME
                          </span>
                          <span>
                            {appliedFilterCountUi("FILTER_STD_SUPPLIER_NAME")}
                          </span>
                        </h4>
                        {isOpenModal &&
                        isViewedFilter.FILTER_STD_SUPPLIER_NAME ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.FILTER_IMPORTER && ImporterAndExporterUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {currentCountry === "INDIA" &&
                allFilterValuesWithCountsArr?.FILTER_FOREIGN_PORT && (
                  <CustomStyledTooltip
                    title={appliedFilterCountTooltipValue(
                      "FILTER_FOREIGN_PORT"
                    )}
                    placement="top"
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [x, y],
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      className="collapse__item"
                      ref={tooltipRef}
                      style={{ position: "relative" }}
                      onMouseEnter={handleMouseEnter}
                    >
                      <label
                        htmlFor="collapse-1"
                        className="collapse-header"
                        onClick={(e) =>
                          handleToggleFilter(
                            FILTER_OPTIONS?.FILTER_FOREIGN_PORT,
                            true
                          )
                        }
                      >
                        <div className="applied-bage">
                          <h4 className="collapse__h6">
                            <span className="ellipsisName">FOREIGN PORT</span>
                            <span>
                              {appliedFilterCountUi("FILTER_FOREIGN_PORT")}
                            </span>
                          </h4>
                          {isOpenModal && isViewedFilter.FILTER_FOREIGN_PORT ? (
                            <KeyboardArrowLeftIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </label>
                      <div style={{ padding: "10px 10px 10px 20px" }}>
                        {" "}
                        {/* {isViewedFilter.FILTER_FOREIGN_PORT && countryOrPortUI()} */}
                      </div>
                    </div>
                  </CustomStyledTooltip>
                )}
              {currentCountry === "INDIA" &&
                allFilterValuesWithCountsArr?.FILTER_INDIAN_PORT && (
                  <CustomStyledTooltip
                    title={appliedFilterCountTooltipValue("FILTER_INDIAN_PORT")}
                    placement="top"
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [x, y],
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      className="collapse__item"
                      ref={tooltipRef}
                      style={{ position: "relative" }}
                      onMouseEnter={handleMouseEnter}
                    >
                      <label
                        htmlFor="collapse-1"
                        className="collapse-header"
                        onClick={(e) =>
                          handleToggleFilter(
                            FILTER_OPTIONS?.FILTER_INDIAN_PORT,
                            true
                          )
                        }
                      >
                        <div className="applied-bage">
                          <h4 className="collapse__h6">
                            <span className="ellipsisName">INDIAN PORT</span>
                            <span>
                              {appliedFilterCountUi("FILTER_INDIAN_PORT")}
                            </span>
                          </h4>
                          {isOpenModal && isViewedFilter.FILTER_INDIAN_PORT ? (
                            <KeyboardArrowLeftIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </label>
                      <div style={{ padding: "10px 10px 10px 20px" }}>
                        {" "}
                        {/* {isViewedFilter.FILTER_INDIAN_PORT && countryOrPortUI()} */}
                      </div>
                    </div>
                  </CustomStyledTooltip>
                )}
              {allFilterValuesWithCountsArr?.FILTER_MONTH && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("MONTH")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(FILTER_OPTIONS?.MONTH, true)
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">MONTH</span>
                          <span>{appliedFilterCountUi("MONTH")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.MONTH ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.MONTH && hsCodeOrMonthUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_UNIT_QUANTITY && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("QUANTITY")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("QUANTITY", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">QUANTITY</span>
                          <span>{appliedFilterCountUi("QUANTITY")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.QUANTITY ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 30px" }}>
                      {" "}
                      {/* {isViewedFilter.QUANTITY && qauntityOrPriceUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {(allFilterValuesWithCountsArr?.FILTER_DUTY ||
                allFilterValuesWithCountsArr?.FILTER_CURRENCY_PRICE_USD ||
                allFilterValuesWithCountsArr?.FILTER_CURRENCY_PRICE_INR) && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("PRICE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("PRICE", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">PRICE</span>
                          <span>{appliedFilterCountUi("PRICE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.PRICE ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 30px" }}>
                      {" "}
                      {/* {isViewedFilter.PRICE && qauntityOrPriceUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {(isMarketCompany || isMarketProduct) && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("VALUE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("VALUE", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">VALUE</span>
                          <span>{appliedFilterCountUi("VALUE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.VALUE ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.VALUE && valueOrShipmentUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {(isMarketCompany || isMarketProduct) && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("SHIPMENT")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("SHIPMENT", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">SHIPMENT</span>
                          <span>{appliedFilterCountUi("SHIPMENT")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.SHIPMENT ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {" "}
                      {/* {isViewedFilter.SHIPMENT && valueOrShipmentUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {allFilterValuesWithCountsArr?.FILTER_PIN && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("FILTER_PIN")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) =>
                        handleToggleFilter(FILTER_OPTIONS?.FILTER_PIN, true)
                      }
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">PIN</span>
                          <span>{appliedFilterCountUi("FILTER_PIN")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.FILTER_PIN ? (
                          <KeyboardArrowLeftIcon
                            style={{ pointerEvents: "none" }}
                          />
                        ) : (
                          <KeyboardArrowRightIcon
                            style={{ pointerEvents: "none" }}
                          />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {/* {isViewedFilter.FILTER_PIN && countryOrPortUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {isGlobalSearch && allFilterValuesWithCountsArr?.DATATYPE && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("DATATYPE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("DATATYPE", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">DATATYPE</span>
                          <span>{appliedFilterCountUi("DATATYPE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.DATATYPE ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {/* {isViewedFilter.DATATYPE && hsCodeOrMonthUI()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {mirrorData && (
                <CustomStyledTooltip
                  title={appliedFilterCountTooltipValue("SEARCH_MODE")}
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [x, y],
                        },
                      },
                    ],
                  }}
                >
                  <div
                    className="collapse__item"
                    ref={tooltipRef}
                    style={{ position: "relative" }}
                    onMouseEnter={handleMouseEnter}
                  >
                    <label
                      htmlFor="collapse-1"
                      className="collapse-header"
                      onClick={(e) => handleToggleFilter("SEARCH_MODE", true)}
                    >
                      <div className="applied-bage">
                        <h4 className="collapse__h6">
                          <span className="ellipsisName">SELECT MODE</span>
                          <span>{appliedFilterCountUi("SEARCH_MODE")}</span>
                        </h4>
                        {isOpenModal && isViewedFilter.SEARCH_MODE ? (
                          <KeyboardArrowLeftIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </div>
                    </label>
                    <div style={{ padding: "10px 10px 10px 20px" }}>
                      {/* {isViewedFilter?.SEARCH_MODE && selectMode()} */}
                    </div>
                  </div>
                </CustomStyledTooltip>
              )}
              {(isFromExplore || isFromWorkspace) &&
                currentTradeType === "EXPORT" &&
                currentCountry === "INDIA" && (
                  <CustomStyledTooltip
                    title={appliedFilterCountTooltipValue(
                      "REMOVE_ORDER_OR_NULL"
                    )}
                    placement="top"
                    arrow
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [x, y],
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      className="collapse__item"
                      ref={tooltipRef}
                      style={{ position: "relative" }}
                      onMouseEnter={handleMouseEnter}
                    >
                      <label
                        htmlFor="collapse-1"
                        className="collapse-header"
                        onClick={(e) =>
                          handleToggleFilter("REMOVE_ORDER_OR_NULL", true)
                        }
                      >
                        <div className="applied-bage">
                          <h4 className="collapse__h6">
                            <span className="ellipsisName">
                              REMOVE ORDER OR NULL
                            </span>
                            <span>
                              {appliedFilterCountUi("REMOVE_ORDER_OR_NULL")}
                            </span>
                          </h4>
                          {isOpenModal &&
                          isViewedFilter.REMOVE_ORDER_OR_NULL ? (
                            <KeyboardArrowLeftIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </label>
                      <div style={{ padding: "10px 10px 10px 20px" }}>
                        {/* {isViewedFilter?.REMOVE_ORDER_OR_NULL &&
                        removeOrderAndNull()} */}
                      </div>
                    </div>
                  </CustomStyledTooltip>
                )}
            </>
          ) : (
            <div className="spinner-filter">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
