import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import FavoriteShipment from "./shipment";
import ErrorPopup from "../../Component/ErrorLoader/ErrorPopup";
import { logger } from "../../Logger/Logger";

const ShipmentWithError = () => {
  function ErrorHandler({ error }) {
    logger(`Favorite Company ${error?.message}`);
    return (
      <div role="alert">
        {error && (
          <ErrorPopup
            errorMsg={"Something Went Wrong , Please Login Again"}
            open={error}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <FavoriteShipment />
      </ErrorBoundary>
    </>
  );
};

export default ShipmentWithError;
